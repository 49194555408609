import styled from "styled-components";

export const StyledMenuWrapper = styled.div`
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    > button {
        margin-top: 12px;
        color: #fff;
        width: 280px;
        height: 50px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
    }
    @media only screen and (min-width: 1530px) {
        > h1 {
            font-size: 58px;
        }
        > button {
            width: 360px;
            height: 60px;
            margin-top: 18px;
            font-size: 24px;
        }
    }
    @media only screen and (max-width: 1182px) {
        > h1 {
            font-size: 38px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding-top: 200px;
        width: 100%;
        > h1 {
            font-size: 54px;
        }
    }
    @media only screen and (max-width: 700px) {
        > h1 {
            font-size: 38px;
        }
        > button {
            font-size: 16px;
            width: 220px;
        }
    }
    @media only screen and (max-width: 585px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        width: 100%;
        padding-bottom: 40px;
        > h1 {
            width: 100%;
        }
    }
    @media only screen and (max-width: 380px) {
        > h1 {
            font-size: 32px;
        }
    }
`;
