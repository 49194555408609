import styled from "styled-components"

export const StyledUsersListInputs = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    > div{
        display: flex;
        align-items: center;
        > input {
            width: 95%;
            padding: 8px 12px;
            font-family: "Poppins";
        }
        &:nth-child(1){
            width: 3%;
            cursor: default;
            > p {
                cursor: default;
            }
        }
        &:nth-child(2){
            width: 25%;
        }
        &:nth-child(3){
            width: 7%;
        }
        &:nth-child(4){
            width: 13%;
        }
        &:nth-child(5){
            width: 12%;
        }
        &:nth-child(6){
            width: 8%;
        }
        &:nth-child(7){
            width: 7%;
        }
        &:nth-child(8){
            width: 7%;
        }
        &:nth-child(9){
            width: 18%;
        }
    }
`