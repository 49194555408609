import React from 'react'

import { StyledSettlementsDoneDataElement } from "../../atoms/SettlementsDoneDataElement/StyledSettlementsDoneDataElement";
import { StyledCheckboxDone } from "../../atoms/SettlementsDoneElement/StyledCheckboxDone";
import { StyledDoneDataElementWrapperData } from "../../atoms/SettlementsDoneDataElement/StyledDoneDataElementWrapperData";
import { StyledDataElement } from "../../atoms/SettlementsDoneDataElement/StyledDataElement";
import { StyledText } from "../../atoms/Text/StyledText";

import { ReactComponent as CheckboxAccept } from "../../../images/checkboxAccept.svg";

import { getLang, getString } from '../../../strings';
import { getDateString } from '../../../logic/eventtime.js';

const SettlementsDoneDataElement = ({
    hour: {
        id,
        subject,
        date,
        hours,
        half,
        trainer: {
            firstname,
            lastname
        },
        price,
        paid
    },
    parentIsSelecting = false
}) => {
    return (
        <StyledSettlementsDoneDataElement>
            {
                (parentIsSelecting) ? (
                    <StyledCheckboxDone hasdeclaredsize="30px">
                        <input type="checkbox" id={`pay-${id}`} />
                        <label htmlFor={`pay-${id}`}>
                            <CheckboxAccept />
                        </label>
                    </StyledCheckboxDone>
                ) : (undefined)
            }
            <StyledDoneDataElementWrapperData checkboxes={parentIsSelecting}>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                    >
                        {getString(`subject__${subject}`)}
                    </StyledText>
                </StyledDataElement>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                    >
                        {getDateString(new Date(date))}
                    </StyledText>
                </StyledDataElement>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                    >
                        {
                            getLang() === "pl" ? (
                                `${half ? "Pół" : hours} godzin${
                                    (hours === 1) ? ('a') : (
                                        (
                                            (half) || (
                                                // kończy się na 2, 3, 4
                                                [2, 3, 4].includes(hours % 10) &&
                                                // wykluczamy 12, 13, 14
                                                (hours < 10 || hours > 20)
                                            )
                                        ) ? ('y') : ('')
                                    )
                                }`
                            ) : (
                                (half) ? ("0.5 an hour") : (`${hours} hour${hours > 1 ? 's' : ''}`)
                            )
                        }
                    </StyledText>
                </StyledDataElement>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                    >
                        {firstname.substring(0, 1)}. {lastname}
                    </StyledText>
                </StyledDataElement>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                    >
                        {price} PLN
                    </StyledText>
                </StyledDataElement>
                <StyledDataElement>
                    <StyledText
                        hasdeclaredfontsize="13px"
                        hasdeclaredfontweight="600"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor={(paid) ? ("#6AB95D") : ("#E18872")}
                    >
                        {getString(`SettlementsDoneDataElement__payment_status__${paid ? '' : "un"}paid`)}
                    </StyledText>
                </StyledDataElement>
            </StyledDoneDataElementWrapperData>
        </StyledSettlementsDoneDataElement>
    )
}

export default SettlementsDoneDataElement
