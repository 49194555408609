import styled from "styled-components";

export const StyledLoginLabel = styled.label`
    width: 32px;
    height: 32px;
    position: absolute;
    top: 35%;
    left: 18px;
    z-index: 1;

    > svg {
        width: 100%;
        height: 100%;
        -o-object-fit: fill;
        object-fit: fill;
    }

    @media only screen and (max-width: 440px) {
        width: 26px;
        height: 26px;
        top: 29%;
        left: 21px;
    }
`;
