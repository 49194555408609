import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledUserPanelTrainerPay } from "../../atoms/UserPanelTrainerPay/StyledUserPanelTrainerPay";

import { getString } from '../../../strings';
import { getDateString } from "../../../logic/eventtime";

const downloadNewsletter = () => {
	const link = `/api/admin/newsletter`;
	const date = getDateString(new Date()).split('.').reverse().join('-');

	const a = document.createElement('a');
	a.href = link;
	a.download = `newsletter_${date}.csv`;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

const UserPanelUsersNewsletter = () => (
	<StyledUserPanelTrainerPay>
		<StyledButton
			hasdeclaredfontweight="bold"
			hasdeclaredfontsize="18px"
			hasdeclaredtextalign="center"
			hasdeclaredbgcolor="#4E6BAA"
			hasdeclaredfontcolor="#fff"
			hasdeclaredtexttransform="uppercase"
			hasdeclaredborderradius="25px"
			hasdeclaredcursor="pointer"
			hasdeclaredpadding="10px 15px 10px 15px"
			onClick={downloadNewsletter}
		>
			{getString("UserPanelUsersNewsletter__tha_button")}
		</StyledButton>
	</StyledUserPanelTrainerPay>
);

export default UserPanelUsersNewsletter;