import styled from "styled-components"

export const StyledDataSum = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
    > div {
        display: flex;
        gap: 5px;
    }
`