import React from "react";

import { StyledCube444 } from "../../atoms/Competitions/StyledCube444";
import { StyledCubeMinx } from "../../atoms/Competitions/StyledCubeMinx";
import { StyledCubeSkewb } from "../../atoms/Competitions/StyledCubeSkewb";
import { StyledCubeSq1 } from "../../atoms/Competitions/StyledCubeSq1";
import { StyledCubePyram } from "../../atoms/Competitions/StyledCubePyram";

import { StyledTournamentCubes } from "../../atoms/Competitions/StyledTournamentCubes";

const CompetitionsCubes = () => {
    return (
        <StyledTournamentCubes>
            <StyledCube444 iscontactpage={true} />
            <StyledCubeMinx iscontactpage={true} />
            <StyledCubeSq1 />
            <StyledCubePyram />
        </StyledTournamentCubes>
    );
};

export default CompetitionsCubes;
