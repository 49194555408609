const axios = require("axios");

const { getLang } = require("../../strings");

const backendRequest = (method = "GET", path, body = null, content_type = null) => (
	new Promise(
		(resolve, reject) => (
			axios({
				method,
				url: path,
				data: body,
				headers: {
					"Accept-Language": getLang(),
					...(content_type ? { "Content-Type": content_type } : {})
				}
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response))
		)
	)
);

export default backendRequest;
