import styled from "styled-components"

export const StyledAboutUsElement = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-bottom: 40px;
    
    @media only screen and (max-width: 400px){
        h2 {
            font-size: 30px;
        }
    }
`