import styled from 'styled-components'

export const StyledCountdownButton = styled.button`
    width: 240px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: ${({ isDisabled }) =>
        isDisabled ? '#c4c4c4' : '#005DF9'};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 18px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    border-radius: 10px;
`
