import styled from "styled-components";

export const StyledNameWrapper = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1100px){
        > p{
            font-size: 15px
        }
    }

`;
