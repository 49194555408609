import styled from "styled-components"

export const StyledOpenElement = styled.div`
    width: 70px;
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background: #dddddd;
    }
`