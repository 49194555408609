import styled from "styled-components"

export const StyledFAQElement = styled.button`
    width: 100%;
    min-height: 50px;
    margin-top: 20px;
    border-radius: 25px;
    background-color: #602B26;
    cursor: pointer;
    border: none;
    
    &:hover {
        > div {
            &:first-child{
                background-color: #602B26;
            }
        }
    }
`