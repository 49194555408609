import React from "react";

import Loader from "../Loader/Loader";
import EmployeesAllElement from "../EmployeesAllElement/EmployeesAllElement";

import { StyledUserPanelEmployees } from "./UserPanelEmployees.styles";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledGlobalScrollWrapper } from "../../atoms/GlobalScrollWrapper/StyledGlobalScrollWrapper";
import { StyledButtonWrapper } from "../../atoms/UserPanelTrainerPay/StyledButtonWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledGlobalHeadingWrapper } from "../../atoms/GlobalHeadingWrapper/StyledGlobalHeadingWrapper";

import { getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";
import useMonthlyPaginate from "../../../logic/hooks/useMonthlyPaginate";
import { listHoursRecords } from "../../../logic/requests/employees";
import { getLastMonths } from "../../../utils/time";

const initialLoadMonths = getLastMonths(2);
const defaultOpenMonth = initialLoadMonths[1];

const UserPanelEmployees = () => {
    const { scrollElement, isScrollBar } = useScrollBar();
    const {
        queryData,
        isError,
        isLoading,
        isSuccess,
        hasMore,
        loadMore,
    } = useMonthlyPaginate(
        ["hoursRecords", "all"],
        listHoursRecords,
        initialLoadMonths[0],
        initialLoadMonths
    );

    return (
        <StyledUserPanelEmployees>
            {
                (isError) ? (
                    <StyledText
                        hasdeclaredpadding="0px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                        >
                            {getString("User_panel_employess_blad_wczytywania")}
                    </StyledText>
                ) : (isLoading && !isSuccess) ? (
                    <StyledUserPanelStudentsLoaderWrapper>
                        <Loader />
                    </StyledUserPanelStudentsLoaderWrapper>
                ) : (null)
            }
            <StyledGlobalHeadingWrapper>
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("User_panel_employess_pracownicy")}:
                </StyledText>
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper $hasDeclaredHeight="calc(100% - 164px)" isscrollbar={isScrollBar} ref={scrollElement}>
                {
                    Object.entries(queryData).map(
                        ([month, data]) => (
                            <EmployeesAllElement
                                month={month}
                                hours={data}
                                defaultOpen={month === defaultOpenMonth}
                            />
                        )
                    )
                }
            </StyledGlobalScrollWrapper>
            {
                (!hasMore) ? (
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="600"
                        hasdeclaredpadding="20px 0"
                        hasdeclaredtextalign="center"
                    >
                        {getString("User_panel_employess_brak_kolejnych_miesiecy")}
                    </StyledText>
                ) : (isLoading) ? (
                    <Loader />
                ) : (
                    <StyledButtonWrapper haspadding>
                        <StyledButton onClick={loadMore}>
                            <StyledText
                                hasdeclaredfontsize="22px"
                                hasdeclaredfontweight="600"
                                hascursor="pointer"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("User_panel_employess_zaladuj_wiecej")}
                            </StyledText>
                        </StyledButton>
                    </StyledButtonWrapper>
                )
            }
        </StyledUserPanelEmployees>
    );
};

export default UserPanelEmployees;
