import React from "react";

import { ReactComponent as ImageCompetition222 } from "../../../images/cubes/222.svg";
import { ReactComponent as ImageCompetition333 } from "../../../images/cubes/333.svg";
import { ReactComponent as ImageCompetition444 } from "../../../images/cubes/444.svg";
import { ReactComponent as ImageCompetition555 } from "../../../images/cubes/555.svg";
import { ReactComponent as ImageCompetition666 } from "../../../images/cubes/666.svg";
import { ReactComponent as ImageCompetition777 } from "../../../images/cubes/777.svg";
import { ReactComponent as ImageCompetitionSkewb } from "../../../images/cubes/skewb.svg";
import { ReactComponent as ImageCompetitionSq1 } from "../../../images/cubes/sq1.svg";
import { ReactComponent as ImageCompetitionPyram } from "../../../images/cubes/pyram.svg";
import { ReactComponent as ImageCompetitionMinx } from "../../../images/cubes/minx.svg";
import { ReactComponent as ImageCompetition333oh } from "../../../images/cubes/333oh.svg";
import { ReactComponent as ImageCompetition333bf } from "../../../images/cubes/333bf.svg";
import { ReactComponent as ImageCompetition333fm } from "../../../images/cubes/333fm.svg";
import { ReactComponent as ImageCompetition333mbf } from "../../../images/cubes/333mbf.svg";
import { ReactComponent as ImageCompetitionClock } from "../../../images/cubes/clock.svg";
import { ReactComponent as ImageCompetition444bf } from "../../../images/cubes/444bf.svg";
import { ReactComponent as ImageCompetition555bf } from "../../../images/cubes/555bf.svg";

import { getString } from "../../../strings";

const competitionElements = {
    "222": <ImageCompetition222 key="222" title={getString("const_competitions_222")} />,
    "333": <ImageCompetition333 key="333" title={getString("const_competitions_333")} />,
    "333bf": <ImageCompetition333bf key="333bf" title={getString("const_competitions_333bf")} />,
    "333oh": <ImageCompetition333oh key="333oh" title={getString("const_competitions_333oh")} />,
    "444": <ImageCompetition444 key="444" title={getString("const_competitions_444")} />,
    "555": <ImageCompetition555 key="555" title={getString("const_competitions_555")} />,
    "666": <ImageCompetition666 key="666" title={getString("const_competitions_666")} />,
    "777": <ImageCompetition777 key="777" title={getString("const_competitions_777")} />,
    "clock": <ImageCompetitionClock key="clock" title={getString("const_competitions_clock")} />,
    "minx": <ImageCompetitionMinx key="minx" title={getString("const_competitions_minx")} />,
    "pyram": <ImageCompetitionPyram key="pyram" title={getString("const_competitions_pyram")} />,
    "skewb": <ImageCompetitionSkewb key="skewb" title={getString("const_competitions_skewb")} />,
    "sq1": <ImageCompetitionSq1 key="sq1" title={getString("const_competitions_sq1")} />,
    "333fm": <ImageCompetition333fm key="333fm" title={getString("const_competitions_333fm")} />,
    "444bf": <ImageCompetition444bf key="444bf" title={getString("const_competitions_444bf")} />,
    "555bf": <ImageCompetition555bf key="555bf" title={getString("const_competitions_555bf")} />,
    "333mbf": <ImageCompetition333mbf key="333mbf" title={getString("const_competitions_333mbf")} />
};

const CompetitionElement = ({ competition }) => (
	competitionElements[competition]
);

export default CompetitionElement;
