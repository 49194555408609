import React from "react";
import { getString } from "../../../strings";

import { StyledTrainingsStartPage } from "../../atoms/TrainingsStartPage/StyledTrainingsStartPage";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledAboutUsElement } from "../../atoms/AboutUs/StyledAboutUsElement";

import useScrollBar from "../../../logic/hooks/useScrollBar";

const TrainingsStartPage = ({ user }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledTrainingsStartPage 
            isscrollbar={isScrollBar} 
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <StyledAboutUsElement>
                <StyledText
                    hasdeclaredfontsize="34px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="0 0 50px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {`${getString("trainings_startpage_witaj")} ${user ? user.firstname : getString("trainings_startpage_speedcuberze")}!`}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {getString("trainings_startpage_cieszymysie")}
                </StyledText>
            </StyledAboutUsElement>
        </StyledTrainingsStartPage>
    );
};

export default TrainingsStartPage;
