import styled from "styled-components"

export const StyledImageWrapper = styled.div`
    width: 31%;
    height: 204px;
    position: relative;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        border: 2px solid #fff;
    }

    @media only screen and (max-width: 1072px) {
        width: 49%;
    }
    @media only screen and (max-width: 909px){
        width: 80%;
    }
    @media only screen and (max-width: 767px){
        height: 300px;
        width: 49%;
    }
    @media only screen and (max-width: 628px) {
        width: 98%;
    }
`;