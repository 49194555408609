import styled from "styled-components"

export const StyledCategoryElementHeading = styled.div`
    width: 100%;
    display: flex;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    background-color: #666;
    margin-bottom: 10px;
    
    > div {
        padding: 5px;
        > input {
            width: 90%;
            padding: 12px;
            border: 1px solid #000;
            color: #000;
        }
        > div {
            > div {
                &:last-child{
                    > div {
                        color: #000;
                        min-height: 250px;
                    }
                }
            }
        }
        &:nth-child(1){
            width: 4%;
        }
        &:nth-child(2){
            width: 80%;
        }
        &:nth-child(3){
            width: 16%;
            > a {
                color: #000;
            }
        }
    }
`