import styled from "styled-components"

export const StyledFavouriteWrapper = styled.div`
    width: 300px;
    
    @media only screen and (max-width: 993px) {
       width: 220px;
    }
    @media only screen and (max-width: 767px){
        width: 100%;
    }
`