import styled from "styled-components";

export const StyledAdminHeader = styled.header`
    width: 100%;
    min-height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    background-color: #df7b63;
    z-index: 100;

    @media only screen and (max-width: 767px){
        position: fixed;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        background-color: transparent;
    }
`;
