import styled from "styled-components";

export const StyledDataTextWrapper = styled.div`
    padding-bottom: 20px;
    > a {
        > button {
            width: 200px;
        }
    }

    @media only screen and (min-width: 1441px) {
        p {
            font-size: 26px;
        }
    }
    > a {
        color: #fff;
        text-decoration: none;
        > button {
            text-align: center;
            font-weight: 600;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 1341px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    @media only screen and (max-width: 865px) {
        p {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        p {
            font-size: 22px;
        }
    }
    @media only screen and (max-width: 540px) {
        p {
            font-size: 16px;
        }
    }
`;
