import styled from "styled-components"

export const StyledRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1240px){
        flex-direction: column;
        gap: 8px;
    }
`;