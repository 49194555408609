import React, { useState, useEffect, useCallback, useMemo } from "react";
import Decimal from "decimal.js";

import Loader from "../Loader/Loader";
import PayAllElement from "./PayAllElement";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelTrainerPay } from "../../atoms/UserPanelTrainerPay/StyledUserPanelTrainerPay";
import { StyledPayWrapper } from "../../atoms/UserPanelTrainerPay/StyledPayWrapper";
import { StyledContent } from "../../atoms/UserPanelTrainerPay/StyledContent";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledSumElement } from "../../atoms/UserPanelTrainerPay/StyledSumElement";
import { StyledSumRow } from "../../atoms/UserPanelTrainerPay/StyledSumRow";
import { StyledButtonWrapper } from "../../atoms/UserPanelTrainerPay/StyledButtonWrapper";

import { listAllPay } from "../../../logic/requests/students";
import { getString } from "../../../strings";
import { taxPercent } from "../../constants/pay";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelTrainerPay = () => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const [hours, setHours] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [err, setErr] = useState(false);
    const [isCheckboxes, setIsCheckboxes] = useState(false);

    const [lastMonth, setLastMonth] = useState(
        (() => {
            const months = Object.keys(hours ?? {});
            if (months.length) {
                const [year, month] = months[months.length - 1].split("-");
                const monthNum = parseInt(month);

                return monthNum === 0
                    ? `${parseInt(year) - 1}-12`
                    : `${year}-${String(monthNum).padStart(2, "0")}`;
            } else {
                const now = new Date();
                const [year, month] = [now.getFullYear(), now.getMonth() + 2];
                return `${year}-${String(month).padStart(2, "0")}`;
            }
        })()
    );
    const loadMonth = useCallback(() => {
        const last = new Date(...lastMonth.split("-"));
        const nextDate = new Date(
            lastMonth.split("-")[1] === "13"
                ? last.setMonth(last.getMonth() - 2)
                : last.setMonth(last.getMonth() - 1)
        );
        const next = `${nextDate.getFullYear()}-${
            lastMonth.split("-")[1] === "13"
                ? String(nextDate.getMonth() + 1).padStart(2, 0)
                : String(nextDate.getMonth()).padStart(2, 0)
        }`;

        setLastMonth(next);
        listAllPay(next)
            .then((res) => {
                if (res.hasNoMore) {
                    setHasMore(false);
                } else {
                    setHours({
                        ...(hours ?? {}),
                        ...res,
                    });
                }
            })
            .catch((err) => {
                if (!hours) {
                    setHours([]);
                    setErr(true);
                }
            });
    }, [hours, lastMonth]);

    useEffect(loadMonth, [setHours, setErr, setHasMore]);

    const revenue = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0])
                      .map((obj) => obj.price)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [hours]
    );
    const hoursAmount = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.half ? "0.5" : cur.hours),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );
    const trainerPay = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.pay),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );
    const income = useMemo(
        () => (revenue && trainerPay ? revenue.minus(trainerPay) : undefined),
        [revenue, trainerPay]
    );
    const revenueAfterTaxes = useMemo(
        () =>
            income
                ? income.times(new Decimal(1).minus(new Decimal(taxPercent).dividedBy(100)))
                : undefined,
        [income]
    );

    return (
        <StyledUserPanelTrainerPay>
            {!hours ? (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            ) : !Object.keys(hours).length ? (
                <StyledText
                    hasdeclaredpadding="0px 20px"
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.4em"
                    as="p"
                >
                    {getString(err ? "blad_wczytywania" : "UserPanelTrainerPay__no_hours")}
                </StyledText>
            ) : (
                <>
                    <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                            >
                                PRZYCHÓD:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {revenue.dp() > 0 ? revenue.toFixed(2) : revenue.toString()} PLN
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Godzin:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {hoursAmount.toString()}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Wypłaty:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {trainerPay.dp() > 0
                                    ? trainerPay.toFixed(2)
                                    : trainerPay.toString()}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Dochód:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {income.dp() > 0 ? income.toFixed(2) : income.toString()} (
                                {revenueAfterTaxes.dp() > 0
                                    ? revenueAfterTaxes.toFixed(2)
                                    : revenueAfterTaxes.toString()}
                                ) PLN
                            </StyledText>
                        </StyledSumRow>
                    </StyledSumElement>
                    <StyledContent>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            Wynagrodzenie:
                        </StyledText>
                        <StyledPayWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            <PayAllElement
                                hours={hours}
                                trigger={trigger}
                                showParentCheckboxes={isCheckboxes}
                            />
                        </StyledPayWrapper>
                    </StyledContent>
                </>
            )}
            {hasMore ? (
                <StyledButtonWrapper haspadding>
                    <StyledButton onClick={loadMonth}>
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredfontweight="600"
                            hascursor="pointer"
                        >
                            {getString("Trainings_trainings_all_trainer_pay_zaladujWiecej")}
                        </StyledText>
                    </StyledButton>
                </StyledButtonWrapper>
            ) : (
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="20px 0"
                    hasdeclaredtextalign="center"
                >
                    {getString("Trainings_trainings_all_trainer_pay_brakKolejnychMiesiecy")}
                </StyledText>
            )}
        </StyledUserPanelTrainerPay>
    );
};

export default UserPanelTrainerPay;
