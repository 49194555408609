import React, { useState, useMemo, useRef } from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import Decimal from "decimal.js";

import { StyledSummaryEditElement } from "../../atoms/SummaryEditElement/StyledSummaryEditElement";
import { StyledSavePayElement } from "../../atoms/PayElement/StyledSavePayElement";
import { StyledClosePayElement } from "../../atoms/PayElement/StyledClosePayElement";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledSummaryElementData } from "../../atoms/SummaryEditElement/StyledSummaryElementData";
import { StyledSumaryData } from "../../atoms/SummaryElement/StyledSumaryData";
import { StyledInputEditWrapper } from "../../atoms/SummaryElement/StyledInputEditWrapper";
import { StyledEditWrapper } from "../../atoms/SummaryElement/StyledEditWrapper";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";
import { getDateString } from "../../../logic/eventtime";
import { getString } from "../../../strings";
import { deleteHours, editSummary } from "../../../logic/requests/students";

const handleSubmit = (id, data, defaultValues, onSubmit) => {
    const toSend = Object.entries(data).reduce(
        (out, [key, val]) => {
            if (defaultValues[key] !== val)
                out[key] = val;
            return out;
        }, {}
    );

    if (toSend.hours) {
        toSend.hours = toSend.hours.replace(',', '.');
        toSend.half = toSend.hours === "0.5" ? 1 : 0;
        const int = parseInt(toSend.hours);
        if (isNaN(toSend.hours)) {
            console.warn("handleSubmit", "invalidValue", "toSend.hours", toSend.hours, int);
            return;
        }
        toSend.hours = int;
    }

    if (!Object.keys(toSend).length)
        return onSubmit?.();

    editSummary(id, toSend)
        .then(
            (res) => {
                console.log("editSummary", res);
                onSubmit?.(res);
            }
        )
        .catch(
            (err) => {
                console.error("editSummary", err);
            }
        )
    ;
};

const handleDelete = (id, isAdmin, onSuccess) => {
    let reason;
    if (isAdmin) {
        if (!window.confirm("Czy na pewno usunąć godzinę?"))
            return;
    } else {
        if (!(reason = window.prompt("Podaj powód usunięcia godziny")))
            return;
    }

    deleteHours(id, reason)
        .then(
            (res) => {
                console.log("handleDelete", res);
                onSuccess?.();
            }
        )
        .catch(
            (err) => {
                console.error("handleDelete", err);
            }
        )
    ;
};

const SummaryEditElement = ({
    hour,
    setHour,
    deleteHour,
    students,
    trainers,
    subjects,
    isAdmin
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const student = useMemo(() => `${hour.student.firstname} ${hour.student.lastname}`, [hour]);
    const trainer = useMemo(() => `${hour.trainer.firstname} ${hour.trainer.lastname}`, [hour]);

    const defaultValues = useMemo(
        () => ({
            student: hour.student.id,
            date: new Date(hour.date).toISOString().split('T')[0],
            hours: hour.half ? "0.5" : hour.hours,
            subject: hour.subject,
            trainer: hour.trainer.id,
            price: hour.price,
            price_trainer: hour.price_trainer
        }), [hour]
    );
    
    const price = useMemo(() => new Decimal(hour.price), [hour]);
    const price_trainer = useMemo(() => new Decimal(hour.price_trainer), [hour]);

    const values = {
        student: useRef(),
        date: useRef(),
        hours: useRef(),
        subject: useRef(),
        trainer: useRef(),
        price: useRef(),
        price_trainer: useRef()
    };

    return (
        <StyledSummaryEditElement>
            <ScrollSyncPane>
                <div>
                    {!isEdit ? (
                        <StyledSummaryElementData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {student}
                                </StyledText>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getDateString(new Date(hour.date))}
                                </StyledText>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {hour.half ? "0.5" : hour.hours}
                                </StyledText>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString(`subject__${hour.subject}`)}
                                </StyledText>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {trainer}
                                </StyledText>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                </StyledText>
                            </StyledSumaryData>
                            {isAdmin ? (
                                <StyledSumaryData>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {price_trainer.dp() > 0 ? price_trainer.toFixed(2) : price_trainer.toString()} PLN
                                    </StyledText>
                                </StyledSumaryData>
                            ) : null}
                        </StyledSummaryElementData>
                    ) : (
                        <StyledEditWrapper>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <select
                                        defaultValue={defaultValues.student}
                                        ref={values.student}
                                    >
                                        {
                                            students.map(
                                                (student) => (
                                                    <option
                                                        key={student.id}
                                                        value={student.id}
                                                    >
                                                        {student.lastname} {student.firstname}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <input
                                        type="date"
                                        defaultValue={defaultValues.date}
                                        ref={values.date}
                                    />
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <input
                                        type="text"
                                        defaultValue={defaultValues.hours}
                                        ref={values.hours}
                                    />
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <select
                                        defaultValue={defaultValues.subject}
                                        ref={values.subject}
                                    >
                                        {
                                            subjects.map(
                                                (subject) => (
                                                    <option
                                                        key={subject}
                                                        value={subject}
                                                    >
                                                        {getString(`subject__${subject}`)}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <select
                                        defaultValue={defaultValues.trainer}
                                        ref={values.trainer}
                                    >
                                        {
                                            trainers.map(
                                                (trainer) => (
                                                    <option
                                                        key={trainer.id}
                                                        value={trainer.id}
                                                    >
                                                        {trainer.lastname} {trainer.firstname}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            <StyledSumaryData>
                                <StyledInputEditWrapper>
                                    <input
                                        type="text"
                                        defaultValue={defaultValues.price}
                                        ref={values.price}
                                    />
                                </StyledInputEditWrapper>
                            </StyledSumaryData>
                            {isAdmin ? (
                                <StyledSumaryData>
                                    <StyledInputEditWrapper>
                                        <input
                                            type="text"
                                            defaultValue={defaultValues.price_trainer}
                                            ref={values.price_trainer}
                                        />
                                    </StyledInputEditWrapper>
                                </StyledSumaryData>
                            ) : null}
                        </StyledEditWrapper>
                    )}
                </div>
            </ScrollSyncPane>
            <StyledSavePayElement
                isedit={isEdit}
                onClick={
                    (isEdit) ? (
                        () => handleSubmit(
                            hour.id,
                            Object.fromEntries(
                                Object.entries(values)
                                .map(
                                    ([key, val]) => ([
                                        key,
                                        (
                                            (["student", "trainer"].includes(key)) ? (
                                                parseInt(val?.current?.value)
                                            ) : (
                                                val?.current?.value
                                            )
                                        )
                                    ])
                                )
                            ),
                            defaultValues,
                            (newHour) => {
                                setIsEdit(false);
                                setHour(
                                    (hour) => ({
                                        ...hour,
                                        ...newHour,
                                        student: (
                                            (newHour?.student) ? (
                                                students.find((s) => (s.id === newHour.student))
                                            ) : (hour.student)
                                        ),
                                        trainer: (
                                            (newHour?.trainer) ? (
                                                trainers.find((t) => (t.id === newHour.trainer))
                                            ) : (hour.trainer)
                                        )
                                    })
                                )
                            }
                        )
                    ) : (
                        () => setIsEdit(true)
                    )
                }
            >
                {!isEdit ? <PencilIcon /> : <AcceptIcon />}
            </StyledSavePayElement>
            <StyledClosePayElement
                onClick={
                    () => handleDelete(hour.id, isAdmin, deleteHour)
                }
            >
                <CloseIcon />
            </StyledClosePayElement>
        </StyledSummaryEditElement>
    );
};

export default SummaryEditElement;
