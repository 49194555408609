import styled from "styled-components";

export const StyledContent = styled.div`
    width: 1060px;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px 100px;
    margin-bottom: 10px;

    scrollbar-color: #6786be rgba(103, 134, 190, 0.3);
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        height: 12px;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: rgba(103, 134, 190, 0.3);
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
    @media only screen and (max-width: 1064px) {
        overflow-y: visible;
        width: 90%;
        height: auto;
        > h2 {
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 725px) {
        > div {
            > h2 {
                font-size: 50px;
            }
        }
    }

    @media only screen and (max-width: 365px) {
        > div {
            > h2 {
                font-size: 38px;
            }
        }
    }
`;
