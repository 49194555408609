import styled from 'styled-components'

export const StyledSelectButton = styled.button`
    background: #6786be;
    padding: 18px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    a {
        text-decoration: none;
        color: #fff;
    }
`
