import React, { useEffect } from "react";
import { sessionService } from "redux-react-session";
import { useNavigate } from "react-router-dom";
import { logout as logoutServerSide } from "../../logic/requests/users";

const Logout = () => {
	const navigate = useNavigate();

	useEffect(
		() => (
			(
				async () => {
					let ret;
					try {
						ret = await logoutServerSide();
						console.log("logged out server-side", ret);
						// TODO: redux-react-session chyba może coś rzucić - obsługa błędów?
						await sessionService.deleteUser();
					} catch (err) {
						console.log("error logging out server-side", err);
						switch (err?.data?.code) {
							case "no_login":
								// już wylogowano, wylogowujemy na kliencie
								await sessionService.deleteUser();
								break;
							default:
								break;
						}
					}
					console.log("redirecting");
					navigate("/", { replace: true });
				}
			)()
		),
		[]
	);

	return <></>;
};

export default Logout;
