import styled from "styled-components"

export const StyledScrollElementWrapper = styled.div`
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 5px;

    scrollbar-color: ${({ isscrollbar, hidetrack }) =>
        isscrollbar ? hidetrack ? "transparent transparent" : "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: ${({ hidetrack }) => hidetrack ? "0" : "6px"};
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar, hidetrack }) =>
            isscrollbar ? hidetrack ? "transparent" : "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
`