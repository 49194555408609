import styled from "styled-components"
import shape from "../../../images/LoginPage/Vector.png"

export const StyledRightVector = styled.div`
    background: url(${shape});
    background-size: cover;
    height: 100%;
    width: 58%;
    position: absolute;
    right: 0;
    top: 0;
`