import React, { useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import RoleElement from "../../molecules/UserPanelAddRole/RoleElement";

import { StyledRoleElement } from "../../atoms/UserPanelAddRole/StyledRoleElement";
import { StyledHeading } from "../../atoms/PayElement/StyledHeading";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledRoleElementHeader } from "../../atoms/UserPanelAddRole/StyledRoleElementHeader";
import { StyledElementsWrapper } from "../../atoms/UserPanelAddRole/StyledElementsWrapper";
import { StyledScrollElementWrapper } from "../../atoms/UserPanelAddRole/StyledScrollElementWrapper";
import { StyledDeleteCheckbox } from "../../atoms/UserPanelAddRole/StyledDeleteCheckbox";
import { StyledIsOpenWrapper } from "../../atoms/UserPanelAddRole/StyledIsOpenWrapper";

import { getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelRoleElement = ({
    role,
    users,
    setUsers,
    trigger
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar();

    const handleOpen = () => {
        trigger();
        setIsOpen(!isOpen);
    };

    return (
        <StyledRoleElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {getString(`roles_${role}`)}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <ScrollSync>
                    <div>
                        <StyledIsOpenWrapper>
                            <ScrollSyncPane>
                                <StyledScrollElementWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                                    <StyledRoleElementHeader>
                                        <div>
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                            >
                                                {getString("UserPanelAddRole__list__user")}
                                            </StyledText>
                                        </div>
                                        <div>
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                            >
                                                {getString("UserPanelAddRole__list__email")}
                                            </StyledText>
                                        </div>
                                        <div>
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                            >
                                                {getString("UserPanelAddRole__list__roles")}
                                            </StyledText>
                                        </div>
                                    </StyledRoleElementHeader>
                                </StyledScrollElementWrapper>
                            </ScrollSyncPane>
                            <StyledDeleteCheckbox isbgcolor />
                        </StyledIsOpenWrapper>
                        <StyledElementsWrapper>
                            {
                                users.map(
                                    (user) => (
                                        <RoleElement
                                            key={user.id}
                                            user={user}
                                            onDelete={
                                                () => {
                                                    setUsers(
                                                        (users) => {
                                                            users.splice(users.indexOf(user), 1, ({
                                                                ...user,
                                                                roles: user.roles.filter(user_role => user_role !== role)
                                                            }));

                                                            return [...users];
                                                        }
                                                    );
                                                }
                                            }
                                            role={role}
                                        />
                                    )
                                )
                            }
                        </StyledElementsWrapper>
                    </div>
                </ScrollSync>
            ) : null}
        </StyledRoleElement>
    );
};

export default UserPanelRoleElement;
