import React, { useState } from "react";

import AdminNav from "../AdminNav/AdminNav";
import HamburgerMenu from "./HamburgerMenu";

import { StyledAdminHeader } from "../../atoms/AdminHeader/StyledAdminHeader";

const AdminHeader = () => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <StyledAdminHeader>
            <HamburgerMenu openMenu={openMenu} setOpenMenu={setOpenMenu}/>
            <AdminNav openMenu={openMenu}/>
        </StyledAdminHeader>
    );
};

export default AdminHeader;
