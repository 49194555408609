import backendRequest from "./index.js";

const getMenuEntries = () => (
	backendRequest("GET", "/images/gallery/menu")
);

const listImagesForEvent = (eventId) => (
	backendRequest("GET", `/images/gallery/event/${eventId}`)
);

const getFavorites = () => (
	backendRequest("GET", "/images/gallery/favorites/get")
);

const addToFavorites = (eventId, fileName) => (
	backendRequest("POST", "/images/gallery/favorites/add", { eventId, fileName })
);

const removeFromFavorites = (eventId, fileName) => (
	backendRequest("POST", "/images/gallery/favorites/remove", { eventId, fileName })
);

export {
	getMenuEntries,
	listImagesForEvent,
	getFavorites,
	addToFavorites,
	removeFromFavorites
};
