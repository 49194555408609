import styled from "styled-components";

export const StyledCookiesPopUp = styled.div`
    width: 100%;
    background: rgba(0, 0, 0, 0.72);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 20px;
    z-index: 100;

    @media only screen and (max-width: 1623px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        > button {
            padding-left: 0px;
        }
    }
`;
