import "./fonts/fonts.css";
import { createGlobalStyle } from "styled-components";
import FilsonProBlack from "./fonts/FilsonProBlack.otf";
import FilsonProBlackItalic from "./fonts/FilsonProBlackItalic.otf";
import FilsonProBold from "./fonts/FilsonProBold.otf";
import FilsonProBoldItalic from "./fonts/FilsonProBoldItalic.otf";
import FilsonProBook from "./fonts/FilsonProBook.otf";
import FilsonProBookItalic from "./fonts/FilsonProBookItalic.otf";
import FilsonProHeavy from "./fonts/FilsonProHeavy.otf";
import FilsonProHeavyItalic from "./fonts/FilsonProHeavyItalic.otf";
import FilsonProLight from "./fonts/FilsonProLight.otf";
import FilsonProLightItalic from "./fonts/FilsonProLightItalic.otf";
import FilsonProMedium from "./fonts/FilsonProMedium.otf";
import FilsonProMediumItalic from "./fonts/FilsonProMediumItalic.otf";
import FilsonProRegular from "./fonts/FilsonProRegular.otf";
import FilsonProRegularItalic from "./fonts/FilsonProRegularItalic.otf";
import FilsonProThin from "./fonts/FilsonProThin.otf";
import FilsonProThinItalic from "./fonts/FilsonProThinItalic.otf";

const AdminGlobalStyle = createGlobalStyle`
  @font-face{
    font-family: 'FilsonProBlack';
    src: url(${FilsonProBlack});
  }
  @font-face{
    font-family: 'FilsonProBlackItalic';
    src: url(${FilsonProBlackItalic});
  }
  @font-face{
    font-family: 'FilsonProBold';
    src: url(${FilsonProBold});
  }
  @font-face{
    font-family: 'FilsonProBoldItalic';
    src: url(${FilsonProBoldItalic});
  }
  @font-face{
      font-family: 'FilsonProBook';
      src: url(${FilsonProBook});
  }
  @font-face{
      font-family: 'FilsonProBookItalic';
      src: url(${FilsonProBookItalic});
  }
  @font-face{
      font-family: 'FilsonProHeavy';
      src: url(${FilsonProHeavy});
  }
  @font-face{
      font-family: 'FilsonProHeavyItalic';
      src: url(${FilsonProHeavyItalic});
  }
  @font-face{
      font-family: 'FilsonProLight';
      src: url(${FilsonProLight});
  }
  @font-face{
      font-family: 'FilsonProLightItalic';
      src: url(${FilsonProLightItalic});
  }
  @font-face{
      font-family: 'FilsonProMedium';
      src: url(${FilsonProMedium});
  }
  @font-face{
      font-family: 'FilsonProMediumItalic';
      src: url(${FilsonProMediumItalic});
  }
  @font-face{
      font-family: 'FilsonProRegular';
      src: url(${FilsonProRegular});
  }
  @font-face{
      font-family: 'FilsonProRegularItalic';
      src: url(${FilsonProRegularItalic});
  }
  @font-face{
      font-family: 'FilsonProThin';
      src: url(${FilsonProThin});
  }
  @font-face{
      font-family: 'FilsonProThinItalic';
      src: url(${FilsonProThinItalic});
  }

  :root {
    --dark: #303030;
    --white: #fff;
  }

  *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
`;

export default AdminGlobalStyle;
