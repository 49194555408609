import styled from "styled-components"

export const StyledChooseGallery = styled.div`
    margin: 22px 0;
    padding: 0 22px;
    background-color: #6786be;
    min-height: 50px;
    border-radius: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > p {
        width: 100%;
    }

    @media only screen and (max-width: 374px){
        > p {
            font-size: 16px;
        }
    }
`;