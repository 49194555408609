import styled from "styled-components"
import { motion } from "framer-motion"

export const StyledImageListLoadingWrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media only screen and (max-width: 510px){
        > h1{
            font-size: 32px;
        }
    }
`