import styled from "styled-components"

export const StyledAdminUserButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 10px;

    @media only screen and (max-width: 922px){
        flex-direction: column;
    }
`