import styled from "styled-components";

export const StyledContentData = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 20px;
    > a {
        width: 30%;
        margin-right: 20px;
        > img {
            width: 100%;
            height: 100%;
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
    > div {
        width: 70%;
    }
    @media only screen and (max-width: 934px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        > a {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
            height: 200px;
        }
        > div {
            width: 90%;
        }
    }
    @media only screen and (max-width: 520px) {
        > a {
            width: 95%;
        }
        > div {
            width: 95%;
        }
    }
`;
