import { subMonths } from "date-fns";

export const getMonthString = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth()+1).padStart(2, '0');

	return `${year}-${month}`;
};

export const getCurrentMonth = () => (
	getMonthString(new Date())
);

export const getLastMonths = (amount) => {
	const now = new Date();

	return (
		[...Array(amount)]
		.map(
			(_val, idx) => getMonthString(subMonths(now, idx)) 
		)
	);
};
