import styled from "styled-components"

export const StyledSettlementsCheckBoxWrapper = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`