import styled from "styled-components"

export const StyledEmailWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    > p{
        a {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 460px) {
        p {
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 340px) {
        p {
            font-size: 13px;
        }
    }
`;