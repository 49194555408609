import styled from "styled-components"

export const StyledCoachElement = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin-bottom: 40px;

    @media only screen and (max-width: 517px){
        > h2 {
            font-size: 24px;
        }
    }
`