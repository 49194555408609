import styled from "styled-components";

export const StyledButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    > button {
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
