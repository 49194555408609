import React, { useEffect, useState } from 'react'
import CoachElement from './CoachElement'
import Loader from "../Loader/Loader";

import useWindowSize from '../../../utils/getWindowSize';

import { StyledCoach } from "../../atoms/Coach/StyledCoach"
import { StyledText } from "../../atoms/Text/StyledText";
import { aboutTrainers } from '../../../logic/requests/trainings'

import { getString } from "../../../strings";
import useScrollBar from '../../../logic/hooks/useScrollBar';

const Coach = () => {
    const width = useWindowSize();
    const { scrollElement, isScrollBar } = useScrollBar();

    const [trainers, setTrainers] = useState();
    useEffect(
        () => {
            aboutTrainers(false)
                .then(
                    res => {
                        console.log("aboutTrainers", res);
                        setTrainers(res);
                    }
                )
                .catch(
                    err => {
                        console.error("aboutTrainers", err);
                    }
                )
            ;
        }, []
    );
    
    useEffect(
        () => {
            const element = scrollElement?.current;
            if (element && width < 768)
                element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        },
        [ scrollElement, width, trainers ]
    );

    return (
        (!trainers) ? (
            <Loader />
        ) : (!trainers.length) ? (
            // TODO: Co tutaj?
            <></>
        ) : (
            <StyledCoach 
                isscrollbar={isScrollBar} 
                ref={scrollElement}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <StyledText
                    hasdeclaredfontsize="34px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="0 30px 10px"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString("Trainings_coach_poznaj_naszych_prowadzacych")}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 30px 60px"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {getString("Trainings_coach_wsrod")}
                </StyledText>
                {
                    trainers.map(
                        trainer => (
                            <CoachElement data={trainer} />
                        )
                    )
                }
            </StyledCoach>
        )
    )
}

export default Coach
