import styled from "styled-components"

export const StyledRoleElementHeader = styled.div`
    min-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    
    > div {
        width: 33%;
    }
`