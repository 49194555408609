import React from "react";

import RegisterErrorMessage from "./RegisterErrorMessage";

import { StyledInputWrapper } from "../../../components/atoms/Register/StyledInputWrapper";
import { StyledSelectInput } from "../../../components/molecules/Register/StyledSelectInput";
import { StyledText } from "../../atoms/Text/StyledText";

import { cebulaCompare } from "../../../logic/arrays.js";

import wca_countries from "../../constants/wca_countries.json";

import countries from "i18n-iso-countries";
// w środowisku przeglądarkowym trzeba zadeklarować języki do spakowania przez webpack'a
// następująca linia musi nastąpić po wszystkich importach (błąd 'import/first')
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

const wca_countries_pl = (() => {
    const ret = {};
    Object.keys(wca_countries).forEach((key) => (ret[key] = countries.getName(key, "pl")));
    return ret;
})();

const SelectCountry = ({
    title,
    name,
    inputType,
    error,
    annotation,
    hasDeclaredBottomPosition,
}) => (
    <StyledInputWrapper>
        <label htmlFor={name}>
            <StyledText
                hasdeclaredfontsize="20px"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="8px 0 8px 0"
                hasdeclaredfontweight="600"
            >
                {title}
            </StyledText>
        </label>
        <StyledSelectInput error={error} component={inputType} name={name} id={name}>
            <option />
            {Object.keys(wca_countries_pl)
                // sortowanie po (wyświetlanej) wartości
                .sort((a, b) => cebulaCompare(wca_countries_pl[a], wca_countries_pl[b]))
                // ale mapowanie po kluczach, żeby mieć je w 'value'
                .map((key) => (
                    <option key={key} value={key} label={wca_countries_pl[key]}></option>
                ))}
        </StyledSelectInput>
        {annotation && (
            <StyledText
                hasdeclaredfontsize="12px"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="2px 0 1px 0"
                hasdeclaredfontweight="600"
            >
                {annotation}
            </StyledText>
        )}
        <RegisterErrorMessage
            name={name}
            hasDeclaredBottomPosition={hasDeclaredBottomPosition}
        />
    </StyledInputWrapper>
);

export default SelectCountry;
