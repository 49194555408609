import React from "react";
import { getString } from "../../../strings";
import { StyledTournamentStartContent } from "../../atoms/Tournament/StyledTournamentStartContent";
import { StyledTournamentSponsors } from "../../molecules/Tournament/StyledTournamentSponsors";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledTournamentLogoWrapper } from "../../molecules/Tournament/StyledTournamentLogoWrapper";
import { StyledTournamentStartContentLink } from "../../molecules/Tournament/StyledTournamentStartContentLink";
import { ReactComponent as Results } from "../../../images/tournaments/results.svg";
import LoadingImage from "../LoadingImage/LoadingImage";
import LoadingImageWrapper from "../LoadingImage/LoadingImageWrapper";

const TournamentStartContent = ({ event }) => {

    if (!event) return null;

    const getLogoBaseLink = `/api/events/get-logo/${event?.id}`;

    return (
        <StyledTournamentStartContent>
            <LoadingImageWrapper container={<StyledTournamentLogoWrapper />}>
                {event?.has_logo ? <LoadingImage src={`${getLogoBaseLink}/event`} alt="logo" /> : (null)}
                {event?.league?.has_logo ? (
                    <LoadingImage src={`${getLogoBaseLink}/league`} alt="league logo" />
                ) : (
                    null
                )}
            </LoadingImageWrapper>
            {event?.wca_live_id && event?.status === "live" ? (
                <StyledTournamentStartContentLink
                    href={`https://live.worldcubeassociation.org/competitions/${event.wca_live_id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <StyledButton
                        hasdeclaredfontsize="44px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredcursor="pointer"
                    >
                        <Results />
                        {getString("tournament_tournamentRequirementsContent_wynikiLive")}
                    </StyledButton>
                </StyledTournamentStartContentLink>
            ) : (
                <></>
            )}
            <LoadingImageWrapper container={<StyledTournamentSponsors />} smallLoader={true}>
                {event?.sponsors?.map((sponsor) => (
                    <LoadingImage key={sponsor.id} src={`${getLogoBaseLink}/sponsor/${sponsor.id}`} alt="sponsor" />
                ))}
            </LoadingImageWrapper>
        </StyledTournamentStartContent>
    );
};

export default TournamentStartContent;
