import styled from "styled-components"

export const StyledAdminPlayersButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 10px;
    > a{
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }
    @media only screen and (max-width: 762px){
        flex-direction: column;
    }
`