import React, { useCallback, useEffect, useState } from "react";
import { getString } from "../../../strings";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledCookiesPopUp } from "../../atoms/CookiesPopUp/StyledCookiesPopUp";
import { StyledButton } from "../../atoms/Button/StyledButton";

import { getCookie, setCookie } from "../../../logic/cookies.js";

const cookie_name = "cookie_info_hidden";

const CookiesPopUp = () => {
    const [isPopUp, setIsPopUp] = useState(false);

    const hideForever = useCallback(() => {
        setIsPopUp(false);
        setCookie(cookie_name, "true");
    }, [setIsPopUp]);

    useEffect(() => (
        setIsPopUp(!(getCookie(cookie_name) === "true"))
    ), [setIsPopUp]);

    return (
        isPopUp ? (
            <StyledCookiesPopUp>
                <StyledText
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredfontsize="12px"
                >
                    {getString("cookiesPopUp_cookiesPopUp_korzystajacZNaszej")}
                </StyledText>
                <StyledButton
                    hasdeclaredcursor="pointer"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="0 0 0 40px"
                    hasdeclaredfontsize="12px"
                    onClick={hideForever}
                >
                    &#x2715;
                </StyledButton>
            </StyledCookiesPopUp>
        ) : null
    );
};

export default CookiesPopUp;
