import styled from "styled-components"

export const StyledHeadingCheckbox = styled.div`
    width: 50px;
    height: 50px;
    background-color: ${({ hasdeclaredbgcolor }) => hasdeclaredbgcolor ? "#ee745c" : "#6786BE"};
    border-radius: 9px;
    cursor: pointer;
    transform: translateX(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    
    i {
        margin-left: 4px;
    }
`