import styled from "styled-components"

export const StyledCloseForm = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: #EE745C;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`