import React from 'react'
import styled from "styled-components"

export const StyledHamburgerMenu = styled.div`
    display: none;

    @media only screen and (max-width: 767px){
        display: block;
        position: fixed;
        z-index: 120;
        top: 0;
        left: 0;

        width: 40px;
        height: 40px;
        background-color: red;
    }
`

const HamburgerMenu = ({openMenu, setOpenMenu}) => {
    return (
        <StyledHamburgerMenu onClick={() => setOpenMenu(!openMenu)}></StyledHamburgerMenu>
    )
}

export default HamburgerMenu
