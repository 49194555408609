import styled from "styled-components"

export const StyledAboutUsImageWrapperImage = styled.div`
    width: 49%;
    margin-bottom: 40px;
    height: ${({ hasdeclaredheight }) => (hasdeclaredheight ? hasdeclaredheight : "200px")};

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;

        -webkit-box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
        -moz-box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
        box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
    }

    @media only screen and (max-width: 630px) {
        width: 100%;
    }
`;