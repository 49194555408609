import styled from "styled-components";

export const StyledScramble = styled.div`
    width: 100%;
    background-color: #6786be;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 22px 0;
    @media only screen and (max-width: 580px) {
        h2 {
            font-size: 46px;
        }
    }
`;
