import React from "react";
import { Link } from "react-router-dom";
import { ErrorMessage } from "formik";

import { getString } from "../../../strings";

import { StyledErrorMessageWrapper } from "./StyledErrorMessageWrapper";
import { StyledLoginButtonsWrapper } from "./StyledLoginButtonsWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledButtonSvg } from "../../atoms/Login/StyledButtonSvg";

import useWindowSize from "../../../utils/getWindowSize";

const LoginButtonsWrapper = ({ loginErr, isSubmitting, loginErrMsg }) => {
    const windowWidth = useWindowSize();
    return (
        <StyledLoginButtonsWrapper>
            <div>
                <Link to="/request-password-reset">
                    <StyledText
                        hasdeclaredfontcolor="#131309"
                        hasdeclaredfontsize="22px"
                        hascursor="pointer"
                        hasdeclaredfontweight="600"
                        as="h3"
                    >
                        {getString("login_loginButtonsWrapper_niePamietaszHasla")}
                    </StyledText>
                </Link>
                {loginErr ? (
                    <StyledText
                        hasdeclaredfontsize={windowWidth < 430 ? "14px" : "20px"}
                        hasdeclaredfontcolor="#f94016"
                        hasdeclaredpadding="12px 0 0 0"
                        hasdeclaredfontweight="600"
                        key={loginErr}
                        initial={{ opacity: 0, x: "100px" }}
                        animate={{
                            opacity: 1,
                            x: 0,
                        }}
                        exit={{ opacity: 0 }}
                    >
                        {loginErrMsg}
                    </StyledText>
                ) : (
                    <></>
                )}
                <ErrorMessage name="login">
                    {(errorMsg) => (
                        <StyledErrorMessageWrapper
                            key={errorMsg}
                            initial={{ opacity: 0, x: "100px" }}
                            animate={{
                                opacity: 1,
                                x: 0,
                            }}
                            exit={{ opacity: 0 }}
                        >
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontcolor="#f94016"
                                hasdeclaredfontweight="600"
                            >
                                {errorMsg}
                            </StyledText>
                        </StyledErrorMessageWrapper>
                    )}
                </ErrorMessage>
                <ErrorMessage name="password">
                    {(errorMsg) => (
                        <StyledErrorMessageWrapper
                            key={errorMsg}
                            initial={{ opacity: 0, x: "100px" }}
                            animate={{
                                opacity: 1,
                                x: 0,
                            }}
                            exit={{ opacity: 0 }}
                        >
                            <StyledText
                                hasdeclaredfontcolor="#f94016"
                                hasdeclaredfontsize="20px"
                                hasdeclaredpadding="28px 0"
                                hasdeclaredfontweight="600"
                            >
                                {errorMsg}
                            </StyledText>
                        </StyledErrorMessageWrapper>
                    )}
                </ErrorMessage>
            </div>
            <StyledButton
                hasdeclaredfontcolor="#fff"
                hasdeclaredfontsize="18px"
                hasdeclaredbgcolor="#6786be"
                hasdeclaredpadding="8px 18px"
                hasdeclaredtextalign="center"
                hasdeclaredcursor="pointer"
                hasdeclaredwidth="100px"
                hasdeclaredheight="100%"
                type="submit"
                disabled={isSubmitting}
                whileTap={{ scale: 0.9 }}
                whileHover={{ scale: 1.05 }}
            >
                <StyledButtonSvg />
            </StyledButton>
        </StyledLoginButtonsWrapper>
    );
};

export default LoginButtonsWrapper;
