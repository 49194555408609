import styled from "styled-components"

export const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 10px;
    > textarea {
        border-radius: 25px;
        resize: none;
        height: 260px;
        border: 3px solid #6d85ba;
        outline: none;
        width: 100%;
        margin-top: 12px;
        font-size: 20px;
        font-family: "Poppins";
        padding: 18px;
    }
    > button{
        height: 50px;
    }
`;