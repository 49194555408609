import styled from "styled-components"

export const StyledTextContent = styled.div`
    width: 70%;

    > div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 7px;
        > a {
            text-decoration: none;
            cursor: pointer;
        }
        > p {
            a {
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

    @media only screen and (max-width: 1240px) {
        > div {
            > p {
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width: 1186px) {
        width: 100%;
        margin-top: 10px;
    }
`;