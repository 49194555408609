import styled from "styled-components"

export const StyledFlag = styled.div`
    width: 30px;
    height: 17px;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid #000;
    }
`