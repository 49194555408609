import React from 'react'

import { StyledHeart } from "../../atoms/Gallery/StyledHeart";

import { ReactComponent as Heart } from "../../../images/gallery/heart.svg";
import { addToFavorites, removeFromFavorites } from "../../../logic/requests/gallery.js";

const AddToFavourite = ({ img, isFill, refreshFavoritesAdd, refreshFavoritesRemove }) => {
    const handleAddToFavourite = (e) => {
        e.preventDefault();
        console.log("handleAddToFavorite", "img", img, "isFill", isFill);
        if (isFill) {
            removeFromFavorites(img.eventId, img.fileName)
                .then(
                    res => {
                        console.log("removeFromFavorites", img, res);
                        refreshFavoritesRemove?.(img);
                    }
                )
                .catch(
                    err => {
                        console.error("removeFromFavorites", img, err);
                        // TODO: Error handling?
                    }
                )
            ;
        } else {
            addToFavorites(img.eventId, img.fileName)
                .then(
                    res => {
                        console.log("addToFavorites", img, res);
                        refreshFavoritesAdd?.(img);
                    }
                )
                .catch(
                    err => {
                        console.error("addToFavorites", img, err);
                        // TODO: Error handling?
                    }
                )
            ;
        }
    }
    return (
        <StyledHeart
            hasfill={isFill}
            onClick={(e) => handleAddToFavourite(e)}
        >
            <Heart />
        </StyledHeart>
    );
};

export default AddToFavourite
