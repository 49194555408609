import styled from "styled-components";

export const StyledSummaryPlayersCube = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 5px;
        margin-top: 8px;
        > svg {
            width: 25px;
            height: 25px;
        }
        > p {
            padding-top: 5px;
            font-weight: 700;
        }
    }
`;
