import styled from "styled-components";
import { motion } from "framer-motion"

export const StyledRightWrapper = styled(motion.div)`
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    @media only screen and (max-width: 767px){
        > h2 {
            color: #000;
        }
    }
`;
