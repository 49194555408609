import React from "react";

import { StyledUserPanelTournamentWrapper } from "../../atoms/UserPanel/StyledUserPanelTournamentWrapper";
import { StyledLeftPanelTouranmentTextWrapper } from "../../molecules/UserPanel/StyledLeftPanelTouranmentTextWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledLoaderWrapper } from "../../atoms/Tournament/StyledLoaderWrapper";
import UserPanelReqElement from "../UserPanelReqElement/UserPanelReqElement";
import UserPanelReqElementTrainer from "../UserPanelReqElement/UserPanelReqElementTrainer";
import Loader from "../Loader/Loader";

import { getString } from "../../../strings";

const UserPanelReq = ({ analyses, setAnalyses, err, trainer = false }) => (
    (err) ? (
        <StyledLeftPanelTouranmentTextWrapper>
            <StyledText
                hasdeclaredtexttransform="uppercase"
                hasdeclaredpadding="0 20px 0 20px"
                hasdeclaredfontsize="46px"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
                hasdeclaredmaxwidth="600px"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString("blad_wczytywania")}
            </StyledText>
        </StyledLeftPanelTouranmentTextWrapper>
    ) : (!analyses) ? (
        <StyledUserPanelTournamentWrapper loading={!err && !analyses}>
            <StyledLoaderWrapper>
                <Loader />
            </StyledLoaderWrapper>
        </StyledUserPanelTournamentWrapper>
    ) : (!analyses.length) ? (
        <StyledLeftPanelTouranmentTextWrapper>
            <StyledText
                hasdeclaredtexttransform="uppercase"
                hasdeclaredpadding="0 20px 0 20px"
                hasdeclaredfontsize="46px"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
                hasdeclaredmaxwidth="600px"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString("UserPanelReq__brak_zamowien")}
            </StyledText>
        </StyledLeftPanelTouranmentTextWrapper>
    ) : (
        <StyledUserPanelTournamentWrapper loading={!err && !analyses}>
            {
                analyses.map(
                    trainer ? (
                        (analysis) => (
                            <UserPanelReqElementTrainer
                                key={analysis.id}
                                name={analysis.name}
                                format={analysis.format}
                                competition={analysis.competition}
                                phone={analysis.phone}
                                email={analysis.email}
                                link={analysis.link}
                                status={analysis.status}
                                orderId={analysis.id}
                                setStatus={
                                    (status) => (
                                        setAnalyses(
                                            (val) => (
                                                val
                                                .map(
                                                    a => (
                                                        (a.id === analysis.id) ? ({
                                                            ...a,
                                                            status
                                                        }) : (a)
                                                    )
                                                )
                                            )
                                        )
                                    )
                                }
                            />
                        )
                    ) : (
                        (analysis, idx) => (
                            <UserPanelReqElement
                                key={analysis.id}
                                number={analyses.length - idx}
                                format={analysis.format}
                                competition={analysis.competition}
                                date={analysis.created}
                                trainer={analysis.trainer}
                                orderId={analysis.id}
                                status={analysis.status}
                                remove={
                                    () => (
                                        setAnalyses(
                                            (val) => (
                                                val
                                                .filter(
                                                    (a) => (a.id !== analysis.id)
                                                )
                                            )
                                        )
                                    )
                                }
                            />
                        )
                    )
                )
            }
        </StyledUserPanelTournamentWrapper>
    )
);

export default UserPanelReq;
