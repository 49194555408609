import styled from "styled-components";

export const StyledInputWrapperPanel = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: ${({ hasdeclaredmargin }) => (hasdeclaredmargin ? hasdeclaredmargin : "0")};
    margin-bottom: 24px;
    padding-bottom: 24px;
    position: relative;
    width: 100%;
    > input {
        width: 100%;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#3474E8")};
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        cursor: pointer;
        background: #fff;
    }
`;
