import styled from "styled-components";

export const StyledSeriesResultsElementResults = styled.div`
    display: flex;
    justify-content: ${({ isscrollbar }) => isscrollbar ? "flex-start" : "center"};
    align-items: center;
    width: 50%;
    scrollbar-width: none;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        background: transparent;
        height: 0;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
        height: 0;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: transparent;
        height: 0;
    }
    > p {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        min-height: 40px;
        margin-right: 5px;
        height: 100%;
    }

    @media only screen and (max-width: 1100px){
        > p{
            font-size: 15px
        }
    }
`;
