import styled from 'styled-components'

export const StyledResultsData = styled.div`
    width: 100%;
    @media only screen and (max-width: 768px) {
        > h3 {
            font-size: 18px;
        }
    }
`
