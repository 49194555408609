import styled from "styled-components"

export const StyledEditElement = styled.div`
    width: 19.5%;
    padding: 0 4px;

    > input, select {
        text-align: center;
        width: 100%;
        height: 30px;
        font-size: 16px;
        border-radius: 25px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        background: #fff;
        text-align: center;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: "Poppins";
            text-align: center;
        }
    }
`