import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { sessionService } from "redux-react-session";
import { getString } from "../../strings";

import ThankYouPage from "./ThankYouPage";

import LogoLink from "../organisms/Logo/LogoLink";
import LoginLink from "../organisms/LoginLink/LoginLink";
import RegisterCube from "../molecules/RegisterCube/RegisterCube";
import HelmetTemplate from "../molecules/HelmetTemplate/HelmetTemplate";

import { StyledThankYouPage } from "../../components/atoms/ThankYouPage/StyledThankYouPage";
import { StyledText } from "../../components/atoms/Text/StyledText";

import { verify } from "../../logic/requests/users";

const VerifyAccount = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const email = searchParams.get("email"),
        token = searchParams.get("token");

    class PageState {
        constructor(state) {
            this.value = state;
        }

        static DEFAULT_LOADING = new PageState(0);
        static VERIFICATION_SUCCESS = new PageState(1);
        static VERIFICATION_FAILURE = new PageState(2);
        static NO_TOKEN = new PageState(3);
    }
    const [pageState, setPageState] = useState(PageState.DEFAULT_LOADING.value);

    const [pageErrNo, setPageErrNo] = useState();
    const [pageErrMsg, setPageErrMsg] = useState();

    useEffect(() => {
        if (email && token) {
            // link z e-mail'a
            verify({ email, token })
                .then((res) => {
                    setPageState(PageState.VERIFICATION_SUCCESS.value);
                    sessionService.saveUser(res.user);
                    setTimeout(() => navigate("/"), 5000);
                })
                .catch((err) => {
                    console.log("verify", err);
                    setPageErrNo(err.status);
                    setPageErrMsg(err.data.message);
                    setPageState(PageState.VERIFICATION_FAILURE.value);
                });
        } else if (state && state.email) {
            // przekierowanie z logowania, prześlij jeszcze raz itd.
            setPageState(PageState.NO_TOKEN.value);
        } else {
            // TODO: dokąd najlepiej?
            navigate("/", {
                replace: true,
            });
        }
    }, [email, token]);

    if (pageState === PageState.NO_TOKEN.value) return <ThankYouPage email={state.email} />;

    return (
        <>
            <HelmetTemplate 
                title={getString("seo_verify_account_title")} 
                desc={getString("seo_verify_account_desc")} 
                ogTitle={getString("seo_verify_account_twitter_title")}
            />
            <StyledThankYouPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <RegisterCube registerCubeFill="rgba(0,0,0,0.1)" />
                <LogoLink />
                <LoginLink />
                <StyledText
                    hasdeclaredfontsize="52px"
                    hasdeclaredfontweight="bold"
                    hasdeclaredmargin="0 0 38px 0"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {(() => {
                        switch (pageState) {
                            case PageState.DEFAULT_LOADING.value:
                                return getString("pages_verifyAccount_weryfikowanie");
                            case PageState.VERIFICATION_SUCCESS.value:
                                return getString("pages_verifyAccount_zweryfikowanoPomyslnie");
                            case PageState.VERIFICATION_FAILURE.value:
                                return getString("pages_verifyAccount_bladWeryfikacji");
                            default:
                                return "";
                        }
                    })()}
                </StyledText>
                {pageState === PageState.VERIFICATION_FAILURE.value && pageErrNo && pageErrMsg ? (
                    <>
                        <StyledText
                            hasdeclaredfontsize="26px"
                            hasdeclaredpadding="16px 0"
                            hasdeclaredfontweight="600"
                        >
                            {`${getString("pages_verifyAccount_serwerBackendZwrocilBlad")} ${pageErrNo}; ${getString("pages_verifyAccount_wiadomosc")}`}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredpadding="16px 0"
                            hasdeclaredfontweight="600"
                        >
                            {pageErrMsg}
                        </StyledText>
                    </>
                ) : (
                    <></>
                )}
            </StyledThankYouPage>
        </>
    );
};

export default VerifyAccount;
