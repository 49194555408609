import styled from "styled-components";
import Background from "../../../images/register/background22.jpg";
import { motion } from "framer-motion";

export const StyledRegisterPage = styled(motion.section)`
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60px;
`;
