import styled from "styled-components"

export const StyledGalleryMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding: 10px;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#ee745c rgba(238,116,92, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(238,116,92, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #ee745c;
        border-radius: 6px;
    }

    > div {
        > div{
            ::-webkit-scrollbar {
                background: transparent;
                width: 0;
            }
            ::-webkit-scrollbar-track {
                background: transparent;
                width: 0;
            }
            ::-webkit-scrollbar-thumb {
                background: transparent;
                width: 0;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: transparent;
                width: 0;
            }        
        }
    }

    @media only screen and (max-width: 767px){
        overflow-y: visible;
        width: 100%;
    }
`