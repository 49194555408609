import styled from "styled-components"

export const StyledUPopupCloseButton = styled.div`
    cursor: pointer;
    border-radius: 25px;
    background-color: #6786be;
    height: 50px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`