import styled from "styled-components";

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
    > button {
        width: 48%;
        height: 50px;
    }
    @media only screen and (max-width: 954px) {
        > button {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 520px) {
        > button {
            font-size: 14px;
        }
    }
`;
