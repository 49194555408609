import backendRequest from ".";

export const listHoursRecords = (month) => (
	backendRequest("GET", `/employees/admin/list-hours-records${month ? `?group_by=${month}` : ""}`)
);

export const submitHoursRecord = (data) => (
	backendRequest("POST", "/employees/submit-hours-record", data, "multipart/form-data")
);

export const listTrainersEmployeeConf = () => (
	backendRequest("GET", "/employees/admin/list-trainers")
);
