import styled from "styled-components"

export const StyledQuestionElement = styled.div`
    width: 100%;
    background-color: ${({ isgreybg }) => isgreybg ? "#EEE" : "#fff"};
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
    align-items: center;

    > div {
        padding: 5px;
        > div {
            > div {
                &:last-child{
                    > div {
                        color: #000;
                        min-height: 250px;
                    }
                }
            }
        }
        > input {
            width: 90%;
            padding: 12px;
            border: 1px solid #000;
        }
        &:nth-child(1){
            width: 4%;
        }
        &:nth-child(2){
            width: 40%;
        }
        &:nth-child(3){
            width: 40%;
        }
        &:nth-child(4){
            display: flex;
            width: 16%;
            > a {
                color: #000;
            }
        }
    }
`