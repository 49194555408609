import backendRequest from "./index.js";

const listLessons = () => (
	backendRequest("GET", "/admin/trainings/lessons/list")
);

const updateLesson = (id, status) => (
	backendRequest("POST", "/admin/trainings/lessons/update", { id, status })
);

export {
	listLessons,
	updateLesson
};
