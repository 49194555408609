import styled from "styled-components"

export const StyledChangeLanguageWrapper = styled.div`
    position: relative;
    z-index: 4;
    transform: ${({ $isInputField }) => $isInputField ? null : " translateY(22px)"};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: ${({ $isMarginTop }) => $isMarginTop ? $isMarginTop : null};
    
    @media only screen and (max-width: 485px) {
        transform: ${({ $isInputField }) => $isInputField ? null : " translateY(5px)"};
    }
`