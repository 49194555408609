import styled from "styled-components"

export const StyledInputWrapp = styled.div`
    width: ${({ isfullwidth }) => (isfullwidth ? "100%" : "50%")};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    > div {
        width: ${({ isfullwidthinput }) => isfullwidthinput};
        > input {
            width: 100%;
            max-width: ${({ isfullwidthinput }) => (isfullwidthinput ? "667px" : "300px")};
            min-height: ${({ isfullwidthinput }) => (isfullwidthinput ? "60px" : "unset")};
        }
    }

    @media only screen and (max-width: 1005px){
        width: 100%;
        padding: 0 20px;
        > div {
            width: 100%;
            max-width: unset;
            input{
                max-width: unset;
                width: 100%;
            }
        }
    }
`;