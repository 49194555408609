import styled from "styled-components";

export const StyledAdminPanel = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background-color: var(--dark);
    min-height: 100vh;
    color: var(--white);

    @media only screen and (max-width: 767px){
        padding-top: 40px;
    }
`;
