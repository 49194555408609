import React from 'react'

import { ImageViewer } from "react-image-viewer-dv";

import { StyledCoachElement } from "../../atoms/Coach/StyledCoachElement"
import { StyledText } from '../../atoms/Text/StyledText'
import { StyledBgWrapper } from "../../atoms/Coach/StyledBgWrapper"
import { StyledPhoneWrapper } from "../../atoms/Coach/StyledPhoneWrapper"
import { StyledEmailWrapper } from "../../atoms/Coach/StyledEmailWrapper"

import { getLang, getDefaultLang, getString } from '../../../strings';

const CoachElement = ({ data }) => {
    if (!data instanceof Object)
        return undefined;

    return (
        <StyledCoachElement>
            <StyledText
                hasdeclaredfontsize="34px"
                hasdeclaredfontweight="700"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="0 0 10px 0"
                hasdeclaredlineheight="1.3em"
                as="h2"
            >
                {
                    (data.firstname && data.lastname) ? (
                        `${data.firstname} ${data.lastname}`
                    ) : ""
                }
            </StyledText>
            <StyledBgWrapper hasavatar={data.has_avatar}>
                {
                    (data.has_avatar) ? (
                        <div>
                            <ImageViewer>
                                <img src={`/api/trainings/trainers/avatar/${data.id}`} />
                            </ImageViewer>
                        </div>
                    ) : (undefined)
                }
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {data.description?.[getLang()] ?? data.description?.[getDefaultLang()] ?? ""}
                </StyledText>
            </StyledBgWrapper>
            {
                (data.phone) ? (
                    <StyledPhoneWrapper>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.3em"
                            hasdeclaredfontcolor="#6786be"
                        >
                            {getString("CoachElement__phone_title")}:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.3em"
                        >
                            <a aria-label={getString("seo_aria_label_zadzwon")} href={`tel:${data.phone}`}>{data.phone}</a>
                        </StyledText>
                    </StyledPhoneWrapper>
                ) : (undefined)
            }
            {
                (data.email) ? (
                    <StyledEmailWrapper>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.3em"
                            hasdeclaredfontcolor="#6786be"
                        >
                            {getString("CoachElement__email_title")}:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.3em"
                        >
                            <a aria-label={getString("seo_aria_label_wyslij_email")} href={`mailito:${data.email}`}>{data.email}</a>
                        </StyledText>
                    </StyledEmailWrapper>
                ) : (undefined)
            }
        </StyledCoachElement>
    );
}

export default CoachElement
