import styled from "styled-components"

export const StyledDoneDataElementWrapperData = styled.div`
    width: ${({ checkboxes }) => checkboxes ? "calc(100% - 41px)" : "100%"};
    height: 100%;
    background: #FFF;
    border: 4px solid #6786BE;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    padding: 5px 0;
`