import styled from "styled-components"

export const StyledPayHour = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > div {
        &:first-child{
            width: calc(100% - 104px);
            overflow-x: scroll;

            scrollbar-color: transparent transparent;
            scrollbar-width: thin;
        
            ::-webkit-scrollbar {
                height: 6px;
            }
            ::-webkit-scrollbar-track {
                background: transparent;
            }
            ::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
    }
`