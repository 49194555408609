import { getCookie, setCookie } from "../logic/cookies.js";

import timetableMappingPL from "./timetableMappingPL.json";
import timetablePartialStringsPL from "./timetablePartialStringsPL.json";

import strings from "./customLabels";
import { cookie_name, default_lang } from "./common.js";

const langList = Object.keys(strings);

const checkLang = (lang) => langList.includes(lang),
	english_fucking_sucks = (lang) => lang.match(/^en-[A-Z]+$/) ? "gb" : lang;

let lang = (
	() => {
		for (
			const lang of [
				getCookie(cookie_name),
				english_fucking_sucks(navigator.language)
			]
		) {
			if (!lang)
				continue;

			const our_lang = lang.includes('-') ? lang.split('-')[0] : lang;
			if (checkLang(our_lang))
				return our_lang;
		}

		return default_lang;
	}
)();

const getString = (name) => (
	strings[lang][name] ?? strings[default_lang][name] ?? name
);

const getStringNameForTimetableString = (str) => (
	str ? (
		timetableMappingPL[
			Object.keys(timetableMappingPL)
			.find(
				key => key.toLowerCase() === str.toLowerCase()
			)
		]
	) : (str)
);

const escapeRegExp = (str) => (
	str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
);

const translateTimetablePart = (str, part) => (
	str.replace(
		RegExp(`(^|\\s)(?:${escapeRegExp(part)})(\\s|$)`),
		"$1" + getString(getStringNameForTimetableString(part)) + "$2"
	)
);

const getTimetableString = (str) => {
	let ret = getString(getStringNameForTimetableString(str));

	if (!ret) {
		ret = str;
		timetablePartialStringsPL.forEach(
			part => {
				if (ret.indexOf(part) > -1)
					ret = translateTimetablePart(ret, part);
			}
		);
	}

	return ret;
};

const getLang = () => lang;

const setLang = (val) => {
	if (!checkLang(val))
		return false;

	return (
		(lang = val) && setCookie(cookie_name, val)
	);
};

export {
	getLocale,
	getDefaultLang
} from "./common.js";

export {
	strings,
	langList,
	getString,
	getTimetableString,
	getLang,
	setLang
};
