import styled from "styled-components";

export const StyledTextWrapper = styled.div`
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : "auto")};
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: ${({ hasdeclaredjustify }) =>
        hasdeclaredjustify ? hasdeclaredjustify : "flex-end"};
    overflow: hidden;
    -moz-overflow: hidden;
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : "#fff"};
    padding: 4px 4px 4px 12px;
    @media only screen and (max-width: 1170px) {
        p {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 768px) {
        display: ${({ ishide }) => (ishide ? "none" : "flex")};
    }
    @media only screen and (max-width: 634px) {
        width: ${({ hasdeclaredwidth, iswider }) => (iswider ? "32%" : hasdeclaredwidth)};
    }
`;
