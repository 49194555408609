import * as Yup from "yup";

import { getString } from "../../strings";
import { validationSchema as userDataValidationSchema } from "./userDataValidationSchema";

export const validationSchema = Yup.object({
    ...userDataValidationSchema,
    country: Yup.string().required(getString("const_userProfileEditValidationSchema_podajPanstwo")),
    competition222: Yup.bool(),
    competition333: Yup.bool(),
    competition333bf: Yup.bool(),
    competition333oh: Yup.bool(),
    competition444: Yup.bool(),
    competition555: Yup.bool(),
    competition666: Yup.bool(),
    competition777: Yup.bool(),
    competitionClock: Yup.bool(),
    competitionMinx: Yup.bool(),
    competitionPyram: Yup.bool(),
    competitionSkewb: Yup.bool(),
    competitionSq1: Yup.bool(),
    delegate_email: (
        Yup.string()
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Email niepoprawny"
            )
            .nullable()
            .notRequired()
    )
});
