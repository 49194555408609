import styled from "styled-components";

export const StyledLeftTournamentWrapper = styled.div`
    width: 100%;

    > a {
        text-decoration: none;
        color: #000;
        transition: opacity 250ms linear;

        &:hover {
            opacity: 0.8;
        }
    }

    @media only screen and (max-width: 1017px) {
        > h2,
        > a h2 {
            font-size: 42px;
        }
    }

    @media only screen and (max-width: 922px) {
        > h2,
        > a h2 {
            font-size: 38px;
        }
    }

    @media only screen and (max-width: 796px) {
        > h2,
        > a h2 {
            text-align: center;
            line-height: 1.2em;
        }
    }
    
    @media only screen and (max-width: 420px) {
        > h2,
        > a h2 {
            font-size: 22px;
            padding-bottom: 8px;
        }
    }
`;
