import React from 'react'

import { StyledCube444 } from "../../atoms/Competitions/StyledCube444";
import { StyledCubeMinx } from "../../atoms/Competitions/StyledCubeMinx";
import { StyledMobileContactCube } from "../../atoms/Contact/StyledMobileContactCube";


const MobileContactCube = () => {
    return (
        <StyledMobileContactCube>
            <StyledCube444 />
            <StyledCubeMinx  />
        </StyledMobileContactCube>
    )
}

export default MobileContactCube
