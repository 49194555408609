import React from "react";

import { getString, getLang } from "../../../strings";

import { StyledCard } from "../../molecules/Home/StyledCard";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledContactSvg } from "../../atoms/Home/StyledContactSvg";
import { StyledContectPhoneSvg } from "../../atoms/Home/StyledContectPhoneSvg";
import { StyledTournamentLink } from "../../atoms/Home/StyledTournamentLink";
import { StyledContactSvgWrapper } from "../../atoms/Home/StyledContactSvgWrapper";
import { StyledCardBackgroundImage } from "../../atoms/Home/StyledCardBackgroundImage";

import mobileContact from "../../../images/mobile/kontaktMobile.jpg";
import useWindowSize from "../../../utils/getWindowSize";

const HomePageCardContact = () => {
    const width = useWindowSize();
    return (
        <StyledTournamentLink  isang={getLang() === "gb" ? true : false} to="/contact">
            <StyledCard isang={getLang() === "gb" ? true : false}>
                {width < 768 ? 
                    <StyledCardBackgroundImage src={mobileContact} alt="tournaments" /> : null
                }
                <StyledContactSvgWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                >
                    <StyledContactSvg />
                    <StyledContectPhoneSvg />
                </StyledContactSvgWrapper>
                <StyledText
                    hasdeclaredfontsize="72px"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredfontweight="600"
                    hascursor="pointer"
                    hasdeclaredpadding="30px 0 0 0"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                    as="h2"
                >
                    {getString("home_homePageCardContact_kontakt")}
                </StyledText>
            </StyledCard>
        </StyledTournamentLink>
    );
};

export default HomePageCardContact;
