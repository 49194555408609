import styled from "styled-components"
import { motion } from "framer-motion";

export const StyledOrderRek = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: ${({ issend }) => (issend ? "100%" : "90%")};
    overflow-y: ${({ issend }) => (issend ? "visible" : "scroll")};
    overflow-x: hidden;
    
    scrollbar-color: ${({ isscrollbar }) =>
        isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;
        > h1 {
            font-size: 24px;
        }
        > h2 {
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 767px){
        > h1 {
            font-size: 20px;
        }
    }
`;