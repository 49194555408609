import styled from "styled-components"

export const StyledCodeWrapper = styled.div`
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`