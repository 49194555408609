import styled from "styled-components";

export const StyledUserEditPanel = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 926px) {
        padding: 0 20px 40px 20px;
    }
`;
