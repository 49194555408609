import styled from "styled-components";
import { ReactComponent as Tournaments } from "../../../images/home/treningi.svg";

export const StyledTrainingSvg = styled(Tournaments)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: #000;

    @media only screen and (min-width: 1920px) {
        height: 270px;
        width: 420px;
    }

    @media only screen and (max-width: 943px) {
        height: 115px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 400px;
        height: 180px;
    }
    
    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
`;
