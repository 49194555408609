import styled from "styled-components";

import loginBackgrund from "../../../images/LoginPage/LoginBackground.png";

export const StyledLoginPageShape = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background: url(${loginBackgrund}) no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    background-position: center;
`;
