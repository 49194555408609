import React from 'react'
import AdminTournaments from "../organisms/AdminTournaments/AdminTournaments";
import AdminTemplate from "../templates/AdminTemplate";

const AdminTournamentsPage = () => {
    return (
        <AdminTemplate>
            <AdminTournaments />
        </AdminTemplate>
    )
}

export default AdminTournamentsPage
