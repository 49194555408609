import styled from "styled-components"

export const StyledInputWrappSelect = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 667px;
    > div {
        width: 100%;
        > select {
            height: 60px;
            max-width: unset;
        }
    }

    @media only screen and (max-width: 1005px) {
        padding: 0 20px;
        max-width: unset;
    }
`;