import styled from "styled-components";

export const StyledHeart = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    > svg {
        width: 100%;
        height: 100%;
        cursor: pointer;
        fill: ${({ hasfill }) => (hasfill ? "#fff" : "none")};
    }
`;
