import styled from "styled-components";

export const StyledIconNameWrapper = styled.div`
    width: 75%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1118px) {
        > p {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 1084px) {
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 1084px) {
        width: 70%;
    }
    @media only screen and (max-width: 921px) {
        > p {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 767px) {
        > p {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 728px) {
        display: none;
    }
`;
