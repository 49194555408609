import React from "react";
import { getString } from "../../../strings";
import { StyledSeriesResultsIcon } from "./StyledSeriesResultsIcon";

import { ReactComponent as ImageCompetition222 } from "../../../images/cubes/222.svg";
import { ReactComponent as ImageCompetition333 } from "../../../images/cubes/333.svg";
import { ReactComponent as ImageCompetition444 } from "../../../images/cubes/444.svg";
import { ReactComponent as ImageCompetition555 } from "../../../images/cubes/555.svg";
import { ReactComponent as ImageCompetition666 } from "../../../images/cubes/666.svg";
import { ReactComponent as ImageCompetition777 } from "../../../images/cubes/777.svg";
import { ReactComponent as ImageCompetitionSkewb } from "../../../images/cubes/skewb.svg";
import { ReactComponent as ImageCompetitionSq1 } from "../../../images/cubes/sq1.svg";
import { ReactComponent as ImageCompetitionPyram } from "../../../images/cubes/pyram.svg";
import { ReactComponent as ImageCompetitionMinx } from "../../../images/cubes/minx.svg";
import { ReactComponent as ImageCompetition333oh } from "../../../images/cubes/333oh.svg";
import { ReactComponent as ImageCompetition333bf } from "../../../images/cubes/333bf.svg";
import { ReactComponent as ImageCompetition333fm } from "../../../images/cubes/333fm.svg";
import { ReactComponent as ImageCompetition333mbf } from "../../../images/cubes/333mbf.svg";
import { ReactComponent as ImageCompetitionClock } from "../../../images/cubes/clock.svg";
import { ReactComponent as ImageCompetition444bf } from "../../../images/cubes/444bf.svg";
import { ReactComponent as ImageCompetition555bf } from "../../../images/cubes/555bf.svg";

const SeriesResultsIcon = ({ iconName }) => {
    return (
        <StyledSeriesResultsIcon>
            {(() => {
                switch (iconName) {
                    case "222":
                        return <ImageCompetition222 title={getString("const_competitions_222")} />;
                    case "555":
                        return <ImageCompetition555 title={getString("const_competitions_555")} />;
                    case "333":
                        return <ImageCompetition333 title={getString("const_competitions_333")} />;
                    case "444":
                        return <ImageCompetition444 title={getString("const_competitions_444")} />;
                    case "666":
                        return <ImageCompetition666 title={getString("const_competitions_666")} />;
                    case "777":
                        return <ImageCompetition777 title={getString("const_competitions_777")} />;
                    case "skewb":
                        return <ImageCompetitionSkewb title={getString("const_competitions_skewb")} />;
                    case "sq1":
                        return <ImageCompetitionSq1 title={getString("const_competitions_sq1")} />;
                    case "pyram":
                        return <ImageCompetitionPyram title={getString("const_competitions_pyram")} />;
                    case "minx":
                        return <ImageCompetitionMinx title={getString("const_competitions_minx")} />;
                    case "333oh":
                        return <ImageCompetition333oh title={getString("const_competitions_333oh")} />;
                    case "333bf":
                        return <ImageCompetition333bf title={getString("const_competitions_333bf")} />;
                    case "333fm":
                        return <ImageCompetition333fm title={getString("const_competitions_333fm")} />;
                    case "333mbf":
                        return <ImageCompetition333mbf title={getString("const_competitions_333mbf")} />;
                    case "clock":
                        return <ImageCompetitionClock title={getString("const_competitions_clock")} />;
                    case "444bf":
                        return <ImageCompetition444bf title={getString("const_competitions_444bf")} />;
                    case "555bf":
                        return <ImageCompetition555bf title={getString("const_competitions_555bf")} />;
                    default:
                        return <></>;
                }
            })()}
        </StyledSeriesResultsIcon>
    );
};

export default SeriesResultsIcon;
