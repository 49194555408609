import { useEffect, useState } from "react";

const useSuccessMessage = () => {
	const [infos, setInfos] = useState();
    useEffect(
        () => {
            if (!infos)
                return;

            const timeout = setTimeout(setInfos, 3000);
            return () => clearTimeout(timeout);
        }, [infos]
    );

	return {
		infos,
		isShowing: infos?.length,
		show: setInfos,
	};
};

export default useSuccessMessage;
