import styled from "styled-components"

export const StyledPartnerElement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;

    > a {
        height: 50px;
        width: 240px;
        > button {
            height: 100%;
            width: 100%;
            text-align: center;
        }
    }

    @media only screen and (max-width: 620px) {
        margin-bottom: 80px;
    }
`;
