import styled from "styled-components"

export const StyledLogoWrapper = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    > svg {
        width: 30%;
        height: 30%;
        object-fit: contain;
    }
    @media only screen and (max-width: 926px) {
        width: 100%;
        display: flex;
        justify-content: center;
        > svg {
            height: 30%;
            max-height: 120px;
        }
    }
`;