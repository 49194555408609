import React, { useState } from 'react'
import { getString } from "../../../strings";
import IconsMobileResults from "../../molecules/Series/IconsMobileResults"

import { StyledText } from '../../atoms/Text/StyledText'
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledSeriesResultsMobileElement } from "../../atoms/Series/StyledSeriesResultsMobileElement";
import { StyledSeriesResultsMobileNameWrapper } from "../../molecules/Series/StyledSeriesResultsMobileNameWrapper";
import { StyledSeriesResultsMobileWrapper } from "../../atoms/Series/StyledSeriesResultsMobileWrapper";
import { StyledNumberMobileWrapper } from "../../atoms/Series/StyledNumberMobileWrapper";
import { StyledIconsMobileResultsWrapper } from "../../molecules/Series/StyledIconsMobileResultsWrapper";
import { StyledRomanizeTextWrapper } from "../../molecules/Series/StyledRomanizeTextWrapper";

import { romanize } from "../../constants/romanize";

import { ACTIVERESULTSTYPE } from '../../constants/Series';

const SeriesResultsMobileElement = ({ number, leagueNumbers, result, activeResults, redcolor, bottomGap = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledSeriesResultsMobileElement
        hasdeclaredmargin={bottomGap ? "0 0 45px 0" : undefined}
    >
        <StyledNumberMobileWrapper>
            <StyledText
                hasdeclaredfontsize="24px"
                hasdeclaredfontcolor="#fff"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
            >
                {number}
            </StyledText>
        </StyledNumberMobileWrapper>
        <StyledSeriesResultsMobileWrapper onClick={() => setIsOpen(!isOpen)} redcolor={redcolor}>
            <StyledSeriesResultsMobileNameWrapper>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hascursor="pointer"
                    hasdeclaredpadding="0 12px"
                    hasdeclaredlineheight="1.4em"
                >
                    {`${result.firstname} ${result.lastname}`}
                </StyledText>
                <StyledArrow isopen={isOpen} blackcolor={true}/>
            </StyledSeriesResultsMobileNameWrapper>
            {isOpen && 
                <StyledIconsMobileResultsWrapper>
                    <div>
                        {
                            leagueNumbers.concat(activeResults?.type === ACTIVERESULTSTYPE.EVENT ? [ "bonus" ] : []).map(
                                activeResults?.type === ACTIVERESULTSTYPE.SERIES ? (
                                    (number, index) => (
                                        <StyledRomanizeTextWrapper key={number}>
                                            <StyledText
                                                hasdeclaredfontsize="18px"
                                                hasdeclaredfontcolor="#000"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                            >
                                                {romanize(index + 1)}
                                            </StyledText>
                                            <StyledText
                                                hasdeclaredfontsize="18px"
                                                hasdeclaredfontcolor="#000"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                            >
                                                {result?.events?.[number] ?? '-'}
                                            </StyledText>
                                        </StyledRomanizeTextWrapper>
                                    )
                                ) : activeResults?.type === ACTIVERESULTSTYPE.EVENT ? (
                                    number => (
                                        <IconsMobileResults
                                            key={number}
                                            result={
                                                number === "bonus" ? (
                                                    result?.bonus
                                                ) : (
                                                    result?.competitions?.find(
                                                        competition => competition.id === number
                                                    )?.score ?? '-'
                                                )
                                            }
                                            icon={number}
                                        />
                                    )
                                ) : null
                            )
                        }
                    </div>
                    <StyledText 
                        hasdeclaredfontsize="22px" 
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="12px 12px 22px 12px"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString("series_seriesResultsMobileElement_sumaPunktow")}: {result.total}
                    </StyledText>
                </StyledIconsMobileResultsWrapper> 
            }
        </StyledSeriesResultsMobileWrapper>
    </StyledSeriesResultsMobileElement>
  )
}

export default SeriesResultsMobileElement