import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getString } from "../../strings";
import LogoLink from "../organisms/Logo/LogoLink";
import LoginLink from "../organisms/LoginLink/LoginLink";
import RegisterCube from "../molecules/RegisterCube/RegisterCube";
import HelmetTemplate from "../molecules/HelmetTemplate/HelmetTemplate";

import { StyledThankYouPage } from "../../components/atoms/ThankYouPage/StyledThankYouPage";
import { StyledText } from "../../components/atoms/Text/StyledText";
import { StyledButton } from "../atoms/Button/StyledButton";
import { StyledEmailInput } from "../atoms/ThankYouPage/StyledEmailInput";
import { StyledContentWrapper } from "../atoms/ThankYouPage/StyledContentWrapper";

import { resetPassword } from "../../logic/requests/users";

const ResetPassword = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isReset, setIsReset] = useState(false);
    const [isErr, setIsErr] = useState(false);
    const passwordInput = useRef(),
        passwordConfirmInput = useRef();

    useEffect(() => {
        if (!(searchParams?.get("email") && searchParams?.get("token")))
            navigate("/", { replace: true });
    }, [searchParams, navigate]);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();

        setIsErr(false);
        setIsReset(false);

        const password = passwordInput?.current?.value,
            confirm_password = passwordConfirmInput?.current?.value;

        if (!password) {
            // TODO: pokazać błąd
            return;
        }
        if (!confirm_password) {
            // TODO: pokazać błąd
            return;
        }

        if (password !== confirm_password) {
            // TODO: pokazać błąd
            return;
        }

        resetPassword(searchParams.get("email"), password, searchParams.get("token"))
            .then((res) => {
                console.log("resetPassword", "succ", res);
                setIsReset(true);
                setTimeout(() => navigate("/login", { replace: true }), 5000);
            })
            .catch((err) => {
                console.error("resetPassword", err);
                setIsErr(true);
            });
    }, []);

    return (
        <>
            <HelmetTemplate 
                title={getString("seo_forgotten_password_title")} 
                desc={getString( "seo_forgotten_password_desc")} 
                ogTitle={getString("seo_forgotten_password_twitter_title")}
            />
            <StyledThankYouPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <RegisterCube registerCubeFill="rgba(0,0,0,0.1)" />
                <LogoLink />
                <LoginLink />
                <StyledContentWrapper>
                    <form onSubmit={handleSubmit}>
                        <StyledText
                            hasdeclaredfontsize="34px"
                            hasdeclaredfontweight="bold"
                            hasdeclaredmargin="0 0 12px 0"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="0 20px"
                            as="h1"
                        >
                            {getString("pages_resetPassword_podajNoweHaslo")}
                        </StyledText>
                        <StyledEmailInput type="password" ref={passwordInput} />
                        <StyledText
                            hasdeclaredfontsize="34px"
                            hasdeclaredfontweight="bold"
                            hasdeclaredmargin="0 0 12px 0"
                        >
                            {getString("pages_resetPassword_powtorzHaslo")}
                        </StyledText>
                        <StyledEmailInput type="password" ref={passwordConfirmInput} />
                        <StyledButton
                            hasdeclaredbgcolor="#6786be"
                            hasdeclaredpadding="12px 44px"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredfontweight="bold"
                            hasdeclaredborderradius="40px"
                            hasdeclaredfontsize="22px"
                            hasdeclaredcursor="pointer"
                            whileHover={{ opacity: 0.8 }}
                            type="submit"
                        >
                            {getString("pages_resetPassword_resetuj")}
                        </StyledButton>
                    </form>
                    {isErr && (
                        <StyledText
                            hasdeclaredmargin="32px 0 0 0"
                            hasdeclaredfontsize="24px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#E84526"
                            hasdeclaredtextalign="center"
                        >
                            {getString("pages_resetPassword_wystapilBladPodczasZmianyHasla")}
                        </StyledText>
                    )}
                    {isReset && (
                        <StyledText
                            hasdeclaredmargin="32px 0 0 0"
                            hasdeclaredfontsize="24px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("pages_resetPassword_hasloZostaloZmienione")}
                        </StyledText>
                    )}
                </StyledContentWrapper>
            </StyledThankYouPage>
        </>
    );
};

export default ResetPassword;
