import styled from "styled-components";

export const StyledRightTournamentWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 796px) {
        margin-top: 22px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        a {
            margin-top: 34px;
        }
    }
`;
