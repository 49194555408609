import styled from "styled-components"

export const StyledScrollWrapper = styled.div`
    margin-top: 8px;
    overflow-y: scroll;
    padding-right: 10px;
    padding-bottom: 10px;
    height: calc(100% - 110px);
    width: 100%;

    scrollbar-color: ${({ isscrollbar }) =>
        isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (max-width: 926px){
        padding: 0 20px;
        overflow-y: visible;
        height: auto;
    }
`