import styled from "styled-components"

export const StyledPreferencesHeading = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 0;

    @media only screen and (max-width: 926px){
        padding-left: 20px;
    }
`