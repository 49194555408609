import React from "react";
import AdminHeader from "../organisms/AdminHeader/AdminHeader";

import { StyledAdminPanel } from "../atoms/AdminPanel/StyledAdminPanel";

import AdminGlobalStyle from "../../styles/AdminGlobalStyle";

const AdminTemplate = ({ children }) => {
    return (
        <>
            <AdminGlobalStyle />
            <StyledAdminPanel>
                <AdminHeader />
                {children}
            </StyledAdminPanel>
        </>
    );
};

export default AdminTemplate;
