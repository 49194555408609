import styled from "styled-components";

export const StyledContentWrapper = styled.div`
    width: 100%;
    border-radius: 40px;
    background-color: #fff;
    border: 10px solid #6786be;
    padding: 16px;
    box-sizing: border-box;

    > a {
        text-decoration: none;
        color: #000;
        cursor: pointer;
    }

    @media only screen and (max-width: 926px){
        > a{
            h2{
                text-align: center;
            }
        }
    }
`;
