import React, { useState, useEffect, useMemo } from "react";
import Decimal from "decimal.js";

import StudentsPaymentsElement from "./StudentsPaymentsElement";
import Loader from "../Loader/Loader";

import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledUserPanelStudentsPayments } from "../../atoms/UserPanelStudentsPayments/StyledUserPanelStudentsPayments";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledScrollWrapper } from "../../atoms/UserPanelStudentsPayments/StyledScrollWrapper";
import { StyledSumElement } from "../../atoms/UserPanelTrainerPay/StyledSumElement";
import { StyledSumRow } from "../../atoms/UserPanelTrainerPay/StyledSumRow";

import { getString } from "../../../strings";
import { listHours } from "../../../logic/requests/students";
import { dateKeyCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelStudentsPayments = () => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const [payments, setPayments] = useState();
    const [err, setErr] = useState(false);
    useEffect(
        () => {
            listHours()
            .then(
                (res) => {
                    console.log("listHours", res);
                    setPayments(
                        Object.fromEntries(
                            Object.entries(res)
                            .map(
                                ([key, val]) => [key, Object.fromEntries(
                                    Object.entries(val)
                                    .map(([key, val]) => [
                                        key,
                                        val
                                        .sort((a, b) => {
                                            const student_id = a.student.id - b.student.id;
                                            if (student_id)
                                                return student_id;

                                            const a_date = new Date(a.date), b_date = new Date(b.date);
                                            const date = b_date.getTime() - a_date.getTime();
                                                return date;
                                        })
                                    ])
                                    .sort(dateKeyCompare)
                                )]
                            )
                        )
                    );
                }
            )
            .catch(
                (err) => {
                    console.error("listHours", err);
                    setErr(true);
                }
            )
        }, [setPayments, setErr]
    );

    const totalUnpaidHours = useMemo(
        () => {
            if (!payments)
                return 0;

            return Object.values(payments.unpaid).reduce(
                (prev, cur) => prev.plus(
                    cur.reduce(
                        (prev, cur) => prev.plus(cur.hours), new Decimal(0)
                    )
                ), new Decimal(0)
            ).toString();
        }, [payments]
    );

    const totalUnpaidDues = useMemo(
        () => {
            if (!payments)
                return 0;

            return Object.values(payments.unpaid).reduce(
                (prev, cur) => prev.plus(
                    cur.reduce(
                        (prev, cur) => prev.plus(cur.price), new Decimal(0)
                    )
                ), new Decimal(0)
            ).toFixed(2);
        }, [payments]
    );

    const oldUnpaidHours = useMemo(
        () => {
            if (!payments)
                return 0;

            const now = new Date();
            const month = `${now.getFullYear()}-${now.getMonth()}`;

            const monthPayments = payments.unpaid[month];
            if (!monthPayments)
                return 0;

            const newUnpaid = (
                monthPayments
                .reduce(
                    (prev, cur) => prev.plus(cur.hours), new Decimal(0)
                )
            );

            return new Decimal(totalUnpaidHours).minus(newUnpaid).toString();
        }, [totalUnpaidHours, payments]
    );

    const oldUnpaidDues = useMemo(
        () => {
            if (!payments)
                return 0;

            const now = new Date();
            const month = `${now.getFullYear()}-${now.getMonth()}`;

            const monthPayments = payments.unpaid[month];
            if (!monthPayments)
                return 0;

            const newUnpaid = (
                monthPayments
                .reduce(
                    (prev, cur) => prev.plus(cur.price), new Decimal(0)
                )
            );

            return new Decimal(totalUnpaidDues).minus(newUnpaid).toString();
        }, [totalUnpaidDues, payments]
    );

    return (
        (payments) ? (
            (Object.keys(payments).length) ? (
                <>
                <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                            >
                                {getString("Trainings_trainings_all_trainer_pay_razem")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {totalUnpaidDues} PLN
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("Trainings_trainings_all_trainer_pay_godziny2")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {totalUnpaidHours} H
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("UserPanelStudentsPayments__summary__old_dues")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {oldUnpaidDues} PLN
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("UserPanelStudentsPayments__summary__old_hours")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {oldUnpaidHours} H
                            </StyledText>
                        </StyledSumRow>
                    </StyledSumElement>
                <StyledUserPanelStudentsPayments>
                    <StyledText
                        hasdeclaredpadding="20px 20px 30px 0"
                        hasdeclaredfontsize="36px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString("user_panel_students_payments")}:
                    </StyledText>
                    <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredmargin="0 0 20px 0"
                        >
                            Nieopłacone
                        </StyledText>
                        {
                            Object.entries(payments.unpaid).map(
                                ([month, hours]) => (
                                    <StudentsPaymentsElement
                                        key={month}
                                        month={month}
                                        hours={hours}
                                        other={payments.paid[month]}
                                        trigger={trigger}
                                    />
                                )
                            )
                        }
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredmargin="0 0 20px 0"
                        >
                            Opłacone
                        </StyledText>
                        {
                            Object.entries(payments.paid).map(
                                ([month, hours]) => (
                                    <StudentsPaymentsElement
                                        key={month}
                                        month={month}
                                        hours={hours}
                                        other={payments.unpaid[month]}
                                        paid
                                        trigger={trigger}
                                    />
                                )
                            )
                        }
                    </StyledScrollWrapper>
                </StyledUserPanelStudentsPayments>
                </>
            ) : (
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("user_panel_students_payments_no_payments")}
                </StyledText>
            )
        ) : (
            (err) ? (
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("blad_wczytywania")}
                </StyledText>
            ) : (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            )
        )
    );
};

export default UserPanelStudentsPayments;
