import styled from "styled-components";

export const StyledScriptHeading = styled.div`
    padding-bottom: 8px;
    margin-top: 22px;
    display: flex;
    align-items: center;
    > p {
        width: 32%;
        display: block;
        padding: 0 4px;
        margin-right: 3%;
        text-align: center;
    }
    @media only screen and (min-width: 1920px) {
        > p {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 1084px) {
        > p {
            font-size: 11px;
        }
    }
    @media only screen and (max-width: 926px) {
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 728px) {
        > p {
            display: none;
        }
    }
`;
