import styled from "styled-components"

export const StyledModalImage = styled.div`
    width: auto;
    max-width: 90%;
    height: 60%;
    margin: 0 auto;
    position: relative;
    > div { 
        width: 100%;
        height: 100%;
        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media only screen and (max-width: 767px){
        height: 50%;
        > div {
            > img {
                object-fit: scale-down;
            }
        }
    }
`;