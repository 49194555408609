import styled from "styled-components";

export const StyledLeftWrapper = styled.div`
    width: 45%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1298px) {
        > p {
            font-size: 52px;
        }
    }
    @media only screen and (max-width: 1126px) {
        width: 60%;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 35vh;
    }
    @media only screen and (max-width: 488px) {
        > p {
            font-size: 36px;
        }
    }
    @media only screen and (max-width: 360px) {
        > p {
            font-size: 28px;
        }
    }
`;
