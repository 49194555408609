import styled from "styled-components"

export const StyledGlobalScrollWrapper = styled.div`
    width: 100%;
    margin-top: 8px;
    overflow-y: scroll;
    height: ${({ $hasDeclaredHeight }) => $hasDeclaredHeight ? $hasDeclaredHeight : "calc(100% - 104px)"};
    padding-right: 15px;

    scrollbar-color: ${({ isscrollbar }) =>
        isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (max-width: 926px){
        padding: 0 20px;
        overflow-y: visible;
        height: auto;
    }
`