import styled from "styled-components"

export const StyledSeriesResultsMobileWrapper = styled.div`
    background: ${({ redcolor }) => redcolor ? "linear-gradient(90deg, rgba(238,116,91,1) 0%, rgba(255,255,255,1) 100%);": "#fff"};
    width: calc(100% - 65px - 12px);
    border: 2px solid #000;
    border-radius: 25px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
`