import React, { useState, useEffect, useRef, useCallback } from "react";

import Loader from "../Loader/Loader";
import UserPanelPreferencesElement from "./UserPanelPreferencesElement";

import { StyledUserPanelPreferences } from "../../atoms/UserPanelPreferences/StyledUserPanelPreferences";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledPreferencesHeading } from "../../atoms/UserPanelPreferences/StyledPreferencesHeading";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledPreferencesScrollWrapper } from "../../atoms/UserPanelPreferences/StyledPreferencesScrollWrapper";
import { StyledInputwrapper } from "../../atoms/TrackHours/StyledInputwrapper";
import { StyledInputsButtonsWrapper } from "../../atoms/TrackHours/StyledInputsButtonsWrapper";
import { StyledSelects } from "../../atoms/UserPanelPreferences/StyledSelects";

import { getString } from "../../../strings";
import { addStudentPreference, listStudents, listTrainers } from "../../../logic/requests/students";
import { useMemo } from "react";
import { cebulaCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelPreferences = ({
    user
}) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const [students, setStudents] = useState();
    const loadStudents = useCallback(
        () => {
            listStudents()
                .then(
                    (res) => {
                        console.log("listStudents", res);
                        setStudents(
                            res
                            .map(
                                (student) => ({
                                    ...student,
                                    name: `${student.lastname} ${student.firstname}`
                                })
                            )
                            .sort(
                                (a, b) => cebulaCompare(a.name, b.name)
                            )
                        );
                    }
                )
                .catch(
                    (err) => {
                        console.error("listStudents", err);
                    }
                )
            ;
        }, [setStudents]
    );
    useEffect(loadStudents, [loadStudents]);

    const [trainers, setTrainers] = useState();
    useEffect(
        () => {
            listTrainers()
                .then(
                    (res) => {
                        console.log("listTrainers", res);
                        setTrainers(
                            res
                            .map(
                                (trainer) => ({
                                    ...trainer,
                                    name: `${trainer.lastname} ${trainer.firstname}`
                                })
                            )
                        );
                    }
                )
                .catch(
                    (err) => {
                        console.error("listTrainers", err);
                    }
                )
            ;
        }, [setTrainers]
    );

    const preferences = useMemo(
        () => (
            (students && trainers) ? (
                Object.entries(
                    students.reduce(
                        (preferences, student) => {
                            Object.entries(student.preferences).forEach(
                                ([trainer, preference]) => {
                                    if (!preferences[trainer])
                                        preferences[trainer] = {};
    
                                    const prefs = preferences[trainer];
                                    prefs[student.id] = {
                                        student,
                                        value: parseInt(preference, 10)
                                    };
                                }
                            )
    
                            return preferences;
                        }, {}
                    )
                )
                .map(
                    ([key, val]) => ({
                        trainer: trainers.find((trainer) => trainer.id === parseInt(key, 10)),
                        ...val
                    })
                )
                .sort(
                    ({ trainer: { name: a } }, { trainer: { name: b } }) => cebulaCompare(a, b)
                )
            ) : (undefined)
        ), [students, trainers]
    );

    const extended = useMemo(
        () => (
            ["trainer_new", "manager", "admin"]
            .some(
                (role) => (user.roles.includes(role))
            )
        )
    );

    const refs = {
        student: useRef(),
        trainer: useRef(),
        preference: useRef()
    };

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();

            const data = Object.fromEntries(Object.entries(refs).map(
                ([field, ref]) => ([field, parseInt(ref.current.value, 10)])
            ));

            if (Object.values(data).some(value => isNaN(value))) {
                console.warn("handleSubmit", "invalid data", refs);
                return;
            }

            addStudentPreference(data)
            .then(
                (res) => {
                    console.log("handleSubmit", res);
                    loadStudents();
                }
            )
            .catch(
                (err) => {
                    console.error("handleSubmit", err);
                }
            )
        }, [loadStudents]
    );

    return (
        <StyledUserPanelPreferences>
            {!(students && trainers) ? (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            ) : (
                <>
                    <StyledPreferencesHeading>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString("Trainings_trainings_userPreferences_preferences")}:
                        </StyledText>
                    </StyledPreferencesHeading>
                    <StyledPreferencesScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        {
                            (extended) ? (
                                <form onSubmit={handleSubmit}>
                                    <StyledInputwrapper hasdeclaredwidth="100%">
                                        <label htmlFor="countTime">
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredpadding="8px 0 8px 0"
                                                hasdeclaredfontweight="600"
                                            >
                                                {getString("Trainings_trainings_userPreferences_user")}
                                            </StyledText>
                                        </label>
                                        <select
                                            defaultValue=""
                                            ref={refs.student}
                                        >
                                            <option value="">- {getString("UserPanelPreferences__form__select_student")} -</option>
                                            {
                                                students.map(
                                                    (student) => (
                                                        <option key={student.id} value={student.id}>{student.lastname} {student.firstname}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </StyledInputwrapper>
                                    <StyledSelects>
                                        <StyledInputwrapper hasdeclaredwidth="49%">
                                            <label htmlFor="countTime">
                                                <StyledText
                                                    hasdeclaredfontsize="20px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0 8px 0"
                                                    hasdeclaredfontweight="600"
                                                >
                                                    {getString("Trainings_trainings_userPreferences_trainer2")}
                                                </StyledText>
                                            </label>
                                            <select
                                                defaultValue=""
                                                ref={refs.trainer}
                                            >
                                                <option value="">- {getString("UserPanelPreferences__form__select_trainer")} -</option>
                                                {
                                                    trainers.map(
                                                        (trainer) => (
                                                            <option key={trainer.id} value={trainer.id}>{trainer.lastname} {trainer.firstname}</option>
                                                        )
                                                    )
                                                }
                                            </select>
                                        </StyledInputwrapper>
                                        <StyledInputwrapper hasdeclaredwidth="49%">
                                            <label htmlFor="countTime">
                                                <StyledText
                                                    hasdeclaredfontsize="20px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0 8px 0"
                                                    hasdeclaredfontweight="600"
                                                >
                                                    {getString("Trainings_trainings_userPreferences_preferencje_type")}
                                                </StyledText>
                                            </label>
                                            <select
                                                defaultValue=""
                                                ref={refs.preference}
                                            >
                                                <option value="">- {getString("UserPanelPreferences__form__select_preference")} -</option>
                                                <option value="1">{getString("preference__preferred")}</option>
                                                <option value="-1">{getString("preference__forbidden")}</option>
                                            </select>
                                        </StyledInputwrapper>
                                    </StyledSelects>
                                    <StyledInputsButtonsWrapper hasmargin="0 0 50px 0">
                                        <input
                                            type="submit"
                                            value={getString("UserPanelTrainerTrackHours__form__add")}
                                        />
                                        <input
                                            type="reset"
                                            value={getString("UserPanelTrainerTrackHours__form__cancel")}
                                        />
                                    </StyledInputsButtonsWrapper>
                                </form>
                            ) : (undefined)
                        }
                        {
                            preferences.map(
                                ({ trainer, ...prefs }) => (
                                    <UserPanelPreferencesElement
                                        key={trainer.id}
                                        trainer={trainer}
                                        loadStudents={loadStudents}
                                        trigger={trigger}
                                        preferences={prefs}
                                        hasDelete={extended || trainer.id === user.id}
                                    />
                                )
                            )
                        }
                    </StyledPreferencesScrollWrapper>
                </>
            )}
        </StyledUserPanelPreferences>
    );
};

export default UserPanelPreferences;
