import React from "react";

import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";

import { StyledInputWrapper } from "../../atoms/StyledInputWrapper/StyledInputWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledInputsButtonsWrapper } from "../../atoms/TrackHours/StyledInputsButtonsWrapper";
import { StyledUserPanelSubjectElement } from "../../atoms/UserPanelSubjects/StyledUserPanelSubjectElement";
import { StyledInputsDivWrapper } from "../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper";

import { getString } from "../../../strings";

const UserPanelSubjectElement = () => {
    return (
        <StyledUserPanelSubjectElement>
            <StyledInputsDivWrapper $switchLang>
                <StyledInputWrapper $switchLang $isCenter hasdeclaredwidth="70px">
                    <ChangeLanguage isInputField isMarginTop="26px" />
                </StyledInputWrapper>
                <StyledInputWrapper $switchLang hasdeclaredwidth="calc(100% - 80px)">
                    <label htmlFor="nazwaPrzedmiotu">
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString("User_panel_subject_element_nazwa_przedmiotu")}
                        </StyledText>
                    </label>
                    <input id="nazwaPrzedmiotu" type="number" />
                    <StyledText
                        as="h4"
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString("User_panel_subject_element_nazwa_przedmiotu_error")}
                    </StyledText>
                </StyledInputWrapper>
            </StyledInputsDivWrapper>
            <StyledInputsDivWrapper hasmarginbottom>
                <StyledInputWrapper error={true}>
                    <label htmlFor="cenaZaGodzine">
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {getString("User_panel_subject_element_cena_ucznia")}
                        </StyledText>
                    </label>
                    <input id="cenaZaGodzine" type="number" />
                    <StyledText
                        as="h4"
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString("User_panel_subject_element_cena_ucznia_error")}
                    </StyledText>
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <label htmlFor="kwotaDodatkowa">
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {getString("User_panel_subject_element_cena_trenera")}
                        </StyledText>
                    </label>
                    <input id="kwotaDodatkowa" type="number" />
                    <StyledText
                        as="h4"
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString("User_panel_subject_element_cena_trenera_error")}
                    </StyledText>
                </StyledInputWrapper>
            </StyledInputsDivWrapper>
            <StyledInputsButtonsWrapper>
                <input
                    type="submit"
                    value={getString("User_panel_subject_element_cena_trenera_zapisz")}
                />
                <input
                    type="reset"
                    value={getString("User_panel_subject_element_cena_trenera_anuluj")}
                />
            </StyledInputsButtonsWrapper>
        </StyledUserPanelSubjectElement>
    );
};

export default UserPanelSubjectElement;
