import styled from "styled-components"

export const StyledUserElement = styled.div`
    width: 100%;
    display: flex;
    border-top: 1px solid rgba(0,0,0,0.2);
    background-color: ${({ blackcolor }) => blackcolor ? "#EEE" : "#fff"};
    > div {
        border-right: 1px solid rgba(0,0,0,0.2);
        color: #000;
        cursor: pointer;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 3px;
        > p{
            font-size: 12px;
            cursor: pointer;
            //color: ${({ blackcolor }) => blackcolor ? "#fff" : "#000"};
            color: #000;
        }
        &:nth-child(1){
            width: 3%;
            cursor: default;
            > p {
                cursor: default;
            }
        }
        &:nth-child(2){
            width: 25%;
        }
        &:nth-child(3){
            width: 7%;
        }
        &:nth-child(4){
            width: 13%;
        }
        &:nth-child(5){
            width: 12%;
        }
        &:nth-child(6){
            width: 8%;
        }
        &:nth-child(7){
            width: 7%;
        }
        &:nth-child(8){
            width: 7%;
        }
        &:nth-child(9){
            width: 18%;
        }
    }
`