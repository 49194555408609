import React, { useEffect, useState } from 'react'

import EventCountdownContent from '../organisms/EventCountdown/EventCountdownContent'

import { StyledEventCountdownPage } from '../atoms/EventCountdown/StyledEventCountdownPage'
import { StyledCountdownLogo } from '../atoms/EventCountdown/StyledCountdownLogo'
import { StyledText } from '../atoms/Text/StyledText'

import Logo from '../../images/logo.png'

import { getDateString, getTimeString } from '../../logic/eventtime.js';
import { getEventData } from '../../logic/requests/events.js'

const EventCountdown = () => {
    // TODO: zrobić to na parametrze
    const event_id = 1
    const [event, setEvent] = useState({
        name: 'Ładowanie...',
        active: false,
        status: '',
    })
    const [isErr, setIsErr] = useState(false)

    useEffect(
        () =>
            getEventData(event_id)
                .then((ret) => {
                    setEvent({
                        ...ret,
                        time_start: new Date(ret.time_start),
                        time_end: new Date(ret.time_end),
                    })
                })
                .catch((err) => {
                    console.log('getEvents', err)
                    setIsErr(true)
                }),
        [event_id]
    )

    return (
        <StyledEventCountdownPage>
            <StyledCountdownLogo src={Logo} alt="logo" />
            <StyledText
                hasdeclaredfontsize="72px"
                hasdeclaredmargin="0 0 22px 0"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="0 10px"
            >
                {event.name}
            </StyledText>
            {isErr ? (
                <EventCountdownContent
                    eventId={event_id}
                    button={false}
                    isDisabled={event.status !== 'live'}
                    isErr={true}
                    date="Błąd komunikacji z serwerem"
                    time="Spróbuj odświeżyć stronę"
                />
            ) : (
                <EventCountdownContent
                    eventId={event_id}
                    button={true}
                    isDisabled={event.status !== 'live'}
                    isErr={false}
                    date={
                        event && event.time_start
                            ? getDateString(event.time_start)
                            : '(Ładowanie...)'
                    }
                    time={
                        event && event.time_start
                            ? getTimeString(event.time_start)
                            : ''
                    }
                />
            )}
        </StyledEventCountdownPage>
    )
}

export default EventCountdown
