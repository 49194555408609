import React from "react";

import { getString } from "../../../strings";

import { PAGEPANEL } from "../../constants/UserPanel";
import UserPanelButton from "../../atoms/UserPanelButton/UserPanelButton";
import { StyledUserPanelStudentsMenu } from "../../atoms/UserPanelStudentsMenu/StyledUserPanelStudentsMenu";

const UserPanelStudentsMenu = ({
	subpanel,
	isOpen = false,
	changePanel = () => undefined
}) => (
	<StyledUserPanelStudentsMenu>
		<UserPanelButton 
			isActive={isOpen} 
			onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_LIST)} 
			label={getString("pages_userPanel_uczniowie")}
			hasArrow
			isOpen={isOpen}
		/> 
		{
			(isOpen) ? (
				<>
					<UserPanelButton 
                        isActive={subpanel === PAGEPANEL.STUDENTS_LIST} 
                        onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_LIST)} 
                        label={getString("UserPanelStudents__menu__students_list")}
                    /> 
					<UserPanelButton 
                        isActive={subpanel === PAGEPANEL.STUDENTS_PREFS} 
                        onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_PREFS)} 
                        label={getString("UserPanelStudents__menu__students_preferences")}
                    /> 
				</>
			) : (undefined)
		}
	</StyledUserPanelStudentsMenu>
);

export default UserPanelStudentsMenu;
