import styled from "styled-components"

export const StyledAdminLessonsHeader = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    min-height: 50px;
    > p,
    > select {
        min-width: 140px;
        color: #000;
        background-color: ${({ blackcolor }) => (blackcolor ? "#EEE" : "#fff")};
        height: 100%;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;