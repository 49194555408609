import React, { useMemo, useState } from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import Decimal from "decimal.js";

import {
    StyledEmployeeDataCostElement,
} from "./EmployeeDataCostElement.styles";
import { StyledEditInputElement } from "../../atoms/EditInputElement/StyledEditInputElement";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledClosePayElement } from "../../atoms/PayElement/StyledClosePayElement";
import { StyledSavePayElement } from "../../atoms/PayElement/StyledSavePayElement";
import { StyledGlobalUserPanelWrapper } from "../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper";
import { StyledEditWrapper } from "../../atoms/StyledEditWrapper/StyledEditWrapper";
import { StyledTextElement } from "../../atoms/StyledTextElement/StyledTextElement";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";

const emptyValue = "...";

const EmployeeDataCostElement = ({
    price,
    bonus_hours,
    bonus_amount,
    contract_type,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const displayPrice = useMemo(
        () => {
            if (!price)
                return emptyValue;

            const parsed = new Decimal(price);
            return (parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString());
        }, [price]
    );
    const displayBonusAmount = useMemo(
        () => {
            if (!bonus_amount)
                return emptyValue;

            const parsed = new Decimal(bonus_amount);
            return (parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString());
        }, [bonus_amount]
    );

    return (
        <StyledEmployeeDataCostElement>
            <ScrollSyncPane>
                <div>
                    {isEdit ? (
                        <StyledEditWrapper>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <select>
                                    <option value="brak" />
                                </select>
                            </StyledEditInputElement>
                        </StyledEditWrapper>
                    ) : (
                        <StyledGlobalUserPanelWrapper>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayPrice}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayBonusAmount}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {bonus_hours ?? emptyValue}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {contract_type ?? emptyValue}
                                </StyledText>
                            </StyledTextElement>
                        </StyledGlobalUserPanelWrapper>
                    )}
                </div>
            </ScrollSyncPane>
            <StyledSavePayElement isedit={isEdit} onClick={() => setIsEdit(!isEdit)}>
                {!isEdit ? <PencilIcon /> : <AcceptIcon />}
            </StyledSavePayElement>
            <StyledClosePayElement>
                <CloseIcon />
            </StyledClosePayElement>
        </StyledEmployeeDataCostElement>
    );
};

export default EmployeeDataCostElement;
