import styled from "styled-components"

export const StyledOverflowWrapper = styled.div`
    width: 80%;
    overflow-y: scroll;
    padding: 0 20px 20px 10px;
    margin-top: 80px;
    min-height: 80%;
    max-height: 80%;
    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#ee745c rgba(238,116,92, 0.3);" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(238,116,92, 0.3);" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #ee745c;
        border-radius: 6px;
    }
    > div {
        &:first-child{
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 1074px){
        overflow-y: visible;
        max-height: unset;
        min-height: unset;
        width: 100%;
        margin-top: 20px;
    }
`