import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as FacebookIcon } from "../../../images/fbv2.svg";
import instagram from "../../../images/instagram.png";

const StyledHomePageSocialMedia = styled(motion.div)`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 260px;
    height: 70px;
    border-radius: 70px 70px 0 0;
    background-color: #000;
    border: 6px solid #fff;
    border-bottom: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    a {
        width: 40px;
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media only screen and (max-width: 768px) {
        height: 60px;
    }

    @media only screen and (max-width: 465px) {
        height: 45px;
        width: 200px;

        a {
            width: 25px;
            height: 25px;
        }
    }
`;

const HomePageSocialMedia = () => {
    return (
        <StyledHomePageSocialMedia
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <a
                aria-label="Przejdź do strony"
                target="_blank"
                href="https://www.facebook.com/cube4fun"
                rel="noreferrer"
            >
                <FacebookIcon />
            </a>
            <a
                aria-label="Przejdź do strony"
                target="_blank"
                href="https://www.instagram.com/cube4funpl/"
                rel="noreferrer"
            >
                <img src={instagram} alt="icon" />
            </a>
            <a
                aria-label="Przejdź do strony"
                target="_blank"
                href="https://www.tiktok.com/@cube4fun.pl"
                rel="noreferrer"
                style={{
                    display: "flex",
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <svg
                    style={{ width: "80%", height: "80%", transform: "translateX(1px)" }}
                    fill="#fff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
                </svg>
            </a>
        </StyledHomePageSocialMedia>
    );
};

export default HomePageSocialMedia;
