import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";


const useGaTracker = () => {
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (
			!([ "localhost", "127.0.0.1" ].includes(window.location.host))
			&& process.env.REACT_APP_GOOGLE_ANALYTICS_ID
		) {
			ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
			setInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (initialized)
			ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
	}, [initialized, location]);
};

export default useGaTracker;
