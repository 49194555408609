import styled from "styled-components"
import { Link } from "react-router-dom"

export const StyledTournamentLink = styled(Link)`
    width: 50%;
    min-height: 50%;
    max-height: 50vh;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;

    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 100%;
        min-height: 250px;
        max-height: none;
    }
`