import styled from "styled-components"

export const StyledDataHeader = styled.div`
    width: ${({ ischeckboxes }) => ischeckboxes ? "calc(100% - 104px)" : "100%"};
    overflow-x: scroll;
    align-self: ${({ ischeckboxes }) => ischeckboxes ? "flex-start" : "flex-end"};

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: ${({ ischeckboxes }) => ischeckboxes ? "900px" : "700px"};
        gap: ${({ ischeckboxes }) => ischeckboxes ? "4px" : "none"};
    }
`