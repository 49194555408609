import styled from "styled-components"

export const StyledAboutUsImageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 630px){
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
`