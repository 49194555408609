import styled from "styled-components"

export const StyledInputsDivWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 10px;
    margin-bottom: ${({ hasmarginbottom }) => hasmarginbottom ? "20px" : "0"};

    @media only screen and (max-width: 600px){
        flex-direction: ${({ $switchLang }) => $switchLang ? "row" : "column"};
    }
`