import styled from "styled-components";
import { motion } from "framer-motion";

import bgcolor from "../../../images/register/background.jpg";

export const StyledHomePage = styled(motion.section)`
    background: url(${bgcolor}) no-repeat;
    background-size: cover;
    width: 100%;

    @media only screen and (max-width: 767px) {
        background-color: #f9ec4b;
    }

    @media only screen and (max-width: 465px) {
        background: #f9ec4b;
    }
`;
