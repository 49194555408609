import React from "react";

const EnvelopeIcon = () => (
    <svg
        width="133"
        height="91"
        viewBox="0 0 133 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M129.6 86.5C129.6 87.6 128.7 88.4 127.7 88.4H4.50009C3.50009 88.4 2.6001 87.5 2.6001 86.5V4.90002C2.6001 3.80002 3.50009 3 4.50009 3H127.7C128.7 3 129.6 3.90002 129.6 4.90002V86.5Z"
            stroke="#6786be"
            stroke-width="10.1447"
            stroke-miterlimit="10"
        />
        <path
            d="M4.79996 2.90002C3.59996 2.90002 3.49995 3.39998 4.39995 4.09998L64.3999 44.5C65.3999 45.2 67 45.2 68 44.5L127.9 4.09998C128.9 3.39998 128.7 2.90002 127.5 2.90002H4.79996Z"
            stroke="#6786be"
            stroke-width="10.7877"
            stroke-miterlimit="10"
        />
    </svg>
);

export default EnvelopeIcon;
