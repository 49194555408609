export const createUTCDate = (date) => (
	(
		(date) => (
			new Date(
				Date.UTC(
					date.getFullYear(), date.getMonth(), date.getDate(),
					date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()
				)
			)
		)
	)(date instanceof Date ? date : !!date ? new Date(date) : new Date())
);

export const convertDateToUTC = (date) => (
	(
		(date) => (
			new Date(
				date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
				date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds()
			)
		)
	)(date instanceof Date ? date : !!date ? new Date(date) : new Date())
);

export const areDatesEqual = (a, b) => (
	a.getFullYear() === b.getFullYear() &&
	a.getMonth() === b.getMonth() &&
	a.getDate() === b.getDate()
);
