import React from "react";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledSummaryPlayersCube } from "../../atoms/Tournament/StyledSummaryPlayersCube";
import CompetitionElement from "../CompetitionElement/CompetitionElement";

const SummaryPlayersCube = ({ cubes }) => (
    <StyledSummaryPlayersCube>
        {
            Object.keys(cubes).map(
                cube => (
                    <div key={cube}>
                        <CompetitionElement competition={cube} />
                        <StyledText>{cubes[cube]}</StyledText>
                    </div>
                )
            )
        }
    </StyledSummaryPlayersCube>
);

export default SummaryPlayersCube;
