import styled from "styled-components";

export const StyledScrollHeaderPayAll = styled.div`
    width: calc(${({ calcwidth }) => calcwidth ? "100% - 54px" : "100%"});
    overflow-x: scroll;
    
    scrollbar-color: ${({ isscrollbar }) =>
        isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
`;
