import styled from "styled-components"

export const StyledUserListElement = styled.div`
    width: 100%;
    background-color: #fff;
    border: 4px solid #6786be;
    border-radius: 25px 25px 9px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 25px 20px;
    position: relative;
`;