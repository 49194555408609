import React, { useState, useEffect, useMemo } from "react";

import UserPanelAddRoleComponent from "./UserPanelAddRoleComponent";
import UserPanelJoinRole from "./UserPanelJoinRole";
import UserPanelDeleteRole from "./UserPanelDeleteRole";
import Loader from "../Loader/Loader";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelAddRole } from "../../atoms/UserPanelAddRole/StyledUserPanelAddRole";
import { StyledUserPanelAddRoleScrollWrapper } from "../../atoms/UserPanelAddRole/StyledUserPanelAddRoleScrollWrapper";
import { StyledSumElement } from "../../atoms/UserPanelTrainerPay/StyledSumElement";
import { StyledSumRow } from "../../atoms/UserPanelTrainerPay/StyledSumRow";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";

import { getString } from "../../../strings";
import { listRoles, listUsers } from "../../../logic/requests/students";
import { cebulaCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelAddRole = () => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const [err, setErr] = useState(false);
    const [users, setUsers] = useState();
    const [roles, setRoles] = useState();
    useEffect(
        () => {
            listUsers()
                .then(
                    (res) => {
                        const users = (
                            res
                            .map(
                                (user) => ({
                                    ...user,
                                    name: `${user.lastname} ${user.firstname}`
                                })
                            )
                            .sort(
                                (a, b) => cebulaCompare(a.name, b.name)
                            )
                        );
                        console.log("setUsers", res, "->", users);
                        setUsers(users);
                    }
                )
                .catch(
                    (err) => {
                        console.error("setUsers", err);
                        setErr(true);
                    }
                )
            ;
            listRoles()
                .then(
                    (res) => {
                        console.log("listRoles", res);
                        setRoles(res);
                    }
                )
                .catch(
                    (err) => {
                        console.error("listRoles", err);
                        setErr(true);
                    }
                )
            ;
        }, [setErr, setUsers, setRoles]
    );

    const sums = useMemo(
        () => {
            const initial = {
                students: 0,
                parents: 0,
                trainers: 0
            };

            return (
                (users) ? (
                    users.reduce(
                        (prev, cur) => {
                            for (let role of ["student", "parent", "trainer"]) {
                                if (cur.roles.includes(role))
                                    prev[`${role}s`]++;
                            }
                            return prev;
                        }, initial
                    )
                ) : (initial)
            )
        }, [users]
    );

    return (
        <StyledUserPanelAddRole>
            {
                (err) ? (
                    <StyledText
                        hasdeclaredpadding="20px 20px 30px 0"
                        hasdeclaredfontsize="36px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString("blad_wczytywania")}:
                    </StyledText>
                ) : (!(users && roles)) ? (
                    <StyledUserPanelStudentsLoaderWrapper>
                        <Loader />
                    </StyledUserPanelStudentsLoaderWrapper>
                ) : (
                    <>
                    <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("UserPanelAddRole__summary__students")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {sums.students}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("UserPanelAddRole__summary__parents")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {sums.parents}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("UserPanelAddRole__summary__trainers")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {sums.trainers}
                            </StyledText>
                        </StyledSumRow>
                    </StyledSumElement>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString("UserPanelTrainerAddRoleManageRoles")}:
                        </StyledText>
                        <StyledUserPanelAddRoleScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            <UserPanelAddRoleComponent
                                users={users}
                                setUsers={setUsers}
                                roles={roles}
                                trigger={trigger}
                            />
                        </StyledUserPanelAddRoleScrollWrapper>
                    </>
                )
            }
        </StyledUserPanelAddRole>
    );
};

export default UserPanelAddRole;
