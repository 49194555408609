import styled from "styled-components";

export const StyledRightWrapper = styled.div`
    width: 55%;
    min-height: 100vh;
    height: 100%;
    z-index: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media only screen and (max-width: 1126px) {
        width: 60%;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 65vh;
    }
    @media only screen and (max-width: 430px) {
        padding-top: 20px;
    }
    @media only screen and (max-height: 720px) and (max-width: 600px) {
        min-height: 580px;
        padding-top: 50px;
    }
    @media only screen and (max-height: 720px) and (max-width: 375px) {
        min-height: 580px;
        padding-top: 0;
    }
`;
