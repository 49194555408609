import styled from "styled-components";

export const StyledTournamentCubes = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    -moz-overflow: hidden;
    @media only screen and (max-width: 1182px) {
        display: none;
    }
`;
