import styled from "styled-components"

export const StyledUserPanelPreferencesElement = styled.div`
    width: 100%;
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid ${({ hasdeclaredbordercolor }) => hasdeclaredbordercolor ? "#ee745c": "#6786BE"};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => isopen ? "20px" : "0"};
    padding-left: 10px;
    margin-top: ${({ isfirstchild }) => isfirstchild ? "0px" : "27px"};
`