import styled from "styled-components"

export const StyledGalleryMenu = styled.div`
    width: 25%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 220px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 1305px) {
        width: 38%;
    }
    @media only screen and (max-width: 1220px){
        width: 30%;
        margin-right: 10%;
    }
    @media only screen and (max-width: 1090px){
        width: 35%;
        margin-right: 5%;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: ${({ ismobile }) => (ismobile ? "40px" : "none")};
        width: 100%;
        height: auto;
        padding: 0 20px;
        margin-right: 0;
    }
`;