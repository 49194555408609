import styled from "styled-components";

export const StyledWhiteRowCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    @media only screen and (max-width: 1355px) {
        > p {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 1061px) {
        > p {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 1017px) {
        > p {
            font-size: 10px;
        }
    }
    @media only screen and (max-width: 871px) {
        > p {
            font-size: 8px;
        }
    }
    @media only screen and (max-width: 767px) {
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 689px) {
        > p {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 600px) {
        min-height: 60px;
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 519px) {
        min-height: 40px;
        > p {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 411px) {
        min-height: 35px;
        > p {
            font-size: 10px;
        }
    }
`;
