import styled from "styled-components"

export const StyledPlayerHistoryElement = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: ${({ isgraycolor }) => isgraycolor ? "#DDD" : "#fff"};
    margin-top: 2px;
    > div {
        width: 50%;
        //color: ${({ isgraycolor }) => isgraycolor ? "#fff" : "#000"};
        color: #000;
        display: flex;
        align-items: center;
        padding: 0 10px;
        min-height: 50px;
        &:first-child{
            border-right: 1px solid #000;
        }
    }

    @media only screen and (max-width: 922px){
        > div {
            width: 100%;
            &:first-child{
                border-right: none;
                border-bottom: 1px solid rgba(0,0,0,0.2);
            }
        }
    }
`