import styled from "styled-components"

export const StyledCheckboxDone = styled.div`
    position: relative;
    width: 50px !important;
    height: 50px;
    
    input {
        display: none;
    }

    label {
        cursor: pointer;
        width: ${({ hasdeclaredsize }) => hasdeclaredsize ? hasdeclaredsize : "50px"};
        height: ${({ hasdeclaredsize }) => hasdeclaredsize ? hasdeclaredsize : "50px"};
        border: 4px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
        border-radius: 4px;
        display: flex;
        background-color: #fff;
        transition: background-color 250ms linear;
        
        svg {
            width: ${({ hasdeclaredsize }) => hasdeclaredsize ? hasdeclaredsize : "50px"};
            height: ${({ hasdeclaredsize }) => hasdeclaredsize ? hasdeclaredsize : "50px"};
            display: none;
        }
    }

    input:checked + label {
        background-color: #6786BE;
        transition: background-color 250ms linear;

        svg{
            transform: translate(-2px, -8px);
            display: block;
        }
    }
`