import styled from "styled-components"

export const StyledDesc = styled.div`
    border: 4px solid #EE745C;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 19px;
    padding: 20px;

    > div {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
`