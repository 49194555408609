import styled from "styled-components";

export const StyledEditInputElement = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : "25%")};
    padding: 0 4px;
    display: ${({ $isFlex }) => $isFlex ? "flex" : "block"};
    align-items: center;

    > input,
    select {
        text-align: center;
        width: 100%;
        height: 30px;
        font-size: 16px;
        border-radius: 25px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        background: #fff;
        text-align: center;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: "Poppins";
            text-align: center;
        }
    }
`;
