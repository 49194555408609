import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { sessionService } from "redux-react-session";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getString } from "../../../strings";
import RegisterCompetitions from "../../molecules/Register/RegisterCompetitions";
import SelectSize from "../../molecules/Register/SelectSize";
import Loader from "../Loader/Loader";

import { StyledCheckboxWrapper } from "../../molecules/Register/StyledCheckboxWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledDisplayContainer } from "../../atoms/Tournament/StyledDisplayContainer";
import { StyledRegistrationContent } from "../../atoms/Tournament/StyledRegistrationContent";
import { StyledDataTextWrapper } from "../../molecules/Tournament/StyledDataTextWrapper";
import { StyledRegistrationTournamentForm } from "../../atoms/Tournament/StyledRegistrationTournamentForm";
import { StyledRegistrationAccepted } from "../../molecules/Tournament/StyledRegistrationAccepted";
import { StyledRegistrationContentWrapper } from "../../atoms/Tournament/StyledRegistrationContentWrapper";
import { StyledCheckboxContentWrapper } from "../../atoms/Tournament/StyledCheckboxContentWrapper";
import { StyledLoaderWrapper } from "../../atoms/Tournament/StyledLoaderWrapper";

import { ReactComponent as CheckboxAccept } from "../../../images/checkboxAccept.svg";
import { getDateString } from "../../../logic/eventtime";
import { registerUserForEvent } from "../../../logic/requests/events";
import { setupEventPayment } from "../../../logic/requests/payments.js";

import { PAGEPANEL } from "../../constants/UserPanel";

import REGISTER_STATUS from "../../constants/register_status.json";

import useScrollBar from "../../../logic/hooks/useScrollBar";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

const handlePay = (event, setLoader) => {
    console.log("handlePay", event);

    setLoader(true);
    setupEventPayment(event.id)
        .then(
            res => {
                console.log("setupEventPayment", res);
                if (!res.token) {
                    // TODO: pokazać błąd? to nie powinno się zdażyć
                    return
                }
                // TODO: Pokazać coś chwilę przed przekierowaniem?
                const service = process.env.NODE_ENV === "production" ? "secure" : "sandbox";
                window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
            }
        )
        .catch(err => {
            console.error("setupEventPayment", err);
            // TODO: Pokazać błąd
        })
        .finally(
            () => {
                setLoader(false);
            }
        )
    ;
};

const TournamentRegistrationContent = ({ event, registerText, registerStatus, setRegisterStatus }) => {
    class RegisteredState {
        constructor(state) {
            this.value = state;
        }

        static LOADING_ERROR = new RegisteredState(-1);
        static REGISTERED = new RegisteredState(1);
        static NOT_REGISTERED = new RegisteredState(2);
        static JUST_REGISTERED = new RegisteredState(3);
        static NOT_OPEN = new RegisteredState(4);
        static NOT_LOGGED_IN = new RegisteredState(5);
    }

    const [user, setUser] = useState();
    const { scrollElement, isScrollBar } = useScrollBar();
    const buttonRef = useRef(null);
    const [justRegistered, setJustRegistered] = useState(false);

    const registeredState = (
        user ? (
            event.register_status === "upcoming" ? (
                // jeśli nie rozpoczęła się jeszcze rejestracja - NOT_OPEN
                RegisteredState.NOT_OPEN.value
            ) : (
                event.register_status === "closed" ? (
                    // zakończyła się, ale "PŁATNOŚĆ W TOKU" może jeszcze dokończyć
                    (
                        registerStatus.value &&
                        !registerStatus.reserve &&
                        registerStatus.register_status === REGISTER_STATUS["TRANSACTION_PENDING"]
                    ) ? (
                        RegisteredState.REGISTERED.value
                    ) : (
                        RegisteredState.NOT_OPEN.value
                    )
                ) : (
                    event.register_status === "open" ? (
                        justRegistered ? (
                            RegisteredState.JUST_REGISTERED.value
                        ) : (
                            registerStatus.value ? (
                                RegisteredState.REGISTERED.value
                            ) : (
                                RegisteredState.NOT_REGISTERED.value
                            )
                        )
                    ) : (
                        // to się nie powinno zdażyć
                        RegisteredState.LOADING_ERROR.value
                    )
                )
            )
        ) : (
            RegisteredState.NOT_LOGGED_IN.value
        )
    );

    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const handleSubmit = (data) => {
        setIsRegistering(true);
        const { regulationsField: _, shirtSize: shirt_size, ...competitions_obj } = data;
        const competitions = Object.keys(competitions_obj).filter((key) => competitions_obj[key]);
        console.log("handleSubmit", shirt_size, competitions);
        if (buttonRef?.current)
            buttonRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        registerUserForEvent(event.id, { shirt_size, competitions })
            .then((res) => {
                console.log("registerUserForEvent", res);
                setJustRegistered(true);
                setRegisterStatus(res.registerStatus);
            })
            .catch((err) => {
                console.error("registerUserForEvent", err);
            })
            .finally(() => {
                setIsRegistering(false);
            });
    };

    const initialCompetitions = {};
    if (user?.competitions?.length) {
        for (let competition of user.competitions) {
            if (event?.competitions?.includes(competition)) {
                initialCompetitions[competition] = true;
            }
        }
    }
    const initialValues = {
        ...initialCompetitions,
        regulationsField: false,
        shirtSize: user?.shirtsize?.id ?? "",
    };

    const [isRegistering, setIsRegistering] = useState(false);

    return (
        <StyledRegistrationContent>
            {(() => {
                switch (registeredState) {
                    case RegisteredState.LOADING_ERROR.value:
                        return (
                            <StyledRegistrationAccepted>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString("tournament_tournamentRegistrationContent_bladWczytywania")}
                                </StyledText>
                            </StyledRegistrationAccepted>
                        );
                    case RegisteredState.REGISTERED.value:
                        return (
                            <StyledRegistrationAccepted>
                                {
                                    (registerStatus.register_status === REGISTER_STATUS.CONFIRMED) ? (
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("tournament_tournamentRegistrationContent_jestesJuzZarejestrowany")}{" "}
                                                {event.payment_method.includes("transfer") ? getString("tournament_tournamentRegistrationContent_pamietajOUiszczeniu") + ' ' : ""}
                                                {getString("tournament_tournamentRegistrationContent_jestesJuzZarejestrowany2")}{" "}
                                                <Link to="/my-account">{getString("tournament_tournamentRegistrationContent_mojeKonto")}</Link>.
                                            </StyledText>
                                    ) : (
                                        (registerStatus.reserve) ? (
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                                as="p"
                                            >
                                                {getString("tournament_tournamentRegistrationContent_dziekujemyZaUtworzenie")} 🙂
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                    as="p"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_pomyslnieUtworzono")}{" "}
                                                    <Link to="/my-account">{getString("tournament_tournamentRegistrationContent_mojeKonto2")}</Link> {getString("tournament_tournamentRegistrationContent_lubKliknijPrzyciskPonizej")}
                                                </StyledText>
                                                <StyledButton
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredbgcolor="#4E6BAA"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredborderradius="25px"
                                                    hasdeclaredcursor="pointer"
                                                    hasdeclaredwidth="220px"
                                                    hasdeclaredheight="50px"
                                                    hasdeclaredmargin="20px 0 0 0"
                                                    onClick={() => handlePay(event, setIsRegistering)}
                                                >
                                                    {
                                                        (registerStatus.register_status === REGISTER_STATUS.TRANSACTION_PENDING) ? (
                                                            getString("tournament_tournamentRegistrationContent_dokonczPlatnosc")
                                                        ) : (
                                                            getString("tournament_tournamentRegistrationContent_oplacUdzial")
                                                        )
                                                    }
                                                </StyledButton>
                                            </>
                                        )
                                    )
                                }
                            </StyledRegistrationAccepted>
                        );
                    case RegisteredState.NOT_REGISTERED.value:
                        return (
                            <>
                                <StyledRegistrationContentWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                                    <StyledDisplayContainer>
                                        <div>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_imieINazwisko")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {user.firstname} {user.lastname}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_plec")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {getString(`gender_${user.gender.id}`)}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_adresEmail")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {user.email}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            {user.wca_id ? (
                                                <StyledDataTextWrapper>
                                                    <StyledText
                                                        hasdeclaredfontsize="22px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredfontcolor="#6D85BA"
                                                        hasdeclaredpadding="0 0 6px 0"
                                                    >
                                                        {getString("tournament_tournamentRegistrationContent_wcaID")}
                                                    </StyledText>
                                                    <StyledText hasdeclaredfontsize="22px">
                                                        {user.wca_id}
                                                    </StyledText>
                                                </StyledDataTextWrapper>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_dataUrodzenia")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {getDateString(new Date(user.birthdate))}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_miejscowosc")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {`${user.city}, ${countries.getName(user.country, "pl")}`}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            <StyledDataTextWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6D85BA"
                                                    hasdeclaredpadding="0 0 6px 0"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_numerTelefonu")}
                                                </StyledText>
                                                <StyledText hasdeclaredfontsize="22px">
                                                    {user.phone}
                                                </StyledText>
                                            </StyledDataTextWrapper>
                                            <StyledDataTextWrapper>
                                                <Link
                                                    to={`/my-account/${PAGEPANEL.USERDATA}`}
                                                >
                                                    <StyledButton
                                                        hasdeclaredbgcolor="#DF7B63"
                                                        hasdeclaredcursor="cursor"
                                                        hasdeclaredwidth="100%"
                                                        hasdeclaredheight="40px"
                                                        hasdeclaredborderradius="25px"
                                                    >
                                                        {getString("tournament_tournamentRegistrationContent_edytujDane")}
                                                    </StyledButton>
                                                </Link>
                                            </StyledDataTextWrapper>
                                        </div>
                                    </StyledDisplayContainer>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={Yup.object({
                                            competitions: Yup.bool().test(
                                                "competitions",
                                                getString("tournament_tournamentRegistrationContent_zaznaczConajmniejJednaKonkurencje"),
                                                function (val) {
                                                    const value = this.parent;
                                                    return Boolean(
                                                        Object.keys(value)
                                                            .filter(
                                                                (key) =>
                                                                    key !== "regulationsField" &&
                                                                    key !== "shirtSize"
                                                            )
                                                            .filter((key) => value[key]).length
                                                    );
                                                }
                                            ),
                                            regulationsField: Yup.bool().oneOf(
                                                [true],
                                                getString("tournament_tournamentRegistrationContent_akceptujRegulamin")
                                            ),
                                            shirtSize: Yup.string().nullable(),
                                        })}
                                        onSubmit={handleSubmit}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                    >
                                        {({
                                            errors,
                                            handleSubmit,
                                            isSubmitting,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <StyledRegistrationTournamentForm
                                                onSubmit={handleSubmit}
                                            >
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="22px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="24px 0 8px 0"
                                                    >
                                                        {getString("tournament_tournamentRegistrationContent_wyborKonkurencji")}
                                                    </StyledText>
                                                    <RegisterCompetitions
                                                        competitions={event.competitions}
                                                        initialValues={initialCompetitions}
                                                    />
                                                </div>
                                                <div>
                                                    <SelectSize
                                                        title={getString("tournament_tournamentRegistrationContent_rozmiarKoszulkiTitle")}
                                                        name="shirtSize"
                                                        error={errors.shirtSize}
                                                        selected={initialValues?.shirtSize}
                                                        onChange={(event) =>
                                                            setFieldValue(
                                                                "shirtSize",
                                                                event.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <StyledCheckboxContentWrapper>
                                                    <StyledCheckboxWrapper
                                                        error={errors.regulationsField}
                                                    >
                                                        <Field
                                                            type="checkbox"
                                                            name="regulationsField"
                                                            id="regulationsField"
                                                        />
                                                        <label htmlFor="regulationsField">
                                                            <CheckboxAccept />
                                                        </label>
                                                        <StyledText
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredpadding="0 0 0 14px"
                                                        >
                                                            {getString("tournament_tournamentRegistrationContent_akceptuje")}{" "}
                                                            {event?.id ? (
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href="/regulamin"
                                                                    aria-label={getString("seo_aria_label_przejdz_do_strony")}
                                                                >
                                                                    {getString("tournament_tournamentRegistrationContent_regulamin")}
                                                                </a>
                                                            ) : (
                                                                getString("tournament_tournamentRegistrationContent_regulamin")
                                                            )}
                                                        </StyledText>
                                                    </StyledCheckboxWrapper>
                                                    {
                                                        isRegistering ? (
                                                            <StyledLoaderWrapper>
                                                                <Loader />
                                                            </StyledLoaderWrapper>
                                                        ) : (null)
                                                    }
                                                    <StyledButton
                                                        hasdeclaredbgcolor="#6786BE"
                                                        hasdeclaredwidth="300px"
                                                        hasdeclaredheight="50px"
                                                        hasdeclaredborderradius="25px"
                                                        hasdeclaredfontsize="22px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontcolor="#fff"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredcursor="pointer"
                                                        hasdeclaredmargin="18px 0 0 0"
                                                        type="submit"
                                                        ref={buttonRef}
                                                    >
                                                        {getString("tournament_tournamentRegistrationContent_zarejestrujSie")}
                                                    </StyledButton>
                                                </StyledCheckboxContentWrapper>
                                            </StyledRegistrationTournamentForm>
                                        )}
                                    </Formik>
                                </StyledRegistrationContentWrapper>
                            </>
                        );
                    case RegisteredState.JUST_REGISTERED.value:
                        return (
                            <StyledRegistrationAccepted>
                                {
                                    event.payment_method === "p24" ? (
                                        (registerStatus.reserve) ? (
                                            <StyledText
                                                hasdeclaredfontsize="22px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                                as="p"
                                            >
                                                {getString("tournament_tournamentRegistrationContent_dziekujemyZaUtworzenie")} 🙂
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                    as="p"
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_pomyslnieUtworzono")}{" "}
                                                    <Link to="/my-account">{getString("tournament_tournamentRegistrationContent_mojeKonto2")}</Link> {getString("tournament_tournamentRegistrationContent_lubKliknijPrzyciskPonizej")}
                                                </StyledText>
                                                <StyledButton
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredbgcolor="#4E6BAA"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredborderradius="25px"
                                                    hasdeclaredcursor="pointer"
                                                    hasdeclaredwidth="220px"
                                                    hasdeclaredheight="50px"
                                                    hasdeclaredmargin="20px 0 0 0"
                                                    onClick={() => handlePay(event, setIsRegistering)}
                                                >
                                                    {getString("tournament_tournamentRegistrationContent_oplacUdzial")}
                                                </StyledButton>
                                            </>
                                        )
                                    ) : (
                                        <StyledText
                                            hasdeclaredfontsize="22px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredlineheight="1.4em"
                                            as="p"
                                        >
                                            {getString("tournament_tournamentRegistrationContent_pomyslnieZarejestrowanoNaZawody")}{" "}
                                            <Link to="/my-account">{getString("tournament_tournamentRegistrationContent_mojeKonto3")}</Link>.
                                        </StyledText>
                                    )
                                }
                            </StyledRegistrationAccepted>
                        );
                    case RegisteredState.NOT_OPEN.value:
                        return (
                            <StyledRegistrationAccepted>
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                    as="p"
                                >
                                    {getString("tournament_tournamentRegistrationContent_rejestracjaRozpocznieSie")} {registerText}
                                </StyledText>
                            </StyledRegistrationAccepted>
                        );
                    case RegisteredState.NOT_LOGGED_IN.value:
                        return (
                            <StyledRegistrationAccepted>
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                    as="p"
                                >
                                    {getString("tournament_tournamentRegistrationContent_abyZarejestrowacSie")}{" "}
                                    <Link
                                        to="/login"
                                        state={{ loginRedirect: `/competition/${event.code}/register` }}
                                    >
                                        {getString("tournament_tournamentRegistrationContent_zalogowac")}
                                    </Link>
                                    .
                                </StyledText>
                            </StyledRegistrationAccepted>
                        );
                    default:
                        return <></>;
                }
            })()}
        </StyledRegistrationContent>
    );
};

export default TournamentRegistrationContent;
