import styled from "styled-components";

export const StyledCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 0;
    margin-bottom: 12px;
    > input {
        display: none;
    }
    > input:checked + label {
        background-color: #3474e8;
    }
    > label {
        width: 36px;
        height: 36px;
        border-radius: 10px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#3474E8")};
        position: absolute;
        background-color: #fff;
        left: -44px;
        top: 0;
        cursor: pointer;
        transition: background-color 250ms;
        > svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: translate(-1px, -6px);
        }
    }
    > p {
        display: flex;
        align-items: center;
        > a {
            color: ${({ hasdeclaredadnotationcolor }) =>
                hasdeclaredadnotationcolor ? hasdeclaredadnotationcolor : "#6D85BA"};
            text-decoration: none;
            margin-left: 6px;
            text-transform: uppercase;
        }
    }
    @media only screen and (max-width: 613px) {
        > p {
            font-size: 14px;
        }
    }
`;
