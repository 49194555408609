import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";

export const StyledInfiniteScroll = styled(InfiniteScroll)`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`;
