import styled from "styled-components";

export const StyledHeaderText = styled.div`
    @media only screen and (max-width: 902px) {
        p {
            padding-bottom: 22px;
        }
    }
    @media only screen and (max-width: 767px){
        p {
            color: #000;
        }
    }
`;
