import styled from "styled-components"

export const StyledFlexWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        justify-content: space-between;
    }
`