import styled from "styled-components"

export const StyledEditHandler = styled.div`
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 50px;
    height: 50px;
    border-radius: 9px;
    background-color: #6786be;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;