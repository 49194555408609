import styled from "styled-components"
import { motion } from "framer-motion"

export const StyledGalleryContent = styled(motion.div)`
    width: 62%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-top: 120px;
    padding-right: 20px;
    
    @media only screen and (min-width: 1920){
        > h2{
            font-size: 54px;
        }
    }
    @media only screen and (max-width: 1220px) {
        width: 60%;
        > h2{
            font-size: 42px;
        }
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        > h2{
            font-size: 32px;
        }
    }
`;