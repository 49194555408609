import React from "react";

import LoginForm from "../organisms/Login/LoginForm";
import CubeIcons from "../organisms/Login/CubeIcons";

import LogoLink from "../organisms/Logo/LogoLink";
import LoginLeftWrapper from "../molecules/Login/LoginLeftWrapper";
import HelmetTemplate from "../molecules/HelmetTemplate/HelmetTemplate";

import { StyledLoginPage } from "../molecules/Login/StyledLoginPage";
import { StyledLoginPageImage } from "../atoms/Login/StyledLoginPageImage";
import { StyledRightWrapper } from "../atoms/Login/StyledRightWrapper";
import { StyledLoginPageShape } from "../atoms/Login/StyledLoginPageShape";
import { StyledLoginPageShapeMobile } from "../atoms/Login/StyledLoginPageShapeMobile";

import HeroImage from "../../images/LoginPage/HeroImageFinal1080.jpg";
import HeroImageMobile from "../../images/LoginPage/HeroImageFinalMobile.jpg";

import useWindowSize from "../../utils/getWindowSize";
import { getString } from "../../strings";

const Login = ({ user }) => {
    const width = useWindowSize();

    return (
        <>
            <HelmetTemplate 
                title={getString("seo_login_title")} 
                desc={getString("seo_login_desc")} 
                ogTitle={getString("seo_login_twitter_title")}
            />
            <StyledLoginPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <LogoLink />
                <StyledLoginPageImage
                    src={width < 540 ? HeroImageMobile : HeroImage}
                    alt="hero image"
                />
                <LoginLeftWrapper />
                <StyledRightWrapper>
                    {width < 768 ? <StyledLoginPageShapeMobile /> : <StyledLoginPageShape />}
                    <CubeIcons />
                    <LoginForm user={user} />
                </StyledRightWrapper>
            </StyledLoginPage>
        </>
    );
};

export default Login;
