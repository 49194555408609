import styled from "styled-components";
import Background from "../../../images/tournaments/fullhdBlue.jpg";
import SeriesMobile from "../../../images/seriesMobile.jpg"
import { motion } from "framer-motion";

export const StyledSeriesPage = styled(motion.section)`
    width: 100%;
    height: 100vh;
    min-height: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    
    @media only screen and (max-width: 767px){
        flex-direction: column;
        -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        background-image: url(${SeriesMobile});
        overflow-y: scroll;
        height: 100vh; 
        min-height: unset;
    }
`;
