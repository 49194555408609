import styled from "styled-components";
import { ReactComponent as Phone } from "../../../images/home/telefon.svg";

export const StyledContectPhoneSvg = styled(Phone)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: none;
    @media only screen and (min-width: 1920px) {
        height: 205px;
    }
    @media only screen and (max-width: 1154px) {
        height: 140px;
        width: 240px;
    }
    @media only screen and (max-width: 943px) {
        height: 110px;
        width: 210px;
    }
    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 300px;
        height: 180px;
    }
    @media only screen and (max-width: 601px) {
        width: 260px;
        height: 140px;
    }
    @media only screen and (max-width: 535px) {
        width: 200px;
    }
    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
    @media only screen and (max-width: 359px) {
        width: 180px;
        height: 90px;
    }
`;
