import styled from "styled-components"

export const StyledRowInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    > div {
        display: flex;
        gap: 10px;
    }
`