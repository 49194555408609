import styled from "styled-components"

export const StyledUserPanelResultsSumWrapper = styled(({ headerText, ...props }) => <div {...props} />)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::after{
        content: '${({ headerText }) => (headerText)}';
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -26px;
    }
`