import React from "react";

import UserPanelButton from "../../atoms/UserPanelButton/UserPanelButton";

import { StyledButton } from "../../atoms/Button/StyledButton";

import { getString } from "../../../strings";

import { PAGEPANEL } from "../../constants/UserPanel";
import { StyledUserPanelStudentsMenu } from "../../atoms/UserPanelStudentsMenu/StyledUserPanelStudentsMenu";

const UserPanelStudentsMenu = ({
	subpanel,
	isOpen = false,
	changePanel = () => undefined
}) => (
	<StyledUserPanelStudentsMenu>
		<UserPanelButton 
			isActive={isOpen} 
			onClick={() => changePanel(PAGEPANEL.USERS, PAGEPANEL.USERS_NEWSLETTER)} 
			label={getString("pages_userPanel_uzytkownicy")}
			hasArrow
			isOpen={isOpen}
		/> 
		{
			(isOpen) ? (
				<>
					<UserPanelButton 
                        isActive={subpanel === PAGEPANEL.USERS_NEWSLETTER} 
                        onClick={() => changePanel(PAGEPANEL.USERS, PAGEPANEL.USERS_NEWSLETTER)} 
                        label={getString("UserPanelUsers__menu__newsletter")}
                    /> 
				</>
			) : (undefined)
		}
	</StyledUserPanelStudentsMenu>
);

export default UserPanelStudentsMenu;
