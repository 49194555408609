import React from "react";
import parse from "html-react-parser";
import { getString } from "../../../strings";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledTournamentRequirementsContent } from "../../atoms/Tournament/StyledTournamentRequirementsContent";
import { StyledRequirementsContent } from "../../atoms/Tournament/StyledRequirementsContent";
import { StyledTournamentRequirementsContentWrapper } from "../../atoms/Tournament/StyledTournamentRequirementsContentWrapper";

import useScrollBar from "../../../logic/hooks/useScrollBar";

const TournamentRequirementsContent = ({ event }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledTournamentRequirementsContent>
            <StyledTournamentRequirementsContentWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                <StyledText
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontsize="26px"
                    hasdeclaredfontweight="700"
                    hasdeclaredfontcolor="#6786be"
                    hasdeclaredpadding="0 0 24px 0"
                >
                    {getString("tournament_tournamentRequirementsContent_wymaganiaRejestracyjne")}
                </StyledText>
                <StyledRequirementsContent>
                    {parse(event?.requirements_text || "")}
                </StyledRequirementsContent>
            </StyledTournamentRequirementsContentWrapper>
        </StyledTournamentRequirementsContent>
    );
};

export default TournamentRequirementsContent;
