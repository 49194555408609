import React, { useCallback, useState } from "react";
import { ScrollSyncPane } from "react-scroll-sync";

import PaymentsPopup from "../../organisms/Payments/PaymentsPopup";

import { StyledDataRoleElement } from "../../atoms/UserPanelAddRole/StyledDataRoleElement";
import { StyledDataWrapper } from "../../atoms/UserPanelAddRole/StyledDataWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledDeleteCheckbox } from "../../atoms/UserPanelAddRole/StyledDeleteCheckbox";
import { StyledIsOpenWrapper } from "../../atoms/UserPanelAddRole/StyledIsOpenWrapper";
import { StyledScrollElementWrapper } from "../../atoms/UserPanelAddRole/StyledScrollElementWrapper";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import { getString } from "../../../strings";
import { unassignRole } from "../../../logic/requests/students";

const RoleElement = ({
    user,
    role,
    onDelete
}) => {
    const [showPopup, setShowPopUp] = useState(false);

    const handleDelete = useCallback(
        () => {
            const prompt = (
                "Czy na pewno usunąć rolę:" + ' ' +
                getString(`roles_${role}`).toUpperCase() + ' ' +
                `dla użytkownika: ${user.name}?`
            );

            if (!window.confirm(prompt))
                return;

            unassignRole(user.id, role)
            .then(
                (res) => {
                    console.log("unassignRole", res);
                    onDelete?.();
                }
            )
            .catch(
                (err) => {
                    console.log("unassignRole", err);
                }
            )
            ;
        }, [user, role, onDelete]
    );

    return (
        <>
            {showPopup ?
                <PaymentsPopup
                    title={getString("Czy na pewno usunąć rolę:" + ' ' +
                        getString(`roles_${role}`).toUpperCase() + ' ' +
                        `dla użytkownika: ${user.name}?`)
                    }
                    onClose={() => setShowPopUp(false)}
                />
            : null}
            <StyledDataRoleElement>
                <StyledIsOpenWrapper>
                    <ScrollSyncPane>
                        <StyledScrollElementWrapper hidetrack>
                            <StyledDataWrapper>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {user.name}
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {user.email}
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {
                                            user.roles
                                            .map(
                                                (role) => getString(`roles_${role}`)
                                            )
                                            .join(", ")
                                        }
                                    </StyledText>
                                </div>
                            </StyledDataWrapper>
                        </StyledScrollElementWrapper>
                    </ScrollSyncPane>
                    <StyledDeleteCheckbox
                        onClick={handleDelete}
                    >
                        <CloseIcon />
                    </StyledDeleteCheckbox>
                </StyledIsOpenWrapper>
            </StyledDataRoleElement>
        </>
    );
};

export default RoleElement;
