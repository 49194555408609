import styled from "styled-components"

export const StyledFAQHeaderText = styled.div`
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ee745c;
    border-radius: 25px;
    padding: 2px 10px;
    transition: background-color 250ms linear;
`