import styled from "styled-components"

import multiclock1 from "../../../images/loader/multiclock1.svg"
import multiclock2 from "../../../images/loader/multiclock2.svg"
import multiclock3 from "../../../images/loader/multiclock3.svg"
import multiclock4 from "../../../images/loader/multiclock4.svg"

export const StyledBigLoader = styled.div`
    width: ${({ hasdeclaredwidth }) => hasdeclaredwidth ? hasdeclaredwidth : "120px"};
    height: ${({ hasdeclaredheight }) => hasdeclaredheight ? hasdeclaredheight : "120px"};
    border-radius: 50%;
    animation-name: loaderBigAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    
    @keyframes loaderBigAnimation {
        0%{
            background: url(${multiclock1});
        }
        25%{
            background: url(${multiclock2});
        }
        50%{
            background: url(${multiclock3});
        }
        75%{
            background: url(${multiclock4});
        }
        100%{
            background: url(${multiclock1});
        }
    }
`