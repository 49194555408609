import styled from "styled-components";

export const StyledSeriesResultsWrapper = styled.div`
    width: 90%;
    min-height: 70%;
    max-height: 73%;
    overflow-y: scroll;
    margin: 0 auto;
    padding-bottom: 70px;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
    
    @media only screen and (max-width: 1204px){
        width: 95%;
    }
    @media only screen and (max-width: 767px){
        width: 100%;
        padding-bottom: 60px;
        min-height: unset;
        max-height: unset;
        overflow-y: visible;
    }
`;
