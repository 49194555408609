import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";

import { StyledSeriesResultsElement } from "../../atoms/Series/StyledSeriesResultsElement";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledSeriesResultsElementResults } from "../../molecules/Series/StyledSeriesResultsElementResults";
import { StyledNumberWrapper } from "../../molecules/Series/StyledNumberWrapper";
import { StyledNameWrapper } from "../../molecules/Series/StyledNameWrapper";
import { StyledSumWrapper } from "../../molecules/Series/StyledSumWrapper";
import { StyledContentWrapper } from "../../atoms/Series/StyledContentWrapper";

import { ACTIVERESULTSTYPE } from "../../constants/Series";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const SeriesResultsElement = ({ number, leagueNumbers, result, activeResults, redcolor, bottomGap = false }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledSeriesResultsElement
            hasdeclaredmargin={bottomGap ? "0 0 35px 0" : undefined}
        >
            <StyledNumberWrapper iscolored={true}>
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredfontcolor="#FFF"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                >
                    {number}
                </StyledText>
            </StyledNumberWrapper>
            <StyledContentWrapper iscolored={true} redcolor={redcolor}>
                <StyledNameWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                    >
                        {`${result.firstname} ${result.lastname}`}
                    </StyledText>
                </StyledNameWrapper>
                <ScrollSyncPane>
                    <StyledSeriesResultsElementResults isscrollbar={isScrollBar} ref={scrollElement}>
                        {
                            leagueNumbers.concat(activeResults?.type === ACTIVERESULTSTYPE.EVENT ? [ "bonus" ] : []).map(
                                number => (
                                    <StyledText
                                        key={number}
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontcolor="#000"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                    >
                                        {
                                            activeResults?.type === ACTIVERESULTSTYPE.SERIES ? (
                                                result?.events?.[number] ?? '-'
                                            ) : activeResults?.type === ACTIVERESULTSTYPE.EVENT ? (
                                                number === "bonus" ? (
                                                    result?.bonus
                                                ) : (
                                                    result?.competitions?.find(
                                                        competition => competition.id === number
                                                    )?.score ?? '-'
                                                )
                                            ) : null
                                        }
                                    </StyledText>
                                )
                            )
                        }
                    </StyledSeriesResultsElementResults>
                </ScrollSyncPane>
                <StyledSumWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                    >
                        {result.total}
                    </StyledText>
                </StyledSumWrapper>
            </StyledContentWrapper>
        </StyledSeriesResultsElement>
    )
};

export default SeriesResultsElement;
