import styled from "styled-components";

import { ReactComponent as Cube } from "../../../images/cubes/skewb.svg";

export const StyledSkwebCube = styled(Cube)`
    @media only screen and (max-width: 1920px) {
        width: 240px;
        height: 240px;
    }
    @media only screen and (max-width: 1919px) {
        width: inherit;
        height: inherit;
    }
    @media only screen and (max-width: 1036px) {
        transform: translate(-90px, 5px);
    }
`;
