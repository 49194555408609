import styled from "styled-components";

export const StyledAdminTournamentData = styled.div`
    width: 100%;
    padding-top: 30px;
    font-family: "Poppins";
    > div {
        display: flex;
        min-height: 60px;
        align-items: center;
        border-bottom: 1px solid #000;
        > div {
            width: 80%;
            display: flex;
            align-items: center;
            padding: 0 20px;
            &:first-child {
                width: 20%;
                border-right: 1px solid #000;
            }
        }
    }
`;
