import styled from "styled-components";

export const StyledWhiteRow = styled.div`
    width: calc(100% - 128px);
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : "#fff"};
    border: 2px solid #000;
    border-radius: 10px;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "konkurencja runda format cutoff limit przechodzi";

    &:nth-child(1) {
        grid-area: konkurencja;
    }
    &:nth-child(2) {
        grid-area: runda;
    }
    &:nth-child(3) {
        grid-area: format;
    }
    &:nth-child(4) {
        grid-area: cutoff;
    }
    &:nth-child(5) {
        grid-area: limit;
    }
    &:nth-child(6) {
        grid-area: przechodzi;
    }

    @media only screen and (max-width: 1179px) {
        width: calc(100% - 98px);
    }
    @media only screen and (max-width: 1017px) {
        width: calc(100% - 78px);
    }
    @media only screen and (max-width: 871px) {
        width: calc(100% - 68px);
    }
    @media only screen and (max-width: 767px) {
        width: calc(100% - 128px);
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;
