import styled from 'styled-components'

export const StyledInput = styled.input`
    border: none;
    padding: 12px 22px;
    background: rgba(103, 134, 190, 0.35);
    width: 220px;
    margin: 18px 0;
    font-size: 22px;
    border-radius: 5px;
    outline-color: #135ada;
`
