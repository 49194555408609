import styled from "styled-components";
import { Field } from "formik";

export const StyledSelectInput = styled(Field)`
    border: 3px solid ${({ error }) => (error ? "#F74017" : "#3474E8")};
    max-width: 300px;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
    padding: 0 12px;
    font-family: "Poppins";
    outline: none;
    margin-bottom: 8px;
    transition: outline 250ms;
    cursor: pointer;
    background: #fff;

    > option {
        font-size: 16px;
        padding: 0 12px;
        font-family: "Poppins";
    }
`;
