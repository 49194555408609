import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledError = styled(motion.div)`
    position: absolute;
    bottom: ${({ hasdeclaredbottomposition }) =>
        hasdeclaredbottomposition ? hasdeclaredbottomposition : "0"};
    left: 0;
    width: 100%;
`;
