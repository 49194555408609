import styled from "styled-components"

export const StyledUsersListHeader = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    > div {
        padding: 20px 2px;
        border-right: 1px solid rgba(0,0,0,0.2);
        background-color: #fff;
        color: #000;
        cursor: pointer;
        > p{
            font-size: 14px;
            cursor: pointer;
        }
        &:nth-child(1){
            width: 3%;
            cursor: default;
            > p {
                cursor: default;
            }
        }
        &:nth-child(2){
            width: 25%;
        }
        &:nth-child(3){
            width: 7%;
        }
        &:nth-child(4){
            width: 13%;
        }
        &:nth-child(5){
            width: 12%;
        }
        &:nth-child(6){
            width: 8%;
        }
        &:nth-child(7){
            width: 7%;
        }
        &:nth-child(8){
            width: 7%;
        }
        &:nth-child(9){
            width: 18%;
        }
    }
`