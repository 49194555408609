import styled from "styled-components"

export const StyledSelectsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    
    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    > div:nth-child(2n) {
        width: 50%;
    }
`