import React, { useState } from 'react'
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { AnimatePresence } from "framer-motion";
import { getString } from "../../../strings";

import InputWrapper from "../../molecules/Register/InputWrapper";
import RegisterErrorMessage from "../../molecules/Register/RegisterErrorMessage";

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledTrainingsJoinUs } from "../../atoms/TrainingsJoinUs/StyledTrainingsJoinUs";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledTrainingsForm } from "../../atoms/TrainingsJoinUs/StyledTrainingsForm";
import { StyledTextAreaWrapper } from "../../atoms/TrainingsJoinUs/StyledTextAreaWrapper";
import { StyledAnswerWrapper } from "../../atoms/OrderRek/StyledAnswerWrapper";

import { signUp } from '../../../logic/requests/trainings.js';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const TrainingsJoinUs = ({ user }) => {
    const { scrollElement, isScrollBar } = useScrollBar();
    const [isSend, setIsSend] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().min(3, "Imię jest za krótkie").required("Imię wymagane"),
        lastName: Yup.string().required("Nazwisko wymagane"),
        phone: Yup.string()
            .matches(
                /^(?:\+?\d{1,2}\s?)?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{2,4}$/,
                "Nieprawidłowy numer telefonu"
            )
            .required("Numer telefonu wymagany"),
        email: Yup.string()
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Email niepoprawny"
            )
            .required("Email wymagany"),
        message: Yup.string()
            .min(10, 'Minimum 10 znaków')
            .required('Wiadomość wymagana'),
    });

    const initialValues = (
        user ? ({
            name: user.firstname,
            lastName: user.lastname,
            phone: user.phone,
            email: user.email,
            message: ""
        }) : ({
            name: "",
            lastName: "",
            phone: "",
            email: "",
            message: ""
        })
    );

    const handleSubmit = (data, { setSubmitting }) => {
        console.log("handleSubmit", data);
        signUp(data.name, data.lastName, data.email, data.phone, data.message)
            .then(
                (res) => {
                    console.log("handleSubmit", "succ", res);
                    setSubmitting(false);
                    setIsSend(true);
                }
            )
            .catch(
                (err) => {
                    console.error("handleSubmit", err);
                    setSubmitting(false);
                }
            )
        ;
    };

    return (
        <StyledTrainingsJoinUs
            issend={isSend} 
            isscrollbar={isScrollBar} 
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {isSend ? (
                <StyledAnswerWrapper>
                    <StyledText
                        hasdeclaredfontsize="30px"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredpadding="0 20px 40px 20px"
                        as="h2"
                    >
                        {getString("trainings_joinus_udalosie")}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredpadding="0 20px"
                    >
                        {getString("trainings_joinus_cieszymysie")}
                    </StyledText>
                    <StyledButton
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredmargin="24px 0 0 0"
                        hasdeclaredbgcolor="#6786BE"
                        hasdeclaredfontweight="600"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredwidth="240px"
                        hasdeclaredheight="50px"
                        hasdeclaredtextalign="center"
                        onClick={() => setIsSend(false)}
                    >
                        {getString("trainings_joinus_zamknij")}
                    </StyledButton>
                </StyledAnswerWrapper>
            ) : (
                <>
                    <StyledText
                        hasdeclaredfontsize="34px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 20px 0"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="700px"
                        as="h1"
                    >
                        {getString("trainings_joinus_jesteszainteresowany")}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 30px 0"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="700px"
                        as="h2"
                    >
                        {getString("trainings_joinus_contactus")}
                        <a aria-label={getString("seo_aria_label_zadzwon")} href={`tel:796 340 358`}>796 340 358</a>
                        {getString("trainings_joinus_contactusemailing")}
                        <a aria-label={getString("seo_aria_label_wyslij_email")} href="mailto:lekcje@cube4fun.pl">LEKCJE@CUBE4FUN.PL</a>
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 10px 0"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="700px"
                        as="h2"
                    >
                        {getString("trainings_joinus_mozesztez")}
                    </StyledText>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ errors, handleSubmit, isSubmitting }) => (
                            <AnimatePresence>
                                <StyledTrainingsForm onSubmit={handleSubmit}>
                                    <div>
                                        <InputWrapper
                                            inputType="text"
                                            title={getString("trainings_joinus_imie")}
                                            name="name"
                                            error={errors.name}
                                            hasDeclaredBottomPosition="-3px"
                                        />
                                    </div>
                                    <div>
                                        <InputWrapper
                                            inputType="text"
                                            title={getString("trainings_joinus_nazwisko")}
                                            name="lastName"
                                            error={errors.lastName}
                                            hasDeclaredBottomPosition="-3px"
                                        />
                                    </div>
                                    <div>
                                        <InputWrapper
                                            inputType="email"
                                            title={getString("trainings_joinus_e-mail")}
                                            name="email"
                                            error={errors.email}
                                            hasDeclaredBottomPosition="-3px"
                                        />
                                    </div>
                                    <div>
                                        <InputWrapper
                                            inputType="text"
                                            title={getString("trainings_joinus_telefon")}
                                            name="phone"
                                            error={errors.phone}
                                            hasDeclaredBottomPosition="-3px"
                                        />
                                    </div>
                                    <StyledTextAreaWrapper error={errors.message}>
                                        <label htmlFor="message">
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.2em"
                                            >
                                                {getString("trainings_joinus_opis")}
                                            </StyledText>
                                        </label>
                                        <Field
                                            as="textarea"
                                            name="message"
                                            placeholder={getString("trainings_joinus_placeholde")}
                                        />
                                        <RegisterErrorMessage
                                            name="message"
                                            hasDeclaredBottomPosition="-3px"
                                        />
                                    </StyledTextAreaWrapper>
                                    <div>
                                        <StyledButton
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredmargin="24px 0 0 0"
                                            hasdeclaredbgcolor="#6786BE"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredborderradius="25px"
                                            hasdeclaredcursor="pointer"
                                            type="submit"
                                            disabled={isSubmitting}
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredwidth="240px"
                                            hasdeclaredheight="50px"
                                            hasdeclaredtextalign="center"
                                            whileTap={{ scale: 0.9 }}
                                            whileHover={{ scale: 1.05 }}
                                        >
                                            {getString("trainings_joinus_zapiszsie")}
                                        </StyledButton>
                                    </div>
                                </StyledTrainingsForm>
                            </AnimatePresence>
                        )}
                    </Formik>
                </>
            )}
        </StyledTrainingsJoinUs>
    );
}

export default TrainingsJoinUs
