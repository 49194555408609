import styled from "styled-components";

export const StyledUserMenu = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 30px;

    button {
        width: 300px;
        min-height: 50px;
    }

    @media only screen and (max-width: 320px){
        width: 95%;

        button{
            width: 100%;
            margin: 20px auto 0;
        }
    }
`;
