import React, { useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import SubjectDataElement from "../SubjectDataElement/SubjectDataElement";

import {
    StyledUserPanelSubjectDataElement,
    StyledElement,
    StyledHeader,
    StyledHeaderElement,
    StyledDeleteButton
} from "./UserPanelSubjectDataElement.styles";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledHeading } from "../../atoms/PayElement/StyledHeading";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";

import { getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelSubjectDataElement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar();

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <StyledUserPanelSubjectDataElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {getString("User_panel_subject_element_nazwa_przedmiotu2")}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElement>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                <StyledHeader
                                    isscrollbar={isScrollBar}
                                    ref={scrollElement}
                                    hasalignself="flex-start"
                                >
                                    <div>
                                        <StyledHeaderElement $hasWidth="10%" />
                                        <StyledHeaderElement $hasWidth="40%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_subject_element_nazwa_przedmiotu_tytul")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="25%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_subject_element_nazwa_przedmiotu_cena_ucznia")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="25%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_subject_element_nazwa_przedmiotu_cena_trenera")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                    </div>
                                </StyledHeader>
                                <SubjectDataElement /> 
                                <SubjectDataElement /> 
                                <SubjectDataElement /> 
                                <SubjectDataElement /> 
                            </ScrollSyncPane>
                        </>
                    </ScrollSync>
                    <StyledDeleteButton>
                        <CloseIcon />
                    </StyledDeleteButton>
                </StyledElement>
            ) : null}
        </StyledUserPanelSubjectDataElement>
    );
};

export default UserPanelSubjectDataElement;
