import styled from "styled-components"

export const StyledDataContent = styled.div`
    margin-top: 8px;
    width: 100%;
    overflow-x: scroll;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 700px;
    }
`