import styled from "styled-components"

export const StyledUserPanelStudentsPayments = styled.div`
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 926px){
        > h2 {
            padding-left: 20px;
        }
    }
`