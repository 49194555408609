import styled from "styled-components";

export const StyledTournamentButtons = styled.div`
    margin-top: 25px;
    button {
        cursor: pointer;
        padding: 17px 30px;
        margin-left: 10px;
        border-radius: 15px;
    }
    a {
        text-decoration: none;
        color: #000;
    }
`;
