import React from "react";
import { getString } from "../../../strings";
import { Link } from "react-router-dom";

import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledSeriesButton } from "../SeriesButton/StyledSeriesButton";

const SeriesButton = ({ isSeriesPage }) => {
    return (
        <StyledSeriesButton>
            <Link to={isSeriesPage ? "/competitions" : "/series"}>
                <StyledButton
                    hasdeclaredfontsize="30px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredtextalign="center"
                    hasdeclaredfontweight="600"
                    hasdeclaredcursor="pointer"
                >
                    {isSeriesPage ? getString("tournaments_seriesButton_zawody") : getString("tournaments_seriesButton_serie")}
                </StyledButton>
            </Link>
        </StyledSeriesButton>
    );
};

export default SeriesButton;
