import styled from "styled-components";
import bg from "../../../images/tournaments/newBg3.jpg";

import { motion } from "framer-motion";

export const StyledTournaments = styled(motion.section)`
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background: url(${bg}) no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -moz-overflow: hidden;
    overflow: hidden;
    
    @media only screen and (max-width: 1064px) {
        height: auto;
        position: relative;
        padding-bottom: 40px;
    }
`;
