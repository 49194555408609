import styled from "styled-components";

export const StyledTournamentContent = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 75%;

    > a {
        text-decoration: none;
        color: #000;
        margin-right: 30px;

        transition: opacity 250ms linear;

        &:hover {
            opacity: 0.8;
        }
    }

    > a,
    > img {
        width: 35%;
    }

    > img {
        margin-right: 30px;
    }

    > a img {
        width: 100%;
    }

    > img,
    > a img {
        max-height: 200px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    @media only screen and (max-width: 922px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;

        > a {
            width: 100%;
            margin-right: 0;
        }
        
        > img {
            width: 100%;
        }
    }

    @media only screen and (max-width: 796px) {
        width: 100%;
    }
`;
