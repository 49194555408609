import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledCard = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h2,
    > div,
    > div svg {
        transition: transform 250ms linear;
    }

    &:hover {
        > h2,
        > div {
            transform: scale(1.03);
        }

        > h2,
        > div svg {
            transform: ${({ scaleSvg }) => scaleSvg ? "scale(1.03)" : null};
        }
    }

    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 100%;
        min-height: 400px;

        h2 {
            padding-top: 24px;
        }
    }

    @media only screen and (max-width: 545px) {
        h2 {
            padding-top: 10px;
            font-size: ${({ isang }) => isang ? "54px" : "62px"}
        }
    }

    @media only screen and (max-width: 465px) {
        min-height: 250px;
        
        h2 {
            font-size: 24px;
        }
    }
`;
