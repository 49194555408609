import React, { useState, useRef } from 'react'

import UserPanelTournamentButton from "../../molecules/UserPanelTournament/UserPanelTournamentButton";
import CompetitionElement from "../../molecules/CompetitionElement/CompetitionElement";

import { StyledUserPanelReqElement } from "../../atoms/UserPanelReqElement/StyledUserPanelReqElement";
import { StyledDataWrapper } from "../../atoms/UserPanelReqElement/StyledDataWrapper";
import { StyledLogoWrapper } from "../../atoms/UserPanelReqElement/StyledLogoWrapper";
import { StyledTextContent } from "../../atoms/UserPanelReqElement/StyledTextContent";
import { StyledContent } from "../../atoms/UserPanelReqElement/StyledContent";
import { StyledButtonsWrapper } from "../../atoms/UserPanelReqElement/StyledButtonsWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButtonWrapper } from "../../atoms/UserPanelReqElement/StyledButtonWrapper";
import { StyledInputWrapper } from "../../atoms/UserPanelReqElement/StyledInputWrapper";
import { StyledCustomFile } from "../../atoms/UserPanelReqElement/StyledCustomFile";

import { getString } from '../../../strings';

import { beginAnalysis, editAnalysis, rejectAnalysis, submitAnalysis } from '../../../logic/requests/trainings';

const handleBegin = (id, setStatus) => {
    console.log("handleBegin", id, setStatus);

    beginAnalysis(id)
        .then(
            res => {
                console.log("beginAnalysis", res);
                setStatus?.("in-progress");
            }
        )
        .catch(
            err => {
                console.error("beginAnalysis", err);
            }
        )
    ;
};

const handleCancel = (id, reason, setStatus, hideBox) => {
    console.log("handleCancel", id, reason, setStatus);

    if (!reason?.length) {
        // TODO: co tutaj?
        console.error("handleCancel", "reason is empty", "reason:", reason);
        return;
    }

    hideBox?.();
    // TODO: Jakiś loader?

    rejectAnalysis(id, reason)
        .then(
            res => {
                console.log("handleCancel", res);
                setStatus?.("cancelled");
            }
        )
        .catch(
            err => {
                console.error("handleCancel", err);
            }
        )
    ;
};

const handleFileSelect = (e, setFile) => {
    if (!e.target?.files?.length)
        return;

    const file = e.target.files[0];
    console.log("handleFileSelect", file);

    if (file.type !== "application/pdf") {
        // TODO: Co tutaj?
        console.warn("handleFileSelect", "Got invalid file type");
    }

    setFile?.(file);
};

const handleSubmit = (id, file, status, setStatus, clearForm) => {
    if (!file) {
        // TODO: Co tutaj?
        console.log("handleSubmit", "Got no file");
        return;
    }

    const pain = new FormData();
    pain.append("content", file);

    const submit = (
        (status === "done") ? (
            editAnalysis
        ) : (
            submitAnalysis
        )
    );

    submit(id, pain)
        .then(
            res => {
                console.log("submitAnalysis", res);
                clearForm?.();
                setStatus?.("done");
            }
        )
        .catch(
            err => {
                console.error("submitAnalysis", err);
            }
        )
    ;
};

const UserPanelReqElementTrainer = ({ name, format, competition, phone, email, link, status, orderId, setStatus }) => {
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [file, setFile] = useState();
    const rejectionReason = useRef();

    const buttons = (() => {
        const ret = [];

        switch (status) {
            case "pending":
                // może przyjąć albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__rozpocznij")}
                        color="#4E6BAA"
                        onClick={() => handleBegin(orderId, setStatus)}
                    />,
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__odrzuc_analize")}
                        color="#E18872"
                        onClick={() => setCancel((val) => (!val))}
                    />
                );
                break;
            case "in-progress":
                // może przesłać albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__przeslij")}
                        color="#4E6BAA"
                        onClick={
                            () => {
                                setSubmit((val) => (!val));
                                setFile(undefined);
                            }
                        }
                    />,
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__odrzuc_analize")}
                        color="#E18872"
                        onClick={() => setCancel((val) => (!val))}
                    />
                );
                break;
            case "done":
                // może wyświetlić albo edytować
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__zobacz")}
                        color="#4E6BAA"
                        onClick={() => window.open(`/api/trainings/analysis/get-content/${orderId}`, '_blank', 'noopener,noreferrer')}
                    />,
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__edytuj")}
                        color="#E18872"
                        onClick={
                            () => {
                                setSubmit((val) => (!val));
                                setFile(undefined);
                            }
                        }
                    />
                );
                break;
            case "expired":
                // może ponowić (przedłużyć termin) albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__kontynuuj")}
                        color="#4E6BAA"
                        onClick={() => handleBegin(orderId, setStatus)}
                    />,
                    <UserPanelTournamentButton
                        name={getString("UserPanelReq__zakoncz")}
                        color="#E18872"
                        onClick={() => setCancel((val) => (!val))}
                    />
                )
                break;
        }

        return ret;
    })();

    return (
        <StyledUserPanelReqElement>
            <StyledContent>
                <StyledText
                    hasdeclaredfontsize="32px"
                    hasdeclaredfontweight="700"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredtexttransform="uppercase"
                    as="h2"
                >
                    {name}
                </StyledText>
                <StyledDataWrapper>
                    <StyledLogoWrapper>
                        <StyledText
                            hasdeclaredtextalign="center"
                            hasdeclaredfontsize="32px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {format}
                        </StyledText>
                        <CompetitionElement competition={competition} />
                    </StyledLogoWrapper>
                    <StyledTextContent>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("UserPanelReqElement__tel")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {phone}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("UserPanelReqElement__email")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {email}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("UserPanelReqElement__nagranie")}:
                            </StyledText>
                            <a href={link}>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#E18872"
                                    hascursor="pointer"
                                >
                                    {getString("UserPanelReqElement__link")}
                                </StyledText>
                            </a>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString("UserPanelReqElement__status")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor={status === "done" ? "#6AB95D" : "#E18872"}
                            >
                                {getString(`UserPanelReq__analysis_status__${status}__trainer`)}
                            </StyledText>
                        </div>
                    </StyledTextContent>
                </StyledDataWrapper>
            </StyledContent>
            {buttons.length === 1 ? (
                <StyledButtonWrapper>{buttons}</StyledButtonWrapper>
            ) : buttons.length > 1 ? (
                <StyledButtonsWrapper>{buttons}</StyledButtonsWrapper>
            ) : undefined}
            {
                (cancel) ? (
                    <StyledInputWrapper>
                        <StyledText
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredcursor="pointer"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="600"
                            hasdeclaredpadding="18px 0 0 0"
                        >
                            {getString("UserPanelReq__powod_anulowania")}
                        </StyledText>
                        <textarea ref={rejectionReason} />
                        <UserPanelTournamentButton
                            name={getString("UserPanelReq__wyslij")}
                            color="#4E6BAA"
                            onClick={() => handleCancel(orderId, rejectionReason.current.value, setStatus, () => { rejectionReason.current.value = undefined; setCancel(false); })}
                        />
                    </StyledInputWrapper>
                ) : (undefined)
            }
            {
                (submit) ? (
                    <StyledInputWrapper>
                        <StyledCustomFile file={file}>
                            <label htmlFor="upload">{getString("UserPanelReq__submit_title")}</label>
                            <input id="upload" type="file" accept="application/pdf" onChange={(e) => handleFileSelect(e, setFile)} />
                        </StyledCustomFile>
                        <UserPanelTournamentButton
                            name={getString("UserPanelReq__wyslij")}
                            color="#4E6BAA"
                            onClick={(e) => handleSubmit(orderId, file, status, setStatus, () => { setSubmit(false); setFile(undefined); })}
                        />
                    </StyledInputWrapper>
                ) : (undefined)
            }
        </StyledUserPanelReqElement>
    );
}

export default UserPanelReqElementTrainer
