import styled from "styled-components"

export const StyledRadioLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    p {
        color: #333;
        cursor: pointer;
    }
    input {
        pointer-events: none;
        opacity: 0;
        width: 0;
        height: 0;
    }
    input:not(:checked) + p {
        color: rgba(0,0,0,0.2);
    }
    input:checked + p {
        color: #6786be;
        font-weight: bold;
    }
`;