import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";

import { StyledStudentPaymentsElement } from "../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElement";
import { StyledDataElement } from "../../atoms/SettlementsDoneDataElement/StyledDataElement";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledStudentPaymentsElementWrapper } from "../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElementWrapper";
import { StyledScrollWrapperElement } from "../../atoms/UserPanelStudentsPayments/StyledScrollWrapperElement";

const StudentPaymentsElement = ({
    student,
    subject,
    date,
    hours_count,
    trainer,
    price
}) => (
    <StyledStudentPaymentsElementWrapper>
        <ScrollSyncPane>
            <StyledScrollWrapperElement>
                <StyledStudentPaymentsElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {student}
                        </StyledText>
                    </StyledDataElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {subject}
                        </StyledText>
                    </StyledDataElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {date}
                        </StyledText>
                    </StyledDataElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {hours_count}
                        </StyledText>
                    </StyledDataElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {trainer}
                        </StyledText>
                    </StyledDataElement>
                    <StyledDataElement>
                        <StyledText
                            hasdeclaredfontsize="13px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                        >
                            {price}
                        </StyledText>
                    </StyledDataElement>
                </StyledStudentPaymentsElement>
            </StyledScrollWrapperElement>
        </ScrollSyncPane>
    </StyledStudentPaymentsElementWrapper>
);

export default StudentPaymentsElement;
