import React, { useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Decimal } from "decimal.js";
import Loader from "../Loader/Loader";
import PayElement from "./PayElement";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelTrainerPay } from "../../atoms/UserPanelTrainerPay/StyledUserPanelTrainerPay";
import { StyledPayWrapper } from "../../atoms/UserPanelTrainerPay/StyledPayWrapper";
import { StyledContent } from "../../atoms/UserPanelTrainerPay/StyledContent";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledSumElement } from "../../atoms/UserPanelTrainerPay/StyledSumElement";
import { StyledSumRow } from "../../atoms/UserPanelTrainerPay/StyledSumRow";

import { getPayData, listPay, listStudents, listSubjects } from "../../../logic/requests/students";
import { getLang, getString } from "../../../strings";
import { cebulaCompare, dateKeyCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const now = new Date();

const UserPanelTrainerPay = ({ user }) => {
    const { scrollElement, isScrollBar, trigger } = useScrollBar(true);

    const { data: studentsResponse, status: studentsQueryStatus } = useQuery(["students", "all"], listStudents);
    const students = useMemo(
        () => studentsQueryStatus === 'success'
            ? studentsResponse.sort(
                (a, b) => cebulaCompare(getString(`subject__${a}`), getString(`subject__${b}`))
            )
            : undefined,
        [studentsResponse, studentsQueryStatus]
    );
    
    const { data: subjectsResponse, status: subjectsQueryStatus } = useQuery(["subjects", "all"], listSubjects);
    const subjects = useMemo(
        () => subjectsQueryStatus === 'success'
            ? subjectsResponse.sort(
                (a, b) => cebulaCompare(getString(`subject__${a}`), getString(`subject__${b}`))
            )
            : undefined,
        [subjectsResponse, subjectsQueryStatus]
    );

    const { data: payDataResponse, status: payDataQueryStatus } = useQuery(["payData", user.id], getPayData);
    const trainerPayData = useMemo(
        () => (
            payDataQueryStatus === 'success'
            && payDataQueryStatus instanceof Object
            && Object.keys(payDataQueryStatus).length
        ) ? (
            Object.fromEntries(Object.entries(payDataQueryStatus).map(
                ([key, val]) => [key, new Decimal(val ?? 0)]
            ))
        ) : (undefined),
        [payDataResponse, payDataQueryStatus]
    );

    const { data: listPayResponse, status: listPayQueryStatus } = useQuery(["listPay", user.id], listPay);
    const payData = useMemo(
        () => listPayQueryStatus === 'success'
            ? Object.fromEntries(
                Object.entries(listPayResponse)
                .map(
                    ([key, { bonus, ...rest }]) => ([key, {
                        ...rest,
                        bonus: bonus ? new Decimal(bonus) : bonus,
                    }])
                )
                .sort()
                .reverse()
            )
            : undefined,
        [listPayResponse, listPayQueryStatus]
    );

    const isStatus = useCallback(
        (status) => [
            studentsQueryStatus,
            subjectsQueryStatus,
            payDataQueryStatus,
            listPayQueryStatus,
        ].some((queryStatus) => (queryStatus === status)),
        [
            studentsQueryStatus,
            subjectsQueryStatus,
            payDataQueryStatus,
            listPayQueryStatus,
        ]
    );
    const isLoading = useMemo(
        () => isStatus("loading"),
        [isStatus]
    );
    const isError = useMemo(
        () => isStatus("error"),
        [isStatus]
    );

    const currentHours = useMemo(
        () => (
            payData ? (
                Object.entries(payData)
                .filter(
                    ([key, value]) => (
                        key === `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`
                        && value.hours
                    )
                )
                .map(
                    ([, { hours: { hours } }]) => (hours)
                )
                .reduce((prev, cur) => prev.concat(cur), [])
            ) : (undefined)
        ),
        [payData]
    );
    const currentHoursAmount = useMemo(
        () => (
            currentHours ? (
                currentHours.reduce(
                    (prev, cur) => (
                        prev
                        .plus(
                            (cur.half) ? ("0.5") : (cur.hours)
                        )
                    ), new Decimal(0)
                )
            ) : (undefined)
        ),
        [currentHours]
    );

    const currentBonus = useMemo(
        () => (
            payData ? (
                Object.entries(payData)
                .find(
                    ([key]) => (key === `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`)
                )?.[1]?.bonus
            ) : (undefined)
        ), [payData]
    );

    const currentAdditions = useMemo(
        () => (
            (payData) ? (
                Object.entries(payData)
                .filter(
                    ([key]) => (key === `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`)
                )
                .map(
                    ([, value]) => (value)
                )
                .reduce((prev, cur) => prev.concat(cur), [])
            ) : (undefined)
        ), [payData]
    );

    const totalHours = useMemo(
        () => (
            (currentHours) ? (
                currentHours.reduce(
                    (prev, cur) => (prev.plus(cur.half ? "0.5" : cur.hours)),
                    new Decimal(0)
                )
                .toString()
            ) : (undefined)
        ), [currentHours]
    );
    const totalHoursPay = useMemo(
        () => {
            if (!currentHours)
                return undefined;

            const ret = currentHours.reduce(
                (prev, cur) => (prev.plus(cur.price)),
                new Decimal(0)
            );

            return (
                (ret.dp() > 0) ? (
                    ret.toFixed(2)
                ) : (
                    ret.toString()
                )
            );
        }, [currentHours]
    );
    
    const totalAdditionsPay = useMemo(
        () =>
            currentAdditions
                ? currentAdditions.reduce(
                      (prev, { additions: curr }) =>
                          curr?.length
                              ? prev.plus(
                                    curr.reduce(
                                        (total, current) => total.plus(current.amount ?? 0),
                                        new Decimal(0)
                                    )
                                )
                              : undefined,
                      new Decimal(0)
                  )
                : undefined,
        [currentAdditions]
    );

    const total = useMemo(
        () => {
            if (!currentHours)
                return undefined;

            const ret = currentHours.reduce(
                (prev, cur) => (prev.plus(cur.price)),
                new Decimal(0)
            )
            .plus(currentBonus ?? 0)
            .plus(totalAdditionsPay ?? 0);

            return (
                (ret.dp() > 0) ? (
                    ret.toFixed(2)
                ) : (
                    ret.toString()
                )
            );
        }, [currentHours, trainerPayData, currentBonus, totalAdditionsPay]
    );

    return (
        <StyledUserPanelTrainerPay>
            {isError ? (
                <StyledText
                    hasdeclaredpadding="0px 20px"
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.4em"
                    as="p"
                >
                    {getString("blad_wczytywania")}
                </StyledText>
            ) : isLoading ? (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            ) : !(Object.keys(payData).length) ? (
                <StyledText
                    hasdeclaredpadding="0px 20px"
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.4em"
                    as="p"
                    >
                    {getString("UserPanelTrainerPay__no_hours")}
                </StyledText>
            ) : (
                <>
                    <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                            >
                                {getString("Trainings_trainings_all_trainer_pay_razem")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {total} PLN
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString("Trainings_trainings_all_trainer_pay_godziny2")}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {totalHoursPay} PLN ({totalHours} H)
                            </StyledText>
                        </StyledSumRow>
                        {
                            (trainerPayData || currentBonus) ? (
                                <StyledSumRow iscentertext>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString("Trainings_trainings_all_trainer_pay_premia")}:
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredtextalign="center"
                                    >
                                        {
                                            (currentBonus) ? (`${currentBonus.dp() > 0 ? currentBonus.toFixed(2) : currentBonus.toString()} PLN`) : (
                                                `${getString("UserPanelTrainerPay__summary__remaining_until_bouns")} ${trainerPayData.bonus_hours.minus(currentHoursAmount)} H`
                                            )
                                        }
                                    </StyledText>
                                </StyledSumRow>
                            ) : (undefined)
                        }
                        {
                            (totalAdditionsPay && totalAdditionsPay?.toFixed(2) > 0) ? (
                                <StyledSumRow iscentertext>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString("Trainings_trainings_all_trainer_pay_dodatkowe")}:
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredtextalign="center"
                                    >
                                        {
                                            (totalAdditionsPay.dp() > 0) ? (
                                                totalAdditionsPay.toFixed(2)
                                            ) : (
                                                totalAdditionsPay.toString()
                                            )
                                        } PLN
                                    </StyledText>
                                </StyledSumRow>
                            ) : (undefined)
                        }
                    </StyledSumElement>
                    <StyledContent>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString("Trainings_trainings_all_trainer_pay_wynagrodzenie")}:
                        </StyledText>
                        <StyledPayWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            <div>
                                {Object.entries(payData).map(([month, {
                                    additions,
                                    bonus,
                                    hasContract,
                                    ...value
                                }]) => (
                                    <PayElement
                                        key={month}
                                        month={month}
                                        keyValue={new Date(month).toLocaleString(getLang(), { month: "long", year: "numeric" })}
                                        hours={value.hours?.hours ?? []}
                                        user={user}
                                        isSubmitted={value.hours?.submitted ?? false}
                                        defaultOpen={month === `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`}
                                        additions={additions}
                                        trigger={trigger}
                                        bonus={
                                            (bonus) ? (
                                                (bonus.dp() > 0) ? (
                                                    bonus.toFixed(2)
                                                ) : (
                                                    bonus.toString()
                                                )
                                            ) : (undefined)
                                        }
                                        students={students}
                                        subjects={subjects}
                                        hasContract={hasContract}
                                    />
                                ))}
                            </div>
                        </StyledPayWrapper>
                    </StyledContent>
                </>
            )}
        </StyledUserPanelTrainerPay>
    );
};

export default UserPanelTrainerPay;
