import styled from "styled-components";

export const StyledSvgAndTextWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 18px;
    svg {
        max-height: 50px;
        width: 52px;
        stroke: #fff;
    }

    @media only screen and (max-width: 319px){
        p{
            font-size: 26px;
        }
    }
`;
