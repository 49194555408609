import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledHomePageLogo = styled(motion.div)`
    width: 200px;
    height: 200px;
    border: 6px solid #fff;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 35px;
    overflow: hidden;
    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        display: none;
    }
`;
