import backendRequest from "./index.js";

const signUp = (firstname, lastname, email, phone, desc) => (
	backendRequest("POST", `/trainings/sign-up`, { firstname, lastname, email, phone, desc })
);

const aboutTrainers = (extended = false) => (
	backendRequest("GET", "/trainings/trainers/about" + (extended ? "?extended=true" : ""))
);

const getFormData = () => (
	backendRequest("GET", "/trainings/analysis/list-offers")
);

const priceCheck = (competition, format, bonus_code) => (
	backendRequest("POST", "/trainings/analysis/price-check", { competition, format, bonus_code })
);

const orderAnalysis = (data) => (
	backendRequest("POST", "/trainings/analysis/order", data)
);

const getAnalysesUser = () => (
	backendRequest("GET", "/trainings/analysis/get/user")
);

const getAnalysesTrainer = () => (
	backendRequest("GET", "/trainings/analysis/get/trainer")
);

const beginAnalysis = (id) => (
	backendRequest("GET", `/trainings/analysis/update/begin/${id}`)
);
	
const rejectAnalysis = (id, reason) => (
	backendRequest("POST", `/trainings/analysis/update/cancel/${id}`, { reason })
);

const submitAnalysis = (id, file) => (
	backendRequest("POST", `/trainings/analysis/update/submit/${id}`, file, "multipart/form-data")
);

const editAnalysis = (id, file) => (
	backendRequest("POST", `/trainings/analysis/update/edit/${id}`, file, "multipart/form-data")
);

const cancelAnalysis = (id) => (
	backendRequest("POST", `/trainings/analysis/cancel/${id}`)
);

export {
	signUp,
	aboutTrainers,
	getFormData,
	priceCheck,
	orderAnalysis,
	getAnalysesUser,
	getAnalysesTrainer,
	beginAnalysis,
	rejectAnalysis,
	submitAnalysis,
	editAnalysis,
	cancelAnalysis
};
