import styled from "styled-components"

export const StyledAdminPlayersSection = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;

    @media only screen and (max-width: 762px){
        > p {
            font-size: 16px;
        }
    }
`