import styled from "styled-components"

export const StyledTrackHours = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @media only screen and (max-width: 926px){
        margin-top: 60px;
    }
`;