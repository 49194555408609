const func = (link, fileName) => {
	const a = document.createElement('a');
	a.href = link;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export default func;
