import styled from "styled-components";
import { ReactComponent as ContactSvg } from "../../../images/home/koperta.svg";

export const StyledContactSvg = styled(ContactSvg)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: none;
    transform: translateX(70px);

    @media only screen and (min-width: 1920px) {
        height: 260px;
    }
    @media only screen and (max-width: 1154px) {
        height: 140px;
        width: 240px;
    }
    @media only screen and (max-width: 943px) {
        height: 110px;
        width: 210px;
    }
    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 300px;
        height: 180px;
    }
    @media only screen and (max-width: 601px) {
        width: 260px;
        height: 140px;
    }
    @media only screen and (max-width: 535px) {
        transform: translateX(0);
    }
    @media only screen and (max-width: 465px) {
        width: 160px;
        height: 110px;
        transform: translateX(40px);
    }
    @media only screen and (max-width: 359px) {
        width: 130px;
    }
`;
