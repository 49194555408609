import styled from "styled-components"

export const StyledOrderRekForm = styled.form`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    padding-bottom: 40px;
    max-width: 800px;
    @media only screen and (max-width: 1183px){
        width: 95%;
    }
    @media only screen and (max-width: 1005px){
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;