import React, { useEffect, useRef, useState } from "react";
import SmallLoader from "../Loader/SmallLoader";
import Loader from "../Loader/Loader";

import LoadingImage from "./LoadingImage";

import { StyledLoadingWrapper } from "../../atoms/LoadingImage/StyledLoadingWrapper";

const LoadingImageWrapper = ({ children, style: orig_style, container = <div />, smallLoader = false, ...props }) => {
	const childrenReducer = (
		(prev, curr) => (
			prev + (
				Array.isArray(curr) ? (
					curr.reduce(childrenReducer, 0)
				) : (
					curr?.type === LoadingImage ? 1 : 0
				)
			)
		)
	),
	imageCount = (
		children ? (
			children.reduce(
				childrenReducer,
				0
			)
		) : (
			0
		)
	),
	[imagesLoaded, setImagesLoaded] = useState(0),
	[imagesDoneLoading, setImagesDoneLoading] = useState(!(
		children?.length ? (
			children.filter(
				child => child?.type === LoadingImage
			)
			.length
		) : false
	)),
	loadingCallback = () => {
		const count = imagesLoaded + 1;

		setImagesLoaded(count);

		if (count >= imageCount)
			setImagesDoneLoading(true);
	},
	childrenMapper = (
		(child, i) => (
			Array.isArray(child) ? (
				child.map(childrenMapper)
			) : (
				child?.type === LoadingImage ? (
					<LoadingImage {...child.props} loadingCallback={loadingCallback} />
				) : (
					child
				)
			)
		)
	),
	newRef = useRef(),
	ref = props?.ref ?? newRef,
	style = { ...(orig_style ?? {}), display: "none" };

	useEffect(
		() => {
			if (imagesDoneLoading)
				ref.current.style.display = orig_style?.display ?? null;
		},
		[ imagesDoneLoading ]
	);

	return (
		<>
			{
				!imagesDoneLoading ? (
					<container.type>
							{
								(smallLoader) ? (
									<StyledLoadingWrapper>
										<SmallLoader declaredWidth="100px" declaredHeight="100px" />
									</StyledLoadingWrapper>
								) : (
									<Loader />
								)
							}
					</container.type>
				) : (null)
			}
			<container.type ref={ref} style={style} {...props}>
				{children.map(childrenMapper)}
			</container.type>
		</>
	);
};

export default LoadingImageWrapper;
