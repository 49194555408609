import styled from "styled-components";

export const StyledSelectCompetitionWrapper = styled.div`
    margin-top: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media only screen and (max-width: 908px) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }
    @media only screen and (max-width: 488px) {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
`;
