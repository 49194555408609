import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import UserPanelEmployeeConfElement from "./UserPanelEmployeeConfElement";
import UserPanelEmployeeDataElement from "../../molecules/UserPanelEmployeeDataElement/UserPanelEmployeeDataElement";
import Loader from "../Loader/Loader";

import { StyledUserPanelEmployeeConf } from "../../atoms/UserPanelEmployeeConfiguration/StyledUserPanelEmployeeConf";
import { StyledGlobalScrollWrapper } from "../../atoms/GlobalScrollWrapper/StyledGlobalScrollWrapper";
import { StyledGlobalHeadingWrapper } from "../../atoms/GlobalHeadingWrapper/StyledGlobalHeadingWrapper";
import { StyledText } from "../../atoms/Text/StyledText";

import { getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";
import { createUTCDate } from "../../../logic/time";

import { listTrainersEmployeeConf } from "../../../logic/requests/employees";
import SuccessMessage from "../../molecules/SuccessMessage";
import useSuccessMessage from "../../../logic/hooks/useSuccessMessage";

const now = new Date();

const UserPanelEmployeeConfiguration = () => {
    const { scrollElement, isScrollBar } = useScrollBar();
    const { infos, isShowing, show } = useSuccessMessage();

    const { data, status } = useQuery(["trainers", "all", "employeeConf"], listTrainersEmployeeConf);
    const trainers = useMemo(
        () => (!data) ? ([]) : (
            Object.values(data).map(
                ({ contracts, ...trainer }) => ({
                    ...trainer,
                    contracts: contracts.map(
                        ({ start, end, ...contract }) => ({
                            ...contract,
                            start: createUTCDate(start),
                            end: end ? createUTCDate(end) : end,
                        })
                    ),
                })
            ).map(
                ({ contracts, ...trainer }) => ({
                    ...trainer,
                    contracts,
                    current_contract: contracts.find(
                        ({ start, end }) => (
                            (start.getTime() <= now.getTime()) &&
                            (!end || end.getTime() >= now.getTime())
                        )
                    )
                })
            ).sort(
                (a, b) => {
                    const lastname = a.lastname.localeCompare(b.lastname);
                    if (lastname)
                        return lastname;
    
                    const firstname = a.firstname.localeCompare(a.firstname);
                    if (firstname)
                        return firstname;
    
                    return 0;
                }
            )
        ),
        [data]
    );

    switch (status) {
        case 'loading':
            return (
                <Loader />
            );
        case 'error':
            return (
                <p>Error</p>
            );
        case 'success':
            break;
        default:
            return null;
    }

    return (
        <StyledUserPanelEmployeeConf>
            <StyledGlobalHeadingWrapper>
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("User_panel_employee_konfiguracja_pracownika")}:
                </StyledText>
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                {(!isShowing) ? (null) : (
                    <SuccessMessage
                        title="test"
                        infos={infos}
                    />
                )}
                <UserPanelEmployeeConfElement
                    trainers={trainers}
                    onSuccess={show}
                />
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString("User_panel_employee_konfiguracja_pracownicy")}:
                </StyledText>
                {
                    trainers.map(
                        ({ id, ...trainer }) => (
                            <UserPanelEmployeeDataElement
                                key={id}
                                trainer={{ id, ...trainer }}
                            />
                        )
                    )
                }
            </StyledGlobalScrollWrapper>
        </StyledUserPanelEmployeeConf>
    );
};

export default UserPanelEmployeeConfiguration;
