import styled from "styled-components"

export const StyledPreferencesElements = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 20px;
    gap: 10px;
`