import styled from "styled-components"

export const StyledLeftTrainingWrapper = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
    padding-bottom: 20px;
    min-height: 400px;

    @media only screen and (max-width: 1196px){
        width: 35%;
    }
    @media only screen and (max-width: 767px){
        width: 100%;
        padding-top: 80px;
    }
`