import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledContactSvgWrapper = styled(motion.div)`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`;
