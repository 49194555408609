import styled from "styled-components"

export const StyledPay = styled.div`
    width: 100%;
    min-width: 800px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 4px solid ${({ bordercolor }) => bordercolor ? bordercolor : "#6786be"};
    border-radius: 25px;
    background-color: #fff;
    gap: 4px;
    min-height: 48px;
`