import styled from "styled-components"

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex; 
    justify-content: ${({ checkboxes, hasminwidth }) => checkboxes ? "space-between" : hasminwidth ? "flex-end" : "space-between"};    
    align-self: flex-end;
    gap: 13px;
    margin-top: 18px;

    > button{
        width: 48%;
        height: 50px;
    }

    @media only screen and (max-width: 1082px){
        > button {
            font-size: ${({ bottomsize }) => bottomsize ? "14px" : "16px"};
        }
    }
    
    @media only screen and (max-width: 926px){
        > button {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 610px){
        > button {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 515px){
        flex-direction: column;
        
        > button {
            width: 100%;
            font-size: 16px;
        }
    }
`