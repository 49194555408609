import styled from "styled-components"

export const StyledCustomFile = styled.div`
    width: ${({ $hasDeclaredWidth }) => $hasDeclaredWidth ? $hasDeclaredWidth : "100%"};
    height: ${({ $hasDeclaredHeight }) => $hasDeclaredHeight ? $hasDeclaredHeight : "70px"};

    > input {
        opacity: 0;
    }

    > label {
        display: flex;
        width: 100%;
        height: 100%;
        font-family: "Poppins";
        background-color: ${({ file }) => file ? "#6786be" : "#ee745c"};
        border: 6px solid ${({ file }) => (file ? "#6786be" : "#ee745c")};
        border-radius: 25px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 515px){
       width: 100%; 
    }
`;