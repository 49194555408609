import * as Yup from "yup";
import { checkWCA } from "../../logic/requests/users.js";
import { getString } from "../../strings";

const max_birth_date = new Date();
max_birth_date.setFullYear(max_birth_date.getFullYear()-2);

const name_character_blacklist = [
    '`', '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '=', '+',
    '[', ']', '{', '}',
    ';', ':', '\'', '"', '\\',
    ',', '.', '<', '>', '/', '?',
    '\t'
]
const contains_blacklist_character = (s) => {
    if (!s?.length)
        return false;
    for (let char of name_character_blacklist) {
        if (s.indexOf(char) > -1)
            return true;
    }
    return false;
};

export const validationSchema = {
    firstname: Yup.string().min(2, getString("const_userDataValidationSchema_zaKrotkieImie")).required(getString("const_userDataValidationSchema_imieWymagane")).test(
        "firstname-blacklist",
        getString("const_userDataValidationSchema_imieZawieraNiedozwoloneZnaki"),
        (val) => !contains_blacklist_character(val)
    ),
    birthdate: Yup.date().required(getString("const_userDataValidationSchema_dataWymagana")).max(max_birth_date, getString("const_userDataValidationSchema_minWiek")),
    phone: Yup.string()
        .matches(/^(?:\+?\d{1,2}\s?)?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{2,4}$/, getString( "const_userDataValidationSchema_nieprawidlowy"))
        .required(getString("const_userDataValidationSchema_numerTelefonu")),
    lastname: Yup.string().required(getString("const_userDataValidationSchema_nazwiskoWymagane")).test(
        "lastname-blacklist",
        getString("const_userDataValidationSchema_nazwiskoZawieraNiedozZnaki"),
        (val) => !contains_blacklist_character(val)
    ),
    gender: Yup.string().required(getString("const_userDataValidationSchema_podajPlec")).nullable(),
    shirtSize: Yup.string().nullable(),
    city: Yup.string().required(getString("const_userDataValidationSchema_miastoWymagane")),
    // 4 cyfry (rok), 4 litery (z nazwiska), 2 cyfry (nr porządkowy) => 2021KOWA01
    wca_id: Yup.string()
        .matches(/\d{4}\w{4}\d{2}/, getString("const_userDataValidationSchema_nieprawidlowy2"))
		.notRequired()
        .nullable()
        .test(
            "wca_api",
            getString("const_userDataValidationSchema_uzytkownikOTakimWCA"),
            function (val) {
                return new Promise((resolve, reject) =>
                    val && val.length
                        ? checkWCA(val)
                              .then((res) => {
                                  // jeśli istnieje - jest OK
                                  resolve(true);
                              })
                              .catch((err) => resolve(false))
                        : resolve(true)
                );
            }
        ),
    file: Yup.mixed().notRequired(),
    newsletterField: Yup.bool(),
};
