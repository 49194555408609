import styled from "styled-components"

export const StyledContent = styled.div`
    width: 100%;
    border-radius: 40px;
    background-color: #fff;
    border: 10px solid #6786be;
    padding: 16px;
    box-sizing: border-box;

    @media only screen and (max-width: 926px) {
        h2 {
            text-align: center;
            font-size: 32px;
        }
    }

    @media only screen and (max-width: 500px) {
        h2 {
            text-align: center;
            font-size: 26px;
        }
    }
`;