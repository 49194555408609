import styled from "styled-components"

export const StyledPhoneLink = styled.a`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 14px;

    @media only screen and (max-width: 528px){
        font-size: 16px;
    }
`;