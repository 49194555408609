import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledImgLogoWrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
