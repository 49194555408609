import styled from "styled-components";

export const StyledSumWrapper = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1100px){
        > p{
            font-size: 15px
        }
    }
`;
