import styled from 'styled-components'

export const StyledScrambleNet = styled.div`
    margin-top: 60px;
    max-width: 600px;

    img {
        width: 100%;
    }

    @media only screen and (max-width: 660px) {
        width: 100%;
    }
`
