import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { getString } from "../../../strings";
import TournamentsCompetitions from "../../molecules/Tournaments/TournamentsCompetitions";
import RegisterCompetitions from "../../molecules/Register/RegisterCompetitions";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledUserPanelTournament } from "../../atoms/UserPanel/StyledUserPanelTournament";
import { StyledButtonsWrapper } from "../../molecules/UserPanel/StyledButtonsWrapper";
import { StyledContentWrapper } from "../../molecules/UserPanel/StyledContentWrapper";
import { StyledButtonWrapper } from "../../molecules/UserPanel/StyledButtonWrapper";
import { StyledResignationWrapper } from "../../atoms/UserPanel/StyledResignationWrapper";
import { StyledEditWrapper } from "../../atoms/UserPanel/StyledEditWrapper";
import { StyledContentData } from "../../molecules/UserPanel/StyledContentData";
import { StyledDataWrapper } from "../../molecules/UserPanel/StyledDataWrapper";

import UserPanelTournamentButton from "../../molecules/UserPanelTournament/UserPanelTournamentButton";
import UserPanelTournamentScript from "./UserPanelTournamentScript";

import { getDateTimeString, getUTCDateString } from "../../../logic/eventtime.js";
import { updateUserRegistration, unregisterUserFromEvent, getEventScriptForCurrentUser, registerUpgrade } from "../../../logic/requests/events";
import { setupEventPayment } from "../../../logic/requests/payments.js";
import UserPanelTournamentResults from "./UserPanelTournamentResults";
import downloadFile from "../../../utils/downloadFile.js";

import REGISTER_STATUS from "../../constants/register_status.json";
import axios from "axios";

const handleRegisterUpgrade = (event, setEvent) => {
    console.log("handleRegisterUpgrade", event);
    registerUpgrade(event.id)
        .then(
            res => {
                console.log("registerUpgrade", res);
                setEvent({
                    ...event,
                    user_register_status: 1
                });
            }
        )
        .catch(
            err => {
                console.error("registerUpgrade", err);
            }
        )
    ;
};

const REGISTER_STATUS_TEST = {
    [REGISTER_STATUS["UNCONFIRMED"]]: getString("userPanel_userPanelTournament_oczekujacy"),
    [REGISTER_STATUS["CONFIRMED"]]: getString("userPanel_userPanelTournament_zatwierdzony"),
    [REGISTER_STATUS["REJECTED"]]: getString("userPanel_userPanelTournament_odrzucony"),
    [REGISTER_STATUS["TRANSACTION_PENDING"]]: getString("userPanel_userPanelTournament_platnosc_w_toku")
};

const handlePay = (event) => {
    console.log("handlePay", event);

    // TODO: Pokazać tu loader?
    setupEventPayment(event.id)
        .then(
            res => {
                console.log("setupEventPayment", res);
                if (!res.token) {
                    // TODO: pokazać błąd? to nie powinno się zdażyć
                    return
                }
                // TODO: Pokazać coś chwilę przed przekierowaniem?
                const service = process.env.NODE_ENV === "production" ? "secure" : "sandbox";
                window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
            }
        )
        .catch(err => {
            console.error("setupEventPayment", err);
            // TODO: Pokazać błąd
        })
    ;
};

const UserPanelTournament = ({ user, event, setEvent, removeEvent, setCompetitionDeadlinePopup }) => {
    class ActiveTournamentFragment {
        constructor(state) {
            this.value = state;
        }

        static NONE = new ActiveTournamentFragment(0);
        static EDIT = new ActiveTournamentFragment(1);
        static RESIGN = new ActiveTournamentFragment(2);
        static SCRIPT = new ActiveTournamentFragment(3);
        static RESULTS = new ActiveTournamentFragment(4);
    }

    const [activeTournamentFragment, setActiveTournamentFragment] = useState(
        ActiveTournamentFragment.NONE.value
    );

    const [userCompetitions, setUserCompetitions] = useState(event?.user_competitions ?? []);
    const [eventScript, seteventScript] = useState();

    const time_start = useMemo(() => getUTCDateString(new Date(event.time_start)), [event]),
        time_end = useMemo(() => getUTCDateString(new Date(event.time_end)), [event]),
        edit_initial_values = Object.fromEntries(
            new Map(userCompetitions.map((competition) => [competition, true]))
        );

    const downloadCubeComps = () => downloadFile(
        `/api/admin/cubecomps/${event.id}`,
        `cubecomps_${event.code}.csv`
    );
    const downloadContactList = () => downloadFile(
        `/api/admin/contact-list/${event.id}`,
        `lista_kontaktowa_${event.code}.csv`
    );
    const downloadABCD = () => downloadFile(
        `/api/admin/abcd/${event.id}`,
        `abcd_${event.code}.csv`
    );
    const loadScenarios = () => {
        if (!window.confirm(`${getString("userPanel_userPanelTournament_czyNaPewnoChceszWczytacScenariusze")} ${event.name}?`))
            return;

        axios.get(`/events/grab-script/${event.id}`)
        .then((
            { data }) => alert(
                data.message === "OK"
                ? getString("userPanel_userPanelTournament_wczytanoPomyslnie")
                : JSON.stringify(data)
            )
        )
        .catch(err => alert(JSON.stringify(err)));
    };
    const downloadIDCards = () => downloadFile(
        `/api/events/get-ids/${event.code}`,
        `id-cards_${event.code}.pdf`
    );
    const downloadThanksCards = () => downloadFile(
        `/api/events/admin/get-thanks/${event.id}`,
        `thanks_${event.code}.pdf`
    );
    const downloadDiplomaCards = () => downloadFile(
        `/api/events/admin/get-diploma/${event.id}`,
        `diplomas_${event.code}.pdf`
    );
    const downloadMedalsCards = () => downloadFile(
        `/api/events/admin/get-medals/${event.id}`,
        `medals_${event.code}.pdf`
    );
    const downloadCupsCards = () => downloadFile(
        `/api/events/admin/get-cups/${event.id}`,
        `cups_${event.code}.pdf`
    );

    useEffect(
        () => {
            if (event?.script_available && user) {
                console.log(event);
                getEventScriptForCurrentUser(event.id)
                    .then(
                        res => {
                            console.log("getEventScriptForCurrentUser", res);
                            // nie sprawdzamy co tu jest - backend zawsze zwróci coś dobrego, bo jak nie, to rzuca 500
                            seteventScript(res);
                        }
                    )
                    .catch(
                        err => {
                            // TODO: Pokazać coś?
                            console.error("getEventScriptForCurrentUser", err);
                        }
                    )
                ;
            }
        },
        [ event ]
    );

    const handleSubmit = (data) => {
        const competitions = [];
        Object.keys(data).forEach((key) => {
            if (data[key]) competitions.push(key);
        });

        // conajmniej jedna konkurencja jest wymagana
        if (!competitions.length) {
            // TODO: pokazać jakiś błąd?
            return;
        }

        const has_changed = !(
            competitions.length === userCompetitions.length &&
            competitions.every((element) => userCompetitions.includes(element))
        );
        if (!has_changed) {
            // brak zmian, nie mieszaj nic w bazie daynch
            setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
            return;
        }

        updateUserRegistration(event.id, competitions)
            .then((res) => {
                console.log("updateUserRegistration", "success", res);
                setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
                setUserCompetitions(competitions);
            })
            .catch((err) => {
                console.error("updateUserRegistration", err);
                if (err.code) {
                    switch (err.code) {
                        case "deadline":
                            setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
                            setEvent({
                                ...event,
                                competition_deadline_expired: 1
                            });
                            setCompetitionDeadlinePopup?.(getDateTimeString(new Date(event.competition_deadline)));
                            break;
                        default:
                            break;
                    }
                }
            });
    };

    const handleRetire = () => {
        unregisterUserFromEvent(event.id)
            .then((res) => {
                console.log("unregisterUserFromEvent", "success", res);
                setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
                const expired = res.payment_return_deadline_expired;
                if (isNaN(expired)) {
                    removeEvent(event.id)
                } else {
                    removeEvent(event.id, !res.payment_return_deadline_expired);
                }
            })
            .catch((err) => {
                // TODO: pokazać jakiś błąd?
                console.error("unregisterUserFromEvent", err);
            });
    };

    const toggleActiveTournamentFragment = useCallback(
        (newFragment) => (
            setActiveTournamentFragment(
                (activeTournamentFragment === newFragment) ? (
                    ActiveTournamentFragment.NONE.value
                ) : (
                    newFragment
                )
            )
        ),
        [ ActiveTournamentFragment.NONE.value, activeTournamentFragment, setActiveTournamentFragment ]
    ),
    buttons = ([
        ...(() => {
            let ret = [];

            // ODRZUCONY - brak jakichkolwiek przycisków
            console.log("event", event);
            if (event.user_register_status === REGISTER_STATUS["REJECTED"])
                return ret;

            if (event.results_available) {
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("userPanel_userPanelTournament_wyniki")}
                        color="#4E6BAA"
                        onClick={() => toggleActiveTournamentFragment(ActiveTournamentFragment.RESULTS.value)}
                    />
                );
                return ret;
            }

            if (event.script_available && event.status !== "over") {
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("userPanel_userPanelTournament_scenariusz")}
                        color="#4E6BAA"
                        onClick={() => toggleActiveTournamentFragment(ActiveTournamentFragment.SCRIPT.value)}
                    />
                );
            }

            if (!event.reserve) {
                if (event.user_register_status === REGISTER_STATUS["CONFIRMED"]) {
                    if (!event.competition_deadline_expired) {
                        ret.push(
                            <UserPanelTournamentButton
                                name={getString("userPanel_userPanelTournament_zmienKonkurencje")}
                                color="#4E6BAA"
                                onClick={() => toggleActiveTournamentFragment(ActiveTournamentFragment.EDIT.value)}
                            />
                        );
                    }
                } else {
                    if (
                        // jeszcze nie upłynął termin
                        !event.pending_deadline_expired ||
                        // rozpoczął transakcję zanim upłynął termin
                        event.user_register_status === REGISTER_STATUS["TRANSACTION_PENDING"] ||
                        // ręczny override
                        event.pending_deadline_override
                    ) {
                        ret.push(
                            event.payment_method === "p24" ? (
                                <UserPanelTournamentButton
                                    name={
                                        (event.user_register_status === REGISTER_STATUS["TRANSACTION_PENDING"]) ? (
                                            getString("userPanel_userPanelTournament_dokonczPlatnosc")
                                        ) : (
                                            getString("userPanel_userPanelTournament_oplacUdzial")
                                        )
                                    }
                                    color="#4E6BAA"
                                    onClick={() => handlePay(event)}
                                />
                            ) : (
                                <UserPanelTournamentButton
                                    name={getString("userPanel_userPanelTournament_zatwierdz")}
                                    color="#4E6BAA"
                                    onClick={() => handleRegisterUpgrade(event, setEvent)}
                                />
                            )
                        );
                    }
                }
            }

            if (!event.pending_deadline_expired) {
                ret.push(
                    <UserPanelTournamentButton
                        name={getString("userPanel_userPanelTournament_zrezygnuj")}
                        color="#E18872"
                        onClick={() => toggleActiveTournamentFragment(ActiveTournamentFragment.RESIGN.value)}
                    />
                );
            }

            return ret;
        })(),
        ...(() => {
            const ret = [];

            if (
                Array.isArray(user?.roles)
                && (
                    event.user_role === "delegate"
                    || user.roles.includes("admin")
                )
            ) {
                ret.push(
                    <UserPanelTournamentButton
                        name="CUBECOMPS"
                        color="#4E6BAA"
                        onClick={downloadCubeComps}
                    />
                );
                ret.push(
                    <UserPanelTournamentButton
                    name={getString("userPanel_userPanelTournament_listaKontaktowa")}
                        color="#4E6BAA"
                        onClick={downloadContactList}
                    />
                );
            }

            if (Array.isArray(user?.roles)) {
                if (user.roles.includes("admin")) {
                    // tylko admin ma ABCD
                    ret.push(
                        <UserPanelTournamentButton
                        name={getString("userPanel_userPanelTournament_ABCD")}
                            color="#4E6BAA"
                            onClick={downloadABCD}
                        />,
                    );
                    if (event.register_status === "closed") {
                        ret.push(
                            <UserPanelTournamentButton
                            name={getString("userPanel_userPanelTournament_wczytajScenariusze")}
                                color="#4E6BAA"
                                onClick={loadScenarios}
                            />
                        );
                    }
                }

                if (["admin", "designer"].some((role) => (user.roles.includes(role)))) {
                    ret.push(
                        <UserPanelTournamentButton
                        name={getString("userPanel_userPanelTournament_pobierzIdentyfikatory")}
                            color="#4E6BAA"
                            onClick={downloadIDCards}
                        />,
                        <UserPanelTournamentButton
                            name={getString("userPanel_userPanelTournament_pobierzPodziekowania")}
                            color="#4E6BAA"
                            onClick={downloadThanksCards}
                        />,
                        <UserPanelTournamentButton
                            name={getString("userPanel_userPanelTournament_pobierzDyplomy")}
                            color="#4E6BAA"
                            onClick={downloadDiplomaCards}
                        />,
                        <UserPanelTournamentButton
                            name={getString("userPanel_userPanelTournament_pobierzMedale")}
                            color="#4E6BAA"
                            onClick={downloadMedalsCards}
                        />,
                        <UserPanelTournamentButton
                            name={getString("userPanel_userPanelTournament_pobierzPuchary")}
                            color="#4E6BAA"
                            onClick={downloadCupsCards}
                        />,
                    );
                }
            }

            return ret;
        })()
    ]),
    status_text_color = (
        (event?.reserve) ? (
            "#E18872"
        ) : (
            {
                [REGISTER_STATUS["UNCONFIRMED"]]: "#E18872",
                [REGISTER_STATUS["CONFIRMED"]]: "#6AB95D",
                [REGISTER_STATUS["REJECTED"]]: "#E18872"
            }[event?.user_register_status ?? REGISTER_STATUS["UNCONFIRMED"]]
        )
    );

    const thisRef = useRef();

    return (
        <StyledUserPanelTournament>
            <StyledContentWrapper ref={thisRef}>
                <Link to={`/competition/${event.code}`}>
                    <StyledText
                        hasdeclaredfontsize="32px"
                        hasdeclaredfontweight="700"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredtexttransform="uppercase"
                        hascursor="pointer"
                        as="h2"
                    >
                        {event?.name}
                    </StyledText>
                </Link>
                <StyledContentData>
                    {event?.has_logo ? (
                        <Link to={`/competition/${event.code}`}>
                            <img src={`/api/events/get-logo/${event.id}/event`} alt="logo" />
                        </Link>
                    ) : (
                        <></>
                    )}
                    <div>
                        <StyledDataWrapper>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                            >
                                {getString("userPanel_userPanelTournament_data")}
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#000"
                                hasdeclaredpadding="0 0 0 8px"
                            >
                                {time_start === time_end ? time_start : `${time_start} - ${time_end}`}
                            </StyledText>
                        </StyledDataWrapper>
                        <StyledDataWrapper>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                            >
                                {getString("userPanel_userPanelTournament_statusRejestracji")}
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor={
                                    event?.reserve ? "#E18872" : event?.user_register_status ? status_text_color : "#E18872"
                                }
                                hasdeclaredpadding="0 0 0 8px"
                            >
                                {
                                    event?.reserve ? (
                                        getString("userPanel_userPanelTournament_listaRezerwowa")
                                    ) : (
                                        event?.user_register_status ? (
                                            REGISTER_STATUS_TEST[event.user_register_status]
                                        ) : (
                                            getString("userPanel_userPanelTournament_oczekujacy")
                                        )
                                    )
                                }
                            </StyledText>
                        </StyledDataWrapper>
                        <StyledDataWrapper>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredpadding="0 8px 0 0"
                            >
                                {getString("userPanel_userPanelTournament_mojeKonkurencje")}
                            </StyledText>
                            <TournamentsCompetitions competitions={userCompetitions} size="18px" />
                        </StyledDataWrapper>
                    </div>
                </StyledContentData>
            </StyledContentWrapper>
            {
                buttons.length > 1 ? (
                    <StyledButtonsWrapper>
                        {buttons}
                    </StyledButtonsWrapper>
                ) : (
                    <StyledButtonWrapper>
                        {buttons}
                    </StyledButtonWrapper>
                )
            }
            {(() => {
                switch (activeTournamentFragment) {
                    case ActiveTournamentFragment.RESIGN.value:
                        return (
                            <StyledResignationWrapper>
                                <StyledText
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                >
                                    {
                                        (event.reserve || (!event.user_register_status || event.user_register_status === REGISTER_STATUS["UNCONFIRMED"]) || event.payment_method !== "p24") ? (
                                            getString("userPanel_userPanelTournament_czyNaPewnoChcesz")
                                        ) : (
                                            !event.payment_return_deadline_expired ? (
                                                getString("userPanel_userPanelTournament_czyNaPewnoChceszZrezygnowacPotwierdzajac")
                                            ) : (
                                                getString("userPanel_userPanelTournament_czyNaPewnoChceszZrezygnowacPotwierdzajacLeczOplata")
                                            )
                                        )
                                    }
                                </StyledText>
                                <div>
                                    <StyledButton
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredbgcolor="#4E6BAA"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredcursor="pointer"
                                        onClick={() =>
                                            setActiveTournamentFragment(
                                                ActiveTournamentFragment.NONE.value
                                            )
                                        }
                                    >
                                        {getString("userPanel_userPanelTournament_nie")}
                                    </StyledButton>
                                    <StyledButton
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredbgcolor="#E18872"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredcursor="pointer"
                                        onClick={() => handleRetire()}
                                    >
                                        {getString("userPanel_userPanelTournament_tak")}
                                    </StyledButton>
                                </div>
                            </StyledResignationWrapper>
                        );
                    case ActiveTournamentFragment.EDIT.value:
                        return (
                            <Formik
                                initialValues={edit_initial_values}
                                onSubmit={handleSubmit}
                            >
                                {({ errors, handleSubmit, isSubmitting, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <StyledEditWrapper>
                                            <RegisterCompetitions
                                                bordersize="6px"
                                                competitions={event?.competitions}
                                                initialValues={edit_initial_values}
                                            />
                                            <StyledButtonsWrapper>
                                                <StyledButton
                                                    hasdeclaredfontweight="bold"
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredbgcolor="#4E6BAA"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredborderradius="25px"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredcursor="pointer"
                                                    onClick={() =>
                                                        setActiveTournamentFragment(
                                                            ActiveTournamentFragment.NONE
                                                                .value
                                                        )
                                                    }
                                                >
                                                    {getString("userPanel_userPanelTournament_anuluj")}
                                                </StyledButton>
                                                <StyledButton
                                                    hasdeclaredfontweight="bold"
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredbgcolor="#E18872"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredborderradius="25px"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredcursor="pointer"
                                                >
                                                    {getString("userPanel_userPanelTournament_zapisz")}
                                                </StyledButton>
                                            </StyledButtonsWrapper>
                                        </StyledEditWrapper>
                                    </form>
                                )}
                            </Formik>
                        );
                    case ActiveTournamentFragment.SCRIPT.value:
                        return eventScript ? (
                            <UserPanelTournamentScript
                                user={user}
                                eventScript={eventScript}
                                close={
                                    () => {
                                        setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
                                        thisRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                                    }
                                }
                            />
                            // TODO: Pokazać coś, jeśli nie ma tych danych? Albo brak WCA ID?
                        ) : (<></>);
                    case ActiveTournamentFragment.RESULTS.value:
                        return (
                            <UserPanelTournamentResults
                                eventId={event.id}
                                close={
                                    () => {
                                        setActiveTournamentFragment(ActiveTournamentFragment.NONE.value);
                                        thisRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                                    }
                                }
                            />
                        );
                    case ActiveTournamentFragment.NONE.value:
                    default:
                        return <></>;
                }
            })()}
        </StyledUserPanelTournament>
    );
};

export default UserPanelTournament;
