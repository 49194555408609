import styled from "styled-components"

export const StyledRadioCompetitionWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    min-height: 60px;
    border: ${({ haserror }) => (haserror ? "3px solid red" : "3px solid #3474e8")};
    border-radius: 25px;
    padding: 8px 12px;
    margin-top: 12px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    max-width: 667px;
    width: 100%;
`;