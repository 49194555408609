import styled, { css } from "styled-components";

export const StyledAllTrainersPage = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media only screen and (max-width: 926px) {
        padding: 0 20px;
        h2 {
            font-size: 32px;
            padding: 40px 0 10px;
        }
    }
    @media only screen and (max-width: 350px) {
        h2 {
            font-size: 28px;
        }
    }
`;

export const StyledScrollWrapper = styled.div`
    width: 100%;
    margin-top: 8px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: calc(100% - 60px);
    padding-right: 15px;

    scrollbar-color: ${({ isscrollbar }) =>
        isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }

    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
        overflow-y: visible;
        height: auto;
        padding-right: 0;
    }
`;

const gridCss = css`
    grid-template-columns: 250px calc(100% - 250px);
    align-content: center;
    justify-content: center;
    grid-template-areas:
        "image b"
        "image c";

    @media only screen and (max-width: 1476px) {
        grid-template-columns: 150px calc(100% - 150px);
    }

    @media only screen and (max-width: 993px) {
        grid-template-columns: 100px calc(100% - 100px);
    }

    @media only screen and (max-width: 926px) {
        grid-template-columns: 250px calc(100% - 250px);
    }

    @media only screen and (max-width: 707px) {
        grid-template-columns: 150px calc(100% - 150px);
    }
`;

const noGridCss = css`
    grid-template-columns: 100%;
    align-content: center;
    justify-content: center;
    grid-template-areas:
        "b"
        "c";
`;

const flexElement = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    > h3 {
        display: block;
        width: calc(100% - 300px);

        @media only screen and (max-width: 1476px) {
            width: calc(100% - 150px);
        }

        @media only screen and (max-width: 993px) {
            width: calc(100% - 100px);
        }

        @media only screen and (max-width: 926px) {
            width: calc(100% - 250px);
        }

        @media only screen and (max-width: 707px) {
            width: calc(100% - 150px);
        }

        @media only screen and (max-width: 572px) {
            width: 100%;
        }
    }
`;

export const StyledElement = styled.div`
    width: 100%;
    border-radius: 40px;
    background-color: #fff;
    border: 10px solid #6786be;
    padding: 16px;
    box-sizing: border-box;
    display: grid;
    ${({ $hasAvatar, $hasData }) => ($hasAvatar ? (!$hasData ? flexElement : gridCss) : noGridCss)};

    > h3 {
        grid-area: b;
    }

    @media only screen and (max-width: 1322px) {
        h3 {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 926px) {
        h3 {
            font-size: 24px;
        }
    }

    @media only screen and (max-width: 634px) {
        h3 {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 572px) {
        flex-direction: column;
        grid-template-columns: 100%;

        grid-template-areas:
            "b"
            "image"
            "c";
    }

    @media only screen and (max-width: 436px) {
        h3 {
            font-size: 18px;
        }
    }
`;

export const StyledImageWrapper = styled.div`
    height: 100%;
    width: 250px;
    height: 250px;
    grid-area: image;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid black;
        border-radius: 15px;
        overflow: hidden;
    }

    @media only screen and (max-width: 1476px) {
        width: 150px;
        height: 150px;
    }

    @media only screen and (max-width: 993px) {
        width: 100px;
        height: 150px;
    }

    @media only screen and (max-width: 926px) {
        width: 250px;
        height: 250px;
    }

    @media only screen and (max-width: 707px) {
        width: 150px;
        height: 150px;
    }

    @media only screen and (max-width: 572px) {
        width: 100%;    
        height: 350px;
        margin: 20px 0;

        img {
            border: none;
            object-fit: contain;
        }
    }
`;

export const StyledTextWrapper = styled.div`
    grid-area: c;
    padding-left: 20px;

    @media only screen and (max-width: 572px) {
        width: 100%;
        padding-left: 0;
    }
`;

export const StyledTextElement = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: ${({ $hasAvatar, $isFirst }) => (!$hasAvatar && $isFirst ? "20px" : "0px")};

    @media only screen and (max-width: 572px) {
        justify-content: center;
        margin-bottom: 5px;
    }

    p {
        display: flex;
        align-items: center;
        gap: 10px;

        a {
            text-decoration: none;
            color: #000;
        }

        span {
            svg {
                height: 20px;
                width: 32px;
                margin-top: 5px;
            }
        }

        @media only screen and (max-width: 1322px) {
            font-size: 16px;
        }

        @media only screen and (max-width: 1160px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 926px) {
            font-size: 18px;
        }

        @media only screen and (max-width: 634px) {
            font-size: 16px;
        }

        @media only screen and (max-width: 572px) {
            text-align: center;
        }

        @media only screen and (max-width: 436px) {
            font-size: 14px;
        }
    }
`;
