import styled from "styled-components";
import bgShape from "../../../images/tournaments/blueShape.svg";

export const StyledRightShape = styled.div`
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: url(${bgShape}) no-repeat right;
    background-size: cover;
    @media only screen and (max-width: 1174px) {
        background-size: cover;
    }
    @media only screen and (max-width: 767px) {
        background: transparent;
    }
`;
