import styled from "styled-components"

export const StyledUserPanelParentHeading = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 30px;
    
    > p {
        &:last-child{
            padding-left: 20px;
        }
        
        > a{
            color: #EE745C;
            text-decoration: none;
        }
    }

    @media only screen and (max-width: 1502px){
        flex-direction: column;
    }
`