import { useCallback, useEffect, useRef, useState } from "react";

const useScrollBar = (useTrigger = false) => {
	const scrollElement = useRef(null);
	const [isScrollBar, setIsScrollbar] = useState(false);

	const [triggerVal, setTriggerVal] = useState(false);
	const trigger = useCallback(
		() => setTriggerVal((val) => (!val)),
		[setTriggerVal]
	);

	useEffect(() => {
		const { current: element } = scrollElement;
		if (!element)
			return;

		const { clientHeight, scrollHeight } = element;
		setIsScrollbar(clientHeight < scrollHeight);
    }, useTrigger ? [triggerVal] : undefined);

	return {
		scrollElement,
		isScrollBar,
		trigger,
	};
};

export default useScrollBar;
