import styled from "styled-components";
import { Field } from "formik";

export const StyledRegisterInput = styled(Field)`
    max-width: 300px;
    width: 300px;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
    border: 3px solid ${({ error }) => (error ? "#F74017" : "#3474E8")};
    padding: 0 12px;
    font-family: "Poppins";
    outline: none;
    margin-bottom: 8px;
    transition: outline 250ms;
    background: #fff;

    &::-webkit-datetime-edit {
        width: 100%;
    }

    &::-webkit-datetime-edit-fields-wrapper {
        padding-left: 24px;
    }

    &::-webkit-datetime-edit-text {
        color: #000;
    }

    &::-webkit-datetime-edit-month-field {
        text-transform: uppercase;
        color: #000;
    }

    &::-webkit-datetime-edit-day-field {
        text-transform: uppercase;
        color: #000;
    }

    &::-webkit-datetime-edit-year-field {
        text-transform: uppercase;
    }
    
    &::-webkit-calendar-picker-indicator {
        font-size: 16px;
        cursor: pointer;
        background-color: #fff;
        color: #000;
        margin-right: 12px;
        border-radius: 4px;
        padding: 4px;
    }

    @media only screen and (max-width: 370px) {
        width: 90%;
    }
`;
