import styled from "styled-components";

import loginBackgrund from "../../../images/LoginPage/MobileVector.png";

export const StyledLoginPageShapeMobile = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(${loginBackgrund}) no-repeat;
    -ms-background-size: 100% 100%;
    background-size: 100% 100%;

    @media only screen and (max-height: 720px) and (max-width: 340px) {
        -ms-background-size: cover;
        background-size: cover;
    }
`;
