import styled from "styled-components";

export const StyledUserPanelTournamentWrapper = styled(({ loading, ...props }) => <div {...props} />)`
    width: 100%;
    height:  ${({ loading }) => loading ? "100%" : "auto"};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media only screen and (max-width: 926px) {
        margin-top: 70px;
    }
`;
