import styled from "styled-components"

export const StyledAllPayTrainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border: 4px solid ${({ bordercolor }) => bordercolor ? bordercolor : "#6786be"};
    border-radius: 25px;
    justify-content: space-evenly;
    background-color: #fff;
    padding: 3px 0;
    gap: 12px;
    margin-top: 5px;
    min-height: 40px;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(20% - 12px);
        min-height: 40px;
    }

    @media only screen and (max-width: 531px){
        > div {

            > p {
                font-size: 16px;
            }
        }
    }
`