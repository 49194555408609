import styled from "styled-components"

export const StyledSeriesResultsMobileNameWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 25px;

    @media only screen and (max-width: 462px){
        > p {
            font-size: 18px;
        }
    }
`