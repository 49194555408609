import styled from "styled-components";

export const StyledTournamentStartContentLink = styled.a`
    text-decoration: none;
    width: 60%;
    cursor: pointer;
    > button {
        background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(30%, rgba(103, 134, 190, 1)),
            to(rgba(238, 116, 92, 1))
        );
        background-image: -o-linear-gradient(
            left,
            rgba(103, 134, 190, 1) 30%,
            rgba(238, 116, 92, 1) 100%
        );
        background-image: linear-gradient(
            90deg,
            rgba(103, 134, 190, 1) 30%,
            rgba(238, 116, 92, 1) 100%
        );
        height: 80px;
        width: 100%;
        border-radius: 45px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        > svg {
            width: 55px;
            height: 55px;
            margin-right: 12px;
        }
    }
    @media only screen and (max-width: 1235px) {
        width: 90%;
        > button {
            font-size: 34px;
        }
    }
    @media only screen and (max-width: 767px){
        display: none;
    }
`;
