import styled from "styled-components"

export const StyledPayAllElementContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    @media only screen and (max-width: 926px){
        gap: 0;
    }
`