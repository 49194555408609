import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field, Formik, ErrorMessage } from "formik";
import { sessionService } from "redux-react-session";
import { usePrompt } from "../../../hooks/react-router-dom-beforeunload.js";
import { getString } from "../../../strings";
import InputWrapper from "../../molecules/Register/InputWrapper";
import RegisterCompetitions from "../../molecules/Register/RegisterCompetitions";
import SelectSize from "../../molecules/Register/SelectSize";
import SelectCountry from "../../molecules/Register/SelectCountry.js";

import { StyledRegisterForm } from "../../atoms/Register/StyledRegisterForm";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledAvatarInformation } from "../../atoms/Register/StyledAvatarInformation";
import { StyledInputsEditPanelWrapper } from "../../atoms/Register/StyledInputsEditPanelWrapper";
import { StyledSendFileLabel } from "../../atoms/Register/StyledSendFileLabel";
import { StyledUserEditPanel } from "../../atoms/UserPanel/StyledUserEditPanel";
import { StyledSendFileInput } from "../../molecules/Register/StyledSendFileInput";
import { StyledInputWrapperPanel } from "../../molecules/UserPanel/StyledInputWrapperPanel";
import { StyledResetPassword } from "../../atoms/UserPanel/StyledResetPassword";
import { StyledFormEditPanel } from "../../atoms/UserPanel/StyledFormEditPanel";
import { StyledEditPanelError } from "../../atoms/UserPanel/StyledEditPanelError";
import { StyledUserEditPasswordForm } from "../../atoms/UserPanel/StyledUserEditPasswordForm";
import { StyledButtonsWrapperPanel } from "../../atoms/UserPanel/StyledButtonsWrapperPanel";
import { StyledSuccessEditPassword } from "../../atoms/UserPanel/StyledSuccessEditPassword";
import { StyledWCAIDInformation } from "../../molecules/Register/StyledWCAIDInformation";

import { ReactComponent as AvatarIcon } from "../../../images/register/avatarIcon.svg";

import FormikOnChange from "../../FormikOnChange.js";

import { validationSchema } from "../../constants/userProfileEditValidationSchema";
import { resetPasswordValidationSchema } from "../../constants/resetPasswordValidationSchema";

import allCompetitions from "../../constants/competitions.json";

import { getDateString } from "../../../logic/isotime.js";
import { changePassword, checkWCA, update } from "../../../logic/requests/users.js";
import { readFileAsync, arrayBufferToBase64 } from "../../../logic/file.js";

const UserEditPanel = ({ setIsPanelLocked }) => {
    const [initialValues, setInitialValues] = useState({
        firstname: "",
        birthdate: "",
        country: "",
        phone: "",
        lastname: "",
        gender: null,
        city: "",
        wca_id: "",
        avatar: null,
        shirtsize: "M",
        delegate_email: null
    });
    const [initialCompetitionValues, setInitialCompetitionValues] = useState([]);
    const refreshInitialValues = useCallback(
        (data) => {
            const initialValues = {};
            const initialCompetitions = [];
            for (let key of ["firstname", "country", "phone", "lastname", "city", "wca_id", "delegate_email"])
                initialValues[key] = data?.[key];
            for (let competition of allCompetitions) {
                if (data?.competitions?.includes(competition))
                    initialCompetitions.push(competition);
            }
            initialValues.gender = data?.gender?.id;
            initialValues.shirtsize = data?.shirtsize?.id;
            initialValues.birthdate = getDateString(new Date(data?.birthdate));
            setInitialValues(initialValues);
            setInitialCompetitionValues(initialCompetitions);
        },
        [setInitialValues, setInitialCompetitionValues]
    );

    const [user, setUser] = useState();
    useEffect(
        () =>
            (async () => {
                if (user) return;
                let session_user;
                try {
                    session_user = await sessionService.loadUser();
                } catch (err) {}
                setUser(session_user ?? false);
                refreshInitialValues(session_user);
                setCompetitionValues(initialCompetitionValues);
                setRegisterInitialValues(
                    Object.fromEntries(
                        new Map(
                            session_user?.competitions?.map((competition) => [competition, true])
                        )
                    )
                );
            })(),
        [user, setUser, refreshInitialValues, initialCompetitionValues]
    );

    const [isAvatarSrc, setIsAvatarSrc] = useState(false);
    const [isFileReady, setIsFileReady] = useState(true);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isSetPassword, setIsSetPassword] = useState(false);
    const [isClicked, setIsClicked] = useState(0);
    const [isChange, setIsChange] = useState(true);
    const [avatar, setAvatar] = useState();
    const [avatarImg, setAvatarImg] = useState();
    const [imageLastUpdated, setImageLastUpdated] = useState(Date.now());
    const [isAvatarInformation, setIsAvatarInformation] = useState(false);
    const inputElement = useRef(null);
    const [editResponse, setEditResponse] = useState();
    const [editResponseColor, setEditResponseColor] = useState();
    const [editResponseTimeout, setEditResponseTimeout] = useState();
    const [isChanged, setIsChanged] = useState(false);
    const [passwordResetErr, setPasswordResetErr] = useState();
    const [wcaInfoBox, setWcaInfoBox] = useState(false);
    const [submitData, setSubmitData] = useState();

    const showResponse = useCallback(
        (msg, timeout, color) => {
            if (editResponseTimeout) clearTimeout(editResponseTimeout);

            setEditResponse(msg);
            setEditResponseColor(color);

            setEditResponseTimeout(
                setTimeout(() => {
                    setEditResponse(null);
                    setEditResponseColor(null);
                }, timeout)
            );
        },
        [editResponseTimeout, setEditResponseTimeout, setEditResponse]
    );
    const [competitionValues, setCompetitionValues] = useState([]);
    const [registerInitialValues, setRegisterInitialValues] = useState(
        Object.fromEntries(new Map(user?.competitions?.map((competition) => [competition, true])))
    );

    const compsChanged = useCallback(
        () =>
            !(
                competitionValues.length === initialCompetitionValues.length &&
                competitionValues.every((element) => initialCompetitionValues.includes(element))
            ),
        [competitionValues, initialCompetitionValues]
    );

    const extractNewData = useCallback(
        (data) => {
            delete data.formikRefreshYouDick;
            const new_data = {};
            for (let comp of allCompetitions) delete data[comp];
            for (let key of Object.keys(data)) {
                if (data[key] !== initialValues[key]) {
                    new_data[key] = data[key];
                }
            }
            if (compsChanged()) new_data.competitions = competitionValues;

            if (isAvatarSrc) new_data.avatar = avatar;

            return new_data;
        },
        [initialValues, compsChanged, competitionValues, isAvatarSrc, avatar]
    );

    const handleSubmit = async (data, props) => {
        setWcaInfoBox(false);

        if (data.wca_id) {
            let res;
            try {
                res = await checkWCA(data.wca_id);
                console.log("checkWCA", res);

                if (!res?.person?.name?.startsWith?.(`${data.firstname} ${data.lastname}`)) {
                    // nie zgadza się; really_handleSubmit będzie wywołany z closeWcaPopUp
                    setSubmitData({ data, props });
                    setWcaInfoBox(true);
                    return;
                }
            } catch (err) {
                console.error("checkWCA", err);
                // TODO: Co teraz? Pokazać coś?
            }
        }

        really_handleSubmit(data, props);
    };

    const closeWcaPopUp = (accepted) => {
        setWcaInfoBox(false);
        if (accepted) {
            really_handleSubmit(submitData.data, submitData.props);
        } else {
            submitData.props.setSubmitting(false);
        }
    };

    const really_handleSubmit = (data, { setSubmitting, resetForm }) => {
        console.log(
            "really_handleSubmit",
            data,
            "initialValues",
            initialValues,
            "registerInitialValues",
            registerInitialValues
        );
        if (!isFileReady) {
            // bug: po pierwszym przesłaniu formularza Formik w zwracanych wartościach nie podaje konkurencji
            refreshInitialValues({
                ...user,
                formikRefreshYouDick: Date.now(),
            });

            showResponse(getString("userPanel_userEditPanel_avatarNieZostalJeszcze"), 3000, "#6D85BA");
            // tutaj resetForm zresetuje zmienione wartości, oby Formik znowu się nie sypał przy drugim submit'cie

            setSubmitting(false);
            return;
        }

        const new_data = extractNewData(data);

        if (!Object.keys(new_data).length) {
            // naprawa Formik'a, znów
            refreshInitialValues({
                ...user,
                formikRefreshYouDick: Date.now(),
            });
            resetForm();

            showResponse(getString("userPanel_userEditPanel_brakZmian"), 3000, "#000");

            setSubmitting(false);
            return;
        }

        for (const prop of [ "firstname", "lastname", "city", "wca_id" ]) {
            if (new_data[prop])
                new_data[prop] = new_data[prop].trim();
        }

        if (new_data["wca_id"])
            new_data["wca_id"] = new_data["wca_id"].toUpperCase();

        // mamy zmianę w danych użytkownika i/lub konkurencjach
        console.log("new_data", new_data);
        update(new_data)
            .then(async (res) => {
                console.log("update", "success", res);
                showResponse(getString("userPanel_userEditPanel_zapisano"), 3000, "#6786BE");
                setImageLastUpdated(Date.now());
                setIsAvatarSrc(false);

                // aktualizacja lokalnych danych na kliencie
                const new_user = {
                    ...user,
                    ...new_data,
                    avatar: undefined,
                    has_avatar: new_data.avatar ? true : user.has_avatar,
                    gender: {
                        ...user.gender,
                        id: new_data?.gender ?? user.gender.id,
                        // TODO: Ustawić tu 'name' - wczytać z bazy?
                    },
                    shirtsize: {
                        ...user?.shirtsize,
                        id: new_data?.shirtsize ?? user?.shirtsize?.id,
                    },
                };
                console.log("new_user", new_user);
                await sessionService.saveUser(new_user);
                setUser(new_user);
                refreshInitialValues(new_user);
                resetForm();
                setAvatar(null);
                setSubmitting(false);
                setIsChanged(false);
                setIsPanelLocked(false);
            })
            .catch((err) => {
                // TODO: pokazać coś?
                console.error("update", err);
                showResponse(getString("userPanel_userEditPanel_bladZapisu"), 3000, "#DF7B63");
                setSubmitting(false);
            });
    };

    const handleFile = (event) => {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            console.log("handleFile", "got file", file);

            if (!file.type.startsWith("image")) {
                console.log("handleFile", "got non-image file, refusing to continue");
                return;
            }

            setIsFileReady(false);
            (async () => {
                try {
                    const ret = arrayBufferToBase64(await readFileAsync(file));
                    setAvatar(ret);
                    setAvatarImg(`data:${file.type};base64,${ret}`);
                    setIsAvatarSrc(true);
                    setIsChanged(true);
                } catch (err) {
                    console.log("readFileAsync", err);
                }
                setIsFileReady(true);
            })();
        }
    };

    const sendFileHandler = (e) => {
        if (isClicked === 0) setIsAvatarInformation(true);
        setIsClicked(1);
        if (!isAccepted) e.preventDefault();
        setIsAccepted(true);
    };
    const acceptInformationHandler = () => {
        inputElement.current.click();
        setIsAvatarInformation(false);
    };

    const handlePasswordChange = (data, { setSubmitting }) => {
        setPasswordResetErr(null);
        const { oldPassword, newPassword } = data;
        changePassword(oldPassword, newPassword)
            .then((res) => {
                console.log("changePassword", "succ", res);
                setIsChange(false);
            })
            .catch((err) => {
                console.error("changePassword", err);
                setPasswordResetErr(
                    (() => {
                        switch (err?.data?.code) {
                            case "bad_pass":
                                return getString("userPanel_userEditPanel_wprowadzoneHasloJestNie");
                            default:
                                return true;
                        }
                    })()
                );
            });
        setSubmitting(false);
    };

    const refreshIsChanged = useCallback(
        ({ prevValues, nextValues, formik }) => {
            const new_data = extractNewData(nextValues);
            delete new_data?.competitions;
            const is_changed = new_data && Object.keys(new_data).length ? true : false;
            if (is_changed) {
                setIsChanged(true);
                setIsPanelLocked(true);
            }
        },
        [extractNewData, setIsChanged, setIsPanelLocked]
    );

    usePrompt(getString("userPanel_userEditPanel_daneZostanaUtracone"), isChanged);

    const setCompetition = useCallback(
        (competition, val, { dirty } = { dirty: true }) => {
            if (val) {
                if (!competitionValues.includes(competition)) competitionValues.push(competition);
            } else {
                if (competitionValues.includes(competition)) competitionValues.pop(competition);
            }
            setCompetitionValues(competitionValues);
            if (dirty) {
                setIsChanged(true);
                setIsPanelLocked(true);
            }
        },
        [competitionValues, setCompetitionValues, setIsChanged, setIsPanelLocked]
    );

    return (
        <StyledUserEditPanel>
            {!isSetPassword ? (
                <StyledFormEditPanel>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                    >
                        {({ errors, handleSubmit, isSubmitting, values, setFieldValue }) => (
                            <StyledRegisterForm onSubmit={handleSubmit} isuserpanel={true}>
                                <FormikOnChange onChange={refreshIsChanged} />
                                <StyledSendFileInput>
                                    <StyledSendFileLabel
                                        htmlFor="avatar"
                                        issend={isAvatarSrc ? true : false}
                                    >
                                        {avatarImg || user?.has_avatar ? (
                                            <img
                                                src={
                                                    avatarImg ??
                                                    `/api/users/get-avatar/${user?.id}?react_refresh_you_dick=${imageLastUpdated}`
                                                }
                                                alt="avatar"
                                            />
                                        ) : (
                                            <AvatarIcon />
                                        )}
                                    </StyledSendFileLabel>
                                    <Field
                                        type="file"
                                        name="avatar"
                                        id="avatar"
                                        innerRef={inputElement}
                                        onClick={(e) => sendFileHandler(e)}
                                        onChange={handleFile}
                                    />
                                    <StyledText hasdeclaredfontweight="600">
                                        {getString("userPanel_userEditPanel_ustawAwatar")}
                                    </StyledText>
                                </StyledSendFileInput>
                                {isAvatarInformation ? (
                                    <StyledAvatarInformation
                                        key={isAvatarInformation}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <StyledText hasdeclaredfontsize="22px">
                                            {getString("userPanel_userEditPanel_zanimPrzesleszZdjecie")}
                                        </StyledText>
                                        <StyledText>{getString("userPanel_userEditPanel_list1")}</StyledText>
                                        <StyledText>
                                            {getString("userPanel_userEditPanel_list2")}
                                        </StyledText>
                                        <StyledText>
                                            {getString("userPanel_userEditPanel_list3")}
                                        </StyledText>
                                        <StyledText>
                                            {getString("userPanel_userEditPanel_list4")}
                                        </StyledText>
                                        <StyledText>
                                            {getString("userPanel_userEditPanel_list5")}
                                        </StyledText>
                                        <StyledButton
                                            hasdeclaredbgcolor="#3474e8"
                                            hasdeclaredpadding="14px 20px"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredborderradius="22px"
                                            hasdeclaredmargin="24px 0 0 0"
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredcursor="pointer"
                                            onClick={acceptInformationHandler}
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            {getString("userPanel_userEditPanel_akceptuje")}
                                        </StyledButton>
                                    </StyledAvatarInformation>
                                ) : null}
                                <StyledInputsEditPanelWrapper>
                                    <InputWrapper
                                        inputType="text"
                                        title={getString("userPanel_userEditPanel_firstnameTitle")}
                                        name="firstname"
                                        error={errors.firstname}
                                    />
                                    <InputWrapper
                                        inputType="text"
                                        title={getString("userPanel_userEditPanel_lastnameTitle")}
                                        name="lastname"
                                        error={errors.lastname}
                                    />
                                    <InputWrapper
                                        inputType="date"
                                        title={getString("userPanel_userEditPanel_dateTitle")}
                                        name="birthdate"
                                        error={errors.birthdate}
                                    />
                                    <SelectCountry
                                        inputType="select"
                                        title={getString("userPanel_userEditPanel_countryTitle")}
                                        name="country"
                                        error={errors.country}
                                    />
                                    <InputWrapper
                                        inputType="text"
                                        title={getString("userPanel_userEditPanel_phoneTitle")}
                                        name="phone"
                                        error={errors.phone}
                                    />
                                    <InputWrapper
                                        inputType="select"
                                        title={getString("userPanel_userEditPanel_genderTitle")}
                                        name="gender"
                                        error={errors.gender}
                                    />
                                    <InputWrapper
                                        inputType="text"
                                        title={getString("userPanel_userEditPanel_miejscowoscTitle")}
                                        name="city"
                                        error={errors.city}
                                    />
                                    <InputWrapper
                                        inputType="text"
                                        title={getString("userPanel_userEditPanel_wcaIDTitle")}
                                        name="wca_id"
                                        error={errors.wca_id}
                                        annotation={getString("userPanel_userEditPanel_wcaIDAnnotation")}
                                        hasDeclaredBottomPosition="-28px"
                                    />
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        {
                                            user?.roles?.includes?.("delegate") ? (
                                                <InputWrapper
                                                    inputType="text"
                                                    title="E-mail delegacki"
                                                    name="delegate_email"
                                                    error={errors.delegate_email}
                                                    hasDeclaredBottomPosition="-28px"
                                                />
                                            ) : (null)
                                        }
                                    </div>
                                    {wcaInfoBox && (
                                        <StyledWCAIDInformation isusereditpanel={true}>
                                            <div>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                    hasdeclaredfontcolor="#F74017"
                                                >
                                                    {getString("userPanel_userEditPanel_ostrzezeniePodane")}
                                                </StyledText>
                                                <div>
                                                    <StyledButton
                                                        hasdeclaredbgcolor="#3474e8"
                                                        hasdeclaredpadding="12px 18px"
                                                        hasdeclaredfontcolor="#fff"
                                                        hasdeclaredborderradius="25px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredmargin="22px 0 0 0"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredcursor="pointer"
                                                        whileTap={{ scale: 0.9 }}
                                                        whileHover={{ scale: 1.05 }}
                                                        onClick={() => closeWcaPopUp(true)}
                                                    >
                                                        {getString("userPanel_userEditPanel_akceptuje2")}
                                                    </StyledButton>
                                                    <StyledButton
                                                        hasdeclaredbgcolor="rgb(223, 123, 99)"
                                                        hasdeclaredpadding="12px 18px"
                                                        hasdeclaredfontcolor="#fff"
                                                        hasdeclaredborderradius="25px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredmargin="4px 0 0 0"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredcursor="pointer"
                                                        whileTap={{ scale: 0.9 }}
                                                        whileHover={{ scale: 1.05 }}
                                                        onClick={() => closeWcaPopUp(false)}
                                                    >
                                                        {getString("userPanel_userEditPanel_anuluj")}
                                                    </StyledButton>
                                                </div>
                                            </div>
                                        </StyledWCAIDInformation>
                                    )}
                                </StyledInputsEditPanelWrapper>
                                <StyledText
                                    hasdeclaredfontsize="24px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="42px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString("userPanel_userEditPanel_mojeDomyslneKonkurencje")}
                                </StyledText>
                                <RegisterCompetitions
                                    competitions={[
                                        "222",
                                        "333",
                                        "333bf",
                                        "333oh",
                                        "444",
                                        "555",
                                        "666",
                                        "777",
                                        "clock",
                                        "minx",
                                        "pyram",
                                        "skewb",
                                        "sq1",
                                    ]}
                                    initialValues={registerInitialValues}
                                    setFieldValue={setCompetition}
                                />
                                <SelectSize
                                    title={getString("userPanel_userEditPanel_rozmiarKoszulkiTitle")}
                                    name="shirtsize"
                                    error={errors.shirtsize}
                                    selected={initialValues?.shirtsize}
                                    onChange={(event) =>
                                        setFieldValue("shirtsize", event.target.value)
                                    }
                                />
                                <StyledButton
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredbgcolor="#ee745c"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredwidth="220px"
                                    hasdeclaredheight="50px"
                                    hasdeclaredborderradius="35px"
                                    hasdeclaredmargin="26px 0 0 0"
                                    hasdeclaredfontweight="600"
                                    type="submit"
                                    disabled={isSubmitting}
                                    whileTap={{ scale: 0.9 }}
                                    whileHover={{ scale: 1.05 }}
                                >
                                    {getString("userPanel_userEditPanel_zapisz")}
                                </StyledButton>
                            </StyledRegisterForm>
                        )}
                    </Formik>
                    {editResponse ? (
                        <StyledText
                            hasdeclaredfontcolor={editResponseColor}
                            hasdeclaredpadding="22px 20px"
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredtextalign="center"
                        >
                            {editResponse}
                        </StyledText>
                    ) : (
                        <></>
                    )}
                    <StyledButton
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredfontsize="22px"
                        hasdeclaredbgcolor="#6d85ba"
                        hasdeclaredtextalign="center"
                        hasdeclaredcursor="pointer"
                        hasdeclaredwidth="280px"
                        hasdeclaredheight="60px"
                        hasdeclaredborderradius="35px"
                        hasdeclaredmargin="44px 0 0 0"
                        hasdeclaredfontweight="600"
                        whileTap={{ scale: 0.9 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => setIsSetPassword(true)}
                    >
                        {getString("userPanel_userEditPanel_zmienHaslo")}
                    </StyledButton>
                </StyledFormEditPanel>
            ) : (
                <>
                    {isChange ? (
                        <StyledResetPassword>
                            <Formik
                                initialValues={{
                                    newPassword: "",
                                    newPasswordConfirmation: "",
                                    oldPassword: "",
                                }}
                                validationSchema={resetPasswordValidationSchema}
                                onSubmit={handlePasswordChange}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({ errors, handleSubmit, isSubmitting, values }) => (
                                    <StyledUserEditPasswordForm onSubmit={handleSubmit}>
                                        <StyledInputWrapperPanel>
                                            <StyledText
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontsize="26px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredpadding="0 0 12px 0"
                                            >
                                                {getString("userPanel_userEditPanel_noweHaslo")}
                                            </StyledText>
                                            <Field type="password" name="newPassword" />
                                            <ErrorMessage name="newPassword">
                                                {(errorMsg) => (
                                                    <StyledEditPanelError
                                                        key={errorMsg}
                                                        initial={{ opacity: 0, x: "100px" }}
                                                        animate={{
                                                            opacity: 1,
                                                            x: 0,
                                                        }}
                                                        exit={{ opacity: 0 }}
                                                    >
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontcolor="#F74017"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtexttransform="uppercase"
                                                            as="h4"
                                                        >
                                                            {errorMsg}
                                                        </StyledText>
                                                    </StyledEditPanelError>
                                                )}
                                            </ErrorMessage>
                                        </StyledInputWrapperPanel>
                                        <StyledInputWrapperPanel>
                                            <StyledText
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontsize="26px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredpadding="0 0 12px 0"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("userPanel_userEditPanel_powtorzNoweHaslo")}
                                            </StyledText>
                                            <Field type="password" name="newPasswordConfirmation" />
                                            <ErrorMessage name="newPasswordConfirmation">
                                                {(errorMsg) => (
                                                    <StyledEditPanelError
                                                        key={errorMsg}
                                                        initial={{ opacity: 0, x: "100px" }}
                                                        animate={{
                                                            opacity: 1,
                                                            x: 0,
                                                        }}
                                                        exit={{ opacity: 0 }}
                                                    >
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontcolor="#F74017"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtexttransform="uppercase"
                                                            hasdeclaredlineheight="1.4em"
                                                            as="h4"
                                                        >
                                                            {errorMsg}
                                                        </StyledText>
                                                    </StyledEditPanelError>
                                                )}
                                            </ErrorMessage>
                                        </StyledInputWrapperPanel>
                                        <StyledInputWrapperPanel hasdeclaredmargin="22px 0 0 0">
                                            <StyledText
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontsize="26px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredpadding="0 0 12px 0"
                                            >
                                                {getString("userPanel_userEditPanel_stareHaslo")}
                                            </StyledText>
                                            <Field type="password" name="oldPassword" />
                                            <ErrorMessage name="oldPassword">
                                                {(errorMsg) => (
                                                    <StyledEditPanelError
                                                        key={errorMsg}
                                                        initial={{ opacity: 0, x: "100px" }}
                                                        animate={{
                                                            opacity: 1,
                                                            x: 0,
                                                        }}
                                                        exit={{ opacity: 0 }}
                                                    >
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontcolor="#F74017"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtexttransform="uppercase"
                                                            hasdeclaredlineheight="1.4em"
                                                            as="h4"
                                                        >
                                                            {errorMsg}
                                                        </StyledText>
                                                    </StyledEditPanelError>
                                                )}
                                            </ErrorMessage>
                                        </StyledInputWrapperPanel>
                                        {passwordResetErr ? (
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontcolor="#F74017"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtexttransform="uppercase"
                                                hasdeclaredpadding="0 0 24px 0"
                                                hasdeclaredlineheight="1.4em"
                                                as="h4"
                                            >
                                                {typeof passwordResetErr === "string"
                                                    ? passwordResetErr
                                                    : getString("userPanel_userEditPanel_wystapilBlad")}
                                            </StyledText>
                                        ) : (
                                            <></>
                                        )}
                                        <StyledButtonsWrapperPanel>
                                            <StyledButton
                                                hasdeclaredfontcolor="#fff"
                                                hasdeclaredfontsize="18px"
                                                hasdeclaredbgcolor="#6d85ba"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredcursor="pointer"
                                                hasdeclaredwidth="48%"
                                                hasdeclaredheight="50px"
                                                hasdeclaredborderradius="35px"
                                                hasdeclaredfontweight="700"
                                                whileTap={{ scale: 0.9 }}
                                                whileHover={{ scale: 1.05 }}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {getString("userPanel_userEditPanel_zmienHaslo2")}
                                            </StyledButton>
                                            <StyledButton
                                                hasdeclaredfontcolor="#fff"
                                                hasdeclaredfontsize="18px"
                                                hasdeclaredbgcolor="#df7b63"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredcursor="pointer"
                                                hasdeclaredwidth="48%"
                                                hasdeclaredheight="50px"
                                                hasdeclaredborderradius="35px"
                                                hasdeclaredfontweight="700"
                                                whileTap={{ scale: 0.9 }}
                                                whileHover={{ scale: 1.05 }}
                                                onClick={() => setIsSetPassword(false)}
                                            >
                                                {getString("userPanel_userEditPanel_anuluj2")}
                                            </StyledButton>
                                        </StyledButtonsWrapperPanel>
                                    </StyledUserEditPasswordForm>
                                )}
                            </Formik>
                        </StyledResetPassword>
                    ) : (
                        <StyledSuccessEditPassword>
                            <StyledText
                                hasdeclaredfontsize="44px"
                                hasdeclaredfontweight="700"
                                hasdeclaredpadding="0 20px"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredmargin="70px 0 0 0"
                                hasdeclaredtextalign="center"
                                hasdeclaredlineheight="1.4em"
                                as="h3"
                            >
                                {getString("userPanel_userEditPanel_hasloZostaloZmienione")}
                            </StyledText>
                            <StyledButton
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredfontsize="18px"
                                hasdeclaredbgcolor="#6d85ba"
                                hasdeclaredtextalign="center"
                                hasdeclaredcursor="pointer"
                                hasdeclaredwidth="300px"
                                hasdeclaredheight="50px"
                                hasdeclaredborderradius="35px"
                                hasdeclaredmargin="30px 0 0 0"
                                hasdeclaredfontweight="700"
                                whileTap={{ scale: 0.9 }}
                                whileHover={{ scale: 1.05 }}
                                onClick={() => setIsChange(true)}
                            >
                                {getString("userPanel_userEditPanel_gotowe")}
                            </StyledButton>
                        </StyledSuccessEditPassword>
                    )}
                </>
            )}
        </StyledUserEditPanel>
    );
};

export default UserEditPanel;
