import styled from "styled-components"

export const StyledUserPanelSummaryHeading = styled.div`
    margin: 20px 0 0;

    @media only screen and (max-width: 926px){
        margin-top: 40px;

        > h2 {
            padding-left: 20px;
        }
    }
`