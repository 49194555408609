import styled from "styled-components"

export const StyledMobileContactCube = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    
    > svg{
        width: 220px;
        height: 220px;
        left: 35%;
        transform: translateX(-50%) rotate(45deg);
        &:last-child{
            left: 70%;
            width: 280px;
            height: 280px;
        }
    }
`