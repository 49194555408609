import React, { useMemo, useState, useRef } from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import Decimal from "decimal.js";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledPay } from "../../atoms/UserPanelTrainerPay/StyledPay";
import { StyledElement } from "../../atoms/UserPanelTrainerPay/StyledElement";
import { StyledClosePayElement } from "../../atoms/PayElement/StyledClosePayElement";
import { StyledSavePayElement } from "../../atoms/PayElement/StyledSavePayElement";
import { StyledPayHour } from "../../atoms/PayElement/StyledPayHour";
import { StyledEditPay } from "../../atoms/PayElement/StyledEditPay";
import { StyledEditElement } from "../../atoms/PayElement/StyledEditElement";

import { getDateString } from "../../../logic/eventtime";
import { getString } from "../../../strings";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";
import { deleteHours, editHours } from "../../../logic/requests/students";

const handleSubmit = (hour, data, students, succFunc) => {
    if (!Object.keys(data).length)
        return succFunc();

    if (data.hours) {
        const hours = new Decimal(data.hours);
        if (!hours.isInteger()) {
            if (hours.eq("0.5")) {
                data.half = 1;
                data.hours = 0;
            } else {
                console.log("handleSubmit", "Data validation failed - hours must be an integer or '0.5'");
                return;
            }
        } else {
            data.half = 0;
        }
    }

    if (data.student)
        data.student = parseInt(data.student);

    const onSuccess = (backend_data) => succFunc?.({
        ...hour,
        ...data,
        student: students.find(student => student.id === parseInt(data.student)) ?? hour.student,
        ...(backend_data ?? {})
    });

    editHours(hour.id, data)
    .then(
        (res) => {
            console.log("handleSubmit", res);
            onSuccess(res);
        }
    )
    .catch(
        (err) => {
            console.error("handleSubmit", err);
        }
    )
    ;
};

const handleDelete = (id, onSuccess) => {
    if (!window.confirm("Czy na pewno chcesz usunąć godzinę?"))
        return;

    deleteHours(id)
        .then(
            (res) => {
                console.log("handleDelete", res);
                onSuccess?.();
            }
        )
        .catch(
            (err) => {
                console.error("handleDelete", err);
            }
        )
    ;
};

const PayHour = ({ hour, students, subjects, reloadHours }) => {
    const [isEdit, setIsEdit] = useState(false);
    const price = useMemo(() => new Decimal(hour.price), [hour]);

    const name = useMemo(() => `${hour.student.firstname} ${hour.student.lastname}`, [hour]);
    const date = useMemo(() => getDateString(new Date(hour.date)), [hour]);
    const hours = useMemo(() => hour.half ? "0.5" : hour.hours, [hour]);

    const inputRefs = {
        student: useRef(),
        date: useRef(),
        hours: useRef(),
        subject: useRef()
    };
    const initialValues = useMemo(() => ({
        student: String(hour.student.id),
        date: new Date(hour.date).toISOString().split('T')[0],
        hours,
        subject: hour.subject
    }), [hour, hours]);

    return (
        <StyledPayHour>
            <ScrollSyncPane>
                <div>
                    {!isEdit ? (
                        <StyledPay>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredtextalign="center"
                                >
                                    {name}
                                </StyledText>
                            </StyledElement>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredtextalign="center"
                                >
                                    {date}
                                </StyledText>
                            </StyledElement>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredtextalign="center"
                                >
                                    {hours}
                                </StyledText>
                            </StyledElement>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString(`subject__${hour.subject}`)}
                                </StyledText>
                            </StyledElement>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                </StyledText>
                            </StyledElement>
                        </StyledPay>
                    ) : (
                        <StyledEditPay>
                            <StyledEditElement>
                                <select ref={inputRefs.student} defaultValue={initialValues.student}>
                                    {
                                        students.map(
                                            (student) => (
                                                <option key={student.id} value={student.id}>{`${student.lastname} ${student.firstname}`}</option>
                                            )
                                        )
                                    }
                                </select>
                            </StyledEditElement>
                            <StyledEditElement>
                                <input type="date" ref={inputRefs.date} defaultValue={initialValues.date} />
                            </StyledEditElement>
                            <StyledEditElement>
                                <input type="number" ref={inputRefs.hours} defaultValue={initialValues.hours} />
                            </StyledEditElement>
                            <StyledEditElement>
                                <select ref={inputRefs.subject} defaultValue={initialValues.subject}>
                                    {
                                        subjects.map(
                                            (subject) => (
                                                <option key={subject} value={subject}>{getString(`subject__${subject}`)}</option>
                                            )
                                        )
                                    }
                                </select>
                            </StyledEditElement>
                            <StyledElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                </StyledText>
                            </StyledElement>
                        </StyledEditPay>
                    )}
                </div>
            </ScrollSyncPane>
            <StyledSavePayElement
                isedit={isEdit}
                onClick={
                    (isEdit) ? (
                        () => handleSubmit(
                            hour,
                            Object.fromEntries(
                                Object.entries(inputRefs)
                                .map(
                                    ([key, value]) => [key, value?.current?.value]
                                )
                                .filter(
                                    ([key, value]) => initialValues[key] !== value
                                )
                            ),
                            students,
                            (data) => {
                                setIsEdit(false);
                                if (data)
                                    reloadHours();
                            }
                        )
                    ) : (
                        () => setIsEdit(true)
                    )
                }
            >
                {!isEdit ? <PencilIcon /> : <AcceptIcon />}
            </StyledSavePayElement>
            <StyledClosePayElement
                onClick={
                    (isEdit) ? (
                        () => setIsEdit(false)
                    ) : (
                        () => handleDelete(hour.id, reloadHours)
                    )
                }
            >
                <CloseIcon />
            </StyledClosePayElement>
        </StyledPayHour>
    );
};

export default PayHour;
