import styled from "styled-components"

export const StyledTrainingsInformationsImages = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 90%;
    margin-bottom: 60px;
    
    @media only screen and (max-width: 1078px){
        width: 100%;
    }

    @media only screen and (max-width: 538px){
        flex-direction: column;
        gap: 10px;
    }
`