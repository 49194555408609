import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { hasPaidForEvent, hasPaidForAnalysis, transactionStatus } from "../../logic/requests/payments.js";

const regex = /http(?:s)?.*\.przelewy24.pl/;

const AfterTransaction = () => {
	const navigate = useNavigate();
	const { type, id } = useParams();

	useEffect(
		() => {
			if (isNaN(id) || !regex.test(document.referrer)) {
				// nie podano ID lub użytkownik nie przyszedł tutaj z Przelewy24 tylko np. wpisując link ręcznie
				navigate("/", { replace: true });
				return;
			}

			switch (type) {
				case "event":
					hasPaidForEvent(id)
						.then(
							res => {
								console.log("hasPaidForEvent", res);
								navigate(
									"/my-account",
									{
										replace: true,
										state: {
											afterTransaction: true,
											afterTransactionErr: !res.has_paid
										}
									}
								);
							}
						)
						.catch(
							err => {
								console.error("hasPaidForEvent", err);
								navigate("/", { replace: true });
							}
						)
					;
					break;
				case "analysis":
					hasPaidForAnalysis(id)
						.then(
							res => {
								console.log("hasPaidForAnalysis", res);
								navigate(
									"/trainings/order-analysis",
									{
										replace: true,
										state: {
											afterTransaction: true,
											afterTransactionErr: !res.has_paid
										}
									}
								);
							}
						)
						.catch(
							err => {
								console.error("hasPaidForAnalysis", err);
							}
						)
					;
					break;
				case "lessons":
					transactionStatus(id)
						.then(
							(res) => {
								console.log("transactionStatus", res);
								navigate(
									"/my-account/payments",
									{
										replace: true,
										state: {
											afterTransaction: true,
											transactionType: type,
											transactionStatus: res.status
										}
									}
								);
							}
						)
						.catch(
							(err) => {
								console.error("transactionStatus", err);
								navigate('/', { replace: true });
							}
						)
					;
				default:
					navigate("/", { replace: true });
					break;
			}
		},
		[ type, id ]
	);

	// TODO: Jakaś strona / loader?
	return null;
};

export default AfterTransaction;
