import styled from "styled-components";

export const StyledResignationWrapper = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
    > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 40%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 20px;
        > button {
            height: 50px;
            width: 120px;
            &:last-child {
                margin-left: 12px;
            }
        }
    }
`;
