import styled from "styled-components";

export const StyledPlayerElementWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    min-height: 60vh;
    overflow-y: scroll;
    margin-top: 90px;
    padding-right: 10px;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (min-width: 1441px) {
        margin-top: 54px;
    }
    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 40px;
    }
    @media only screen and (max-height: 599px){
        margin-top: 40px;
    }
`;
