import styled from "styled-components";
import { motion } from "framer-motion"

export const StyledSeriesResults = styled(motion.div)`
    width: 75%;
    height: 100vh;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 1204px){
        width: 70%;
    }
    
    @media only screen and (max-width: 767px){
        width: 100%;
        height: 100%;
        padding: 0 20px 80px 20px;
    }
`;
