import React, { useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';

import FAQElement from "./FAQElement";

import { StyledFAQCategoryElement } from "../../atoms/FAQ/StyledFAQCategoryElement";
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledArrow } from "../../atoms/Series/StyledArrow";

const FAQCategoryElement = ({ triggerParentScroll, categoryName, elements }) => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    useEffect(() => triggerParentScroll?.(), [triggerParentScroll, isOpen]);

    return (
        <StyledFAQCategoryElement isopen={isOpen}>
            <button onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredpadding="0 10px 0 0"
                    hascursor="pointer"
                    hasdeclaredtextalign="center"
                >
                    {categoryName}
                </StyledText>
                <StyledArrow isopen={isOpen} />
            </button>
            {
                isOpen ? (
                    elements.map(
                        faq => (
                            <FAQElement 
                                triggerParentScroll={triggerParentScroll}
                                headerText={faq.pytanie}
                                answerText={faq.odpowiedz}
                            />
                        )
                    )
                ) : (null)
            }
        </StyledFAQCategoryElement>
    )
}

export default FAQCategoryElement
