import styled from 'styled-components'

export const StyledResultPage = styled.section`
    @media only screen and (max-width: 768px) {
        h2 {
            font-size: 32px;
        }
    }
    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 24px;
        }
    }
`
