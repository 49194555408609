import styled from "styled-components";

export const StyledNav = styled.div`
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    font-family: "Poppins";
    a {
        padding-left: 32px;
        text-decoration: none;
        color: #000;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
    }
    p {
        padding-left: 32px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
    }

    @media only screen and (max-width: 952px){
        > a,
        > p {
            font-size: 11px;
        }
    }
    @media only screen and (max-width: 767px){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        padding-right: 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        transform: ${({ openmenu }) => openmenu ? "translateX(0)" : "translateX(-100%)"};
        > a,
        > p {
            font-size: 14px;
            text-align: center;
            color: #000;
            padding: 0;
        }
    }
`;
