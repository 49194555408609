import styled from "styled-components";

export const StyledStudentPaymentsElement = styled.div`
    width: 100%;
    min-width: 700px;
    height: 100%;
    background: #FFF;
    border: 4px solid #6786BE;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    padding: 5px 0;
`;
