import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";

import RegisterForm from "../organisms/Register/RegisterForm";
import LogoLink from "../organisms/Logo/LogoLink";
import LoginLink from "../organisms/LoginLink/LoginLink";
import RegisterCube from "../molecules/RegisterCube/RegisterCube";
import HelmetTemplate from "../molecules/HelmetTemplate/HelmetTemplate";

import { StyledRegisterPage } from "../../components/atoms/Register/StyledRegisterPage";
import { getString } from "../../strings";

const Register = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState();
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                if (user && Object.keys(user).length) navigate("/", { replace: true });
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    if (user && Object.keys(user).length) return <></>;

    return (
        <>
            <HelmetTemplate 
                title={getString("seo_register_title")} 
                desc={getString("seo_register_desc")} 
                ogTitle={getString("seo_register_twitter_title")}
            />
            <StyledRegisterPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <LogoLink />
                <RegisterForm />
                <LoginLink />
                <RegisterCube registerCubeFill="rgba(0,0,0,0.1)" />
            </StyledRegisterPage>
        </>
    );
};

export default Register;
