import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLogoLink = styled(Link)`
    position: fixed;
    top: 0;
    left: 0;
    width: 185px;
    height: 185px;
    background-color: #000;
    display: block;
    border-bottom-right-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    z-index: 100;
    overflow: hidden;

    > img {
        width: 70%;
        height: 70%;
        -o-object-fit: contain;
        object-fit: contain;
        -webkit-transform: translate(-15px, -12px);
        -ms-transform: translate(-15px, -12px);
        transform: translate(-15px, -12px);
    }

    @media only screen and (max-width: 1266px){
        width: 160px;
        height: 160px;
    }

    @media only screen and (max-width: 500px) {
        width: 125px;
        height: 125px;

        > img {
            -webkit-transform: translate(-7px, -12px);
            -ms-transform: translate(-7px, -12px);
            transform: translate(-7px, -12px);
        }
    }

    @media only screen and (max-width: 363px) {
        width: 105px;
        height: 105px;
    }
`;
