import styled from "styled-components"

export const StyledBgWrapper = styled.div`
    width: 100%;
    border-radius: 25px;
    border: 6px solid #6786be;
    background-color: #fff;
    padding: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
        width: ${({ hasavatar }) => (hasavatar ? "30%" : "0%")};
        min-width: ${({ hasavatar }) => (hasavatar ? "180px" : "unset")};

        > div {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 2px solid black;
                border-radius: 15px;
            }
        }
    }

    > p {
        display: block;
        width: ${({ hasavatar }) => (hasavatar ? "70%" : "100%")};
    }

    @media only screen and (max-width: 517px) {
        flex-direction: column;
        align-items: center;

        p {
            font-size: 18px;
            width: 100%;
            padding: 8px;
        }
        
        > div {
            width: 65%;
        }
    }
`;