import styled from "styled-components"

export const StyledSeriesResultsElementHeadingResults = styled.div`
    display: flex;
    justify-content: ${({ isscrollbar }) => isscrollbar ? "flex-start" : "center"};
    align-items: center;
    width: 50%;
    overflow-x: scroll;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3);" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
    
    > p {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        min-height: 40px;
        margin-right: 5px;
        height: 100%;
    }

    @media only screen and (max-width: 1100px){
        > p{
            font-size: 15px
        }
    }
`