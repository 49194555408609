import styled from "styled-components"

export const StyledOpenButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: #333;
    z-index: ${({ hasindex }) => hasindex ? hasindex : 0};
    border-radius: 25px;
    @media only screen and (max-width: 762px){
        top: 31px;
        padding-top: 4px;
    }
`