import styled from "styled-components"

export const StyledGalleryMenuWrapperDiv = styled.div`
    width: 100%;

    > div {
        &:last-child{
            width: 100%;
            > div {
                width: 100%;
                scrollbar-width: none;
                ::-webkit-scrollbar {
                    background: transparent;
                    width: 0;
                }
                ::-webkit-scrollbar-track {
                    background: transparent;
                    width: 0;
                }
                ::-webkit-scrollbar-thumb {
                    background: transparent;
                    width: 0;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background: transparent;
                    width: 0;
                }
            }
        }
    }
`