import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { AnimatePresence } from "framer-motion";
import { Formik } from "formik";
import { getString, setLang } from "../../../strings";

import LoginButtonsWrapper from "../../molecules/Login/LoginButtonsWrapper.js";

import { useNavigate, useLocation } from "react-router-dom";
import { sessionService } from "redux-react-session";

import { login } from "../../../logic/requests/users.js";

import { StyledLoginInput } from "../../atoms/Login/StyledLoginInput";
import { StyledLoginForm } from "../../molecules/Login/StyledLoginForm";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledLoginLabel } from "../../molecules/Login/StyledLoginLabel";
import { StyledInputWrapper } from "../../atoms/Login/StyledInputWrapper";

import useWindowSize from "../../../utils/getWindowSize";

import { ReactComponent as IconUser } from "../../../images/LoginPage/iconuser.svg";
import { ReactComponent as Key } from "../../../images/LoginPage/key.svg";

const LoginForm = () => {
    const [loginErr, setLoginErr] = useState(false);
    const [loginErrMsg, setLoginErrMsg] = useState("");
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    const windowWidth = useWindowSize();
    const [user, setUser] = useState();
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const validationSchema = Yup.object({
        login: Yup.string().email(getString("login_loginForm_niepoprawnyEmail")).required(getString("login_loginForm_emailWymagany")),
        password: Yup.string().min(3, getString("login_loginForm_zaKrotkieHaslo")).required(getString("login_loginForm_hasloWymagane")),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        setLoginErr(false);
        login(values.login, values.password)
            .then((data) => {
                sessionService.saveUser(data);
                setUser(data);
                setLang(data.lang);
            })
            .catch((res) => {
                switch (res.data.code) {
                    case "no_body":
                    case "no_user":
                    case "no_pass":
                        setLoginErrMsg(getString("login_loginForm_serwerOtrzymal"));
                        break;
                    case "logged_in":
                        setLoginErrMsg(getString("login_loginForm_uzytkownikJestJuzZalogowany"));
                        break;
                    case "login":
                        setLoginErrMsg(getString("login_loginForm_blednyEmail"));
                        break;
                    case "pass":
                        setLoginErrMsg(getString("login_loginForm_bledneHaslo"));
                        break;
                    case "verify":
                        navigate("/verify-account", {
                            replace: true,
                            state: {
                                email: values.login,
                            },
                        });
                        break;
                    default:
                        setLoginErrMsg(getString("login_loginForm_bladLogowania"));
                        break;
                }
                setLoginErr(true);
            });
        setSubmitting(false);
    };

    useEffect(() => {
        if (user && Object.keys(user).length) {
            navigate(state && state.loginRedirect ? state.loginRedirect : "/", { replace: true });
        }
    }, [user, state]);

    return (
        <Formik
            initialValues={{ login: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, handleSubmit, isSubmitting }) => (
                <AnimatePresence>
                    <StyledLoginForm
                        onSubmit={handleSubmit}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={
                            windowWidth < 500 ? { duration: 0.2, delay: 0.2 } : { duration: 0.2 }
                        }
                    >
                        <StyledText
                            hasdeclaredfontfamily="FilsonProBold"
                            hasdeclaredfontsize="58px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="0 0 20px 0"
                            hasdeclaredfontweight="600"
                            as="h2"
                        >
                            {getString("login_loginForm_zalogujSie")}
                        </StyledText>
                        <StyledInputWrapper>
                            <StyledLoginLabel>
                                <IconUser />
                            </StyledLoginLabel>
                            <StyledLoginInput type="text" name="login" placeholder={getString("login_loginForm_emailPlaceholder")} />
                        </StyledInputWrapper>
                        <StyledInputWrapper>
                            <StyledLoginLabel>
                                <Key />
                            </StyledLoginLabel>
                            <StyledLoginInput type="password" name="password" placeholder={getString("login_loginForm_passwordPlaceholder")} />
                        </StyledInputWrapper>
                        <LoginButtonsWrapper
                            loginErr={loginErr}
                            isSubmitting={isSubmitting}
                            loginErrMsg={loginErrMsg}
                        />
                    </StyledLoginForm>
                </AnimatePresence>
            )}
        </Formik>
    );
};

export default LoginForm;
