import styled from "styled-components";
import { ReactComponent as Cube } from "../../../images/cubes/pyram.svg";

export const StyledCubePyram = styled(Cube)`
    position: absolute;
    right: 35px;
    top: 100px;
    transform: rotate(-75deg);
    width: 200px;
    height: 200px;
    fill: rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: 1920px) {
        right: 75px;
        width: 330px;
        height: 330px;
    }
`;
