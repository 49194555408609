import React, { useState, useMemo } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import AdditionDataElement from "../AdditionDataElement/AdditionDataElement"

import { StyledUserPanelAdditionElement, StyledHeader, StyledHeaderElement } from "./UserPanelAdditionElement.styles";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledHeading } from "../../atoms/PayElement/StyledHeading";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";
import { StyledElements } from "../../atoms/UserPanelStudentsPayments/StyledElements";

import { getLang, getLocale, getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelAdditionElement = ({ month, additions, trainers }) => {
    const [isOpen, setIsOpen] = useState(false);
    const title = useMemo(
        () => (
            new Date(month)
            .toLocaleDateString(
                getLocale(getLang()),
                { year: "numeric", month: "long" }
            )
        ), [month]
    );

    const { scrollElement, isScrollBar } = useScrollBar();

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <StyledUserPanelAdditionElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {title}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElements>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                <StyledHeader
                                    isscrollbar={isScrollBar}
                                    ref={scrollElement}
                                    hasalignself="flex-start"
                                >
                                    <div>
                                        <StyledHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_addition_data_element_imie")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_addition_data_element_nazwisko")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="10%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_addition_data_element_kwota")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="40%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("User_panel_addition_data_element_opis")}
                                            </StyledText>
                                        </StyledHeaderElement>
                                    </div>
                                </StyledHeader>
                                {(additions ?? []).map(
                                    ({ id, id_Trainer, amount, description }) => (
                                        <AdditionDataElement
                                            key={id}
                                            id={id}
                                            trainer={trainers.find(({ id }) => id === id_Trainer)}
                                            amount={amount}
                                            description={description}
                                        />
                                    )
                                )}
                            </ScrollSyncPane>
                        </>
                    </ScrollSync>
                </StyledElements>
            ) : null}
        </StyledUserPanelAdditionElement>
    );
};

export default UserPanelAdditionElement;
