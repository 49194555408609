import styled from "styled-components";
import { Field } from "formik";

export const StyledLoginInput = styled(Field)`
    font-family: "Poppins";
    font-size: 20px;
    border: 3px solid #6786be;
    border-radius: 30px;
    margin-top: 5px;
    padding: 18px 16px;
    color: #6786be;
    outline: #306efa;
    text-indent: 45px;
    position: relative;
    width: 100%;

    &::placeholder {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 26px;
        color: #ccc;
        transform: translateY(3px);
    }
    @media only screen and (max-width: 440px) {
        padding: 8px 12px;
        font-size: 16px;

        &::placeholder {
            font-size: 18px;
            transform: translateY(0);
        }
    }
`;
