import styled from "styled-components";

export const StyledSeriesButton = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 3;
    > a {
        text-decoration: none;
        > button {
            width: 260px;
            height: 70px;
            border-radius: 70px 70px 0 0;
            background-color: #df7b63;
        }
    }

    @media only screen and (max-width: 470px) {
        > a {
            > button {
                width: 220px;
                height: 50px;
                font-size: 22px;
            }
        }
    }
`;
