import styled from "styled-components";
import { ReactComponent as Tournaments } from "../../../images/home/kostka.svg";

export const StyledTournamentSvg = styled(Tournaments)`
    position: relative;
    z-index: 1;
    width: 400px;
    height: 180px;
    fill: #fff;
    @media only screen and (min-width: 1920px) {
        height: 270px;
    }
    @media only screen and (max-width: 943px) {
        height: 115px;
        width: 210px;
    }
    @media only screen and (max-width: ${({ isang }) => isang ? "1036px" : "767px"}) {
        width: 400px;
        height: 180px;
    }
    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
`;
