import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledEditPanelError = styled(motion.div)`
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
`;
