import styled from "styled-components"

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: ${({ center }) => center ? "center" : "space-between"};
    margin-top: ${({ ismgtop }) => ismgtop ? "0" : "20px"};

    > button {
        width: 48%;
        height: 50px;
    }
    
    @media only screen and (max-width: 1232px) {
        > button {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 520px) {
        > button {
            font-size: 12px;
        }
    }
`;