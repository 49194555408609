import styled from "styled-components";

export const StyledRegistrationContentWrapper = styled.div`
    width: 100%;
    height: 75%;
    margin-top: 200px;
    overflow-y: scroll;
    padding: 22px 10px;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    @media only screen and (max-width: 767px) {
        height: 100%;
        margin-top: 20px;
        overflow-y: visible;
        padding: 22px 0 40px 0;
    }
`;
