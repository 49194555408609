import React from "react";
import { getString } from "../../../strings";
import RegisterErrorMessage from "./RegisterErrorMessage";

import { StyledRegisterInput } from "../../../components/atoms/Register/StyledRegisterInput";
import { StyledInputWrapper } from "../../../components/atoms/Register/StyledInputWrapper";
import { StyledSelectInput } from "../../../components/molecules/Register/StyledSelectInput";
import { StyledText } from "../../atoms/Text/StyledText";

const InputWrapper = ({ title, name, inputType, error, annotation, hasDeclaredBottomPosition }) => {
    return (
        <StyledInputWrapper>
            <label htmlFor={name}>
                <StyledText
                    hasdeclaredfontsize="20px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="8px 0 8px 0"
                    hasdeclaredfontweight="600"
                >
                    {title}
                </StyledText>
            </label>
            {inputType === "select" ? (
                <StyledSelectInput error={error} component={inputType} name={name} id={name}>
                    <option></option>
                    <option value="m" label={getString("register_inputWrapper_mezczyzna")}></option>
                    <option value="f" label={getString("register_inputWrapper_kobieta")}></option>
                    <option value="o" label={getString("register_inputWrapper_inna")}></option>
                </StyledSelectInput>
            ) : (
                <StyledRegisterInput error={error} type={inputType} name={name} id={name} />
            )}
            {annotation && (
                <StyledText
                    hasdeclaredfontsize="12px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="2px 0 1px 0"
                    hasdeclaredfontweight="600"
                >
                    {annotation}
                </StyledText>
            )}
            <RegisterErrorMessage name={name} hasDeclaredBottomPosition={hasDeclaredBottomPosition}/>
        </StyledInputWrapper>
    );
};

export default InputWrapper;
