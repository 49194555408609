import styled from "styled-components";

export const StyledLoginPageImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    top: 0;
    left: 0;

    @media only screen and (max-width: 767px) {
        object-fit: cover;
    }
`;
