import React, { useState, useEffect } from "react";
import { ScrollSync } from "react-scroll-sync";

import Loader from "../Loader/Loader";

import { StyledAdminLessons } from "../../atoms/AdminLessons/StyledAdminLessons";
import { StyledAdminLessonsContent } from "../../atoms/AdminLessons/StyledAdminLessonsContent";
import { StyledAdminLessonsHeader } from "../../atoms/AdminLessons/StyledAdminLessonsHeader";
import { StyledText } from "../../atoms/Text/StyledText";

import { listLessons, updateLesson } from "../../../logic/requests/admin";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const lessonStates = {
	"new": "Nowe",
	"arranged": "Umówione",
	"done": "Zrealizowane"
};

const AdminLessons = () => {
	const [lessons, setLessons] = useState();
	const { scrollElement, isScrollBar } = useScrollBar();

	useEffect(
		() => {
			if (lessons)
				return;
			
			listLessons()
				.then(
					res => {
						console.log("listLessons", res);
						setLessons(res);
					}
				)
				.catch(
					err => {
						console.error("listLessons", err);
					}
				)
			;
		}, [ lessons ]
	);

	const handleStatusChange = (id, status, component) => (
		updateLesson(id, status)
		.then(
			res => {
				console.log("updateLesson", res.message);
				setLessons(
					lessons.map(
						lesson => (
							(lesson.id === id) ? ({
								...lesson,
								status: status
							}) : (lesson)
						)
					)
				);
			}
		)
		.catch(
			err => {
				console.error("updateLesson", err);
				component.value = lessons.find(lesson => lesson.id === id).status;
			}
		)
	);

	return (
		<StyledAdminLessons isscrollbar={isScrollBar} ref={scrollElement}>
			<ScrollSync>
				{
				(!lessons) ? (
					<Loader />
				) : (!lessons.length) ? (
					"Brak jakichkolwiek zapisów na lekcje :("
				) : (
					<StyledAdminLessonsContent>
						<StyledAdminLessonsHeader>
							<StyledText>ID zgłoszenia</StyledText>
							<StyledText>Stan</StyledText>
							<StyledText>ID użytkownika</StyledText>
							<StyledText>Imię</StyledText>
							<StyledText>Nazwisko</StyledText>
							<StyledText>E-mail</StyledText>
							<StyledText>Telefon</StyledText>
							<StyledText>Opis</StyledText>
						</StyledAdminLessonsHeader>
						{
							lessons.map(
								(lesson, index) => (
									<StyledAdminLessonsHeader blackcolor={index % 2 === 0}>
										<p>{lesson.id}</p>
										<select
											defaultValue={lesson.status}
											onChange={
												(e) => handleStatusChange(lesson.id, e.target.value, e.target)
											}
										>
											{
												Object.keys(lessonStates).map(
													state => (
														<option value={state}>
															{lessonStates[state]}
														</option>
													)
												)
											}
										</select>
										<p>{lesson.user_id}</p>
										<p>{lesson.firstname}</p>
										<p>{lesson.lastname}</p>
										<p>{lesson.email}</p>
										<p>{lesson.phone}</p>
										<p>{lesson.description}</p>
									</StyledAdminLessonsHeader>
								)
							)
						}
					</StyledAdminLessonsContent>
				)
			}
			</ScrollSync>
		</StyledAdminLessons>
	);
};

export default AdminLessons;
