import styled from "styled-components";

export const StyledTournamentForm = styled.form`
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > div {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        width: 100%;
        padding: 12px 0;
        border-bottom: 1px dashed #333;
        > input,
        > select {
            width: 800px;
            height: 40px;
            font-family: "Poppins";
            background-color: #56e4a0;
            border: none;
        }
        > label {
            padding: 12px 0;
        }
        > p {
            font-size: 22px;
        }
    }
`;
