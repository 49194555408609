import styled from "styled-components"

export const StyledRoleWrapper = styled.div`
    @media only screen and (max-width: 926px){
        > p{
            &:first-child{
                color: #ee745c;
            }
            color: #6786be;
        }
    }
`