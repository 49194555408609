import styled from "styled-components";
import mobile from "../../../images/mobile/contactMobileShape.png"
import { motion } from "framer-motion";

export const StyledLeftWrapper = styled(motion.div)`
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    padding-bottom: 40px;

    > button {
        transition: background-color 250ms linear;

        &:hover {
            background-color: #602B26;
        }
    }

    a {
        cursor: pointer;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    @media only screen and (max-width: 1223px){
        padding-top: 80px;
    }

    @media only screen and (max-width: 1074px) {
        padding-top: 150px;
        margin-top: 0;
        width: 100%;
        height: 100%;
        background-image: url(${mobile});
        background-size: cover;
    }

    @media only screen and (max-width: 502px) {
        max-width: 100%;
        background-size: fill;
        background-position: -80px 0;

        > button {
            width: 90%;
        }

        > a{
            width: 100%;
            background: #6786be;
        }
    }
`;
