import styled from "styled-components"

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    
    > button {
        &:nth-child(1){
            cursor: pointer;
            border-radius: 25px;
            background-color: #ee745c;
            height: 50px;
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-family: 'Poppins';
            font-weight: 700;
            color: #fff;
            font-size: 18px;
        }
    }
`