import React, { useState } from "react";
import { ScrollSyncPane } from "react-scroll-sync";

import PaymentsPopup from "../../organisms/Payments/PaymentsPopup";

import { StyledFlag } from "../../atoms/ChangeLanguage/StyledFlag";
import {
    StyledSubjectDataElement,
    StyledSubjectDataTextElement,
} from "./SubjectDataElement.styles";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledClosePayElement } from "../../atoms/PayElement/StyledClosePayElement";
import { StyledSavePayElement } from "../../atoms/PayElement/StyledSavePayElement";
import { StyledEditInputElement } from "../../atoms/EditInputElement/StyledEditInputElement";
import { StyledGlobalUserPanelWrapper } from "../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper";
import { StyledEditWrapper } from "../../atoms/StyledEditWrapper/StyledEditWrapper"

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";
import Flag from "react-world-flags";
import ChangeLanguage from "../../organisms/ChangeLanguage/ChangeLanguage";
import { getString } from "../../../strings";

const SubjectDataElement = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [isSend, setIsSend] = useState(false);

    return (
        <>
            {isSend ? <PaymentsPopup title="sukces" onClose={() => setIsSend(false)} /> : null}
            <StyledSubjectDataElement>
                <ScrollSyncPane>
                    <div>
                        {isEdit ? (
                            <StyledEditWrapper>
                                <StyledEditInputElement $isFlex $hasDeclaredWidth="50%">
                                    <ChangeLanguage isInputField />
                                    <input type="text" />
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="25%">
                                    <input type="number" />
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="25%">
                                    <input type="number" />
                                </StyledEditInputElement>
                            </StyledEditWrapper>
                        ) : (
                            <StyledGlobalUserPanelWrapper>
                                <StyledSubjectDataTextElement $hasDeclaredWidth="10%">
                                    <StyledFlag>
                                        <Flag code="GB" />
                                    </StyledFlag>
                                </StyledSubjectDataTextElement>
                                <StyledSubjectDataTextElement $isFlex $hasDeclaredWidth="40%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredpadding="0 0 0 15px"
                                    >
                                        {getString("Subject_data_element_nazwa")}
                                    </StyledText>
                                </StyledSubjectDataTextElement>
                                <StyledSubjectDataTextElement $hasDeclaredWidth="25%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Subject_data_element_cena_ucznia")}
                                    </StyledText>
                                </StyledSubjectDataTextElement>
                                <StyledSubjectDataTextElement $hasDeclaredWidth="25%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Subject_data_element_cena_trenera")}
                                    </StyledText>
                                </StyledSubjectDataTextElement>
                            </StyledGlobalUserPanelWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
                <StyledSavePayElement isedit={isEdit} onClick={() => setIsEdit(!isEdit)}>
                    {!isEdit ? <PencilIcon /> : <AcceptIcon />}
                </StyledSavePayElement>
                <StyledClosePayElement>
                    <CloseIcon />
                </StyledClosePayElement>
            </StyledSubjectDataElement>
        </>
    );
};

export default SubjectDataElement;
