import styled from "styled-components";

export const StyledLeftPanelTouranmentTextWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    > h2{
        margin-top: -20%;
    }
    @media only screen and (max-width: 975px) {
        > h2 {
            font-size: 38px;
        }
    }
    @media only screen and (max-width: 926px) {
        > h2 {
            margin-top: 0;
            font-size: 26px;
            padding: 80px 20px 40px 20px;
        }
    }
`;
