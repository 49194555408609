import React from 'react'

import { Helmet } from 'react-helmet'
import { getLang } from '../../../strings'

const HelmetTemplate = ({ title, desc, ogTitle }) => {
  return (
    <Helmet htmlAttributes={{ lang: getLang() }}>
        <meta charset="utf-8" />
        <title>{title}</title>
        <meta property="twitter:title" content={title} />
        <meta property="og:title" content={ogTitle ? ogTitle : title} />
        <meta name="twitter:title" content={title} />

        <meta name="description" content={desc} />
        <meta property="twitter:description" content={desc} />
        <meta property="og:description" content={desc} />
        <meta name="twitter:description" content={desc} />

        <meta name="robots" data-react-helmet="true" />
        
        <meta property="og:image" content="https://cube4fun.pl/api/images/seo/ogimage_8.png" data-react-helmet="true" />
        <meta property="twitter:image" content="https://cube4fun.pl/api/images/seo/ogimage_8.png" />
        <meta name="twitter:image" content="https://cube4fun.pl/api/images/seo/ogimage_8.png" />

        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:url" content={seo.url} /> */}
        {/* <meta name="twitter:creator" content={seo.twitterUsername} /> */}
    </Helmet>
  )
}

export default HelmetTemplate