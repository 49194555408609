import React from "react";

import UserPanelSubjectElement from "./UserPanelSubjectElement"
import UserPanelSubjectDataElement from "../../molecules/UserPanelSubjectDataElement/UserPanelSubjectDataElement"

import { StyledUserPanelSubjects } from "../../atoms/UserPanelSubjects/StyledUserPanelSubjects";
import { StyledGlobalScrollWrapper } from "../../atoms/GlobalScrollWrapper/StyledGlobalScrollWrapper";
import { StyledGlobalHeadingWrapper } from "../../atoms/GlobalHeadingWrapper/StyledGlobalHeadingWrapper";
import { StyledText } from "../../atoms/Text/StyledText";

import { getString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelSubjects = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledUserPanelSubjects>
            <StyledGlobalHeadingWrapper>
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("User_panel_subject_dodaj_przedmiot")}:
                </StyledText>
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper
                isscrollbar={isScrollBar}
                ref={scrollElement}
            >
                <UserPanelSubjectElement />
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString("User_panel_subject_przedmioty")}:
                </StyledText>
                <UserPanelSubjectDataElement />
                <UserPanelSubjectDataElement />
                <UserPanelSubjectDataElement />
            </StyledGlobalScrollWrapper>
        </StyledUserPanelSubjects>
    );
};

export default UserPanelSubjects;
