import styled from "styled-components";

export const StyledHeadingWrapper = styled.div`
    width: 100%;
    margin-bottom: 4px;

    @media only screen and (max-width: 1017px) {
        > p {
            font-size: 26px;
        }
    }
    @media only screen and (max-width: 767px) {
        > p {
            font-size: 34px;
        }
    }
    @media only screen and (max-width: 411px) {
        > p {
            font-size: 28px;
        }
    }
`;
