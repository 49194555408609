import React from "react";

import { Link } from "react-router-dom";

import { StyledNav } from "../../atoms/AdminNav/StyledNav";

const AdminNav = ({ openMenu }) => {
    return (
        <StyledNav openmenu={openMenu}>
            <Link to="/">Strona główna</Link>
            <Link to="/admin/lessons">Lekcje</Link>
        </StyledNav>
    );
};

export default AdminNav;
