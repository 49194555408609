import styled from "styled-components"

export const StyledUserPanelStudentsMenu = styled.div`
    width: 100%;

    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        > i {
            position: absolute;
            right: 20px;
        }
    }
`