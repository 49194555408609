import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { sessionService } from "redux-react-session";

import { isLoggedIn } from "./logic/requests/users";

import CookiesPopUp from "./components/organisms/CookiesPopUp/CookiesPopUp";
import HelmetTemplate from "./components/molecules/HelmetTemplate/HelmetTemplate";

import Login from "./components/pages/Login";
import Logout from "./components/pages/Logout";
import EventCountdown from "./components/pages/EventCountdown";
import SelectOfCompetition from "./components/pages/SelectOfCompetition";
import Attempt from "./components/pages/Attempt";
import ForgottenPassword from "./components/pages/ForgottenPassword";
import Results from "./components/pages/Results";
import Page404 from "./components/pages/Page404";
import Register from "./components/pages/Register";
import ThankYouPage from "./components/pages/ThankYouPage";
import VerifyAccount from "./components/pages/VerifyAccount";
import Home from "./components/pages/Home";
import AdminPanel from "./components/pages/AdminPanel";
import AdminTournament from "./components/pages/AdminTournament";
import AddTournament from "./components/pages/AddTournament";
import Tournaments from "./components/pages/Tournaments";
import Contact from "./components/pages/Contact";
import Tournament from "./components/pages/Tournament";
import ResetPassword from "./components/pages/ResetPassword";
import Trainings from "./components/pages/Trainings";
import GallerySite from "./components/pages/GallerySite";
import UserPanel from "./components/pages/UserPanel";
import Series from "./components/pages/Series";
import AfterTransaction from "./components/pages/AfterTransaction";
import ScrollToTop from "./components/organisms/ScrollToTop/ScrollToTop";
import AdminTournamentsPage from "./components/pages/AdminTournamentsPage";
import GlobalStyle from "./styles/GlobalStyle";
import AdminUsers from "./components/pages/AdminUsers";
import AdminFAQ from "./components/pages/AdminFAQ";
import AdminUser from "./components/pages/AdminUser";

import GaTracker from "./components/GaTracker";
import UnderConstruction from "./components/pages/UnderConstruction";

import { getLang, getString } from "./strings";
import { setLang as setLangBackend } from "./logic/requests/users";

const App = () => {
    /*
        TODO: można użyć tego stanu zamiast wczytywać w każdym komponencie
        z osobna, ale wtedy dalej będzie 'null' po zalogowaniu / wylogowaniu
        trzeba by wtedy odświeżyć stronę, albo podać 'set'a do komponentu
        z logowaniem
    */
    const [user, setUser] = useState();
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                if (user && Object.keys(user).length) {
                    let res;
                    try {
                        res = await isLoggedIn();
                        const loggedIn = res?.loggedIn ?? false;
                        console.log("isLoggedIn", "loggedin:", loggedIn, "res", res);
                        if (loggedIn) {
                            const lang = getLang();
                            if (user.lang !== lang) {
                                user.lang = lang
                                setLangBackend(lang)
                                    .catch(
                                        (err) => {
                                            console.error("setLangBackend", err);
                                        }
                                    )
                                ;
                            }
                            console.log("setUser", user);
                            setUser(user);
                        } else {
                            console.log("isLoggedIn", "logging out locally");
                            try {
                                await sessionService.deleteUser();
                                window.location.reload(false);
                            } catch (err) {}
                        }
                    } catch (err) {
                        console.log("isLoggedIn", err);
                    }
                }
            })(),
        []
    );
    useEffect(() => console.log("App", "user", user), [ user ]);

    return (
        <div>
            <HelmetTemplate
                title={getString("seo_home_title")} 
                desc={getString("seo_home_desc")} 
                ogTitle={getString("seo_home_twitter_title")}
            />
            <GlobalStyle />
            <Router>
                <GaTracker />
                <ScrollToTop />
                <Routes>
                    <Route path="/online-2021" element={<EventCountdown />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    {process.env.NODE_ENV === "production" ? null : (
                    <>
                    <Route path="/selectOfCompetition/:eventid" element={<SelectOfCompetition />} />
                    <Route path="/results/:code" element={<Results />} />
                    <Route path="/results/:code/:competitionid/:roundid" element={<Results />} />
                    <Route
                        path="/results/:code/:competitionid/:roundid/:categoryid"
                        element={<Results />}
                    />
                    </>
                    )}
                    <Route path="/thank-you" element={<ThankYouPage />} />
                    <Route path="/verify-account" element={<VerifyAccount />} />
                    {process.env.NODE_ENV === "production" ? null : (
                    <Route path="/attempt/:eventid/:competitionid/:roundid" element={<Attempt />} />
                    )}
                    <Route path="/404" element={<Page404 />} />
                    <Route path="/admin/:page" element={<AdminPanel />} />
                    <Route path="/admin" element={<AdminPanel />} />
                    {process.env.NODE_ENV === "production" ? null : (
                    <>
                    <Route path="/admin/competitions" element={<AdminTournamentsPage />} />
                    <Route path="/admin/users" element={<AdminUsers />} />
                    <Route path="/admin/users/userid" element={<AdminUser />} />
                    <Route path="/admin/faq" element={<AdminFAQ />} />
                    <Route path="/admin/competitions-1" element={<AdminTournament />} />
                    <Route path="/admin/add-competitions" element={<AddTournament />} />
                    </>
                    )}
                    <Route path="/competitions" element={<Tournaments />} />
                    <Route path="/competition/:code" element={<Tournament />} />
                    <Route path="/competition/:code/:panel" element={<Tournament />} />
                    <Route path="/contact/faq" element={<Contact isFAQPage={true} />} />
                    <Route path="/contact" element={<Contact isFAQPage={false} />} />
                    <Route path="/request-password-reset" element={<ForgottenPassword />} />
                    <Route path="/password-reset" element={<ResetPassword />} />
                    <Route path="/trainings/:panel" element={<Trainings />} />
                    <Route path="/trainings" element={<Trainings />} />
                    <Route path="/gallery/:code" element={<GallerySite />} />
                    <Route path="/gallery" element={<GallerySite />} />
                    <Route path="/my-account/:panel/:subpanel" element={<UserPanel />} />
                    <Route path="/my-account/:panel" element={<UserPanel />} />
                    <Route path="/my-account" element={<UserPanel />} />
                    <Route path="/series/:type/:code" element={<Series />} />
                    <Route path="/series" element={<Series />} />
                    <Route path="/after-transaction/:type/:id" element={<AfterTransaction />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
            <CookiesPopUp />
        </div>
    );
};

export default App;
