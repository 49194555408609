import React, { useState, useEffect } from "react";

import Loader from "../Loader/Loader";
import SummaryElement from "./SummaryElement";

import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";
import { StyledUserPanelSummary } from "../../atoms/UserPanelSummary/StyledUserPanelSummary";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelSummaryHeading } from "../../atoms/UserPanelSummary/StyledUserPanelSummaryHeading";
import { StyledUserPanelSummaryScrollWrapper } from "../../atoms/UserPanelSummary/StyledUserPanelSummaryScrollWrapper";
import { listHoursSummary, listStudents, listSubjects, listTrainers } from "../../../logic/requests/students";

import { getString } from "../../../strings";
import { cebulaCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelSummary = ({ user }) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const [summary, setSummary] = useState();
    const [students, setStudents] = useState();
    const [trainers, setTrainers] = useState();
    const [subjects, setSubjects] = useState();
    useEffect(
        () => {
            listHoursSummary()
                .then(
                    (res) => {
                        console.log("listHoursSummary", res);
                        setSummary(res);
                    }
                )
                .catch(
                    (err) => {
                        console.error("listHoursSummary", err);
                    }
                )
            ;
            listStudents()
                .then(
                    (res) => {
                        console.log("listStudents", res);
                        setStudents(
                            res
                            .sort(
                                (a, b) => (cebulaCompare(`${a.lastname} ${a.firstname}`, `${b.lastname} ${b.firstname}`))
                            )
                        );
                    }
                )
                .catch(
                    (err) => {
                        console.error("listStudents", err);
                    }
                )
            ;
            listTrainers()
                .then(
                    (res) => {
                        console.log("listTrainers", res);
                        setTrainers(
                            res
                            .sort(
                                (a, b) => (cebulaCompare(`${a.lastname} ${a.firstname}`, `${b.lastname} ${b.firstname}`))
                            )
                        );
                    }
                )
                .catch(
                    (err) => {
                        console.error("listTrainers", err);
                    }
                )
            ;
            listSubjects()
                .then(
                    (res) => {
                        console.log("listSubjects", res);
                        setSubjects(
                            res
                            .sort(
                                (a, b) => (cebulaCompare(getString(`subject__${a}`, `subject__${b}`)))
                            )
                        );
                    }
                )
                .catch(
                    (err) => {
                        console.error("listSubjects", err);
                    }
                )
            ;
        }, [ setSummary ]
    );

    return (
        <StyledUserPanelSummary>
            {!(summary && students && trainers && subjects) ? (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            ) : (
                <>
                    <StyledUserPanelSummaryHeading>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString("User_panel_summary_summary")}:
                        </StyledText>
                    </StyledUserPanelSummaryHeading>
                    <StyledUserPanelSummaryScrollWrapper
                        isscrollbar={isScrollBar}
                        ref={scrollElement}
                    >
                      <div>
                        {
                            Object.entries(summary).map(
                                ([id, month]) => (
                                    <SummaryElement
                                        id={id}
                                        month={month}
                                        setMonth={
                                            (val) => setSummary(
                                                (cur) => {
                                                    cur[id] = (typeof val === "function" ? val(cur[id]) : val);
                                                    return {...cur};
                                                }
                                            )
                                        }
                                        students={students}
                                        trainers={trainers}
                                        subjects={subjects}
                                        trigger={trigger}
                                        user={user}
                                    />
                                )
                            )
                        }
                      </div>
                    </StyledUserPanelSummaryScrollWrapper>
                </>
            )}
        </StyledUserPanelSummary>
    );
};

export default UserPanelSummary;
