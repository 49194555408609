import React from "react";

import { getLang } from "../../../strings";

import { StyledHomePageLogo } from "../../atoms/Home/StyledHomePageLogo";
import { StyledImgLogoWrapper } from "./StyledImgLogoWrapper";

import logo from "../../../images/logoSVGEdit.svg";

const HomePageLogo = () => {
    return (
        <StyledHomePageLogo
            initial={{ rotate: -90, x: "-50%", y: "-50%", borderColor: "#6786BE" }}
            animate={{ rotate: 45, borderColor: "#fff" }}
            transition={{
                delay: 0.2,
                duration: 0.2,
                type: "easeInOut",
                borderColor: { delay: 0.6, duration: 0.6 },
            }}
            isang={getLang() === "gb" ? true : false}
        >
            <StyledImgLogoWrapper
                initial={{ rotate: 90, opacity: 0 }}
                animate={{ rotate: -45, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.2, type: "easeInOut" }}
            >
                <img src={logo} alt="cube4fun" />
            </StyledImgLogoWrapper>
        </StyledHomePageLogo>
    );
};

export default HomePageLogo;
