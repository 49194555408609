import React from "react";

import { StyledCubeWrapper } from "../../atoms/LoginCube/StyledCubeWrapper";

import { StyledMinxCube } from "../../atoms/Cubes/StyledMinxCube";

import useWindowSize from "../../../utils/getWindowSize";

const CubeMinx = () => {
    const windowWidth = useWindowSize();
    return (
        <StyledCubeWrapper
            hasdeclaredrightposition={windowWidth < 1600 ? "60px" : "140px"}
            hasdeclaredtopposition={windowWidth < 1600 ? "80px" : "160px"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, rotate: 32 }}
            transition={{ duration: 0.4, delay: 0.2 }}
        >
            <StyledMinxCube
                width={windowWidth >= 2000 ? "290px" : "220px"}
                height={windowWidth >= 2000 ? "290px" : "220px"}
                fill="rgba(0,0,0,0.1)"
            />
        </StyledCubeWrapper>
    );
};

export default CubeMinx;
