import React from "react";
import { motion } from "framer-motion";

import { getString, getLang } from "../../../strings";

import { StyledCard } from "../../molecules/Home/StyledCard";
import { StyledCardBackgroundImage } from "../../atoms/Home/StyledCardBackgroundImage";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledGallerySvg } from "../../atoms/Home/StyledGallerySvg";
import { StyledTournamentLink } from "../../atoms/Home/StyledTournamentLink";

import tournaments from "../../../images/home/gallery1.png";
import mobileGallery from "../../../images/mobile/galeriaMobile.jpg";
import useWindowSize from "../../../utils/getWindowSize";

const HomePageCardGallery = () => {
    const width = useWindowSize();
    return (
        <StyledTournamentLink isang={getLang() === "gb" ? true : false} to="/gallery">
            <StyledCard scaleSvg isang={getLang() === "gb" ? true : false}>
                <StyledCardBackgroundImage src={getLang() === "gb" && width < 1036 ? mobileGallery : width > 767 ? tournaments : mobileGallery} alt="gallery" />
                <motion.div
                    initial={{ rotate: -30 }}
                    animate={{ rotate: 0 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                >
                    <StyledGallerySvg isang={getLang() === "gb" ? true : false}/>
                </motion.div>
                <StyledText
                    hasdeclaredfontsize="72px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hascursor="pointer"
                    hasdeclaredpadding="30px 0 0 0"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                    as="h2"
                >
                    {getString("home_homePageCardGallery_galeria")}
                </StyledText>
            </StyledCard>
        </StyledTournamentLink>
    );
};

export default HomePageCardGallery;
