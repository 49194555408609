import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledCompetitionButton = styled(motion.button)`
    background-color: ${({ bgColor }) =>
        bgColor ? bgColor : 'rgba(196,196,196, 0.27)'};
    color: #000;
    width: 100%;
    height: 40px;
    font-size: 20px;
    border: none;
    cursor: pointer;
`
