import React, { useState, useMemo } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import SummaryEditElement from "./SummaryEditElement";

import { StyledSummaryElement } from "../../atoms/SummaryElement/StyledSummaryElement";
import { StyledContentElement } from "../../atoms/SettlementsDoneElement/StyledContentElement";
import { StyledHeading } from "../../atoms/SettlementsDoneElement/StyledHeading";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledDataWrapper } from "../../atoms/SettlementsDoneElement/StyledDataWrapper";
import { StyledDataHeader } from "../../atoms/SettlementsDoneElement/StyledDataHeader";
import { StyledSumaryData } from "../../atoms/SummaryElement/StyledSumaryData"
import { StyledDataWrapperContent } from "../../atoms/SummaryElement/StyledDataWrapperContent";

import { getLang, getLocale } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const SummaryElement = ({
    id,
    month,
    setMonth,
    students,
    trainers,
    subjects,
    trigger,
    user
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar();

    const isAdmin = useMemo(() => user.roles.includes("admin"), [user]);

    const handleTrigger = () => {
        trigger?.();
        setIsOpen((val) => !val);
    };

    const fixedDate = useMemo(
        () => {
            const stuff = id.split('-');
            stuff[1] = String(parseInt(stuff[1])+1);
            return stuff.join('-');
        }, [id]
    );

    return (
        <StyledSummaryElement>
            <StyledContentElement isopen={isOpen}>
                <StyledHeading onClick={handleTrigger}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786be"
                    >
                        {new Date(fixedDate).toLocaleDateString(getLocale(getLang()), { month: "long", year: "numeric" })}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen && (
                    <StyledDataWrapper summarydata>
                        <ScrollSync>
                            <>
                                <ScrollSyncPane>
                                    <StyledDataHeader ischeckboxes isscrollbar={isScrollBar} ref={scrollElement}>
                                        <div>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    Uczeń
                                                </StyledText>
                                            </StyledSumaryData>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    Data
                                                </StyledText>
                                            </StyledSumaryData>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"                                               
                                                >
                                                    Godzin
                                                </StyledText>
                                            </StyledSumaryData>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    Przedmiot
                                                </StyledText>
                                            </StyledSumaryData>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    Trener
                                                </StyledText>
                                            </StyledSumaryData>
                                            <StyledSumaryData>
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    Cena
                                                </StyledText>
                                            </StyledSumaryData>
                                            {isAdmin ? 
                                                <StyledSumaryData>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        Cena Tr.
                                                    </StyledText>
                                                </StyledSumaryData> 
                                            : null}
                                        </div>
                                    </StyledDataHeader>
                                </ScrollSyncPane>
                                <StyledDataWrapperContent>
                                    {
                                        month.map(
                                            (hour, idx) => (
                                                <SummaryEditElement
                                                    hour={hour}
                                                    setHour={
                                                        (val) => setMonth(
                                                            (cur) => cur.map(
                                                                (v, i) => ((i === idx) ? (typeof val === "function" ? val(v) : val) : (v))
                                                            )
                                                        )
                                                    }
                                                    deleteHour={
                                                        () => setMonth(
                                                            (cur) => cur.filter((_v, i) => (i !== idx))
                                                        )
                                                    }
                                                    students={students}
                                                    trainers={trainers}
                                                    subjects={subjects}
                                                    isAdmin={isAdmin}
                                                />
                                            )
                                        )
                                    }
                                </StyledDataWrapperContent>
                            </>
                        </ScrollSync>
                    </StyledDataWrapper>
                )}
            </StyledContentElement>
        </StyledSummaryElement>
    );
};

export default SummaryElement;
