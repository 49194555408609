import styled from "styled-components"

export const StyledRegulaminWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
    max-width: 700px;
    > div {
        transform: translateX(15px);
    }
`