import styled from "styled-components"

export const StyledUserPanelSubjectDataElement = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid ${({ hasdeclaredbordercolor }) => hasdeclaredbordercolor ? "#ee745c": "#6786BE"};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => isopen ? "70px" : "0"};
    padding-left: 10px;
`

export const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
    width: 100%;
    position: relative;
`

export const StyledHeader = styled.div`
    width: calc(100% - 104px);
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => hasalignself ? hasalignself : "flex-end"};

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: 700px;
        padding-left: 5px;
    }
`

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) => simulatecheckbox ? "41px" : $hasWidth ? $hasWidth : "33%"};
    padding: 0 4px;
`

export const StyledDeleteButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: #ee745c;
    position: absolute;
    bottom: -74px;
    right: -4px;
    border-radius: 9px;
    cursor: pointer;
`