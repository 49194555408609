import styled from "styled-components"

export const StyledAddRoleButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    > button {
        height: 50px;
        width: 48%;
        padding: 0 22px;
    }
`