import styled from "styled-components";
import { ReactComponent as Cube } from "../../../images/cubes/444.svg";

export const StyledCube444 = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    top: 220px;
    left: 20px;
    transform: rotate(-24deg);
    width: 90px;
    height: 90px;
    fill: rgba(0,0,0,0.1);

    @media only screen and (min-width: 1920px) {
        top: 250px;
        width: 130px;
        height: 130px;
    }
`;
