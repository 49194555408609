import styled from "styled-components"

export const StyledPreferencesWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        &:nth-child(2) {
            padding: 0 22px;
            color: #fff;
            border-radius: 20px;
            background-color: ${({ isyes }) => (isyes ? "#ee745c" : "#6786be")};
            cursor: pointer;
            user-select: none;
        }
    }
    
    @media only screen and (max-width: 528px){
        p {
            font-size: 16px;
        }
    }
`;