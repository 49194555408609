import styled from "styled-components"
import { motion } from "framer-motion"

export const StyledChoseImagesWrapper = styled(motion.div)`
    width: 62%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 833px){
        > h2{
            font-size: 32px;
        }
    }
    @media only screen and (max-width: 767px){
        > h2 {
            padding-top: 30px;
        }
    }
`