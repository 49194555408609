import React, { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import {
    StyledAllTrainersPage,
    StyledScrollWrapper,
    StyledElement,
    StyledImageWrapper,
    StyledTextWrapper,
    StyledTextElement,
} from "./StyledAllTrainersPage";
import { StyledText } from "../../atoms/Text/StyledText";
import { getString } from "../../../strings";
import PhoneIcon from "../../atoms/UserPanelStudens/PhoneIcon";

import Loader from "../Loader/Loader";

import { aboutTrainers } from "../../../logic/requests/trainings";
import { StyledLoaderWrapper } from "../../atoms/Tournament/StyledLoaderWrapper";
import EnvelopeIcon from "../../atoms/EnvelopeIcon/EnvelopeIcon";

const AllTrainersPage = () => {
    const scrollElement = useRef(null);
    const [isScrollBar, setIsScrollbar] = useState(false);

    useEffect(() => {
        const element = scrollElement?.current;
        if (element?.clientHeight < element?.scrollHeight) {
            setIsScrollbar(true);
        } else {
            setIsScrollbar(false);
        }
    }, [scrollElement]);

    const { data: trainers, status } = useQuery(["trainers", "public", "extended"], aboutTrainers);

    switch (status) {
        case "loading":
            return (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            );
        case "error":
            return null;
        case "success":
            break;
        default:
            return null;
    }

    return (
        <StyledAllTrainersPage>
            <StyledText
                hasdeclaredpadding="20px 20px 30px 0"
                hasdeclaredfontsize="36px"
                hasdeclaredfontweight="700"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString("User_panel_trainers_trenerzy")}:
            </StyledText>
            <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                {trainers.map(({ id, firstname, lastname, has_avatar, phone, email }) => (
                    <StyledElement $hasAvatar={has_avatar} $hasData={phone || email}>
                        <StyledText
                            as="h3"
                            hasdeclaredfontsize="30px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {firstname} {lastname}
                        </StyledText>
                        {has_avatar ? (
                            <StyledImageWrapper>
                                <img src={`/api/trainings/trainers/avatar/${id}`} alt="avatar" />
                            </StyledImageWrapper>
                        ) : null}
                        {phone || email ? (
                            <StyledTextWrapper>
                                {phone ? (
                                    <StyledTextElement $hasAvatar={has_avatar} $isFirst>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredlineheight="1.3em"
                                            hasdeclaredfontcolor="#6786be"
                                        >
                                            {getString("User_panel_trainers_telefon")}:
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.3em"
                                        >
                                            <a href={`tel:${phone}`}>{phone}</a>
                                        </StyledText>{" "}
                                        <span>
                                            <PhoneIcon />
                                        </span>
                                    </StyledTextElement>
                                ) : null}
                                {email ? (
                                    <StyledTextElement $hasAvatar={has_avatar}>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredlineheight="1.3em"
                                            hasdeclaredfontcolor="#6786be"
                                        >
                                            {getString("User_panel_trainers_email")}:
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.3em"
                                        >
                                            <a href={`mailito:${email}`}>{email}</a>{" "}
                                            <span>
                                                <EnvelopeIcon />
                                            </span>
                                        </StyledText>
                                    </StyledTextElement>
                                ) : null}
                            </StyledTextWrapper>
                        ) : null}
                    </StyledElement>
                ))}
            </StyledScrollWrapper>
        </StyledAllTrainersPage>
    );
};

export default AllTrainersPage;
