import styled from "styled-components";

export const StyledPlayerElementDataWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-right: 20px;
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : "auto")};
    > a {
        text-decoration: none;
        color: #000;
        cursor: pointer;
    }
    @media only screen and (max-width: 767px) {
        padding-right: 0;
    }
    @media only screen and (max-width: 439px) {
        a {
            p {
                font-size: 14px;
            }
        }
    }
`;
