import React, { useMemo, useCallback, useState, useEffect } from "react";

import UserPanelRoleElement from "./UserPanelRoleElement";

import { StyledUserPanelAddRoleComponent } from "../../atoms/UserPanelAddRole/StyledUserPanelAddRoleComponent";
import { StyledAddRoleButtons } from "../../atoms/UserPanelAddRole/StyledAddRoleButtons";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledSelectsWrapper } from "../../atoms/UserPanelAddRole/StyledSelectsWrapper";
import { StyledSelect } from "../../atoms/UserPanelAddRole/StyledSelect";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUSerPanelRoleElements } from "../../atoms/UserPanelAddRole/StyledUSerPanelRoleElements";
import { StyledSuccesMessage } from "../../atoms/TrackHours/StyledSuccesMessage";
import { StyledInfo } from "../../atoms/TrackHours/StyledInfo";
import { StyledRowInfo } from "../../atoms/TrackHours/StyledRowInfo";

import { getString } from "../../../strings";
import { useRef } from "react";
import { assignRole } from "../../../logic/requests/students";
import { cebulaCompare } from "../../../logic/arrays";

const UserPanelAddRoleComponent = ({
    users,
    setUsers,
    roles,
    trigger
}) => {
    const rolesWithUsers = useMemo(
        () => (
            users.reduce(
                (prev, cur) => {
                    cur.roles.forEach(
                        (role) => {
                            if (!prev[role])
                                prev[role] = [];

                            prev[role].push(cur);
                        }
                    );
                    return prev;
                }, {}
            )
        ), [users]
    );

    const [submitSuccess, setSubmitSuccess] = useState();
    useEffect(
        () => {
            if (submitSuccess) {
                const timeout = setTimeout(() => setSubmitSuccess(undefined), 3000);
                return () => clearTimeout(timeout);
            }
        }, [submitSuccess, setSubmitSuccess]
    );

    const formValues = {
        user: useRef(),
        role: useRef()
    };
    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();

            const user_id = parseInt(formValues.user.current?.value);
            const role_id = formValues.role.current?.value;
            const user = users.find(user => user.id === user_id);

            if (
                !user ||
                !roles.some((role) => role === role_id)
            ) {
                console.log("handleSubmit", "data validation failed", user_id, users, role_id, roles);
                return;
            }

            if (user.roles.includes(role_id)) {
                e.target.reset();
                return;
            }

            assignRole(user_id, role_id)
            .then(
                (res) => {
                    console.log("assignRole", res);
                    setUsers(
                        (users) => (
                            [
                                ...users.filter(
                                    user => user.id !== user_id
                                ),
                                {
                                    ...user,
                                    roles: [
                                        ...user.roles,
                                        role_id
                                    ]
                                }
                            ]
                            .sort(
                                (a, b) => cebulaCompare(a.name, b.name)
                            )
                        )
                    );
                    e.target.reset();
                    setSubmitSuccess({
                        who: user.name,
                        email: user.email,
                        role: getString(`roles_${role_id}`)
                    });
                }
            )
            .catch(
                (err) => {
                    console.error("assignRole", err);
                }
            )
            ;
        }, [users, formValues]
    );
    return (
        <StyledUserPanelAddRoleComponent onSubmit={handleSubmit}>
            <form onSubmit={handleSubmit}>
                <StyledSelectsWrapper>
                    <div>
                        <label>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString("UserPanelTrainerAddRoleComponentUser")}:
                            </StyledText>
                        </label>
                        <StyledSelect type="select" ref={formValues.user}>
                            <option value="">- {getString("UserPanelAddRole__form__select_user")} -</option>
                            {
                                users.map(
                                    (user) => (
                                        <option
                                            key={user.id}
                                            value={user.id}
                                        >{user.lastname} {user.firstname} ({user.email})</option>
                                    )
                                )
                            }
                        </StyledSelect>
                        {/*
                        <StyledText
                            hasdeclaredfontsize="14px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredlineheight="1.2em"
                        >
                            error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
                        </StyledText>
                        */}
                    </div>
                    <div>
                        <label>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString("UserPanelTrainerAddRoleComponentRole")}:
                            </StyledText>
                        </label>
                        <StyledSelect type="select" ref={formValues.role} defaultValue="student">
                            {
                                roles.map(
                                    (role) => (
                                        <option
                                            key={role}
                                            value={role}
                                        >{getString(`roles_${role}`)}</option>
                                    )
                                )
                            }
                        </StyledSelect>
                        {/*
                        <StyledText
                            hasdeclaredfontsize="14px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredlineheight="1.2em"
                        >
                            error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
                        </StyledText>
                        */}
                    </div>
                </StyledSelectsWrapper>
                <StyledAddRoleButtons>
                    <StyledButton
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="22px"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredmargin="20px 0 0 0"
                        hasdeclaredbgcolor="#6786be"
                        type="submit"
                    >
                        {getString("UserPanelTrainerAddRoleComponentSave")}
                    </StyledButton>
                    <StyledButton
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="22px"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredmargin="20px 0 0 0"
                        hasdeclaredbgcolor="#ee745c"
                        type="reset"
                    >
                        {getString("UserPanelTrainerAddRoleComponentCancel")}
                    </StyledButton>
                </StyledAddRoleButtons>
            </form>
            {
                (submitSuccess) ? (
                    <StyledSuccesMessage>
                        <StyledText
                            hasdeclaredfontcolor="#6AB95D"
                            hasdeclaredpadding="0 0 20px"
                            hasdeclaredfontsize="28px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtexttransform="uppercase"
                            as="p"
                        >
                            {getString("UserPanelAddRole__form_submit__succ")}
                        </StyledText>
                        <StyledInfo>
                            <StyledRowInfo>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredfontcolor="#6786be"
                                    >
                                        {getString("UserPanelTrainerTrackHours__form_submit_after__for_who")}:
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                    >
                                        {submitSuccess.who}
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredfontcolor="#6786be"
                                    >
                                        {getString("UserPanelAddRole__form_submit__email")}:
                                    </StyledText>	
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                    >
                                        {submitSuccess.email}
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredfontcolor="#6786be"
                                    >
                                        {getString("UserPanelAddRole__form_submit__role")}:
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                    >
                                        {submitSuccess.role}
                                    </StyledText>
                                </div>
                            </StyledRowInfo>
                        </StyledInfo>
                    </StyledSuccesMessage>
                ) : (undefined)
            }
            <StyledText
                hasdeclaredpadding="50px 20px 5px 0"
                hasdeclaredfontsize="36px"
                hasdeclaredfontweight="700"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString("UserPanelTrainerAddRoleComponentList")}:
            </StyledText>
            <StyledUSerPanelRoleElements>
                {
                    Object.entries(rolesWithUsers).map(
                        ([role, users]) => (
                            <UserPanelRoleElement
                                key={role}
                                role={role}
                                users={users}
                                setUsers={setUsers}
                                trigger={trigger}
                            />
                        )
                    )
                }
            </StyledUSerPanelRoleElements>
        </StyledUserPanelAddRoleComponent>
    );
};

export default UserPanelAddRoleComponent;
