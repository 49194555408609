import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import { getString } from "../../../strings";
import SeriesResultsIcon from "../../molecules/Series/SeriesResultsIcon";

import { StyledSeriesResultsElement } from "../../atoms/Series/StyledSeriesResultsElement";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledSeriesResultsElementHeadingResults } from "../../molecules/Series/StyledSeriesResultsElementHeadingResults";
import { StyledNumberWrapper } from "../../molecules/Series/StyledNumberWrapper";
import { StyledNameWrapper } from "../../molecules/Series/StyledNameWrapper";
import { StyledSumWrapper } from "../../molecules/Series/StyledSumWrapper";
import { StyledContentWrapper } from "../../atoms/Series/StyledContentWrapper";

import { romanize } from "../../constants/romanize";

import { ACTIVERESULTSTYPE } from "../../constants/Series";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const SeriesResultsElementHeading = ({ activeResults, results }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return(
        <StyledSeriesResultsElement>
            <StyledNumberWrapper></StyledNumberWrapper>
            <StyledContentWrapper>
                <StyledNameWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                    >
                        {getString("series_seriesResultsElementHeading_zawodnik")}
                    </StyledText>
                </StyledNameWrapper>
                <ScrollSyncPane>
                    <StyledSeriesResultsElementHeadingResults isscrollbar={isScrollBar} isheading={true} ref={scrollElement}>
                        {
                            (() => {
                                switch (activeResults.type) {
                                    case ACTIVERESULTSTYPE.SERIES:
                                        return (
                                            results.map(
                                                result => (
                                                    <StyledText
                                                        key={result}
                                                        hasdeclaredfontsize="18px"
                                                        hasdeclaredfontcolor="#000"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                    >
                                                        {romanize(result)}
                                                    </StyledText>
                                                )
                                            )
                                        );
                                    case ACTIVERESULTSTYPE.EVENT:
                                        return (
                                            results.concat(["bonus"]).map(
                                                result => (
                                                    result === "bonus" ? (
                                                        <StyledText
                                                            key={result}
                                                            hasdeclaredfontsize="18px"
                                                            hasdeclaredfontcolor="#000"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                        >
                                                            {getString("series_seriesResultsElementHeading_bonus")}
                                                        </StyledText>
                                                    ) : (
                                                        <SeriesResultsIcon key={result} iconName={result} />
                                                    )
                                                )
                                            )
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </StyledSeriesResultsElementHeadingResults>
                </ScrollSyncPane>
                <StyledSumWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                    >
                        {getString("series_seriesResultsElementHeading_sumaPunktow")}
                    </StyledText>
                </StyledSumWrapper>
            </StyledContentWrapper>
        </StyledSeriesResultsElement>
    )
};

export default SeriesResultsElementHeading;
