import styled from "styled-components";

export const StyledInputWrapper = styled.div`
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : "50%")};
    display: ${({ $isCenter }) => ($isCenter ? "flex" : "block")};
    align-items: ${({ $isCenter }) => ($isCenter ? "center" : "flex-start")};

    input {
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786be")};
        width: 100%;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        ${({ disabled }) => (!disabled ? "cursor: pointer;" : "")}
        background: #fff;
        text-align: center;
    }

    select {
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786be")};
        width: 100%;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        cursor: pointer;
        background: #fff;
        text-align: center;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: "Poppins";
            text-align: center;
        }
    }

    > h4 {
        display: ${({ error }) => (error ? "block" : "none")};
    }

    @media only screen and (max-width: 1072px) {
        label {
            p {
                font-size: 14px;
            }
        }
    }

    @media only screen and (max-width: 926px) {
        label {
            p {
                font-size: 20px;
            }
        }
    }

    textarea {
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786be")};
        width: 100%;
        height: 200px;
        border-radius: 25px;
        font-size: 16px;
        padding: 12px;
        font-family: "Poppins";
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        background: #fff;
        resize: none;
    }

    @media only screen and (max-width: 600px) {
        width: ${({ $switchLang }) => ($switchLang ? null : "100%")};
    }
`;
