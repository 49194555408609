import styled from 'styled-components'

export const StyledCompetitionMenu = styled.div`
    width: 300px;
    text-align: center;
    @media only screen and (max-width: 992px) {
        width: 150px;
    }
    @media only screen and (max-width: 400px) {
        width: 100px;
    }
    @media only screen and (max-width: 370px) {
        width: 80px;
    }
`
