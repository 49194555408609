import styled from "styled-components"

export const StyledSaveForm = styled.div`
    background-color: #5FEE5C;
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    bottom: -4px;
    right: -4px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`