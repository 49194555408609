import styled from "styled-components"

import smallclock1 from "../../../images/loader/clock1.svg"
import smallclock2 from "../../../images/loader/clock2.svg"
import smallclock3 from "../../../images/loader/clock3.svg"
import smallclock4 from "../../../images/loader/clock4.svg"

export const StyledSmallLoader = styled.div`
    width: ${({ hasdeclaredwidth }) => hasdeclaredwidth ? hasdeclaredwidth : "120px"};
    height: ${({ hasdeclaredheight }) => hasdeclaredheight ? hasdeclaredheight : "120px"};
    border-radius: 50%;
    animation-name: loaderSmallAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    
    @keyframes loaderSmallAnimation {
        0%{
            background: url(${smallclock1});
        }
        25%{
            background: url(${smallclock2});
        }
        50%{
            background: url(${smallclock3});
        }
        75%{
            background: url(${smallclock4});
        }
        100%{
            background: url(${smallclock1});
        }
    }
`