import styled from "styled-components"

export const StyledPayColoredWrapper = styled.div`
    width: ${({ showparentcheckboxes }) => showparentcheckboxes ? "calc(100% - 54px)" : "100%"};
    margin-bottom: 20px;
    border-radius: 25px 9px 9px 25px;
    min-height: ${({ isopen }) => isopen ? "unset" : "43px"};
    border: 4px solid ${({ hasdeclaredbordercolor }) => hasdeclaredbordercolor ? "#ee745c": "#6786BE"};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => isopen ? "20px" : "0"};
    padding-left: 10px;
`