import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

import "moment/locale/pl";
import "moment/locale/en-gb";
// co to za nazwy?
// czeski
import "moment/locale/cs";
// ukraiński
import "moment/locale/uk";

import { getLang, getString } from "../strings";

const momentLangs = {
    "pl": "pl",
    "gb": "en-gb",
    "cz": "cs",
    "ua": "uk"
};

const useEventRegisterCountdown = (event, setEventRegisterStatus = null) => {
	const register_time_start = useMemo(
            () => (
                event.register_time_start ? (
                    new Date(event.register_time_start)
                ) : (
                    null
                )
            ),
            [ event ]
        ),
        register_time_end = useMemo(
            () => (
                event.register_time_end ? (
                    new Date(event.register_time_end)
                ) : (
                    null
                )
            ),
            [ event ]
        );

    const getRegisterStartText = useCallback(() => moment(register_time_start).locale(momentLangs[getLang()]).fromNow(), [ register_time_start ]);
    const [registerStartText, setRegisterStartText] = useState(getRegisterStartText());
    const getRegisterEndText = useCallback(() => moment(register_time_end).locale(momentLangs[getLang()]).fromNow(), [ register_time_end ]);
    const [registerEndText, setRegisterEndText] = useState(getRegisterEndText());
    const [registerTextUpdate, setRegisterTextUpdate] = useState(Date.now());

    useEffect(
        () => {
            console.log("useEventRegisterCountdown: registerTextUpdate");
            setRegisterStartText(getRegisterStartText());
            setRegisterEndText(getRegisterEndText());
            if (setEventRegisterStatus) {
                if (moment(register_time_end).isSameOrBefore()) {
                    // if'y potrzebne - aby zapobiec nieskończonej pętli wywoływania hook'ów
                    if (event.register_status === "open")
                        setEventRegisterStatus("closed");
                } else if (moment(register_time_start).isSameOrBefore()) {
                    if (event.register_status === "upcoming")
                        setEventRegisterStatus("open");
                }
            }
            setTimeout(() => setRegisterTextUpdate(Date.now()), 60000);
        },
        [
            registerTextUpdate,
            setRegisterTextUpdate,
            getRegisterStartText,
            setRegisterStartText,
            getRegisterEndText,
            setRegisterEndText,
            event,
            setEventRegisterStatus,
            register_time_start,
            register_time_end
        ]
    );

	const registerText = useMemo(
		() => (
			register_time_start && register_time_end ? (
				registerTextUpdate > register_time_end ? (
					getString("tournament_tournamentElement_zakonczona")
				) : (
					registerTextUpdate > register_time_start ? (
						registerEndText
					) : (
						registerStartText
					)
				)
			) : (
				event?.register_status === "upcoming" ? (
					getString("tournament_tournamentElement_informacjeWkrotce")
				) : (
					getString("tournament_tournamentElement_brakDanych")
				)
			)
		),
		[
			register_time_start,
			register_time_end,
			registerTextUpdate,
			registerStartText,
			registerEndText,
			event
		]
	);

	return registerText;
};

export default useEventRegisterCountdown;
