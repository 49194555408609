import styled from "styled-components"

export const StyledPartnerElementImage = styled.div`
    width: 75%;
    max-height: 250px;
    border: 15px solid #6786be;
    background-color: #fff;
    border-radius: 75px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media only screen and (max-width: 1100px) {
        border: 8px solid #6786be;
        border-radius: 40px;
    }
    @media only screen and (max-width: 767px) {
        width: 90%;
        max-height: unset;
        padding: 0;
        > img {
            padding: 20px;
        }
    }
`;
