import styled from "styled-components"

export const StyledAnswerWrapper = styled.div`
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;

    > p {
        padding: 10px;
        > a {
            color: #6786be;
            text-decoration: none;
        }
    }

    @media only screen and (max-width: 520px) {
        width: 98%;
    }
`;