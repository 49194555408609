import styled from "styled-components"

export const StyledTextAreaWrapper = styled.div`
    > textarea {
        border-radius: 25px;
        resize: none;
        height: 260px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#3474E8")};
        outline: none;
        width: 80%;
        margin-top: 12px;
        font-size: 20px;
        font-family: "Poppins";
        padding: 18px;
    }

    @media only screen and (max-width: 450px){
        > textarea{
            width: 100%;
        }
    }
`;