import styled from "styled-components"

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    @media only screen and (max-width: 926px){
        padding: 0 20px;
        h2 {
            font-size: 32px;
            padding: 40px 0 10px;
        }
    }
    @media only screen and (max-width: 350px){
        h2 {
            font-size: 28px;
        }
    }
`