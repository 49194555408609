import Decimal from "decimal.js";
import React, { useEffect, useMemo } from "react";
import { useToggle } from "react-use";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledHeading } from "../../atoms/PayElement/StyledHeading";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledDataSum } from "../../atoms/SettlementsDoneDataElement/StyledDataSum";
import { StyledCheckboxDone } from "../../atoms/SettlementsDoneElement/StyledCheckboxDone";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledAllPayTrainer } from "../../atoms/UserPanelAllTrainerPay/StyledAllPayTrainer";
import { StyledPayAllElementHeader } from "../../atoms/UserPanelAllTrainerPay/StyledPayAllElementHeader";
import { StyledScrolContentPayAll } from "../../atoms/UserPanelAllTrainerPay/StyledScrolContentPayAll";
import { StyledScrollHeaderPayAll } from "../../atoms/UserPanelAllTrainerPay/StyledScrollHeaderPayAll";
import { StyledButtonsWrapper } from "../../atoms/UserPanelParent/StyledButtonsWrapper";
import { StyledFlexBoxWrapper } from "../../atoms/UserPanelTrainerPay/StyledFlexBoxWrapper";
import { StyledPayAllElement } from "../../atoms/UserPanelTrainerPay/StyledPayAllElement";
import { StyledPayColoredWrapper } from "../../atoms/UserPanelTrainerPay/StyledPayColoredWrapper";

import { ReactComponent as CheckboxAccept } from "../../../images/checkboxAccept.svg";
import { getLang, getString } from "../../../strings";
import { taxPercent } from "../../constants/pay";
import { cebulaCompare } from "../../../logic/arrays";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const OpenElement = ({ defaultOpen, triggerParentScroll, keyElement, val }) => {
    const [showCheckboxes, toggleShowCheckboxes] = useToggle(false);

    const [isOpen, toggleIsOpen] = useToggle(defaultOpen ?? false);

    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);
    useEffect(() => trigger?.(), [trigger, isOpen]);
    useEffect(() => triggerParentScroll?.(), [triggerParentScroll, isOpen]);

    const revenue = useMemo(
        () => (
            (val && Object.keys(val).length) ? (
                Object.values(val)
                .map(
                    obj => obj.price
                )
                .reduce(
                    (prev, cur) => (prev.plus(cur)), new Decimal(0)
                )
            ) : (undefined)
        ), [val]
    );
    const trainerPay = useMemo(
        () => (
            (val && Object.keys(val).length) ? (
                Object.values(val)
                .reduce(
                    (prev, cur) => (prev.plus(cur.pay)), new Decimal(0)
                )
            ) : (undefined)
        ), [val]
    );
    const income = useMemo(
        () => (
            (revenue && trainerPay) ? (
                revenue.minus(trainerPay)
            ) : (undefined)
        ), [revenue, trainerPay]
    );
    const hours = useMemo(
        () => (
            (val && Object.keys(val).length) ? (
                Object.values(val)
                .map(
                    obj => obj.half ? "0.5" : obj.hours
                )
                .reduce(
                    (prev, cur) => (prev.plus(cur)), new Decimal(0)
                )
            ) : (undefined)
        ), [val]
    );
    const incomeAfterTaxes = useMemo(
        () => (
            (income) ? (
                income.times(
                    new Decimal(1).minus(new Decimal(taxPercent).dividedBy(100))
                )
            ) : (undefined)
        ), [income]
    );
    
    return (
        <div>
            <StyledPayColoredWrapper isopen={isOpen}>
                <StyledHeading onClick={toggleIsOpen}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {(() => {
                            const [year, month] = keyElement.split("-"),
                                prettyMonth = new Date(year, month - 1).toLocaleString(getLang(), {
                                    month: "long",
                                });

                            return `${prettyMonth} ${year}`.toLocaleUpperCase();
                        })()}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen && (
                    <StyledPayAllElement>
                        <ScrollSync>
                            <>
                                <StyledFlexBoxWrapper calcwidth={showCheckboxes}>
                                    <ScrollSyncPane>
                                        <StyledScrollHeaderPayAll
                                            isscrollbar={isScrollBar}
                                            ref={scrollElement}
                                            calcwidth={showCheckboxes}
                                        >
                                            <div>
                                                <StyledPayAllElementHeader>
                                                    <div>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredlineheight="1.4em"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="0 4px 2px 4px"
                                                        >
                                                            {getString(
                                                                "Trainings_trainings_all_trainer_pay_trener"
                                                            )}
                                                        </StyledText>
                                                    </div>
                                                    <div>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredlineheight="1.4em"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="0 4px 2px 4px"
                                                        >
                                                            {getString(
                                                                "Trainings_trainings_all_trainer_pay_godziny"
                                                            )}
                                                        </StyledText>
                                                    </div>
                                                    <div>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredlineheight="1.4em"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="0 4px 2px 4px"
                                                        >
                                                            {getString(
                                                                "Trainings_trainings_all_trainer_pay_wyplata"
                                                            )}
                                                        </StyledText>
                                                    </div>
                                                    <div>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredlineheight="1.4em"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="0 4px 2px 4px"
                                                        >
                                                            {getString(
                                                                "Trainings_trainings_all_trainer_pay_dochod"
                                                            )}
                                                        </StyledText>
                                                    </div>
                                                    <div>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredlineheight="1.4em"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="0 4px 2px 4px"
                                                        >
                                                            {getString(
                                                                "Trainings_trainings_all_trainer_pay_przychod"
                                                            )}
                                                        </StyledText>
                                                    </div>
                                                </StyledPayAllElementHeader>
                                            </div>
                                        </StyledScrollHeaderPayAll>
                                    </ScrollSyncPane>
                                </StyledFlexBoxWrapper>
                                {Object.values(val).sort(
                                    (a, b) => {
                                        const lastname = cebulaCompare(a.lastname, b.lastname);
                                        if (lastname)
                                            return lastname;

                                        const firstname = cebulaCompare(a.firstname, b.firstname);
                                        if (firstname)
                                            return firstname;

                                        return 0;
                                    }
                                ).map((trainer) => (
                                    <StyledFlexBoxWrapper>
                                        {showCheckboxes ? (
                                            <StyledCheckboxDone>
                                                <input type="checkbox" id="elo" />
                                                <label htmlFor="elo">
                                                    <CheckboxAccept />
                                                </label>
                                            </StyledCheckboxDone>
                                        ) : undefined}
                                        <ScrollSyncPane>
                                            <StyledScrolContentPayAll>
                                                <div>
                                                    <StyledAllPayTrainer>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {trainer.name}
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {trainer.hours}
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.pay).toFixed(2)} PLN
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.price).minus(trainer.pay).toFixed(2)} PLN
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.price).toFixed(2)} PLN
                                                            </StyledText>
                                                        </div>
                                                    </StyledAllPayTrainer>
                                                </div>
                                            </StyledScrolContentPayAll>
                                        </ScrollSyncPane>
                                    </StyledFlexBoxWrapper>
                                ))}
                            </>
                        </ScrollSync>
                        <StyledButtonsWrapper $centerSingleBtn={showCheckboxes} bottomsize>
                            {
                                (showCheckboxes) ? (
                                    <>
                                    <StyledButton
                                        hasdeclaredbgcolor="#6786BE"
                                        hasdeclaredpadding="0 28px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtexttransform="uppercase"
                                    >
                                        {getString("open_elment_wyplac")}
                                    </StyledButton>
                                    <StyledButton
                                        hasdeclaredbgcolor="#EE745C"
                                        hasdeclaredpadding="0 28px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtexttransform="uppercase"
                                        onClick={toggleShowCheckboxes}
                                    >
                                        {getString("open_element_anuluj")}
                                    </StyledButton>
                                    </>
                                ) : (
                                    <StyledButton
                                        hasdeclaredbgcolor="#EE745C"
                                        hasdeclaredpadding="0 28px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredmargin="0 auto"
                                        onClick={toggleShowCheckboxes}
                                    >
                                        {getString("open_elment_wybierz_i_wyplac")}
                                    </StyledButton>
                                )
                            }
                        </StyledButtonsWrapper>
                        <StyledDataSum>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                    hasdeclaredtexttransform="uppercase"
                                >
                                    {getString("open_element_przychod")}: <span style={{ color: "#000" }}>{revenue.dp() > 0 ? revenue.toFixed(2) : revenue.toString()} PLN</span>
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                    hasdeclaredtexttransform="uppercase"
                                >
                                    {getString("open_element_godziny")}: <span style={{ color: "#000" }}>{hours.toString()}</span>
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                    hasdeclaredtexttransform="uppercase"
                                >
                                    {getString("open_element_dochód")}: <span style={{ color: "#000" }}>{income.dp() > 0 ? income.toFixed(2) : income.toString()} ({incomeAfterTaxes.dp() > 0 ? incomeAfterTaxes.toFixed(2) : incomeAfterTaxes.toString()}) PLN</span>
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                    hasdeclaredtexttransform="uppercase"
                                >
                                    {getString("open_element_wypłaty")}: <span style={{ color: "#000" }}>{trainerPay.dp() > 0 ? trainerPay.toFixed(2) : trainerPay.toString()} PLN</span>
                                </StyledText>
                            </div>
                        </StyledDataSum>
                    </StyledPayAllElement>
                )}
            </StyledPayColoredWrapper>
        </div>
    );
};

export default OpenElement;
