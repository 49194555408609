import styled from "styled-components"

export const StyledSumRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${({ iscentertext }) => iscentertext ? "center" : "flex-start"};
    align-items: center;
    
    @media only screen and (max-width: 926px){
        justify-content: center;
    }
`