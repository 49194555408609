import styled from "styled-components"

export const StyledUserPanelAdditionElement = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid ${({ hasdeclaredbordercolor }) => hasdeclaredbordercolor ? "#ee745c": "#6786BE"};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => isopen ? "20px" : "0"};
    padding-left: 10px;
`

export const StyledHeader = styled.div`
    width: calc(100% - 104px);
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => hasalignself ? hasalignself : "flex-end"};

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};

    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: 700px;
    }
`

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) => simulatecheckbox ? "41px" : $hasWidth ? $hasWidth : "25%"};
    padding: 0 4px;
`