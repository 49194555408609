import styled from "styled-components"

export const StyledTrainingsWrapper = styled.section`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;

    @media only screen and (max-width: 767px){
        flex-direction: column;
        padding-top: 130px;
        height: auto;
    }
    @media only screen and (max-width: 363px){
        padding-top: 100px;
    }
`