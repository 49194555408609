import styled from "styled-components";

export const StyledMenuItem = styled.div`
    width: 100%;
    background-color: #6b94e1;
    cursor: pointer;
    overflow: hidden;
    -moz-overflow: hidden;
    @media only screen and (max-width: 400px) {
        p {
            font-size: 12px;
        }
    }
`;
