import styled from "styled-components"

export const StyledUserPanelTournamentResults = styled.div`
    margin-top: 22px;
    width: 100%;
    > div {
        width: 100%;
        &:first-child{
            width: 100%;
            overflow: auto;
            padding-bottom: 10px;
            cursor: pointer;
            scrollbar-color: #6786be rgba(103, 134, 190, 0.3);
            scrollbar-width: thin;
            
            ::-webkit-scrollbar {
                height: 6px;
            }
            ::-webkit-scrollbar-track {
                border-radius: 6px;
                background: rgba(103, 134, 190, 0.3);
            }
            ::-webkit-scrollbar-thumb {
                background: #6786be;
                border-radius: 6px;
            }
        }
    }
`