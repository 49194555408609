import styled from "styled-components"

export const StyledColumn = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 1240px){
        width: 100%;
    }

    @media only screen and (max-width: 528px){
        p {
            font-size: 16px;
        }
    }
`;