import React from "react";
import CheckboxRegisterInput from "./CheckboxRegisterInput";
import { StyledRegisterCompetitions } from "../../atoms/Register/StyledRegisterCompetitions";
import CompetitionElement from "../CompetitionElement/CompetitionElement";

import allCompetitions from "../../constants/competitions.json";

const RegisterCompetitions = ({ competitions, initialValues, bordersize, setFieldValue }) => (
    <StyledRegisterCompetitions bordersize={bordersize}>
        {
            (
                (competitions === "all") ? (
                    allCompetitions
                ) : (
                    competitions
                )
            ).map(
                competition => (
                    <CheckboxRegisterInput
                        key={competition}
                        competition={competition}
                        initialChecked={
                            (initialValues && initialValues[competition]) ? (
                                initialValues[competition]
                            ) : (
                                false
                            )
                        }
                        setFieldValue={
                            (setFieldValue) ? (
                                (val, dirty) => setFieldValue(competition, val, { dirty })
                            ) : (
                                () => {}
                            )
                        }
                    >
                        <CompetitionElement competition={competition} />
                    </CheckboxRegisterInput>
                )
            )
        }
    </StyledRegisterCompetitions>
);

export default RegisterCompetitions;
