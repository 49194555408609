import styled from "styled-components";

export const StyledTextWrapper = styled.div`
    background-color: #fff;
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : "0")};
    border-radius: 50px;
    width: 370px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1074px){
        width: 330px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        h3,
        p{
            font-size: 22px;
        }
    }
    @media only screen and (max-width: 437px) {
        padding: 8px 24px;
        width: 300px;
        h3,
        p {
            font-size: 22px;
        }
    }
    @media only screen and (max-width: 316px){
        width: 260px;
        height: 40px;
        h3,
        p{
            font-size: 14px;
        }
    }
`;
