import styled from "styled-components";

import { ReactComponent as ButtonSvg } from "../../../images/LoginPage/arrow.svg";

export const StyledButtonSvg = styled(({ isrotate, ...props }) => <ButtonSvg {...props} />)`
    height: 40px;
    width: 100%;
    transform: ${({ isrotate }) => (isrotate ? "rotate(-180deg)" : "none")};
    display: flex;
    align-items: center;
    justify-content: center;
`;
