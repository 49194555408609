const getCookie = (name) => (
	document?.cookie
	?.split("; ")
	?.find(
		(cookie) => (cookie?.split("=")?.[0] === name)
	)
	?.split("=")?.[1]
);

const setCookie = (name, val) => (
	document.cookie = `${name}=${val};expires=${new Date(2147483647 * 1000).toUTCString()};path=/`
);

export { getCookie, setCookie };
