import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { sessionService } from "redux-react-session";
import AdminTemplate from "../templates/AdminTemplate";
import AdminTournaments from "../organisms/AdminTournaments/AdminTournaments";
import AdminLessons from "../organisms/AdminLessons/AdminLessons";
import Loader from "../organisms/Loader/Loader";

const AdminPanel = () => {
    const [user, setUser] = useState(undefined);
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const navigate = useNavigate();

    useEffect(
        () => {
            if (user === undefined)
                return;
            
            if (!user || !user.roles.includes("admin")) {
                navigate("/");
            }
        },
        [ user ]
    )

    const params = useParams();

    return (
        <AdminTemplate>
            {
                (user === undefined) ? (
                    <Loader />
                ) : (
                    (() => {
                        switch (params.page) {
                            case undefined:
                                return (
                                    // TODO: zaimplementować (style?)
                                    <div>
                                        Admin - strona główna
                                    </div>
                                );
                            case "lessons":
                                return (
                                    <AdminLessons />
                                );
                            case "events":
                                // TODO: zaimplementować, usunąć następną linię
                                if (false)
                                return (
                                    <AdminTournaments />
                                )
                            default:
                                return (
                                    <Navigate to={"/admin"} />
                                )
                        }
                    })()
                )
            }
        </AdminTemplate>
    );
};

export default AdminPanel;
