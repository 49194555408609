import styled from "styled-components"

export const StyledSelect = styled.select`
    border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
    width: 80%;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
    padding: 0 12px;
    font-family: "Poppins";
    outline: none;
    transition: outline 250ms;
    cursor: pointer;
    background: #fff;
    text-align: center;

    > option {
        font-size: 16px;
        padding: 0 12px;
        font-family: "Poppins";
    }

    @media only screen and (max-width: 926px){
        width: 100%;
    }
`