import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledLeftPanel = styled(motion.div)`
    width: 60%;
    min-width: 60%;
    margin-right: 10%;
    margin-bottom: 20px;
    height: 80vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px 20px 10px;
    position: relative;
    z-index: 1;

    > div {
        width: 100%;
        height: 100%;
        overflow-y: ${({ hasscroll }) => hasscroll ? 'visible' : 'scroll'};

        scrollbar-color: ${({ isscrollbar }) =>
            isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
        scrollbar-width: ${({ isscrollbar }) => (isscrollbar ? "thin" : "none")};
        
        ::-webkit-scrollbar {
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 6px;
            background: ${({ isscrollbar }) =>
                isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
        }

        ::-webkit-scrollbar-thumb {
            background: #6786be;
            border-radius: 6px;
        }
    }

    @media only screen and (max-width: 1621px){
        width: 55%;
        min-width: 55%;
    }

    @media only screen and (max-width: 1334px) {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    @media only screen and (max-width: 1150px){
       margin-right: 0;
    }

    @media only screen and (max-width: 1114px) {
        width: 58%;
        min-width: 58%;
    }

    @media only screen and (max-width: 990px) {
        width: 55%;
        min-width: 55%;
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
        overflow-y: visible;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 0;
        height: auto;
        margin-right: 0;
        padding-right: 0;
    }
`;
