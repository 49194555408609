import styled from "styled-components"

export const StyledGlobalHeadingWrapper = styled.div`
    margin: 20px 0 0;

    @media only screen and (max-width: 926px){
        margin-top: 40px;
        padding: 0 20px;
        
        > h2 {
            font-size: 30px;

        }
    }
`