import styled from "styled-components";

export const StyledButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 30px;

    @media only screen and (max-width: 1061px) {
        > button {
            width: 80px;
            height: 40px;
            > svg {
                height: 21px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 80px;
    }
`;
