import styled from "styled-components";

export const StyledUserPanelTournament = styled.div`
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 98%;
    margin-bottom: 60px;

    @media only screen and (max-width: 1334px) {
        width: 80%;
    }
    @media only screen and (max-width: 1186px) {
        width: 90%;
    }
    @media only screen and (max-width: 1084px) {
        width: 85%;
    }
    @media only screen and (max-width: 926px) {
        width: 95%;
    }
`;
