import styled from "styled-components";

export const StyledArrow = styled.i`
    border: solid ${({ blackcolor }) => (blackcolor ? "#000" : "#fff")};
    border-width: 0 4px 4px 0;
    display: inline-block;
    cursor: pointer;
    padding: 6px;
    transform: rotate(135deg);
    transform: ${({ isopen }) => (isopen ? "rotate(45deg) translate(-5px)" : "rotate(135deg)")};
    transition: transform 250ms;
`;
