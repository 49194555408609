import React, { useMemo, useState } from "react";
import Decimal from "decimal.js";
import { ScrollSyncPane } from "react-scroll-sync";

import {
    StyledAdditionDataElement,
    StyledAdditionDataTextElement,
} from "./AdditionDataElement.styles";

import { StyledEditWrapper } from "../../atoms/StyledEditWrapper/StyledEditWrapper"
import { StyledEditInputElement } from "../../atoms/EditInputElement/StyledEditInputElement"
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledClosePayElement } from "../../atoms/PayElement/StyledClosePayElement";
import { StyledSavePayElement } from "../../atoms/PayElement/StyledSavePayElement";
import { StyledGlobalUserPanelWrapper } from "../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper";

import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";

const AdditionDataElement = ({
    id,
    trainer,
    amount,
    description,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const displayAmount = useMemo(
        () => {
            const value = new Decimal(amount);

            return `${value.dp() ? value.toFixed(2) : value.toString()} PLN`;
        }, [amount]
    );

    return (
        <StyledAdditionDataElement>
            <ScrollSyncPane>
                <div>
                    {isEdit ? (
                        <StyledEditWrapper>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="25%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="10%">
                                <input type="number" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="40%">
                                <input type="text" />
                            </StyledEditInputElement>
                        </StyledEditWrapper>
                    ) : (
                        <StyledGlobalUserPanelWrapper>
                            <StyledAdditionDataTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {trainer.firstname}
                                </StyledText>
                            </StyledAdditionDataTextElement>
                            <StyledAdditionDataTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {trainer.lastname}
                                </StyledText>
                            </StyledAdditionDataTextElement>
                            <StyledAdditionDataTextElement $hasDeclaredWidth="10%">
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayAmount}
                                </StyledText>
                            </StyledAdditionDataTextElement>
                            <StyledAdditionDataTextElement $hasDeclaredWidth="40%">
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {description}
                                </StyledText>
                            </StyledAdditionDataTextElement>
                        </StyledGlobalUserPanelWrapper>
                    )}
                </div>
            </ScrollSyncPane>
            <StyledSavePayElement isedit={isEdit} onClick={() => setIsEdit(!isEdit)}>
                {!isEdit ? <PencilIcon /> : <AcceptIcon />}
            </StyledSavePayElement>
            <StyledClosePayElement>
                <CloseIcon />
            </StyledClosePayElement>
        </StyledAdditionDataElement>
    );
};

export default AdditionDataElement;
