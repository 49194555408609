import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import UserPanelAdditionsElement from "./UserPanelAdditionsElement";
import UserPanelAdditionElement from "../../molecules/UserPanelAdditionElement/UserPanelAdditionElement";

import { StyledUserPanelAdditions } from "../../atoms/UserPanelAdditions/StyledUserPanelAdditions";
import { StyledGlobalScrollWrapper } from "../../atoms/GlobalScrollWrapper/StyledGlobalScrollWrapper";
import { StyledGlobalHeadingWrapper } from "../../atoms/GlobalHeadingWrapper/StyledGlobalHeadingWrapper";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledLoaderWrapper } from "../../atoms/Tournament/StyledLoaderWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";

import Loader from "../Loader/Loader";

import { getString } from "../../../strings";
import { listAdditions, listTrainers } from "../../../logic/requests/students";
import { cebulaCompare } from "../../../logic/arrays";
import SuccessMessage from "../../molecules/SuccessMessage";
import useMonthlyPaginate from "../../../logic/hooks/useMonthlyPaginate";

import useScrollBar from "../../../logic/hooks/useScrollBar";
import useSuccessMessage from "../../../logic/hooks/useSuccessMessage";

const UserPanelAdditions = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const { data: trainersData, status: queryStatus } = useQuery(["trainers"], listTrainers);
    const trainers = useMemo(
        () => (trainersData ?? []).sort(
            (a, b) => {
                const lastname = cebulaCompare(a.lastname, b.lastname);
                if (lastname)
                    return lastname;

                const firstname = cebulaCompare(a.firstname, b.firstname);
                if (firstname)
                    return firstname;

                return 0;
            }
        ),
        [trainersData]
    );

    const {
        infos: successMessageInfos,
        isShowing: isSuccessMessage,
        show: showSuccessMessage
    } = useSuccessMessage();

    const {
        queryData: additionsData,
        isError: isAdditionsError,
        isLoading: isAdditionsLoading,
        isSuccess: isAdditionsSuccess,
        loadMore,
        hasMore,
        reloadMonth
    } = useMonthlyPaginate(["additions", "all"], listAdditions);

    const onSubmit = useCallback(
        ({ month, infos }) => {
            // Express chuju, nie rzucaj mi 304 Not modified
            setTimeout(() => reloadMonth(month), 100);
            showSuccessMessage(infos);
        }, [reloadMonth, showSuccessMessage]
    );

    return (
        <StyledUserPanelAdditions>
            <StyledGlobalHeadingWrapper>
                <StyledText
                    hasdeclaredpadding="20px 20px 30px 0"
                    hasdeclaredfontsize="36px"
                    hasdeclaredfontweight="700"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString("User_panel_addition_dodaj_dodatek")}:
                </StyledText>
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper
                isscrollbar={isScrollBar}
                ref={scrollElement}
            >
                {
                    (isSuccessMessage) ? (
                        <SuccessMessage
                            title={getString("UserPanelAdditions__success__title")}
                            infos={successMessageInfos}
                        />
                    ) : (undefined)
                }
                {(() => {
                    switch (queryStatus) {
                        case "error":
                            return (
                                <StyledLoaderWrapper>
                                    {getString("blad_wczytywania")}
                                </StyledLoaderWrapper>
                            );
                        case "loading":
                            return (
                                <StyledLoaderWrapper>
                                    <Loader />
                                </StyledLoaderWrapper>
                            );
                        case "success":
                            return (
                                <UserPanelAdditionsElement
                                    trainers={trainers}
                                    onSuccess={onSubmit}
                                />
                            );
                        case "idle":
                        default:
                            return null;
                    };
                })()}
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString("User_panel_addition_dodaj_dodatki")}:
                </StyledText>
                {
                    (isAdditionsSuccess && additionsData) ? (
                        <>
                            {Object.values(additionsData).map(
                                ({ month, data: additions }) => (
                                    <UserPanelAdditionElement
                                        month={month}
                                        additions={additions}
                                        trainers={trainers}
                                    />
                                )
                            )}
                            {
                                (!(isAdditionsLoading || isAdditionsError)) ? (
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <StyledButton
                                            hasdeclaredfontcolor="white"
                                            hasdeclaredbgcolor="#6786be"
                                            hasdeclaredpadding="8px 22px"
                                            hasdeclaredborderradius="25px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredcursor={hasMore ? "pointer" : undefined}
                                            onClick={loadMore}
                                        >
                                            {getString(
                                                hasMore
                                                ? "Trainings_trainings_all_trainer_pay_zaladujWiecej"
                                                : "query__no_more_entries"
                                            )}
                                        </StyledButton>
                                    </div>
                                ) : (null)
                            }
                        </>
                    ) : (null)
                }
                {
                    (isAdditionsError) ? (
                        <StyledLoaderWrapper>
                            {getString("blad_wczytywania")}
                        </StyledLoaderWrapper>
                    ) : (isAdditionsLoading) ? (
                        <StyledLoaderWrapper>
                            <Loader />
                        </StyledLoaderWrapper>
                    ) : (null)
                }
            </StyledGlobalScrollWrapper>
        </StyledUserPanelAdditions>
    );
};

export default UserPanelAdditions;
