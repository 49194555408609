import styled from "styled-components";

export const StyledTimeCell = styled.div`
    width: 60px;
    border-radius: 10px;
    background-color: #6786be;
    border: 2px solid #000;
    min-height: 50px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1355px) {
        > p {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 1179px) {
        width: 45px;
    }
    @media only screen and (max-width: 1061px) {
        > p {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 1017px) {
        width: 35px;
        min-height: 30px;
        > p {
            font-size: 10px;
        }
    }
    @media only screen and (max-width: 871px) {
        width: 30px;
        min-height: 25px;
        > p {
            font-size: 8px;
        }
    }
    @media only screen and (max-width: 767px) {
        width: 60px;
        min-height: 40px;
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 689px) {
        > p {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 600px) {
        width: auto;
        min-height: none;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        position: relative;
        > p {
            font-size: 28px;
            color: #000;
        }
        &::after {
            content: "";
            top: 50%;
            right: 0;
            transform: translate(10px, -50%);
            width: 14px;
            height: 3px;
            background: #000;
            display: ${({ isafterelement }) => (isafterelement ? "block" : "none")};
        }
    }
    @media only screen and (max-width: 519px) {
        > p {
            font-size: 22px;
        }
    }
`;
