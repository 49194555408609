import styled from "styled-components"

export const StyledCloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    transform: rotate(45deg);
    &::after {
        content: "";
        width: 4px;
        height: 70%;
        background-color: ${({ isblack }) => isblack ? "#000" : "#fff"};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 25px;
    }
    &::before {
        content: "";
        width: 70%;
        height: 4px;
        background-color: ${({ isblack }) => isblack ? "#000" : "#fff"};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 25px;
    }
`;