import React, { useMemo } from "react";

import OpenElement from "./OpenElement";

import { StyledPayAllElementContent } from "../../atoms/UserPanelAllTrainerPay/StyledPayAllElementContent";
import { StyledFlexWrapper } from "../../atoms/UserPanelAllTrainerPay/StyledFlexWrapper";

const PayAllElement = ({ hours, trigger, showParentCheckboxes }) => {
    const now = useMemo(() => new Date(), [hours]);

    return (
        <StyledPayAllElementContent>
            <StyledFlexWrapper>
                {Object.entries(hours).map(([key, val]) => (
                    <OpenElement
                        key={key}
                        val={val}
                        keyElement={key}
                        defaultOpen={key === `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`}
                        trigger={trigger}
                        showParentCheckboxes={showParentCheckboxes}
                    />
                ))}
            </StyledFlexWrapper>
        </StyledPayAllElementContent>
    );
};

export default PayAllElement;
