import React from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { Link } from "react-router-dom";
import { getString, getLang } from "../../../strings";

import { StyledTrainingsInformations } from "../../atoms/Trainings/StyledTrainingsInformations";
import { StyledTrainingsInformationsImages } from "../../atoms/Trainings/StyledTrainingsInformationsImages";
import { StyledTrainingsInformationsContent } from "../../atoms/Trainings/StyledTrainingsInformationsContent";
import { StyledTrainingsInformationsImageWrapper } from "../../atoms/Trainings/StyledTrainingsInformationsImageWrapper";
import { StyledAdvantge } from "../../atoms/Trainings/StyledAdvantge";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledAdvantageWrapper } from "../../atoms/Trainings/StyledAdvantageWrapper";
import { StyledSyncWrapp } from "../../atoms/Trainings/StyledSyncWrapp";

import image1 from "../../../images/trainingsDesc/1.jpeg";
import image2 from "../../../images/trainingsDesc/2.jpeg";
import image3 from "../../../images/trainingsDesc/3.jpeg";
import image4 from "../../../images/trainingsDesc/4.jpeg";
import image5 from "../../../images/trainingsDesc/5.jpeg";
import image6 from "../../../images/trainingsDesc/6.jpeg";
import image7 from "../../../images/trainingsDesc/7.jpeg";
import image8 from "../../../images/trainingsDesc/8.jpeg";

import useScrollBar from "../../../logic/hooks/useScrollBar";

const TrainingsInformations = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledTrainingsInformations
            isscrollbar={isScrollBar}
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <StyledTrainingsInformationsContent>
                <StyledText
                    hasdeclaredfontsize="34px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString("Trainings_trainings_informations_jakwygladajalekcje")}
                </StyledText>
                <ScrollSync>
                    <StyledSyncWrapp>
                        <ScrollSyncPane>
                            <StyledAdvantge>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#ee745c"
                                    hasdeclaredzindex="4"
                                    hasdeclaredwidth="20%"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_kostkarubika")}
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#ce5f51"
                                    hasdeclaredzindex="3"
                                    hasdeclaredleft="14%"
                                    hasdeclaredwidth="38%"
                                    hasdeclaredjustifycontent="flex-end"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_personalne")}
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                    >
                                        <span>&#10003;</span>
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#b54b4b"
                                    hasdeclaredzindex="2"
                                    hasdeclaredleft="45%"
                                    hasdeclaredwidth="38%"
                                    hasdeclaredjustifycontent="flex-end"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString(
                                            "Trainings_trainings_informations_szybkiprogress"
                                        )}
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                    >
                                        <span>&#10003;</span>
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#964040"
                                    hasdeclaredzindex="1"
                                    hasdeclaredleft="76%"
                                    hasdeclaredwidth="28%"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredpadding="0 0 0 30px"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_hour1")}
                                    </StyledText>
                                </StyledAdvantageWrapper>
                            </StyledAdvantge>
                            <StyledAdvantge>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#6786be"
                                    hasdeclaredzindex="4"
                                    hasdeclaredwidth="20%"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_grupowa")}
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#5a78a3"
                                    hasdeclaredzindex="3"
                                    hasdeclaredleft="14%"
                                    hasdeclaredwidth="38%"
                                    hasdeclaredjustifycontent="flex-end"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_atmosfera")}
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                    >
                                        <span>&#10003;</span>
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#4a6484"
                                    hasdeclaredzindex="2"
                                    hasdeclaredleft="45%"
                                    hasdeclaredwidth="38%"
                                    hasdeclaredjustifycontent="flex-end"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString(
                                            "Trainings_trainings_informations_roznorodnosczabaw"
                                        )}
                                    </StyledText>
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                    >
                                        <span>&#10003;</span>
                                    </StyledText>
                                </StyledAdvantageWrapper>
                                <StyledAdvantageWrapper
                                    hasdeclaredbgcolor="#3e566d"
                                    hasdeclaredzindex="1"
                                    hasdeclaredleft="76%"
                                    hasdeclaredwidth="28%"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="15px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredpadding="0 0 0 30px"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString("Trainings_trainings_informations_hour2")}
                                    </StyledText>
                                </StyledAdvantageWrapper>
                            </StyledAdvantge>
                            {getLang() === "pl" ? (
                                <StyledAdvantge>
                                    <StyledAdvantageWrapper
                                        hasdeclaredbgcolor="#6786be"
                                        hasdeclaredzindex="4"
                                        hasdeclaredwidth="20%"
                                    >
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredtextalign="center"
                                        >
                                            Przedmioty szkolne
                                        </StyledText>
                                    </StyledAdvantageWrapper>
                                    <StyledAdvantageWrapper
                                        hasdeclaredbgcolor="#5a78a3"
                                        hasdeclaredzindex="3"
                                        hasdeclaredleft="14%"
                                        hasdeclaredwidth="38%"
                                        hasdeclaredjustifycontent="flex-end"
                                    >
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredtextalign="center"
                                        >
                                            personalne podejście
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                        >
                                            <span>&#10003;</span>
                                        </StyledText>
                                    </StyledAdvantageWrapper>
                                    <StyledAdvantageWrapper
                                        hasdeclaredbgcolor="#4a6484"
                                        hasdeclaredzindex="2"
                                        hasdeclaredleft="45%"
                                        hasdeclaredwidth="38%"
                                        hasdeclaredjustifycontent="flex-end"
                                    >
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredtextalign="center"
                                        >
                                            jakość zajęć
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                        >
                                            <span>&#10003;</span>
                                        </StyledText>
                                    </StyledAdvantageWrapper>
                                    <StyledAdvantageWrapper
                                        hasdeclaredbgcolor="#3e566d"
                                        hasdeclaredzindex="1"
                                        hasdeclaredleft="76%"
                                        hasdeclaredwidth="28%"
                                    >
                                        <StyledText
                                            hasdeclaredfontsize="15px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredpadding="0 0 0 30px"
                                            hasdeclaredtextalign="center"
                                        >
                                            85 PLN/h
                                        </StyledText>
                                    </StyledAdvantageWrapper>
                                </StyledAdvantge>
                            ) : (
                                <></>
                            )}
                        </ScrollSyncPane>
                    </StyledSyncWrapp>
                </ScrollSync>
                <StyledText
                    hasdeclaredfontsize="30px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="0 0 10px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString("Trainings_trainings_informations_zajeciaindywidualne")}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {getString("Trainings_trainings_informations_jeslichodzio")}
                    <br />
                    <br />
                    {getString("Trainings_trainings_informations_naukauklada")}
                </StyledText>
                <StyledTrainingsInformationsImages>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image5} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image6} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image7} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image8} />
                    </StyledTrainingsInformationsImageWrapper>
                </StyledTrainingsInformationsImages>
                <StyledText
                    hasdeclaredfontsize="30px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="20px 0 10px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString("Trainings_trainings_informations_zajeciagrupowe")}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {getString("Trainings_trainings_informations_jeslichodziozajecia")}{" "}
                    <Link
                        to="/trainings/questions"
                        style={{ textDecoration: "none", color: "#6786be" }}
                    >
                        {getString("Trainings_trainings_informations_pytania")}
                    </Link>{" "}
                    {getString("Trainings_trainings_informations_gdzieopiszesz")}
                </StyledText>
                <StyledTrainingsInformationsImages>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image1} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image2} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image3} />
                    </StyledTrainingsInformationsImageWrapper>
                    <StyledTrainingsInformationsImageWrapper>
                        <img src={image4} />
                    </StyledTrainingsInformationsImageWrapper>
                </StyledTrainingsInformationsImages>
                {getLang() === "pl" ? (
                    <>
                        <StyledText
                            hasdeclaredfontsize="30px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredpadding="20px 0 10px 0"
                            hasdeclaredlineheight="1.3em"
                            hasdeclaredtextalign="center"
                            as="h2"
                        >
                            ZAJĘCIA INDYWIDUALNE - PRZEDMIOTY SZKOLNE
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontweight="600"
                            hasdeclaredpadding="0 0 20px 0"
                            hasdeclaredlineheight="1.3em"
                            hasdeclaredtextalign="justify"
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Matematyka
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy oraz rozszerzony), pomoc z bieżącym materiałem w
                            szkole podstawowej i średniej
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Fizyka
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy oraz rozszerzony), pomoc z bieżącym materiałem w
                            szkole podstawowej i średniej
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Informatyka
                            </span>{" "}
                            - przygotowanie do matury, przygotowanie do egzaminów w technikum, pomoc
                            z bieżącym materiałem w szkole podstawowej i średniej oraz na studiach
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Chemia
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy i rozszerzony), przygotowanie do olimpiady dla szkół
                            średnich, pomoc z bieżącym materiałem w szkole podstawowej i średniej
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Angielski
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy oraz rozszerzony), pomoc z bieżącym materiałem w
                            szkole podstawowej i średniej oraz na studiach
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Hiszpański
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy oraz rozszerzony), pomoc z bieżącym materiałem w
                            szkole podstawowej i średniej oraz na studiach
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Polski
                            </span>{" "}
                            - przygotowanie do egzaminu ósmoklasisty, przygotowanie do matury
                            (poziom podstawowy oraz rozszerzony), pomoc z bieżącym materiałem w
                            szkole podstawowej i średniej
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                SZACHY
                            </span>{" "}
                            - nauka gry w szachy dla początkujących i nie tylko, wyszkolenie na
                            poziom ELO 1000 lub okolice, nauka poprzez aplikację internetową
                            chess.com lub podobne, dzięki czemu nie potrzebna jest szachownica
                            fizyczna, a wszystko jest zdecydowanie bardziej zrozumiałe poprzez cały
                            interfejs graficzny i szereg możliwości stron poświęconym szachom
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Kurs informatyczny "Podstawy stron internetowych"
                            </span>{" "}
                            - kurs grupowy podzielony na 10 spotkań uczący podstaw stron
                            internetowych. Po ukończonym kursie uczniowie potrafią sami
                            zaprojektować i ostylować stronę internetową na każde urządzenie (wersja
                            komputerowa i mobilna) oraz potrafią zrobić proste akcje na stronie,
                            które umożliwią np. zrobienie własnej gry przeglądarkowej. Kurs rusza od
                            października i może się odbywać w grupach od 3 do 5 osób. Aby kurs
                            wystartował, musi być minimalna liczba chętnych.
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Kurs informatyczny "Podstawy języka Python"
                            </span>{" "}
                            - kurs grupowy podzielony na 10 spotkań podczas, który oferuje naukę
                            podstawowej składni języka Python jak i umożliwi naukę aplikacji
                            konsolowych i desktopowych. Kurs kończy się wspólnym ukończeniem gry
                            konsolowej lub desktopowej (w zależności od poziomu grupy). Kurs rusza
                            od października i może się odbywać w grupach od 3 do 5 osób. Aby kurs
                            wystartował, musi być minimalna liczba chętnych.
                            <br />
                            <br />
                            <span
                                style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: "#6786be",
                                }}
                            >
                                Kurs informatyczny "Nauka programowania"
                            </span>{" "}
                            - kurs grupowy składający się z 10 spotkań, który nauczy podstaw
                            programowania. Kurs skupia się na przekazaniu zasad programowania, uczy
                            składni języka C++ oraz użycia instrukcji warunkowych, pętli jak i
                            wykorzystania gotowych bibliotek. Kurs jest polecany przede wszystkim
                            dla osób nowych w programowaniu i przed zaczęciem kursu "Podstawy języka
                            Python". Po ukończonym kursie uczeń potrafi napisać program w terminalu,
                            który będzie miał w sobie warunki jak i pętle.
                            <br />
                            <br />
                            Cena korepetycji i wszystkie zasady są identyczne jak w przypadku zajęć
                            z kostki Rubika. Ceny kursów wahają się od 600 do 800 złotych - w
                            zależności od liczby osób w grupie.
                        </StyledText>
                    </>
                ) : (
                    <></>
                )}
                <StyledText
                    hasdeclaredfontsize="30px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="20px 0 10px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString("Trainings_trainings_informations_zasadyzajec")}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    {getString("Trainings_trainings_informations_pierwszezajeciazawsze")}
                </StyledText>
            </StyledTrainingsInformationsContent>
        </StyledTrainingsInformations>
    );
};

export default TrainingsInformations;
