import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../Loader/Loader";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledTrackHours } from "../../atoms/TrackHours/StyledTrackHours";
import { StyledHourWrapper } from "../../atoms/TrackHours/StyledHourWrapper";
import { StyledSuccesMessage } from "../../atoms/TrackHours/StyledSuccesMessage";
import { StyledForm } from "../../atoms/TrackHours/StyledForm";
import { StyledSelectWrapper } from "../../atoms/TrackHours/StyledSelectWrapper";
import { StyledInputsWrapper } from "../../atoms/TrackHours/StyledInputsWrapper";
import { StyledInputsButtonsWrapper } from "../../atoms/TrackHours/StyledInputsButtonsWrapper";
import { StyledInputwrapper } from "../../atoms/TrackHours/StyledInputwrapper";
import { StyledCheckbox } from "../../atoms/TrackHours/StyledCheckbox";
import { ReactComponent as CheckboxAccept } from "../../../images/checkboxAccept.svg";
import { StyledInputWithCheckbox } from "../../atoms/TrackHours/StyledInputWithCheckbox";
import { StyledInfo } from "../../atoms/TrackHours/StyledInfo";
import { StyledRowInfo } from "../../atoms/TrackHours/StyledRowInfo";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";

import { addHours, listStudents, listSubjects } from "../../../logic/requests/students.js";
import { getString } from "../../../strings";
import { getDateString } from "../../../logic/isotime";
import { cebulaCompare } from "../../../logic/arrays";
import SuccessMessage from "../../molecules/SuccessMessage";

const handleSubmit = (data, hasFree, onSucc, onErr) => {
	console.log("handleSubmit", data);
	if (
		(!data.student || isNaN(data.student)) ||
		(!data.subject) ||
		(typeof data.half !== "boolean") ||
		((!data.hours || isNaN(data.hours)) && !data.half) ||
		(!data.date?.length)
	) {
		console.log("handleSubmit", "data validation failed");
		return;
	}

	if (data.half)
		delete data.hours;

	if (!hasFree && data.free)
		delete data.free;

	addHours(data)
		.then(
			(res) => {
				console.log("addHours", res);
				onSucc?.();
			}
		)
		.catch(
			(err) => {
				console.error("addHours", err);
				onErr?.();
			}
		)
	;
};

const UserPanelTrainerAddHours = ({ user }) => {
	const hasFree = useMemo(
		() => user.roles.includes("trainer_new") || user.roles.includes("manager"),
		[user]
	);

	const [students, setStudents] = useState();
	const [subjects, setSubjects] = useState();
	const [err, setErr] = useState(false);

	const [submitSucc, setSubmitSucc] = useState();
	useEffect(
		() => {
			if (submitSucc) {
				const timeout = setTimeout(() => setSubmitSucc(false), 3000);
				return () => clearTimeout(timeout);
			}
		},
		[ submitSucc, setSubmitSucc ]
	);
	const [submitErr, setSubmitErr] = useState(false);
	useEffect(
		() => {
			if (submitErr) {
				const timeout = setTimeout(() => setSubmitErr(false), 3000);
				return () => clearTimeout(timeout);
			}
		},
		[ submitErr, setSubmitErr ]
	);
	
	const form = useRef();
	const refs = {
		student: useRef(),
		subject: useRef(),
		hours: useRef(),
		half: useRef(),
		free: useRef(),
		date: useRef()
	}, getRefsData = () => ({
		student: parseInt(refs.student.current.value),
		subject: refs.subject.current.value,
		hours: parseInt(refs.hours.current.value),
		half: refs.half.current.checked,
		...(hasFree ? { free: refs.free.current.checked } : {}),
		date: refs.date.current.value
	});
	const [half, setHalf] = useState(false);
	useEffect(
		() => {
			const { current } = refs.hours;
			if (!current)
				return;

			current.value = half ? 0.5 : 0;
		},
		[ half ]
	);

	const navigate = useNavigate();

	useEffect(
		() => {
			if (!(user && user.roles.includes("trainer")))
				navigate("/my-account", { replace: true });
			listStudents()
				.then(
					(res) => {
						console.log("listStudents", res);
						setStudents(
							res
							.sort(
								(a, b) => cebulaCompare(a.lastname, b.lastname) || cebulaCompare(a.firstname, b.firstname)
							)
						);
					}
				)
				.catch(
					(err) => {
						console.error("listStudents", err);
						setErr(true);
						setStudents([]);
					}
				)
			;
			listSubjects()
				.then(
					(res) => {
						console.log("listSubjects", res);
						setSubjects(
							res
							.sort(
								(a, b) => cebulaCompare(getString(`subject__${a}`), getString(`subject__${b}`))
							)
						);
					}
				)
				.catch(
					(err) => {
						console.error("listSubjects", err);
						setErr(true);
						setSubjects([]);
					}
				)
			;
		},
		[]
	);

	return (
		<StyledTrackHours>
			{
				(!(students && subjects)) ? (
					<StyledUserPanelStudentsLoaderWrapper>
						<Loader />
					</StyledUserPanelStudentsLoaderWrapper>
				) : (!(students.length && subjects.length)) ? (
					<StyledText
						hasdeclaredpadding="0px 20px"
						hasdeclaredfontsize="22px"
						hasdeclaredfontweight="700"
						hasdeclaredtextalign="center"
						hasdeclaredlineheight="1.4em"
						as="p"
					>
						{getString((err) ? "blad_wczytywania" : "UserPanelTrainer__no_students_or_subjects")}
					</StyledText>
				) : (
					<>
						<StyledText
							hasdeclaredpadding="20px 20px 30px 0"
							hasdeclaredfontsize="36px"
							hasdeclaredfontweight="700"
							hasdeclaredlineheight="1.4em"
						>
							{getString("UserPanelTrainerTrackHours__header_title")}:
						</StyledText>
						<StyledHourWrapper>
							<StyledForm
								ref={form}
								onSubmit={
									(e) => {
										e.preventDefault();
										return handleSubmit(
											getRefsData(),
											hasFree,
											() => {
												setSubmitSucc(true);
												setHalf(false);
												// to musi być ostatnie - dla 'podsumowania' - inaczej wartości będą 'undefined' przez kolejne prze-renderowanie
												form.current.reset();
											},
											() => setSubmitErr(true)
										);
									}
								}
							>
								<StyledSelectWrapper>
									<StyledInputwrapper>
										<label htmlFor="countTime">
											<StyledText
												hasdeclaredfontsize="20px"
												hasdeclaredtextalign="center"
												hasdeclaredpadding="8px 0 8px 0"
												hasdeclaredfontweight="600"
											>
												{getString("UserPanelTrainerTrackHours__form__label_student")}:
											</StyledText>
										</label>
										<select ref={refs.student}>
											<option>- {getString("UserPanelTrainerTrackHours__form__select_student")} -</option>
											{
												students.map(
													(student) => (
														<option key={student.id} value={student.id}>{`${student.lastname} ${student.firstname}`}</option>
													)
												)
											}
										</select>
										{/*
										<StyledText
											hasdeclaredfontsize="14px"
											hasdeclaredfontcolor="#F74017"
											hasdeclaredlineheight="1.2em"
										>
											error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
										</StyledText>
										*/}
									</StyledInputwrapper>
									<StyledInputwrapper>
										<label htmlFor="countTime">
											<StyledText
												hasdeclaredfontsize="20px"
												hasdeclaredtextalign="center"
												hasdeclaredpadding="8px 0 8px 0"
												hasdeclaredfontweight="600"
											>
												{getString("UserPanelTrainerTrackHours__form__label_subject")}:
											</StyledText>
										</label>
										<select ref={refs.subject} defaultValue="cube">
											{
												subjects.map(
													(subject) => (
														<option key={subject} value={subject}>{getString(`subject__${subject}`)}</option>
													)
												)
											}
										</select>
										{/*
										<StyledText
											hasdeclaredfontsize="14px"
											hasdeclaredfontcolor="#F74017"
											hasdeclaredlineheight="1.2em"
										>
											error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
										</StyledText>
										*/}
									</StyledInputwrapper>
								</StyledSelectWrapper>
								<StyledInputsWrapper>
									<StyledInputWithCheckbox>
										<div>
											<StyledInputwrapper hasdeclaredwidth="90%" disabled={half}>
												<label htmlFor="countTime">
													<StyledText
														hasdeclaredfontsize="20px"
														hasdeclaredtextalign="center"
														hasdeclaredpadding="8px 0 8px 0"
														hasdeclaredfontweight="600"
													>
														{getString("UserPanelTrainerTrackHours__form__label_hours_amount")}:
													</StyledText>
												</label>
												<input ref={refs.hours} id="countTime" type="number" min={0} max={99} defaultValue={1} disabled={half} />
											</StyledInputwrapper>
											<StyledCheckbox>
												<input id="half" ref={refs.half} type="checkbox" onChange={(e) => setHalf(e.target.checked)} />
												<label htmlFor="half">
													<StyledText
														hasdeclaredfontsize="20px"
														hasdeclaredtextalign="center"
														hasdeclaredpadding="8px 0 8px 0"
														hasdeclaredfontweight="600"
													>
														{getString("UserPanelTrainerTrackHours__form__label_half")}.
														<span>
															<CheckboxAccept />
														</span>
													</StyledText>
												</label>
											</StyledCheckbox>
											{
												(hasFree) ? (
													<StyledCheckbox>
														<input id="free" ref={refs.free} type="checkbox" />
														<label htmlFor="free">
															<StyledText
																hasdeclaredfontsize="20px"
																hasdeclaredtextalign="center"
																hasdeclaredpadding="8px 0 8px 0"
																hasdeclaredfontweight="600"
															>
																{getString("UserPanelTrainerTrackHours__form__label_d")}.
																<span>
																	<CheckboxAccept />
																</span>
															</StyledText>
														</label>
													</StyledCheckbox>
												) : (undefined)
											}
										</div>
										{/*
										<div>
											<StyledText
												hasdeclaredfontsize="14px"
												hasdeclaredfontcolor="#F74017"
												hasdeclaredlineheight="1.2em"
											>
												error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
											</StyledText>
										</div>
										*/}
									</StyledInputWithCheckbox>
									<StyledInputwrapper>
										<label htmlFor="hourDate">
											<StyledText
												hasdeclaredfontsize="20px"
												hasdeclaredtextalign="center"
												hasdeclaredpadding="8px 0 8px 0"
												hasdeclaredfontweight="600"
											>
												{getString("UserPanelTrainerTrackHours__form__label_date")}:
											</StyledText>
										</label>
										<input id="hourDate" ref={refs.date} type="date" defaultValue={getDateString(new Date())} />
										{/*
										<StyledText
											hasdeclaredfontsize="14px"
											hasdeclaredfontcolor="#F74017"
											hasdeclaredlineheight="1.2em"
										>
											error message dsadsad dsadsadas dsadsa dsadsa dsadsa 
										</StyledText>
										*/}
									</StyledInputwrapper>
								</StyledInputsWrapper>
								<StyledInputsButtonsWrapper>
									<input type="submit" value={getString("UserPanelTrainerTrackHours__form__add")} />
									<input type="reset" value={getString("UserPanelTrainerTrackHours__form__cancel")} />
								</StyledInputsButtonsWrapper>
							</StyledForm>
							{
								(submitSucc) ? (
									<SuccessMessage
										title={getString("UserPanelTrainerTrackHours__form_submit__succ") + ':'}
										infos={[
											{
												title: getString("UserPanelTrainerTrackHours__form_submit_after__for_who") + ':',
												value: (() => {
													const student = students.find(student => student.id === parseInt(refs.student.current.value));
													return `${student.firstname} ${student.lastname}`;
												})()
											},
											{
												title: getString("UserPanelTrainerTrackHours__form_submit_after__hours") + ':',
												value: `${parseInt(refs.hours.current.value)}${refs.half.current.checked ? ".5" : ""}`
											},
											...(hasFree && refs.free.current.checked ? [{
												title: getString("UserPanelTrainerTrackHours__form_submit_after__free") + ':',
												value: getString("YES")
											}] : []),
											{
												title: getString("UserPanelTrainerTrackHours__form_submit_after__when") + ':',
												value: refs.date.current.value.split('-').reverse().join('.')
											}
										]}
									/>
								) : (undefined)
							}
							{
								(submitErr) ? (
									<StyledText
										hasdeclaredfontcolor="#ee745c"
										hasdeclaredpadding="0 0 40px"
										hasdeclaredfontsize="22px"
										hasdeclaredfontweight="700"
										hasdeclaredtextalign="center"
										hasdeclaredlineheight="1.4em"
										as="p"
									>
										{getString("UserPanelTrainerTrackHours__form_submit__fail")}
									</StyledText>
								) : (undefined)
							}
						</StyledHourWrapper>
					</>
				)
			}
		</StyledTrackHours>
	);
};

export default UserPanelTrainerAddHours;
