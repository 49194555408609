import styled from "styled-components";

export const StyledScriptDataLeftWrapper = styled.div`
    width: 32%;
    border: 2px solid #000;
    background-color: rgb(223, 123, 99);
    margin-right: 3%;
    min-height: 50px;
    border-radius: 15px;

    @media only screen and (max-width: 728px) {
        width: 15%;
    }
`;
