import React, { useRef, useState } from "react";
import { getString } from "../../../strings";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledPhoneLink } from "../../atoms/UserPanelStudens/StyledPhoneLink";
import { StyledPreferencesWrapper } from "../../atoms/UserPanelStudens/StyledPreferencesWrapper";
import { StyledEditHandler } from "../../atoms/UserPanelStudens/StyledEditHandler";
import { StyledRow } from "../../atoms/UserPanelStudens/StyledRow";
import { StyledColumn } from "../../atoms/UserPanelStudens/StyledColumn";
import { StyledUserListElement } from "../../atoms/UserPanelStudens/StyledUserListElement";
import { StyledUserListEditElement } from "../../atoms/UserPanelStudens/StyledUserListEditElement";
import { StyledEditElementInputWrapper } from "../../atoms/UserPanelStudens/StyledEditElementInputWrapper";
import { StyledCloseForm } from "../../atoms/UserPanelStudens/StyledCloseForm";
import { StyledSaveForm } from "../../atoms/UserPanelStudens/StyledSaveForm";
import { StyledEdirRole } from "../../atoms/UserPanelStudens/StyledEdirRole";
import { StyledSelect } from "../../atoms/UserPanelAddRole/StyledSelect";
import { StyledSelectWrapper } from "../../atoms/UserPanelAddRole/StyledSelectWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledButtonsWrapper } from "../../atoms/UserPanelReqElement/StyledButtonsWrapper";

import PhoneIcon from "../../atoms/UserPanelStudens/PhoneIcon";
import PencilIcon from "../../atoms/UserPanelStudens/PencilIcon";
import CloseIcon from "../../atoms/UserPanelStudens/CloseIcon";
import AcceptIcon from "../../atoms/UserPanelStudens/AcceptIcon";

import { addNonUserParent, assignParent, togglePreference, unassignParent, updateStudent } from "../../../logic/requests/students.js";

const noneString = '-';

const togglePref = (trainer_id, student, setStudent, pref) => {
    if (!["preferred", "forbidden"].includes(pref)) {
        console.warn("togglePref", "got invalid pref value", pref);
        return;
    }
    const numberPref = pref === "preferred" ? 1 : -1;

    togglePreference({ id: student.id, type: numberPref })
        .then((res) => {
            console.log("togglePreference", res);
            setStudent({
                ...student,
                preferences: {
                    ...student.preferences,
                    [trainer_id]: student.preferences[trainer_id]
                        ? student.preferences[trainer_id] === numberPref
                            ? 0
                            : numberPref
                        : numberPref,
                },
            });
        })
        .catch((err) => {
            console.error("togglePreference", err);
        });
};

const getFormData = (refs, defaultValues) =>
    Object.fromEntries(
        Object.entries(refs)
            .map(([key, val]) => {
                const ret = val?.current?.value;
                return (
                    (
                        (ret !== defaultValues[key]) &&
                        !(!defaultValues[key] && !ret.length)
                    ) ? (
                        [key, ret]
                    ) : (undefined)
                );
            })
            .filter((val) => val)
    );

const handleSubmit = (data, student, setStudent, closePanel) => {
    console.log("handleSubmit", student.id, data);
    if (!Object.keys(data).length) return closePanel();

    updateStudent(student.id, data)
        .then((res) => {
            console.log("updateStudent", res);

            const student_data = (
                Object.fromEntries(
                    Object.entries(data)
                    .filter(
                        ([key, ]) => (key.startsWith("student_"))
                    )
                    .map(
                        ([key, val]) => ([key.replace("student_", ''), val])
                    )
                )
            );
            const parent_data = (
                Object.fromEntries(
                    Object.entries(data)
                    .filter(
                        ([key, ]) => (key.startsWith("parent_"))
                    )
                    .map(
                        ([key, val]) => ([key.replace("parent_", ''), val?.length ? val : null])
                    )
                )
            );

            const new_parent = {
                ...student.parent,
                ...parent_data
            };

            const new_student = {
                ...student,
                ...student_data,
                ...(Object.keys(new_parent).length ? { parent: new_parent } : undefined)
            };
            setStudent(new_student);

            closePanel();
        })
        .catch((err) => {
            console.error(err);
        });
};

const handleSubmitParent = (student_id, parent, setStudent, onSuccess) => {
    if (!student_id || !parent || ["id", "firstname", "lastname", "email", "phone"].some((val) => !parent[val])) {
        console.warn("handleSubmitParent", "Got invalid parameters", student_id, parent);
        return;
    }

    // przypisz użytkownika o podanym ID
    assignParent(student_id, parent.id)
        .then(
            (res) => {
                console.log("assignParent", res);
                setStudent?.(
                    (student) => ({
                        ...student,
                        parent: {
                            ...parent,
                            hasAccount: true
                        }
                    })
                );
                onSuccess?.();
            }
        )
        .catch(
            (err) => {
                console.error("assignParent", err);
            }
        )
    ;
};

const handleSubmitNonParent = (student_id, data, setStudent, onSuccess) => {
    console.log("handleSubmitParent", data);

    if (!student_id) {
        console.warn("handleSubmitParent", "No student ID given! Aborting");
        return;
    }

    if (!Object.keys(data).length) {
        // pusty formularz
        onSuccess?.();
        return;
    }
    // brak dodatkowej walidacji - wszystkie pola są opcjonalne, ale musi podać conajmniej jedno

    addNonUserParent(student_id, data)
        .then(
            (res) => {
                console.log("addNonUserParent", res);
                setStudent?.(
                    (student) => ({
                        ...student,
                        parent: {
                            id: res.id,
                            ...data,
                            hasAccount: false
                        }
                    })
                )
                onSuccess?.();
            }
        )
        .catch(
            (err) => {
                console.error("addNonUserParent", err);
            }
        )
    ;
};

const defaultValuesParent = {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: ""
};

const handleDeleteParent = (id, setStudent, onSuccess) => {
    if (!window.confirm(getString("UserPanelStudents__delete_parent__confirm")))
        return;

    unassignParent(id)
        .then(
            (res) => {
                console.log("handleDeleteParent", res);
                setStudent?.(
                    (student) => {
                        const ret = {...student};
                        if (ret.parent)
                            delete ret.parent;
                        return ret;
                    }
                );
                onSuccess?.();
            }
        )
        .catch(
            (err) => {
                console.error("handleDeleteParent", err);
            }
        )
    ;
};

const UserPanelStudentElement = ({
    user,
    student,
    setStudent,
    canEdit,
    isAdmin,
    parents
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isRoleEdit, setIsRoleEdit] = useState(false);
    const [isAddParent, setIsAddParent] = useState(false);
    
    const refs = {
        student_firstname: useRef(),
        student_lastname: useRef(),
        student_phone: useRef(),
        parent_firstname: useRef(),
        parent_lastname: useRef(),
        parent_phone: useRef(),
        parent_email: useRef(),
    };
    const [errors, setErrors] = useState({});
    const defaultValues = {
        student_firstname: student.firstname,
        student_lastname: student.lastname,
        student_phone: student.phone,
        ...(student.parent
            ? {
                  parent_firstname: student.parent.firstname,
                  parent_lastname: student.parent.lastname,
              }
            : {}),
        parent_phone: student.parent?.phone,
        parent_email: student.parent?.email,
    };

    const editElement = (
        <StyledUserListEditElement>
            <StyledCloseForm onClick={() => setIsEdit(false)}>
                <CloseIcon />
            </StyledCloseForm>
            <StyledSaveForm
                onClick={() =>
                    handleSubmit(getFormData(refs, defaultValues), student, setStudent, () =>
                        setIsEdit(false)
                    )
                }
            >
                <AcceptIcon />
            </StyledSaveForm>
            <StyledEditElementInputWrapper>
                <div>
                    <label htmlFor="student_firstname">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("Trainings_trainings_student_element_imieUcznia")}:
                        </StyledText>
                    </label>
                    <input
                        id="student_firstname"
                        ref={refs.student_firstname}
                        type="text"
                        placeholder="Wpisz imię ucznia"
                        defaultValue={defaultValues.student_firstname}
                    />
                </div>
                {errors.student_firstname ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString(
                            `UserPanelStudentElement__edit__errors__${errors.student_firstname}`
                        )}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            <StyledEditElementInputWrapper>
                <div>
                    <label htmlFor="student_lastname">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("Trainings_trainings_student_element_nazwiskoUcznua")}:
                        </StyledText>
                    </label>
                    <input
                        id="student_lastname"
                        ref={refs.student_lastname}
                        type="text"
                        placeholder="Wpisz nazwisko ucznia"
                        defaultValue={defaultValues.student_lastname}
                    />
                </div>
                {errors.student_lastname ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString(
                            `UserPanelStudentElement__edit__errors__${errors.student_lastname}`
                        )}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            <StyledEditElementInputWrapper>
                <div>
                    <label htmlFor="studenttel">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("Trainings_trainings_student_element_nrUcznia")}:
                        </StyledText>
                    </label>
                    <input
                        id="studenttel"
                        ref={refs.student_phone}
                        type="text"
                        placeholder="Wpisz numer telefonu ucznia"
                        defaultValue={defaultValues.student_phone}
                    />
                </div>
                {errors.student_phone ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString(
                            `UserPanelStudentElement__edit__errors__${errors.student_phone}`
                        )}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            {student.parent ? (
                <>
                    <StyledEditElementInputWrapper>
                        <div>
                            <label htmlFor="studentparent_firstname">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString("Trainings_trainings_student_element_imieRodzica")}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparent_firstname"
                                ref={refs.parent_firstname}
                                type="text"
                                placeholder="Wpisz imię rodzica"
                                defaultValue={defaultValues.parent_firstname}
                            />
                        </div>
                        {errors.parent_firstname ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                as="h4"
                            >
                                {getString(
                                    `UserPanelStudentElement__edit__errors__${errors.parent_firstname}`
                                )}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper>
                        <div>
                            <label htmlFor="studentparent_lastname">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString(
                                        "Trainings_trainings_student_element_nazwiskoRodzica"
                                    )}
                                    :
                                </StyledText>
                            </label>
                            <input
                                id="studentparent_lastname"
                                ref={refs.parent_lastname}
                                type="text"
                                placeholder="Wpisz nazwisko rodzica"
                                defaultValue={defaultValues.parent_lastname}
                            />
                        </div>
                        {errors.parent_lastname ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                as="h4"
                            >
                                {getString(
                                    `UserPanelStudentElement__edit__errors__${errors.parent_lastname}`
                                )}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper>
                        <div>
                            <label htmlFor="studentparenttel">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString("Trainings_trainings_student_element_nrRodzica")}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparenttel"
                                ref={refs.parent_phone}
                                type="text"
                                placeholder="Wpisz numer telefonu rodzica"
                                defaultValue={defaultValues.parent_phone}
                            />
                        </div>
                        {errors.parent_phone ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                as="h4"
                            >
                                {getString(
                                    `UserPanelStudentElement__edit__errors__${errors.parent_phone}`
                                )}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper>
                        <div>
                            <label htmlFor="studentparentemail">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString("Trainings_trainings_student_element_emailRodzica")}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparentemail"
                                ref={refs.parent_email}
                                type="text"
                                placeholder="Wpisz adres e-mail rodzica"
                                defaultValue={defaultValues.parent_email}
                            />
                        </div>
                        {errors.parent_email ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                as="h4"
                            >
                                {getString(
                                    `UserPanelStudentElement__edit__errors__${errors.parent_email}`
                                )}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                </>
            ) : undefined}
        </StyledUserListEditElement>
    );

    const basicElement = (
        <>
            <StyledRow>
                <StyledColumn>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString("UserPanelStudents__list__entry__student")}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {`${student.firstname} ${student.lastname}`}
                    </StyledText>
                </StyledColumn>
                <StyledColumn>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString("UserPanelStudents__list__entry__student_phone")}:
                    </StyledText>
                    <StyledPhoneLink href={`tel:${student.phone}`}>
                        {student.phone} <PhoneIcon />
                    </StyledPhoneLink>
                </StyledColumn>
            </StyledRow>
            {student.parent ? (
                <StyledRow>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("UserPanelStudents__list__entry__parent")}:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {student.parent.firstname ?? noneString} {student.parent.lastname ?? noneString}
                        </StyledText>
                    </StyledColumn>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString("UserPanelStudents__list__entry__parent_phone")}:
                        </StyledText>
                        {
                            student.parent.phone ? (
                                <StyledPhoneLink href={`tel:${student.parent.phone}`}>
                                    {student.parent.phone} <PhoneIcon />
                                </StyledPhoneLink>
                            ) : (
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {noneString}
                                </StyledText>
                            )
                        }
                    </StyledColumn>
                </StyledRow>
            ) : undefined}
            <StyledRow>
                <StyledPreferencesWrapper isyes={student.preferences[user.id] > 0}>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString("UserPanelStudents__list__entry__preferred")}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        onClick={() => togglePref(user.id, student, setStudent, "preferred")}
                    >
                        {student.preferences[user.id] > 0 ? getString("YES") : getString("NO")}
                    </StyledText>
                </StyledPreferencesWrapper>
                <StyledPreferencesWrapper isyes={student.preferences[user.id] < 0}>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString("UserPanelStudents__list__entry__forbidden")}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        onClick={() => togglePref(user.id, student, setStudent, "forbidden")}
                    >
                        {student.preferences[user.id] < 0 ? getString("YES") : getString("NO")}
                    </StyledText>
                </StyledPreferencesWrapper>
            </StyledRow>
            {canEdit ? 
                <StyledRow>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            Email ucznia:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {student.email}
                        </StyledText>
                    </StyledColumn>
                    {
                        (student.parent) ? (
                            <StyledColumn>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Email rodzica:
                                </StyledText>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {student.parent.email ?? noneString}
                                </StyledText>
                            </StyledColumn>
                        ) : (undefined)
                    }
                </StyledRow>
            : null}
            {canEdit ? (
                <StyledEditHandler onClick={() => setIsEdit(true)}>
                    <PencilIcon />
                </StyledEditHandler>
            ) : undefined}
            {canEdit ? (
                <StyledEdirRole onClick={() => setIsRoleEdit(true)}>
                    <CloseIcon />
                </StyledEdirRole>
            ) : (undefined)}
        </>
    );

    const refsParent = {
        id: useRef(),
        firstname: useRef(),
        lastname: useRef(),
        email: useRef(),
        phone: useRef()
    };
    const [errorsParent, setErrorsParent] = useState(defaultValuesParent);

    const editRoleElement = (
        <>
            <StyledCloseForm onClick={() => setIsRoleEdit(false)}>
                <CloseIcon />
            </StyledCloseForm>
            <StyledSaveForm
                onClick={
                    () => {
                        const data = getFormData(refsParent, defaultValuesParent);
                        const id = parseInt(data.id);
                        if (!isNaN(id)) {
                            handleSubmitParent(
                                student.id,
                                parents.find(
                                    (parent) => (parent.id === id)
                                ),
                                setStudent,
                                () => {
                                    setIsAddParent(false);
                                    setIsRoleEdit(false);
                                }
                            );
                        } else {
                            handleSubmitNonParent(
                                student.id,
                                data,
                                setStudent,
                                () => {
                                    setIsAddParent(false);
                                    setIsRoleEdit(false);
                                }
                            );
                        }
                    }
                }
            >
                <AcceptIcon />
            </StyledSaveForm>
            {
                (student.parent) ? (
                    <>
                        <StyledRow>
                            <StyledColumn>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Imię:
                                </StyledText>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {student.parent.firstname ?? noneString}
                                </StyledText>
                            </StyledColumn>
                            <StyledColumn>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Nazwisko:
                                </StyledText>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {student.parent.lastname ?? noneString}
                                </StyledText>
                            </StyledColumn>
                        </StyledRow>
                        <StyledRow>
                            <StyledColumn>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Email:
                                </StyledText>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {student.parent.email ?? noneString}
                                </StyledText>
                            </StyledColumn>
                            <StyledColumn>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Telefon:
                                </StyledText>
                                {
                                    (student.parent.phone) ? (
                                        <StyledPhoneLink href={`tel:${student.parent.phone}`}>
                                            {student.parent.phone} <PhoneIcon />
                                        </StyledPhoneLink>
                                    ) : (
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.4em"
                                        >
                                            {noneString}
                                        </StyledText>
                                    )
                                }
                            </StyledColumn>
                        </StyledRow>
                    </>
                ) : (undefined)
            }
            <StyledRow>
                <StyledButtonsWrapper ismgtop center={true}>
                    {
                        (student.parent) ? (
                            <StyledButton
                                hasdeclaredfontweight="600"
                                hasdeclaredfontsize="22px"
                                hasdeclaredtextalign="center"
                                hasdeclaredbgcolor="#ee745c"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredborderradius="25px"
                                hasdeclaredcursor="pointer"
                                hasdeclaredmargin="20px 0 0 0"
                                onClick={
                                    () => handleDeleteParent(
                                        student.id,
                                        setStudent,
                                        () => setIsRoleEdit(false)
                                    )
                                }
                            >
                                USUŃ RODZICA
                            </StyledButton>
                        ) : (
                            <StyledButton
                                hasdeclaredfontweight="600"
                                hasdeclaredfontsize="22px"
                                hasdeclaredtextalign="center"
                                hasdeclaredbgcolor="#6786BE"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredborderradius="25px"
                                hasdeclaredcursor="pointer"
                                hasdeclaredmargin="20px 0 0 0"
                                onClick={() => setIsAddParent(true)}
                            >
                                DODAJ RODZICA
                            </StyledButton>
                        )
                    }
                </StyledButtonsWrapper>
            </StyledRow>
            {isAddParent ? (
                <>
                    <StyledSelectWrapper>
                        <StyledSelect type="select" ref={refsParent.id}>
                            <option value="">-- WYBIERZ RODZICA --</option>
                            {
                                parents.map(
                                    (parent) => (
                                        <option
                                            key={parent.id}
                                            value={parent.id}
                                        >
                                            {parent.lastname} {parent.firstname} ({parent.email})
                                        </option>
                                    )
                                )
                            }
                        </StyledSelect>
                    </StyledSelectWrapper>
                    <StyledEditElementInputWrapper errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Imię:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.firstname}
                                type="text"
                                placeholder="Imię rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.firstname}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Nazwisko:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.lastname}
                                type="text"
                                placeholder="Nazwisko rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.lastname}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    E-mail:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.email}
                                type="text"
                                placeholder="Adres e-mail rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.email}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Telefon:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.phone}
                                type="text"
                                placeholder="Numer telefonu rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.phone}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                </>
            ) : null}
        </>
    );

    return (
        <StyledUserListElement>
            {isRoleEdit ? editRoleElement : isEdit ? editElement : basicElement}
        </StyledUserListElement>
    );
};

export default UserPanelStudentElement;
