import React from "react";

import { StyledLogoLink } from "../../atoms/LogoLink/StyledLogoLink";
import logo from "../../../images/logoSVGEdit.svg";

const LogoLink = () => {
    return (
        <StyledLogoLink to="/">
            <img src={logo} alt="logo" />
        </StyledLogoLink>
    );
};

export default LogoLink;
