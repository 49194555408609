import styled from "styled-components"

export const StyledFlexBoxWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content:  ${({ calcwidth }) => calcwidth ? "flex-end" : "flex-start"};
    align-items: center;
    gap: 8px;
    
    > div {
        > div {
            width: 100%;
            min-width: 800px;
            display: flex;
            justify-content: space-between;
        }
    }
`