import styled from "styled-components";

export const StyledTournamentTimetableContentWrapper = styled.div`
    width: 100%;
    max-height: ${({ ismaxheight }) => ismaxheight ? "92%" : "80%"};
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 10px;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#6786be rgba(103, 134, 190, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(103, 134, 190, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }


    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 40px;
        padding-right: 0;
    }
`;
