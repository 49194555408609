import styled from "styled-components";

export const StyledTournamentCubes = styled.div`
    svg {
        width: ${({ svgsize }) => (svgsize ? svgsize : "220px")};
        height: ${({ svgsize }) => (svgsize ? svgsize : "220px")};
    }

    @media only screen and (min-width: 1591px) {
        svg {
            width: ${({ svgsize2 }) => (svgsize2 ? svgsize2 : "320px")};
            height: ${({ svgsize2 }) => (svgsize2 ? svgsize2 : "320px")};
        }
    }

    @media only screen and (max-width: 1018px) {
        display: none;
    }

    @media only screen and (max-height: 725px){
        svg {
            width: 190px;
            height: 190px;
        }
    }
`;
