import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledContactPage = styled(motion.section)`
    background-color: #6d85ba;
    width: 100%;
    height: 100vh;
    min-height: 740px;
    position: relative;
    overflow: hidden;
    -moz-overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 40px;

    @media only screen and (max-width: 1074px) {
        padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? "250px 0 0 0" : "60px 0 0 0")};
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        background-color: #f9ec4b;
        min-height: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? "70vh" : "100vh")};
        height: 100%;
        overflow: visible;

        > svg {
            display: none;
        }
    }

    @media only screen and (max-width: 767px){
        background-color: #f9ec4b;
    }

    @media only screen and (max-width: 601px) {
        padding: ${({ hasdeclaredpadding }) =>
            hasdeclaredpadding ? "250px 0 0 0" : "120px 0 0 0"};
    }
    
    @media only screen and (max-width: 504px) {
        padding: ${({ hasdeclaredpadding }) =>
            hasdeclaredpadding ? "250px 0 0 0" : "140px 0 0 0"};
    }
`;
