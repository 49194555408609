import styled from "styled-components";

export const StyledTournamentAdditionalInformationsContent = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px) {
        height: auto;
    }
`;
