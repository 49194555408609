import styled from "styled-components"

export const StyledFAQCategoryElement = styled.div`
    width: 100%;

    > button {
        border: none;
        
        &:first-child{
            padding: 0 20px;
            width: 100%;
            min-height: 50px;
            margin-top: 20px;
            border-radius: 25px;
            background-color: ${({ isopen }) => isopen ? "#602B26" : "#ee745c"};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 250ms linear;
            
            &:hover {
                background-color: #602B26;
            }
        }
    }
`