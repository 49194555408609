import styled from "styled-components";

export const StyledTournamentAdditionalData = styled.div`
    font-family: "Poppins";
    line-height: 1.4em;
    font-size: 18px;
    ol,
    ul {
        list-style-type: none;
    }
    iframe{
        width: 100%;
    }
`;
