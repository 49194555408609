import styled from "styled-components"

export const StyledInputEditWrapper = styled.div`
    width: 100%;
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;

    > input, select {
        width: 100%;
        height: 100%;
        font-size: 16px;
        border-radius: 25px;
        border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
        padding: 0 12px;
        font-family: "Poppins";
        outline: none;
        background: #fff;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: "Poppins";
            text-align: center;
        }
    }
`