import styled from "styled-components";
import Background from "../../../images/gallery/gallerybg.jpg";

import { motion } from "framer-motion";

export const StyledGalleryPage = styled(motion.section)`
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-item: center;
        background: #f9ec4b;
    }
`;
