import styled from "styled-components"

export const StyledHeading = styled.div`
    width: 100%;
    display: flex;
    align-items: center;    
    height: 43px;
    cursor: pointer;

    > p {
        width: calc(100% - 50px);
    }

    * {
        cursor: pointer;
        user-select: none;
    }

    @media only screen and (max-width: 425px){
        > p {
            font-size: 18px;
        }
    }
`