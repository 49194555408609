import React, { useEffect, useMemo, useCallback } from "react";
import { useToggle } from "react-use";
import { useQueryClient } from "@tanstack/react-query";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import Decimal from "decimal.js";
import { set } from "date-fns";

import PayHour from "./PayHour";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledPayElement } from "../../atoms/PayElement/StyledPayElement";
import { StyledHeading } from "../../atoms/PayElement/StyledHeading";
import { StyledHeadingCheckbox } from "../../atoms/SettlementsDoneElement/StyledHeadingCheckbox";
import { StyledArrow } from "../../atoms/Series/StyledArrow";
import { StyledPayHeaderElement } from "../../atoms/PayElement/StyledPayHeaderElement";
import { StyledPayHeader } from "../../atoms/PayElement/StyledPayHeader";
import { StyledScrollHeader } from "../../atoms/PayElement/StyledScrollHeader";
import { StyledDataSum } from "../../atoms/SettlementsDoneDataElement/StyledDataSum";
import { StyledDesc } from "../../atoms/SettlementsDoneDataElement/StyledDesc";
import { StyledPaddingWrapper } from "../../atoms/PayElement/StyledPaddingWrapper";
import { StyledButtonsWrapper } from "../../atoms/UserPanelParent/StyledButtonsWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledCustomFile } from "../../atoms/UserPanelReqElement/StyledCustomFile";

import { getString } from "../../../strings";
import downloadFile from "../../../utils/downloadFile";
import useScrollBar from "../../../logic/hooks/useScrollBar";
import { submitHoursRecord } from "../../../logic/requests/employees";
import SuccessMessage from "../../molecules/SuccessMessage";
import useSuccessMessage from "../../../logic/hooks/useSuccessMessage";

import { MAGIC_DATE } from "../../../constants/employee_pay";

const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0 });

const PayElement = ({
    keyValue,
    hours,
    user,
    isSubmitted,
    defaultOpen,
    additions,
    trigger: triggerParent,
    bonus,
    students,
    subjects,
    month,
    hasContract,
}) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar();

    const [isOpen, toggleIsOpen] = useToggle(defaultOpen ?? false);

    useEffect(() => triggerParent?.(), [triggerParent, isOpen]);
    useEffect(() => trigger?.(), [trigger, isOpen]);

    const queryClient = useQueryClient();
    const reloadHours = useCallback(
        () => void queryClient.invalidateQueries(["listPay", user.id]),
        [queryClient, user]
    );

    const downloadRecords = useCallback(
        () => downloadFile(`/api/students/get-hours-template/${month}`),
        [month]
    );

    const totalHoursAmount = useMemo(
        () => (
            hours.reduce(
                (prev, cur) => (
                    prev
                    .plus(
                        (cur.half) ? ("0.5") : (cur.hours)
                    )
                ), new Decimal(0)
            )
        ), [hours]
    );

    const totalAdditionsPay = useMemo(
        () => (
            (additions) ? (
                additions.reduce(
                    (prev, cur) => (prev.plus(cur.amount)),
                    new Decimal(0)
                )
            ) : (undefined)
        ), [additions]
    );

    const total = useMemo(
        () => {
            const ret = hours.reduce(
                (prev, cur) => (prev.plus(cur.price)),
                new Decimal(0)
            )
            .plus(bonus ?? 0)
            .plus(totalAdditionsPay ?? 0);

            return (
                (ret.dp() > 0) ? (
                    ret.toFixed(2)
                ) : (
                    ret.toString()
                )
            );
        }, [hours, bonus, totalAdditionsPay]
    );

    const { isShowing, show } = useSuccessMessage();
    const onSuccess = useCallback(
        (e, res) => {
            show([]);
            reloadHours();
        },
        [show, reloadHours]
    );
    const handleSubmit = useCallback(
        (e) => {
            const file = e?.target?.files?.[0];
            if (!file) {
                console.warn("handleSubmit", "called with no file", e);
                return;
            }

            const data = new FormData();
            data.append("content", file);
            data.append("month", month);

            submitHoursRecord(data)
                .then(
                    (res) => {
                        console.log("handleSubmit", res);
                        onSuccess?.(e, res);
                    }
                )
                .catch(
                    (err) => {
                        console.error("handleSubmit", err);
                    }
                )
            ;
        },
        [month, onSuccess]
    );

    const isRedBackground = useMemo(
        () => {
            const monthDate = new Date(month);

            return (
                // nie liczymy osób bez umowy
                hasContract &&
                // nie liczymy braku ewidencji z czasów, gdy nie była wymagana
                monthDate.getTime() >= MAGIC_DATE.getTime() &&
                // miesiąc już się zakończył
                monthDate.getTime() < currentMonth.getTime() &&
                // nie przesłano ewidencji za miesiąc
                !isSubmitted
            );
        },
        [isSubmitted, month]
    );

    return (
        <StyledPayElement isopen={isOpen} hasdeclaredbordercolor={isRedBackground}>
            <StyledHeading onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor={isRedBackground ? "#EE745C" : "#6786BE"}
                >
                    {keyValue}
                </StyledText>
                <StyledHeadingCheckbox hasdeclaredbgcolor={isRedBackground}>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <ScrollSync>
                    <StyledPaddingWrapper>
                        {hours.length ? (
                        <StyledPayHeader>
                            <ScrollSyncPane>
                                <StyledScrollHeader isscrollbar={isScrollBar} ref={scrollElement}>
                                    <div>
                                        <StyledPayHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("Trainings_trainings_pay_element_uczen")}
                                            </StyledText>
                                        </StyledPayHeaderElement>
                                        <StyledPayHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("Trainings_trainings_pay_element_data")}
                                            </StyledText>
                                        </StyledPayHeaderElement>
                                        <StyledPayHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("Trainings_trainings_pay_element_godzin")}
                                            </StyledText>
                                        </StyledPayHeaderElement>
                                        <StyledPayHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("Trainings_trainings_pay_element_przedmiot")}
                                            </StyledText>
                                        </StyledPayHeaderElement>
                                        <StyledPayHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString("Trainings_trainings_pay_element_kwota")}
                                            </StyledText>
                                        </StyledPayHeaderElement>
                                    </div>
                                </StyledScrollHeader>
                            </ScrollSyncPane>
                        </StyledPayHeader>
                        ) : (null)}
                        {hours.map((hour) => (
                            <PayHour
                                key={hour.id}
                                hour={hour}
                                students={students}
                                subjects={subjects}
                                reloadHours={reloadHours}
                            />
                        ))}
                        {
                            (additions) ? (
                                additions.map(
                                    (addition) => (
                                        <StyledDesc key={addition.id}>
                                            <div>
                                                <StyledText
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredlineheight="1.4em"
                                                    hasdeclaredfontcolor="#6786BE"
                                                >
                                                    {getString("Trainings_trainings_pay_element_dodatek")}:
                                                </StyledText>
                                                <StyledText
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {addition.amount} PLN
                                                </StyledText>
                                            </div>
                                            <div>
                                                <StyledText
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredlineheight="1.4em"
                                                    hasdeclaredfontcolor="#6786BE"
                                                >
                                                    {getString("Trainings_trainings_pay_element_opis")}:
                                                </StyledText>
                                                <StyledText
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {addition.description}
                                                </StyledText>
                                            </div>
                                        </StyledDesc>
                                    )
                                )
                            ) : (undefined)
                        }
                        {
                            (!hasContract) ? (null) : (
                                <>
                                <StyledButtonsWrapper bottomsize>
                                    <StyledButton
                                        hasdeclaredbgcolor="#6786BE"
                                        hasdeclaredpadding="0 28px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredtexttransform="uppercase"
                                        onClick={downloadRecords}
                                    >
                                        {getString("User_panel_pay_element_pobierz_ewidencje")}
                                    </StyledButton>
                                    <StyledCustomFile
                                        $hasDeclaredWidth="50%"
                                        $hasDeclaredHeight="50px"
                                        file={isSubmitted}
                                    >
                                        <label htmlFor="upload">
                                            {isSubmitted
                                                ? getString(
                                                    "User_panel_pay_element_przeslij_ponownie"
                                                    )
                                                : getString("User_panel_pay_element_przeslij_ewidencje")}
                                        </label>
                                        <input
                                            id="upload"
                                            type="file"
                                            onChange={handleSubmit}
                                        />
                                    </StyledCustomFile>
                                </StyledButtonsWrapper>
                                {
                                    (isShowing) ? (
                                        <SuccessMessage
                                            title={getString("UserPanelTrainerPay__hours_record_submit__success__title")}
                                            infos={[]}
                                        />
                                    ) : (null)
                                }
                                </>
                            )
                        }
                        <StyledDataSum>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString("Trainings_trainings_pay_element_lacznaKwotaWynagrodzenia")}: <span style={{ color: "#000" }}>{total} PLN</span>
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString("Trainings_trainings_pay_element_lacznaLiczbaGodzin")}: <span style={{ color: "#000" }}>{totalHoursAmount.toString()}</span>
                                </StyledText>
                            </div>
                            {
                                (bonus) ? (
                                    <div>
                                        <StyledText
                                            hasdeclaredfontsize="18px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.4em"
                                            hasdeclaredfontcolor="#6786BE"
                                        >
                                            {getString("Trainings_trainings_pay_element_premia")}: <span style={{ color: "#000" }}>{bonus} PLN</span>
                                        </StyledText>
                                    </div>
                                ) : (undefined)
                            }
                            {
                                (totalAdditionsPay && !totalAdditionsPay.eq(0)) ? (
                                    <div>
                                        <StyledText
                                            hasdeclaredfontsize="18px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.4em"
                                            hasdeclaredfontcolor="#6786BE"
                                        >
                                            {getString("Trainings_trainings_pay_element_dodatki")}: <span style={{ color: "#000" }}>{totalAdditionsPay.dp() > 0 ? totalAdditionsPay.toFixed(2) : totalAdditionsPay.toString()} PLN</span>
                                        </StyledText>
                                    </div>
                                ) : (undefined)
                            }
                        </StyledDataSum>
                    </StyledPaddingWrapper>
                </ScrollSync>
            ) : null}
        </StyledPayElement>
    );
};

export default PayElement;
