import styled from "styled-components"

export const StyledScrollContainer = styled.div`
    width: 340px;
    max-height: 100%;
    overflow-y: scroll;

    scrollbar-color: ${({ isscrollbar }) => isscrollbar ? "#ee745c rgba(238,116,92, 0.3)" : "transparent transparent"};
    scrollbar-width: ${({ isscrollbar }) => isscrollbar ? "thin" : "none"};
    
    ::-webkit-scrollbar {
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) => isscrollbar ? "rgba(238,116,92, 0.3)" : "transparent"};
    }
    ::-webkit-scrollbar-thumb {
        background: #ee745c;
        border-radius: 6px;
    }

    @media only screen and (max-width: 1196px){
        width: 360px;
        padding-left: 20px;
    }

    @media only screen and (max-width: 767px){
        overflow-y: visible;
        max-height: unset;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }
`