import React from "react";
import { motion } from "framer-motion";

import { getString, getLang } from "../../../strings";

import { StyledCard } from "../../molecules/Home/StyledCard";
import { StyledCardBackgroundImage } from "../../atoms/Home/StyledCardBackgroundImage";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledTournamentSvg } from "../../atoms/Home/StyledTournamentSvg";
import { StyledTournamentLink } from "../../atoms/Home/StyledTournamentLink";

import tournaments from "../../../images/home/tournaments1.png";
import mobileTournaments from "../../../images/mobile/zawodyMobile.jpg";
import useWindowSize from "../../../utils/getWindowSize";

const HomePageCardTournaments = () => {
    const width = useWindowSize();
    return (
        <StyledTournamentLink isang={getLang() === "gb" ? true : false} to="/competitions">
            <StyledCard scaleSvg isang={getLang() === "gb" ? true : false}>
                <StyledCardBackgroundImage src={getLang() === "gb" && width < 1036 ? tournaments : width > 767 ? tournaments : mobileTournaments} alt="tournaments" />
                <motion.div
                    initial={{ rotate: -45 }}
                    animate={{ rotate: 0 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                >
                    <StyledTournamentSvg isang={getLang() === "gb" ? true : false}/>
                </motion.div>
                <StyledText
                    hasdeclaredfontsize="72px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hascursor="pointer"
                    hasdeclaredpadding="24px 0 0 0"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                    as="h2"
                >
                    {getString("home_homePageCardTournaments_zawody")}
                </StyledText>
            </StyledCard>
        </StyledTournamentLink>
    );
};

export default HomePageCardTournaments;
