import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import fuzzysort from "fuzzysort";

import Loader from "../Loader/Loader";

import UserPanelStudentElement from "./UserPanelStudentElement";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledUserPanelStudents } from "../../atoms/UserPanelStudens/StyledUserPanelStudents";
import { StyledUsersSearch } from "../../atoms/UserPanelStudens/StyledUsersSearch";
import { StyledScrollWrapper } from "../../atoms/UserPanelStudens/StyledScrollWrapper";
import { StyledUserPanelStudentsLoaderWrapper } from "../../atoms/UserPanel/StyledUserPanelStudentsLoaderWrapper";

import { getString } from "../../../strings";
import { listParents, listStudents } from "../../../logic/requests/students.js";
import { cebulaCompare } from "../../../logic/arrays.js";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const UserPanelStudents = ({ user }) => {
	const [students, setStudents] = useState();
	const [parents, setParents] = useState();
	const [err, setErr] = useState(false);
	const { scrollElement, isScrollBar } = useScrollBar();
    const isAdmin = useMemo(
        () => user.roles.includes("admin"), [user]
    );
	const canEdit = useMemo(
        () => (
            (isAdmin) || (
                user.roles.some(
                    (role) => (
                        [
                            "trainer_new",
                            "manager"
                        ].includes(role)
                    )
                )
            )
        ), [user, isAdmin]
    );

	const navigate = useNavigate();

	useEffect(
		() => {
			if (!(user && user.roles.includes("trainer")))
				navigate("/my-account", { replace: true });
			listStudents()
				.then(
					(res) => {
						console.log("listStudents", res);
						setStudents(
                            res
                            .sort(
                                (a, b) => {
                                    let ret;

                                    ret = cebulaCompare(a.lastname, b.lastname);

                                    if (!ret)
                                        ret = cebulaCompare(a.firstname, b.firstname);

                                    return ret;
                                }
                            )
                        );
					}
				)
				.catch(
					(err) => {
						console.log("listStudents", err);
						setStudents([]);
						setErr(true);
					}
				)
			;
            if (canEdit) {
                listParents()
                .then(
					(res) => {
						console.log("listParents", res);
						setParents(
                            res
                            .sort(
                                (a, b) => {
                                    let ret;

                                    ret = cebulaCompare(a.lastname, b.lastname);

                                    if (!ret)
                                        ret = cebulaCompare(a.firstname, b.firstname);

                                    return ret;
                                }
                            )
                        );
					}
				)
				.catch(
					(err) => {
						console.log("listParents", err);
						setParents([]);
						setErr(true);
					}
				)
			;
            }
		},
		[]
	);

    const [search, setSearch] = useState();

	return (
        <StyledUserPanelStudents>
            {!students ? (
                <StyledUserPanelStudentsLoaderWrapper>
                    <Loader />
                </StyledUserPanelStudentsLoaderWrapper>
            ) : !students.length ? (
                <StyledText
                    hasdeclaredpadding="0px 20px"
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.4em"
                    as="p"
                >
                    {getString(err ? "blad_wczytywania" : "UserPanelStudents__no_students")}
                </StyledText>
            ) : (
                <>
                    <StyledUsersSearch>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString("UserPanelStudents__list__header__title")}:
                        </StyledText>
                        <input
                            type="text"
                            placeholder={getString(
                                "UserPanelStudents__list__header__search_bar_placeholder"
                            )}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </StyledUsersSearch>
                    <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement} isadmin={isAdmin}>
                        {fuzzysort.go(search, students, { all: true, keys: [ "lastname", "firstname" ] })
                        .map(ret => ret.obj)
                        .map((student) => (
                            <UserPanelStudentElement
                                key={student.id}
                                user={user}
                                student={student}
                                setStudent={
                                    (val) => (
                                        setStudents([
                                            ...students.slice(0, students.indexOf(student)),
                                            typeof val === "function" ? val(student) : val,
                                            ...students.slice(students.indexOf(student)+1)
                                        ])
                                    )
                                }
                                canEdit={canEdit}
                                isAdmin={isAdmin}
                                parents={parents}
                            />
                        ))}
                    </StyledScrollWrapper>
                </>
            )}
        </StyledUserPanelStudents>
    );
};

export default UserPanelStudents;
