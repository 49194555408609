import styled from "styled-components";

export const StyledTournamentsActionButtons = styled.div`
    padding: 40px 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
`;
