import styled from "styled-components"

export const StyledEditElementInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    > h4 {
        padding-left: ${({ errorpdleft }) => errorpdleft ? "0" : "150px"};
    }

    > div {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 50px;
        justify-content: space-between;

        > label {
            width: 200px;

            @media only screen and (max-width: 528px){
                width: 150px;
                p {
                    font-size: 16px;
                }
            }
        }

        > input {
            width: 70%;
            height: 40px;
            border-radius: 25px;
            font-size: 16px;
            border: 3px solid ${({ error }) => (error ? "#F74017" : "#6786BE")};
            padding: 0 12px;
            font-family: "Poppins";
            outline: none;
            transition: outline 250ms;
            background: #fff;
        }

        @media only screen and (max-width: 422px){
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            > label {
                min-width: unset;
                width: 100%;
            }

            > input{
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 528px){
        h4 {
            padding-left: ${({ errorpdleft }) => errorpdleft ? "0" : "100px"};
        }
    }

    @media only screen and (max-width: 422px){
        h4{
            padding-left: 0;
        }
    }
`