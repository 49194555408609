import styled from "styled-components"

export const StyledUsersSearch = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0;

    input {
        height: 50px;
        width: 50%;
        border-radius: 25px;
        border: 4px solid #6786be;
        padding-left: 18px;
        font-size: 22px;

        &:placeholder {
            color: #bababa;
        }
    }

    @media only screen and (max-width: 1375px){
        flex-direction: column;

        > h2 {
            padding: 0 0 5px 0;
        }

        input {
            width: 80%;
        }
    }

    @media only screen and (max-width: 926px){
        margin-top: 40px;
    }
`;