import styled from "styled-components";

export const StyledCubeIcons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
