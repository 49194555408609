import styled from "styled-components"

export const StyledPaymentsPopup = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    min-height: 250px;
    background-color: #fff;
    border-radius: 25px;
    border: 8px solid #6786be;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    z-index: 1000;
    padding-bottom: 10px;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);

    @media only screen and (max-width: 926px){
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 520px){
        width: 98%;
    }
`