import backendRequest from "./index.js";

const setupEventPayment = (eventId) => (
	backendRequest("POST", `/payments/setup-payment/event/${eventId}`)
);

const hasPaidForEvent = (eventId) => (
	backendRequest("POST", `/payments/has-paid-for-event/${eventId}`)
);

const hasPaidForAnalysis = (id) => (
	backendRequest("POST", `/payments/has-paid-for-analysis/${id}`)
);

const transactionStatus = (id) => (
	backendRequest("GET", `/payments/transaction-status/${id}`)
);

export {
	setupEventPayment,
	hasPaidForEvent,
	hasPaidForAnalysis,
	transactionStatus
};
